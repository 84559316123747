import { RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React from "react";
import { useSearchPurchasePayments } from "../../../../../../api/purchase_payment";
import { MoneyText } from "../../../../../MoneyText";
import { buildTabComponent } from "../../../../../TabbedTable";

export const PaymentsTab = buildTabComponent({
  label: "Purchase Payments",
  columns: [
    {
      label: "Paid at",
      orderOptions: { orderKey: "paid_at" },
      render: (purchasePayment) =>
        purchasePayment.paid_at
          ? DateTime.fromISO(purchasePayment.paid_at, { zone: "utc" }).toFormat("LLL dd, yyyy")
          : "-",
    },
    {
      label: "Record",
      orderOptions: { orderKey: "vendor_name" },
      render: (purchasePayment) =>
        purchasePayment.purchase_id ? (
          <RecordLink
            type="Purchase"
            maxWidth="15ch"
            identifier={purchasePayment.purchase_id || ""}
            link={`/services/purchasing/${purchasePayment.purchase_id}`}
          />
        ) : (
          <RecordLink
            type="Contract"
            maxWidth="15ch"
            identifier={purchasePayment.contract_id || ""}
            link={`/services/contracts/${purchasePayment.contract_id}`}
          />
        ),
    },
    {
      label: "Vendor",
      orderOptions: { orderKey: "vendor_name" },
      render: (purchasePayment) => (
        <RecordLink
          type=""
          maxWidth="15ch"
          identifier={purchasePayment.vendor_name}
          link={`/services/vendors/${purchasePayment.vendor_id}`}
        />
      ),
    },
    {
      label: "Cost",
      orderOptions: { orderKey: "amount_cents" },
      render: (purchasePayment) => (
        <MoneyText money={purchasePayment.amount} formatOptions={{ compact: "never" }} />
      ),
    },
  ],
  query: useSearchPurchasePayments,
});
