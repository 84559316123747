import React from "react";
import {
  Badge,
  Heading,
  VStack,
  LinkBox,
  LinkOverlay,
  useColorModeValue,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tag,
  HStack,
  Input,
  Icon,
  InputGroup,
  InputLeftElement,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { BsLayoutTextWindowReverse } from "react-icons/bs";

interface PopoverListProps {
  items: { id: number; name: string; url: string; status?: string; is_archived?: boolean }[];
  selectedItemId: number;
  debouncedSearch: (value: string) => void;
  title: string;
}

const statusMap = {
  new: { label: "New", value: "new", color: "purple" },
  draft: { label: "Draft", value: "draft", color: "gray" },
  active: { label: "Active", value: "active", color: "green" },
  declined: { label: "Declined", value: "declined", color: "red" },
  in_progress: { label: "In Progress", value: "in_progress", color: "orange" },
  review: { label: "Review", value: "review", color: "teal" },
  complete: { label: "Complete", value: "complete", color: "green" },
  abandoned: { label: "Abandoned", value: "abandoned", color: "red" },
  unavailable: { label: "Unavailable", value: "unavailable", color: "red" },
  in_stock: { label: "In Stock", value: "in_stock", color: "green" },
  low_stock: { label: "Low Stock", value: "low_stock", color: "orange" },
  out_of_stock: { label: "Out of Stock", value: "out_of_stock", color: "red" },
  archived: { label: "Archived", value: "archived", color: "red" },
  assigned: { label: "Assigned", value: "assigned", color: "green" },
  not_assigned: { label: "Not Assigned", value: "not_assigned", color: "orange" },
  unfulfilled: { label: "Unfulfilled", value: "unfulfilled", color: "red" },
};

export const popoverStatusMap = (status: string) => {
  return statusMap[status] || { label: status, value: status, color: "gray" };
};

export const PopoverList: React.FC<PopoverListProps> = ({
  items,
  selectedItemId,
  debouncedSearch,
  title,
}) => {
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const selectedBg = useColorModeValue("gray.100", "gray.600");

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton icon={<BsLayoutTextWindowReverse />} colorScheme="teal" aria-label={title} />
      </PopoverTrigger>
      <PopoverContent p={4} zIndex={9999}>
        <PopoverCloseButton />
        <PopoverBody>
          <VStack width="100%" align="start" spacing={4} py={2}>
            <HStack w="100%" justify="space-between" align="center">
              <Heading width={"100%"} size="md" fontWeight="semibold">
                {title}
              </Heading>
            </HStack>
            <HStack w="100%" justify="space-between" align="center">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.300" />}
                />
                <Input
                  placeholder="Search..."
                  onChange={(e) => debouncedSearch(e.target.value)}
                  background={useColorModeValue("white", "gray.700")}
                />
              </InputGroup>
            </HStack>
            <VStack width="100%" align="start" height="25vh" overflow={"auto"}>
              {items.map((item) => (
                <LinkBox key={item.id} width="100%" _hover={{ bg: hoverBg, borderRadius: "md" }}>
                  <LinkOverlay as={RouterLink} to={item.url}>
                    <VStack
                      align="start"
                      p={4}
                      borderRadius={item.id === selectedItemId ? "md" : ""}
                      borderBottom={item.id === selectedItemId ? "none" : "1px"}
                      borderColor="chakra-border-color"
                      bg={item.id === selectedItemId ? selectedBg : ""}>
                      <Heading size="sm" key={item.id}>
                        {item.name}
                      </Heading>
                      {item.status && (
                        <Tag
                          variant="subtle"
                          colorScheme={popoverStatusMap(item.status).color}
                          mt={1}>
                          {popoverStatusMap(item.status).label}
                        </Tag>
                      )}
                    </VStack>
                  </LinkOverlay>
                </LinkBox>
              ))}
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
