import React, { useState } from "react";
import { Flex, Grid, Heading, Skeleton, useColorModeValue } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { ServiceItem } from "../Teams/Team/components/TeamServices";
import { TeamServiceRequestModal } from "./components/TeamServiceModal";
import { ServicePartialData } from "../../api/services";
import { QuickLinkCard } from "./components/QuickLinkCard";
import { FiShoppingCart } from "react-icons/fi";
import { NewPurchaseModal } from "../Purchasing/Purchase/components/NewPurchaseModal";
import { NewReimbursementModal } from "../Purchasing/Reimbursements/components/NewReimbursementModal";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { EmptyState } from "@sciencecorp/helix-components";

export const AllServicesTab = ({ data, isLoading }) => {
  const {
    isOpen: isOpenNewRequest,
    onOpen: onOpenNewRequest,
    onClose: onCloseNewRequest,
  } = useDisclosure();

  const {
    isOpen: isOpenNewPurchase,
    onOpen: onOpenNewPurchase,
    onClose: onCloseNewPurchase,
  } = useDisclosure();

  const {
    isOpen: isOpenNewReimbursement,
    onOpen: onOpenNewReimbursement,
    onClose: onCloseNewReimbursement,
  } = useDisclosure();

  const [activeService, setActiveService] = useState<ServicePartialData | null>(null);

  const getCardBackground = (colorLight: string, colorDark: string) =>
    useColorModeValue(colorLight, colorDark);

  return (
    <>
      <Heading as="h2" size="md" mb={4}>
        Quick Links
      </Heading>
      <Flex gap={5} mb={6} direction={["column", "column", "column", "row"]} alignItems="stretch">
        <QuickLinkCard
          icon={<FiShoppingCart />}
          iconbg={getCardBackground("cyan.500", "cyan.700")}
          title="Buy Something"
          description="If you need something to be purchased, please itemize your purchase and link it to a specific spending authority."
          onClick={onOpenNewPurchase}
        />
        <QuickLinkCard
          icon={<FaRegMoneyBillAlt size={18} />}
          iconbg={getCardBackground("blue.600", "blue.300")}
          title={"Reimbursement Request"}
          description="If you need a reimbursement, please itemize your request with the corresponding receipts and select the an appropriate spending authority."
          onClick={onOpenNewReimbursement}
        />
      </Flex>
      <Heading as="h2" size="md" mb={4}>
        All Services
      </Heading>
      <Skeleton isLoaded={!isLoading}>
        <Grid
          templateColumns="repeat(auto-fit, minmax(var(--chakra-space-72), 1fr))"
          gap={4}
          width="100%">
          {data?.results.length > 0 ? (
            data?.results.map((service) => (
              <ServiceItem
                key={service.id}
                service={service}
                onServiceOpen={onOpenNewRequest}
                setRequestModal={setActiveService}
                location="services"
                archived={false}
              />
            ))
          ) : (
            <EmptyState title="No services available" />
          )}
          {activeService && (
            <TeamServiceRequestModal
              service={activeService}
              isOpen={isOpenNewRequest}
              onClose={onCloseNewRequest}
              setActiveService={setActiveService}
            />
          )}
          <NewPurchaseModal isOpen={isOpenNewPurchase} onClose={onCloseNewPurchase} />
          <NewReimbursementModal
            isOpen={isOpenNewReimbursement}
            onClose={onCloseNewReimbursement}
          />
        </Grid>
      </Skeleton>
    </>
  );
};
