import React, { ReactElement, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  MenuButton,
  Menu,
  MenuList,
  IconButton,
  MenuItem,
  Stack,
} from "@chakra-ui/react";
import { SplitPage, Header, ConfirmationButton } from "@sciencecorp/helix-components";
import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import { FiArchive } from "react-icons/fi";
import { useGetCredential, useUpdateCredential, useSearchCredentials } from "../../api/credentials";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { NewCredentialModal } from "./components/NewCredentialModal";
import { UserCredentialTable } from "./components/UserCredentialTable";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { credentialBadge, SidebarList, TrainingLink } from "./util";
import { useNavigate, useParams } from "react-router";
import { CiUndo } from "react-icons/ci";
import { PopoverList } from "../shared/PopoverList";
import { BsThreeDotsVertical } from "react-icons/bs";
export const CredentialPage = () => {
  const { id } = useParams();
  if (!id) return null;

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: credential } = useGetCredential(+id);
  const { mutate: updateCredential } = useUpdateCredential();
  const { data: credentialsSearch } = useSearchCredentials({
    term: search || "*",
    pagination: { per_page: 10 },
  });
  const currentUser = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUser, "credentials_admin");
  const navigate = useNavigate();

  const {
    isOpen: isOpenNewCredential,
    onOpen: onOpenNewCredential,
    onClose: onCloseNewCredential,
  } = useDisclosure();

  const actions: ReactElement[] = isCredentialsAdmin
    ? ([
        credential?.is_draft && !credential?.is_archived && (
          <ConfirmationButton
            key="publish"
            label="Publish"
            variant="Button"
            rightIcon={<CheckIcon />}
            buttonVariant="outline"
            confirmationButtonLabel="Confirm"
            colorScheme="teal"
            children="Publishing this credential will make it active and visible to all employees. Are you sure you want to proceed?"
            confirmationHeader="Publish Credential"
            onConfirm={() => updateCredential({ id: +id, is_draft: false })}
          />
        ),
        <PopoverList
          key="popover"
          items={
            credentialsSearch?.results
              .filter((item) => !item.is_archived)
              .map((item) => ({
                id: item.id,
                name: item.name,
                url: `/credentials/${item.id}`,
                status: item.is_draft ? "draft" : "active",
              })) || []
          }
          selectedItemId={Number(id)}
          debouncedSearch={debouncedSearch}
          title="Other Credentials"
        />,
        <Menu key="menu">
          <MenuButton
            as={IconButton}
            icon={<BsThreeDotsVertical />}
            size="md"
            aria-label="More actions"
          />
          <MenuList>
            {!credential?.is_archived && (
              <MenuItem onClick={onOpenNewCredential} gap={3} fontWeight="medium">
                <EditIcon />
                Edit Credential
              </MenuItem>
            )}
            {!credential?.is_archived ? (
              <MenuItem
                gap={3}
                fontWeight="medium"
                color="red.500"
                onClick={() => updateCredential({ id: +id, is_archived: true })}>
                <FiArchive />
                Archive Credential
              </MenuItem>
            ) : (
              <MenuItem
                gap={3}
                color="teal"
                fontWeight="medium"
                onClick={() => updateCredential({ id: +id, is_archived: false })}>
                <CiUndo />
                Restore Credential
              </MenuItem>
            )}
          </MenuList>
        </Menu>,
      ].filter(Boolean) as ReactElement[])
    : [];

  return isCredentialsAdmin ? (
    <>
      {credential?.has_training && !credential?.trainings.length && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <VStack align="start" spacing={0}>
            <AlertTitle>Training Required</AlertTitle>
            <AlertDescription>
              This credential requires training, but no training has been linked to it yet
            </AlertDescription>
          </VStack>
        </Alert>
      )}
      {credential?.is_archived && (
        <Alert status="warning" width="100%" mb={4}>
          <AlertIcon />
          <VStack align="start" spacing={0}>
            <AlertTitle>Archived</AlertTitle>
            <AlertDescription>This credential is in the credential archive.</AlertDescription>
          </VStack>
        </Alert>
      )}

      <VStack width="100%" align="center" spacing={4}>
        {credential && (
          <Flex direction="column" width="100%" pb={0}>
            <Header
              title={credential.name}
              badge={credentialBadge(credential)}
              actions={actions}
              crumbsColor="teal"
              crumbs={[{ url: `/credentials/all`, label: "Credentials" }]}
            />
          </Flex>
        )}
        <HStack justify="space-between" width="100%" mb={2}>
          <HStack spacing={6}>
            <Text fontSize="sm">
              Expires:{" "}
              <Box as="span" fontWeight="medium">
                {credential?.expiration_months ? `${credential?.expiration_months} Months` : "N/A"}
              </Box>
            </Text>

            <Text fontSize="sm">
              Visibility:{" "}
              <Box as="span" fontWeight="medium">
                {credential?.is_public ? "Public" : "Private"}
              </Box>
            </Text>
          </HStack>
        </HStack>
        {credential?.trainings && credential?.trainings.length > 0 && (
          <TrainingLink trainings={credential?.trainings} />
        )}
        {isCredentialsAdmin && credential && <UserCredentialTable credential={credential} />}
      </VStack>

      {credential && (
        <NewCredentialModal
          isOpen={isOpenNewCredential}
          onClose={onCloseNewCredential}
          credential={credential}
        />
      )}
    </>
  ) : (
    <Heading>Not Authorized</Heading>
  );
};
