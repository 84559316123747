import React from "react";
import { Heading, Badge, Flex, HStack, Avatar, Link, Text, Stack, Box } from "@chakra-ui/react";
import { Collection, EmptyState, Searchbar, useCollection } from "@sciencecorp/helix-components";
import { TimelineEventData, useSearchTimelineEvents } from "../../../../api/timeline_events";

import { Link as RouterLink } from "react-router-dom";
import { findPathByLocationId } from "../../../shared/TreeSelect";
import { useTreeSelectInventoryLocations } from "../../../../api/inventory_location";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
export const CheckoutHistory = ({ inventory }) => {
  const { pagination, onPagination, order, onOrder } = useCollection();
  const { data: locations } = useTreeSelectInventoryLocations();

  const { search, debouncedSearch } = useDebouncedSearch();

  const { data, isLoading } = useSearchTimelineEvents({
    term: search || "*",
    filters: {
      timelineable_type: "Inventory",
      timelineable_id: inventory.id,
      event_type: ["checkout_used", "checkout_spending_authority", "checkout_waste"],
    },
    pagination,
    order,
  });

  const columns = [
    {
      label: "Checked Out By",
      render: (timelineEvent: TimelineEventData) => (
        <Link as={RouterLink} to={`/users/${timelineEvent.user?.id}`}>
          <HStack>
            <Avatar
              size="sm"
              src={timelineEvent.user?.picture_uri}
              name={timelineEvent.user?.name}
            />
            <Text>{timelineEvent.user?.name}</Text>
          </HStack>
        </Link>
      ),
    },
    {
      label: "Date",
      render: (timelineEvent: TimelineEventData) => (
        <Text>
          {timelineEvent.created_at.toFormat("LLL dd yyyy")} at{" "}
          {timelineEvent.created_at.toFormat("hh:mm a")}
        </Text>
      ),
    },
    {
      label: "Created At",
      render: (event: TimelineEventData) => event.created_at.toFormat("LLL dd yyyy"),
    },
    {
      label: "Quantity",
      render: (event: TimelineEventData) => (
        <Text>
          {event.event_data?.quantity_used || 0} {event.event_data?.unit_of_measurement || "units"}
        </Text>
      ),
    },
    {
      label: "Location",
      render: (event: TimelineEventData) => {
        const path = findPathByLocationId(locations || [], event.event_data.location.id).slice(1);
        return (
          <Stack direction="row" flexWrap="wrap">
            {path?.map((location, idx) => {
              const isLast = idx === path.length - 1;
              return (
                <Box key={`path-${location.label}`}>
                  <HStack fontSize="sm">
                    <Text
                      fontWeight={isLast ? "medium" : "normal"}
                      color={isLast ? "teal.500" : "auto"}
                      overflowWrap="break-word"
                      whiteSpace="nowrap">
                      {location.label}
                    </Text>
                    {!isLast && <ArrowForwardIcon />}
                  </HStack>
                </Box>
              );
            })}
          </Stack>
        );
      },
    },
  ];
  return (
    <Flex
      direction="column"
      w="100%"
      p={6}
      gap={4}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md">
      <Stack direction={{ base: "column", md: "row" }} justify="space-between">
        <HStack>
          <Heading size="md">Checkout History</Heading>
          <Badge colorScheme="teal">Inventory Manager</Badge>
        </HStack>
        <Box>
          <Searchbar onChange={(query) => debouncedSearch(query)} onSelect={() => {}} />
        </Box>
      </Stack>
      {isLoading || (data?.results.length || 0) > 0 ? (
        <Collection
          columns={columns}
          items={data?.results || []}
          isLoading={isLoading}
          pagination={data?.pagination || pagination}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
        />
      ) : (
        <EmptyState title="No checkout history" size="xs" />
      )}
    </Flex>
  );
};
