import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  Box,
  HStack,
  Stack,
  ModalCloseButton,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  Button,
  useToast,
  Divider,
  ModalOverlay,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { findPathByLocationId, TreeSelect, TreeSelectOption } from "../../shared/TreeSelect";
import { ArrowForwardIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import {
  useTreeSelectInventoryLocations,
  LocationId,
  LocationKeyId,
  LocationTreeValue,
} from "../../../api/inventory_location";
import {
  CapitalEquipmentData,
  CapitalEquipmentShowData,
  useBulkMoveCapitalEquipment,
} from "../../../api/capital_equipment";

export type MoveEquipmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedEquipment: CapitalEquipmentShowData[];
  setSelectedEquipment: (equipment: CapitalEquipmentShowData[]) => void;
};

export const MoveEquipmentModal = ({
  isOpen,
  onClose,
  selectedEquipment,
  setSelectedEquipment,
}: MoveEquipmentModalProps) => {
  const { control, reset, watch } = useForm();
  const [locationSearch, setLocationSearch] = useState<string | undefined>();
  const [pathToLocation, setPathToLocation] = useState<LocationKeyId[] | undefined>();
  const treeSelectInventoryLocationsQuery = useTreeSelectInventoryLocations(locationSearch);
  const { mutateAsync: bulkMoveCapitalEquipment, isLoading } = useBulkMoveCapitalEquipment();
  const formValue = watch();
  const [showPreview, setShowPreview] = useState(false);
  const [modalPage, setModalPage] = useState("main");
  const [selectedPath, setSelectedPath] = useState<TreeSelectOption<LocationTreeValue>[] | null>(
    null
  );
  const toast = useToast();
  useEffect(() => {
    const locationId = formValue.location;
    if (locationId !== null) {
      const path = findPathByLocationId(treeSelectInventoryLocationsQuery.data || [], locationId);
      setPathToLocation(path.slice(1));
      setSelectedPath(path.slice(1));
    }
  }, [formValue.location, treeSelectInventoryLocationsQuery.data]);

  const onSubmit = () => {
    bulkMoveCapitalEquipment({
      capital_equipment_ids: selectedEquipment.map((item) => item.id),
      inventory_location_id: formValue.location,
    }).then(() => {
      setSelectedEquipment([]);
      toast({
        title: "Equipment moved successfully",
        status: "success",
      });
      onClose();
      reset();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setModalPage("main");
      }}
      size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight="semibold">Move Equipment</Text>
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            setModalPage("main");
            reset();
            setShowPreview(false);
          }}
        />
        <ModalBody>
          {modalPage === "main" ? (
            <Flex direction="column" w="100%" gap={4}>
              <Text>You are about to move {selectedEquipment?.length} items of equipment</Text>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Choose Destination Location</FormLabel>
                    {showPreview ? (
                      <Stack
                        onClick={() => setShowPreview(false)}
                        direction="row"
                        flexWrap="wrap"
                        px={3}
                        py={2}
                        border="1px"
                        borderColor="chakra-border-color"
                        borderRadius="md">
                        {pathToLocation?.map((location, idx) => {
                          const isLast = idx === pathToLocation?.length - 1;
                          return (
                            <Box key={`destination-${location.label}-${location.value?.id}`}>
                              <HStack>
                                <Text
                                  color={isLast ? "teal.500" : "auto"}
                                  fontWeight={isLast ? "semibold" : "normal"}>
                                  {location.label}
                                </Text>
                                {idx < (pathToLocation?.length || 1) - 1 && <ArrowForwardIcon />}
                              </HStack>
                            </Box>
                          );
                        })}
                      </Stack>
                    ) : (
                      <TreeSelect
                        options={treeSelectInventoryLocationsQuery.data || []}
                        isLoading={treeSelectInventoryLocationsQuery.isLoading}
                        onSearchChanged={(value) => setLocationSearch(value)}
                        defaultValue={null}
                        onChange={(value) => {
                          if (value) {
                            field.onChange(value.id);
                            setShowPreview(true);
                          }
                        }}
                      />
                    )}
                  </FormControl>
                )}
              />
            </Flex>
          ) : (
            <>
              <Flex direction="column" w="100%" gap={2}>
                <Text>Please confirm the destination for the selected equipment:</Text>
                <Stack direction={"row"} marginBottom={"4"}>
                  {selectedPath?.map((location, idx) => {
                    const isLast = idx === selectedPath?.length - 1;
                    return (
                      <Box key={`destination-${location.label}-${location.value?.id}`}>
                        <HStack>
                          <Text
                            color={isLast ? "teal.500" : "auto"}
                            fontWeight={isLast ? "semibold" : "normal"}>
                            {location.label}
                          </Text>
                          {idx < (pathToLocation?.length || 1) - 1 && <ArrowForwardIcon />}
                        </HStack>
                      </Box>
                    );
                  })}
                </Stack>
              </Flex>
              <Flex
                direction="column"
                w="100%"
                p={2}
                border="1px"
                borderColor="chakra-border-color"
                borderRadius="md">
                <Flex direction="column" justify="space-between" align="left" px={2} py={3}>
                  <Flex direction="column">
                    {selectedEquipment.map((equipment, idx) => {
                      const path = equipment.inventory_location_id
                        ? findPathByLocationId(
                            treeSelectInventoryLocationsQuery.data || [],
                            equipment.inventory_location_id
                          ).slice(1)
                        : null;
                      return (
                        <>
                          <Text key={equipment.id} fontWeight="semibold">
                            {equipment.name}
                          </Text>
                          {path && (
                            <>
                              <Stack direction={"row"} py={2}>
                                {path.map((location, idx) => {
                                  const isLast = idx === path.length - 1;
                                  return (
                                    <Box
                                      key={`destination-${location.label}-${location.value?.id}`}>
                                      <HStack>
                                        <Text
                                          color={isLast ? "teal.500" : "inherit"}
                                          fontWeight={isLast ? "semibold" : "normal"}>
                                          {location.label}
                                        </Text>
                                        {idx < path.length - 1 && <ArrowForwardIcon />}
                                      </HStack>
                                    </Box>
                                  );
                                })}
                              </Stack>
                              <Divider mb={2} />
                            </>
                          )}
                        </>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
          <ModalFooter px={0}>
            {modalPage === "main" ? (
              <Button
                isDisabled={!formValue.location}
                onClick={() => setModalPage("final")}
                w="max-content"
                alignSelf="flex-end">
                Next
              </Button>
            ) : (
              <HStack justify="end">
                <Button onClick={() => setModalPage("main")} leftIcon={<ChevronLeftIcon />}>
                  Change Destination
                </Button>
                <Button onClick={onSubmit} colorScheme="teal" isLoading={isLoading}>
                  Submit
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
