import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { invalidateCapitalEquipments } from "./capital_equipment";
import { SearchParams, searchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { createSearchResponseSchema } from "./shared";
import { timelineEventSchema } from "./timeline_events";

export const CAPITAL_EQUIPMENT_LOG_BASE_URL = "/capital_equipment_logs";

export const capitalEquipmentLogSchema = z.object({
  id: z.number(),
  capital_equipment_id: z.number(),
  log_type: z.string(),
  maintenance_date: z.string().nullable(),
  timeline_events: z.array(timelineEventSchema),
});

export type CapitalEquipmentLogData = z.infer<typeof capitalEquipmentLogSchema>;
export type CapitalEquipmentLogCreateParams = Omit<
  CapitalEquipmentLogData,
  "id" | "timeline_events"
> & {
  notes: string;
  maintenance_actionable_id?: number[];
  maintenance_actionable_type?: string;
};
export type CapitalEquipmentLogUpdateParams = Partial<CapitalEquipmentLogCreateParams> & {
  id: number;
};

export const getCapitalEquipmentLogTimelineEvents = async (capital_equipment_log_id: number) => {
  const result = await api.get(
    `${CAPITAL_EQUIPMENT_LOG_BASE_URL}/${capital_equipment_log_id}/events`
  );
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const createCapitalEquipmentLog = async (
  capital_equipment_log: CapitalEquipmentLogCreateParams
) => {
  const result = await api.post(CAPITAL_EQUIPMENT_LOG_BASE_URL, { capital_equipment_log });
  return zodParse(capitalEquipmentLogSchema.pick({ id: true }), result.data);
};

export const updateCapitalEquipmentLog = async (
  capital_equipment_log: CapitalEquipmentLogUpdateParams
) => {
  await api.put(`${CAPITAL_EQUIPMENT_LOG_BASE_URL}/${capital_equipment_log.id}`, {
    capital_equipment_log,
  });
};

export const deleteCapitalEquipmentLog = async (id: number) => {
  await api.delete(`${CAPITAL_EQUIPMENT_LOG_BASE_URL}/${id}`);
  return id;
};

const searchCapitalEquipmentLogs = async ({ aggs, bodyOptions, filters, term }: SearchParams) => {
  const path = [CAPITAL_EQUIPMENT_LOG_BASE_URL, "search"];
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...search });

  return zodParse(createSearchResponseSchema(capitalEquipmentLogSchema), result.data);
};

/** hooks */

export const invalidateCapitalEquipmentLogs = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [CAPITAL_EQUIPMENT_LOG_BASE_URL];
  if (id) queryKey.push(id);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useSearchCapitalEquipmentLog = (params: SearchParams) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENT_LOG_BASE_URL, params],
    queryFn: () => searchCapitalEquipmentLogs(params),
  });
};

export const useGetCapitalEquipmentLogTimelineEvents = (capital_equipment_log_id: number) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENT_LOG_BASE_URL, capital_equipment_log_id],
    queryFn: () => getCapitalEquipmentLogTimelineEvents(capital_equipment_log_id),
  });
};

export const useCreateCapitalEquipmentLog = () => {
  return useMutation({
    mutationFn: createCapitalEquipmentLog,
    onSuccess: invalidateCapitalEquipmentLogs(),
  });
};

export const useUpdateCapitalEquipmentLog = (id?: number) => {
  return useMutation({
    mutationFn: updateCapitalEquipmentLog,
    onSuccess: invalidateCapitalEquipmentLogs(id),
  });
};

export const useDeleteCapitalEquipmentLog = (id?: number) => {
  return useMutation({
    mutationFn: deleteCapitalEquipmentLog,
    onSuccess: invalidateCapitalEquipmentLogs(id),
  });
};
