import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { Pagination, SearchParams, indexParams, searchParams } from "./collection_types";
import { expenditureSchema } from "./expenditures";
import { createPaginatedResponseSchema, createSearchResponseSchema } from "./shared";
import { SpendingAuthorityReference } from "./spending_authority";
import { timelineEventSchema } from "./timeline_events";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";
import { moneySchema } from "../helpers/Money";

export const SUBSCRIPTIONS_BASE_URL = "subscriptions";
export const SUBSCRIPTION_APPROVAL_BASE_URL = "subscription_approvals";

export const subscriptionApprovalSchema = z.object({
  id: z.number(),
  subscription_id: z.number(),
  user_id: z.number().nullable(),
  approved_at: z.string().nullable(),
  declined_at: z.string().nullable(),
});

export const subscriptionApprovalShowSchema = subscriptionApprovalSchema
  .omit({
    subscription_id: true,
    user_id: true,
  })
  .extend({
    user: z.lazy(() => userMinimalSchema).nullable(),
  });

export const subscriptionSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    project_id: z.number().nullable(),
    team_id: z.number().nullable(),
    vendor_id: z.number(),
    virtual_card_id: z.number().nullable(),
    interval: z.enum(["monthly", "annually"]),
    payments_per_interval: z.number(),
    max_amount_per_payment: moneySchema,
    max_amount_per_interval: moneySchema,
    status: z.string(),
    archived_at: z.string().nullable().optional(),
  })
  .extend(expenditureSchema.shape);

export const subscriptionPaymentSchema = z.object({
  id: z.number(),
  amount: moneySchema,
  status: z.string(),
  paid_at: z.string().nullable(),
  decline_reason: z.string().nullable(),
  created_at: z.string(),
});

export const subscriptionShowSchema = subscriptionSchema
  .omit({
    team_id: true,
    vendor_id: true,
  })
  .extend({
    team: z.object({ id: z.number(), name: z.string() }).nullable(),
    vendor: z.object({ id: z.number(), name: z.string() }),
    subscription_approval: subscriptionApprovalShowSchema,
    last_subscription_payment: subscriptionPaymentSchema.nullable(),
    spend_for_interval: moneySchema,
  });

export type SubscriptionData = z.infer<typeof subscriptionSchema>;
export type SubscriptionShowData = z.infer<typeof subscriptionShowSchema>;
export type SubscriptionCreateParams = Omit<
  SubscriptionData,
  | "id"
  | "status"
  | "virtual_card_id"
  | "description"
  | "spending_authority"
  | "root_spending_authority"
  | "team_id"
  | "project_id"
> &
  Partial<SpendingAuthorityReference>;
export type SubscriptionUpdateParams = Partial<SubscriptionData> &
  Partial<SpendingAuthorityReference>;

export type SubscriptionApprovalData = z.infer<typeof subscriptionApprovalSchema>;
export type SubscriptionApprovalShowData = z.infer<typeof subscriptionApprovalShowSchema>;

export type SubscriptionPaymentData = z.infer<typeof subscriptionPaymentSchema>;

export const getSubscriptions = async () => {
  const result = await api.get(SUBSCRIPTIONS_BASE_URL);
  return zodParse(createPaginatedResponseSchema(subscriptionShowSchema), result.data);
};

export const searchSubscriptions = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [SUBSCRIPTIONS_BASE_URL, "search"].join("/");
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const response = await api.post(path, { ...index, ...search });
  return zodParse(createSearchResponseSchema(subscriptionShowSchema), response.data);
};

export const createSubscription = async (subscription: SubscriptionCreateParams) => {
  const result = await api.post(SUBSCRIPTIONS_BASE_URL, { subscription });
  return zodParse(subscriptionShowSchema.pick({ id: true }), result.data);
};

export const getSubscription = async (id: number) => {
  const result = await api.get(`${SUBSCRIPTIONS_BASE_URL}/${id}`);
  return zodParse(subscriptionShowSchema, result.data);
};

export const getSubscriptionPayments = async (id: number, params: Pagination) => {
  const result = await api.get(`${SUBSCRIPTIONS_BASE_URL}/${id}/payments`, { params });
  return zodParse(createPaginatedResponseSchema(subscriptionPaymentSchema), result.data);
};

export const getSubscriptionEvents = async (id: number) => {
  const result = await api.get(`${SUBSCRIPTIONS_BASE_URL}/${id}/events`);
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const updateSubscription = async (subscription: Partial<SubscriptionUpdateParams>) => {
  const result = await api.put(`${SUBSCRIPTIONS_BASE_URL}/${subscription.id}`, {
    subscription,
  });
  return result.data;
};

export const updateSubscriptionApproval = async (
  subscription_approval: Partial<SubscriptionApprovalData>
) => {
  const result = await api.put(`${SUBSCRIPTION_APPROVAL_BASE_URL}/${subscription_approval.id}`, {
    subscription_approval,
  });
  return result.data;
};

export const deleteSubscription = async (id: number) => {
  await api.delete(`${SUBSCRIPTIONS_BASE_URL}/${id}`);
};

export const invalidateSubscription = (id?: number, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [SUBSCRIPTIONS_BASE_URL];
  if (id) queryKey.push(id);
  if (subQuery) queryKey.push(subQuery);

  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetSubscriptions = () => {
  return useQuery({
    queryKey: [SUBSCRIPTIONS_BASE_URL],
    queryFn: () => getSubscriptions(),
  });
};

export const useSearchSubscriptions = (searchParams: SearchParams) => {
  return useQuery({
    queryKey: [SUBSCRIPTIONS_BASE_URL, searchParams],
    queryFn: () => searchSubscriptions(searchParams),
  });
};

export const useGetSubscription = (id: number) => {
  return useQuery({
    queryKey: [SUBSCRIPTIONS_BASE_URL, id],
    queryFn: () => getSubscription(id),
  });
};

export const useGetSubscriptionPayments = (id: number, params) => {
  return useQuery({
    queryKey: [SUBSCRIPTIONS_BASE_URL, id, "payments", params],
    queryFn: () => getSubscriptionPayments(id, params),
  });
};

export const useGetSubscriptionEvents = (id: number) => {
  return useQuery({
    queryKey: [SUBSCRIPTIONS_BASE_URL, id, "events"],
    queryFn: () => getSubscriptionEvents(id),
  });
};

export const useCreateSubscription = () => {
  return useMutation({
    mutationFn: createSubscription,
    onSuccess: invalidateSubscription(),
  });
};

export const useUpdateSubscription = (id?: number) => {
  return useMutation({
    mutationFn: updateSubscription,
    onSuccess: invalidateSubscription(id),
  });
};

export const useDeleteSubscription = () => {
  return useMutation({
    mutationFn: deleteSubscription,
  });
};

export const useUpdateSubscriptionApproval = (id?: number) => {
  return useMutation({
    mutationFn: updateSubscriptionApproval,
    onSuccess: invalidateSubscription(id),
  });
};
