import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Stack, HStack, useColorModeValue, Spacer, Text, Box, VStack } from "@chakra-ui/react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { DeleteIcon } from "@chakra-ui/icons";
import { IoBagCheckOutline } from "react-icons/io5";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";

type SelectCheckoutPurposeProps = {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  showUsed?: boolean;
};

type checkoutPurpose = "spending_authority" | "used" | "waste";

export const SelectCheckoutPurpose = ({
  control,
  setValue,
  showUsed = true,
}: SelectCheckoutPurposeProps) => {
  const purposeOptions: {
    label: string;
    description: string;
    value: checkoutPurpose;
    icon: React.ReactNode;
    enabled: boolean;
  }[] = [
    {
      label: "Checkout to spending authority",
      description: "Transfer these items' cost to a designated project or use",
      value: "spending_authority" as checkoutPurpose,
      icon: <MdOutlineShoppingCartCheckout />,
      enabled: true,
    },
    {
      label: "Mark as used",
      description: "Remove the items from inventory without reallocating funds",
      value: "used" as checkoutPurpose,
      icon: <IoBagCheckOutline />,
      enabled: showUsed,
    },
    {
      label: "Waste",
      description: "Discard the items due to expiration or damage without reallocating funds",
      value: "waste" as checkoutPurpose,
      icon: <DeleteIcon />,
      enabled: true,
    },
  ].filter((option) => option.enabled);

  return (
    <Controller
      name="purpose"
      control={control}
      render={({ field }) => (
        <Stack direction="column" gap={3}>
          <Text fontWeight="semibold">What would you like to do with these inventory items?</Text>
          {purposeOptions.map((option) => (
            <HStack
              _hover={{ cursor: "pointer" }}
              p={3}
              bg={
                field.value === option.value
                  ? useColorModeValue("teal.50", "teal.500")
                  : "chakra-bg-color"
              }
              spacing={6}
              border="1px"
              borderRadius="md"
              borderColor={
                field.value === option.value
                  ? useColorModeValue("teal.200", "teal.400")
                  : "chakra-border-color"
              }
              onClick={() => {
                setValue("purpose", option.value);
              }}>
              {option.icon}
              <VStack direction="column" align="start" gap={0}>
                <Text fontSize="sm" fontWeight="medium">
                  {option.label}
                </Text>
                <Text fontSize="xs">{option.description}</Text>
              </VStack>
              <Spacer />
              {field.value === option.value ? (
                <CheckCircleIcon color={useColorModeValue("teal.500", "teal.200")} boxSize={7} />
              ) : (
                <Box border="2px" borderRadius="full" borderColor="chakra-border-color" p={3} />
              )}
            </HStack>
          ))}
        </Stack>
      )}
    />
  );
};
