import { AddIcon, CheckIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  IconButton,
  Input,
  Spinner,
  Switch,
  Tag,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CollectionTable,
  ConfirmationButton,
  EditableDate,
  EditableSelect,
  Order,
  Select,
} from "@sciencecorp/helix-components";
import { UseMutateFunction } from "@tanstack/react-query";
import { humanize, titleize } from "inflection";
import _, { DebouncedFunc } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CiUndo } from "react-icons/ci";
import { useDeleteFile } from "../../../../api/blob_files";
import { ContractShowData } from "../../../../api/contracts";
import { PurchaseShowData } from "../../../../api/purchase";
import {
  PurchasePaymentCreateParams,
  PurchasePaymentShowData,
  PurchasePaymentUpdateParams,
} from "../../../../api/purchase_payment";
import { UserLoggedInData } from "../../../../api/user";
import { CurrencyProvider, useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyInput } from "../../../MoneyInput";
import { AttachmentPopover } from "../../../shared/AttachmentsPopover";
import { EmptyState } from "../../../shared/EmptyState";
import { PaymentTypes } from "./PaymentDetails";

type PurchasePaymentListProps = {
  purchase?: PurchaseShowData;
  contract?: ContractShowData;
  location: "purchase" | "installment" | "contract";
  editMode?: boolean;
  isPurchasingAdmin?: boolean;
  purchasePayments?: PurchasePaymentShowData[];
  currentUser?: UserLoggedInData;
  paymentScheduleId?: number;
  updatePurchasePayment: DebouncedFunc<
    UseMutateFunction<PurchasePaymentShowData, unknown, PurchasePaymentUpdateParams>
  >;
  deletePurchasePayment: DebouncedFunc<UseMutateFunction<void, unknown, number>>;
  createPurchasePayment?: DebouncedFunc<
    UseMutateFunction<PurchasePaymentShowData, unknown, PurchasePaymentCreateParams>
  >;
  setSession?: React.Dispatch<React.SetStateAction<number>>;
  uploadFileOnSuccess: (id?: number | undefined) => () => Promise<void>;
  isLoadingUpdatePurchasePayment?: boolean;
};

export const PurchasePaymentList = ({
  purchase,
  contract,
  purchasePayments,
  isPurchasingAdmin,
  updatePurchasePayment,
  deletePurchasePayment,
  location,
  setSession,
  paymentScheduleId,
  createPurchasePayment,
  uploadFileOnSuccess,
  isLoadingUpdatePurchasePayment,
}: PurchasePaymentListProps) => {
  const [order, setOrder] = useState<Order>({});
  const [sortedPayments, setSortedPayments] = useState<PurchasePaymentShowData[]>(
    purchasePayments || []
  );
  const currency = useCurrency();
  let editable: boolean;
  if (isPurchasingAdmin) {
    if (location === "purchase" && purchase?.fully_approved) {
      editable = true;
    } else if (location === "contract" && !contract?.archived_at) {
      editable = true;
    } else editable = false;
  } else editable = false;
  let payable: boolean = purchase?.ordered_at !== null;
  const { mutate: deleteFile } = useDeleteFile(uploadFileOnSuccess);

  useEffect(() => {
    if (order && order["paid_at"]) {
      const asc = order["paid_at"] === "asc";
      const sorted = _.sortBy(
        purchasePayments,
        (payment) => payment.due_date && DateTime.fromISO(payment.due_date)
      );
      if (!asc) {
        sorted.reverse();
      }
      setSortedPayments(sorted);
    } else {
      setSortedPayments(purchasePayments || []);
    }
  }, [purchasePayments, order]);

  const onOrder = useCallback(
    (order: Order) => {
      setOrder(order);
    },
    [purchasePayments]
  );

  const columns = useMemo(
    () => [
      {
        label: "Amount",
        weight: 3,
        render: (paymentItem: PurchasePaymentShowData) => (
          <Box mt={2} w="max-content">
            {purchase ? (
              <AmountInputPurchase
                key={`payment-${paymentItem.id}-amount-input-${paymentItem.amount.currency.code}`}
                payment={paymentItem}
                purchase={purchase}
                editable={editable}
                updatePurchasePayment={updatePurchasePayment}
                setSession={setSession}
              />
            ) : (
              <AmountInput
                purchaseItem={paymentItem}
                editable={editable}
                updatePurchasePayment={updatePurchasePayment}
                setSession={setSession}
              />
            )}
          </Box>
        ),
      },
      {
        label: "Payment Type",
        weight: 1,
        render: (paymentItem: PurchasePaymentShowData) => (
          <EditableSelect
            size="sm"
            disabled={!editable}
            onSubmit={(value) => {
              if (value)
                updatePurchasePayment({ id: paymentItem.id, payment_type: value as PaymentTypes });
            }}
            selectedValue={paymentItem.payment_type}
            options={[
              { label: "Wire", value: "wire" },
              { label: "Credit", value: "credit" },
              { label: "Check", value: "check" },
            ]}
          />
        ),
      },
      location === "purchase" && purchase?.purchase_type === "purchase_order"
        ? {
            label: "Invoice Date",
            weight: 3,
            render: (paymentItem: PurchasePaymentShowData) => (
              <Flex direction="column" gap={1}>
                {purchase.vendor?.payment_terms && (
                  <Text>
                    <Box as="span" fontWeight="semibold">
                      Payment Terms:{" "}
                    </Box>
                    {titleize(humanize(purchase.vendor.payment_terms))}
                  </Text>
                )}
                <DueInput
                  purchaseItem={paymentItem}
                  editable={editable}
                  updatePurchasePayment={updatePurchasePayment}
                />
              </Flex>
            ),
          }
        : {
            label: "Due",
            weight: 3,
            render: (paymentItem: PurchasePaymentShowData) => (
              <Box mt={2}>
                <DueInput
                  purchaseItem={paymentItem}
                  editable={editable}
                  updatePurchasePayment={updatePurchasePayment}
                />
              </Box>
            ),
          },
      {
        label: "Status",
        weight: 0.5,
        render: (paymentItem: PurchasePaymentShowData) => (
          <HStack>
            <Tag size="md" colorScheme={paymentItem.status === "new" ? "purple" : "green"}>
              {titleize(humanize(paymentItem.status))}
            </Tag>
            {paymentItem.status === "paid" && (
              <IconButton
                isDisabled={!editable || !payable}
                size="xs"
                aria-label="undo payment"
                icon={<CiUndo />}
                isLoading={isLoadingUpdatePurchasePayment}
                onClick={() => {
                  updatePurchasePayment(
                    { id: paymentItem.id, status: "new", paid_at: null },
                    {
                      onSuccess: () => {
                        setSession && setSession((prev) => ++prev);
                      },
                    }
                  );
                }}
              />
            )}
          </HStack>
        ),
      },
      {
        label: "",
        render: (paymentItem: PurchasePaymentShowData) => (
          <AttachmentPopover
            buttonProps={{ size: "xs", isDisabled: !editable }}
            recordId={paymentItem.id}
            recordType={"PurchasePayment"}
            fileableColumn={"uploaded_files"}
            onSuccessCallback={uploadFileOnSuccess}
            deleteFile={deleteFile}
            files={paymentItem.uploaded_files}
          />
        ),
      },
      {
        label: "Paid At",
        orderOptions: { orderKey: "paid_at" },
        render: (paymentItem: PurchasePaymentShowData) => (
          <HStack>
            {paymentItem.status === "new" ? (
              <HStack>
                <ConfirmationButton
                  size="sm"
                  isDisabled={!editable || !payable}
                  label="Pay"
                  confirmationHeader="Make a Payment"
                  aria-label="make payment"
                  isLoading={isLoadingUpdatePurchasePayment}
                  buttonVariant="ghost"
                  variant="Button"
                  colorScheme="teal"
                  children="Are you sure you want to mark this as paid? This will not actually move money: payment must be remitted separately."
                  onConfirm={() => {
                    updatePurchasePayment(
                      {
                        id: paymentItem.id,
                        status: "paid",
                        paid_at: DateTime.now().toISO(),
                      },
                      { onSuccess: () => setSession && setSession((prev) => ++prev) }
                    );
                  }}
                />
                <ConfirmationButton
                  size="sm"
                  isDisabled={!editable}
                  label="Delete Payment"
                  aria-label="Delete Payment"
                  icon={<DeleteIcon />}
                  variant="IconButton"
                  textColor={useColorModeValue("red.500", "auto")}
                  bg={useColorModeValue("red.50", "auto")}
                  title="Delete Payment"
                  children="Are you sure you want to delete this payment?"
                  onConfirm={() => {
                    deletePurchasePayment(paymentItem.id, {
                      onSuccess: () => setSession && setSession((prev) => ++prev),
                    });
                  }}
                />
              </HStack>
            ) : paymentItem.paid_at ? (
              <Box>
                <EditableDate
                  defaultValue={paymentItem.paid_at}
                  disabled={!isPurchasingAdmin}
                  onSubmit={(value) => {
                    updatePurchasePayment({ id: paymentItem.id, paid_at: value });
                  }}
                />
              </Box>
            ) : null}
          </HStack>
        ),
      },
    ],
    [
      purchase?.currency,
      purchasePayments,
      isPurchasingAdmin,
      purchase?.ordered_at,
      purchase?.fully_approved,
      editable,
      payable,
      isLoadingUpdatePurchasePayment,
    ]
  );

  return (
    <Card flexDirection="column" gap={4} p={4} w="100%" variant={"outline"}>
      <CurrencyProvider currency={currency}>
        <HStack width="100%" justify="space-between">
          <Text fontWeight="semibold">
            {location === "purchase" ? "Payment List" : "Installments"}
          </Text>
        </HStack>
        {purchasePayments ? (
          purchasePayments.length ? (
            <CollectionTable
              columns={columns}
              items={sortedPayments}
              onOrder={onOrder}
              order={order}
            />
          ) : (
            <EmptyState title="There are no payments added" size="3xs" />
          )
        ) : (
          <Spinner />
        )}
        {paymentScheduleId && createPurchasePayment && isPurchasingAdmin && (
          <Flex justify="center" mt={4}>
            <Button
              leftIcon={<AddIcon />}
              onClick={() => createPurchasePayment({ payment_schedule_id: paymentScheduleId })}
              isDisabled={!editable}>
              Add Installment
            </Button>
          </Flex>
        )}
      </CurrencyProvider>
    </Card>
  );
};

export type DebouncedInputProps = {
  payment: PurchasePaymentShowData;
  purchase: PurchaseShowData;
  editable?: boolean;
  updatePurchasePayment: DebouncedFunc<
    UseMutateFunction<PurchasePaymentShowData, unknown, PurchasePaymentUpdateParams>
  >;
  setSession?: React.Dispatch<React.SetStateAction<number>>;
};

const AmountInputPurchase = ({
  payment,
  purchase,
  editable,
  updatePurchasePayment,
  setSession,
}: DebouncedInputProps) => {
  const currency = useCurrency();
  const [paidInFull, setPaidInFull] = useState<boolean>(payment.amount.eq(purchase.line_items_sum));
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState<Money>(payment.amount);
  const isPaid = payment.status === "paid";

  const updatePurchasePaymentAmount = (amount: Money) => {
    Promise.resolve(
      updatePurchasePayment(
        {
          id: payment.id,
          amount: amount,
        },
        {
          onSuccess: () => {
            setSuccess(true);
            setSession && setSession((prev) => prev + 1);
            setTimeout(() => setSuccess(false), 3000);
          },
          onError: (error) => {
            console.error("Failed to save changes:", error);
            setError(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    if (payment.amount.eq(amount)) return;
    setSuccess(false);
    setError(false);
    updatePurchasePaymentAmount(amount);
  }, [amount]);

  const handleSwitchChange = () => {
    const amountInput = purchase.line_items_sum!;
    if (!paidInFull) {
      updatePurchasePayment(
        {
          id: payment.id,
          amount: amountInput,
        },
        {
          onSuccess: () => {
            setSuccess(true);
            setSession && setSession((prev) => ++prev);
            setTimeout(() => setSuccess(false), 3000);
          },
          onError: (error) => {
            console.error("Failed to save changes:", error);
            setError(true);
          },
        }
      );
      setAmount(amountInput);
      setPaidInFull(true);
    } else {
      updatePurchasePayment({ id: payment.id, amount: Money.zero(currency) });
      setAmount(Money.zero(currency));
      setPaidInFull(false);
    }
  };
  return (
    <Flex direction={["column", "row"]} justify="center" align={["start", "center"]} gap={2}>
      <Tooltip label={success ? "Success" : "Error"} isOpen={success || error}>
        <MoneyInput
          size="sm"
          isDisabled={!editable || isPaid || paidInFull}
          value={amount}
          onChange={(value) => setAmount(value)}
        />
      </Tooltip>
      <VStack>
        <HStack>
          <Switch
            isDisabled={!editable || isPaid}
            colorScheme="teal"
            isChecked={paidInFull}
            onChange={handleSwitchChange}
          />
          <Text>Full</Text>
        </HStack>
      </VStack>
    </Flex>
  );
};

type DueInputProps = {
  purchaseItem: PurchasePaymentShowData;
  editable?: boolean;
  updatePurchasePayment: DebouncedFunc<
    UseMutateFunction<PurchasePaymentShowData, unknown, PurchasePaymentUpdateParams>
  >;
  savingStatus?: boolean;
  setSavingStatus?: any;
  setSession?: any;
};

const AmountInput = ({
  purchaseItem,
  editable,
  updatePurchasePayment,
  setSession,
}: DueInputProps) => {
  const currency = useCurrency();
  const [amount, setAmount] = useState<Money>(purchaseItem.amount);
  const isPaid = purchaseItem.status === "paid";
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const updatePurchasePaymentAmount = (amount: Money) => {
    updatePurchasePayment(
      {
        id: purchaseItem.id,
        amount: amount,
      },
      {
        onSuccess: () => {
          setSuccess(true);
          setSession((prev) => ++prev);
          setTimeout(() => setSuccess(false), 3000);
        },
        onError: (error) => {
          console.error("Failed to save changes:", error);
          setError(true);
        },
      }
    );
  };

  useEffect(() => {
    if (purchaseItem.amount.eq(amount)) return;
    setSuccess(false);
    setError(false);
    updatePurchasePaymentAmount(amount);
  }, [amount]);

  const handleBlur = () => {
    if (amount === purchaseItem.amount) return;
    updatePurchasePayment(
      { id: purchaseItem.id, amount: amount },
      {
        onSuccess: () => {
          setSuccess(true);
          setSession((prev) => ++prev);
          setTimeout(() => setSuccess(false), 3000);
        },
        onError: (error) => {
          console.error("Failed to save changes:", error);
          setError(true);
        },
      }
    );
  };
  return (
    <Flex align="center">
      <Box w={60}>
        <MoneyInput
          size={"xs"}
          width={"auto"}
          isDisabled={!editable || isPaid}
          value={amount}
          onChange={(value) => setAmount(value)}
          onBlur={handleBlur}
        />
      </Box>
      <Box ml={2}>
        {success && (
          <Text color="green.500" fontStyle="italic">
            <CheckIcon /> Success
          </Text>
        )}
        {error && (
          <Text color="red.500" fontStyle="italic">
            <CloseIcon /> Error
          </Text>
        )}
      </Box>
    </Flex>
  );
};

const DueInput = ({ purchaseItem, editable, updatePurchasePayment }: DueInputProps) => {
  const [dueType, setDueType] = useState<string>(
    purchaseItem.due_date_condition ? "custom" : "date"
  );

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [input, setInput] = useState<string>(
    dueType === "date" ? purchaseItem.due_date || "" : purchaseItem.due_date_condition || ""
  );

  const updatePurchasePaymentCondition = (condition: string) => {
    if (dueType === "custom") return;
    const inputDate = DateTime.fromISO(condition);
    if (!inputDate.isValid) {
      console.error("Invalid date format.");
      setError(true);
      return;
    }

    const earliestYear = 2020;
    if (inputDate.year < earliestYear) {
      console.error("Date is outside the reasonable range.");
      setError(true);
      return;
    }
    updatePurchasePayment(
      {
        id: purchaseItem.id,
        due_date: condition,
      },
      {
        onSuccess: () => {
          setSuccess(true);
          setTimeout(() => setSuccess(false), 2000);
        },
        onError: (error) => {
          console.error("Failed to save changes:", error);
          setError(true);
        },
      }
    );
  };

  const handleBlur = () => {
    if (
      input ===
      (dueType === "date" ? purchaseItem.due_date || "" : purchaseItem.due_date_condition || "")
    )
      return;
    if (dueType === "date") {
      const inputDate = DateTime.fromISO(input);
      if (!inputDate.isValid) {
        console.error("Invalid date format.");
        setError(true);
        return;
      }

      const earliestYear = 2020;
      if (inputDate.year < earliestYear) {
        console.error("Date is outside the reasonable range.");
        setError(true);
        return;
      }

      updatePurchasePayment(
        { id: purchaseItem.id, due_date: input },
        {
          onSuccess: () => {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 3000);
          },
          onError: (error) => {
            console.error("Failed to save changes:", error);
            setError(true);
          },
        }
      );
    } else {
      updatePurchasePayment(
        { id: purchaseItem.id, due_date_condition: input },
        {
          onSuccess: () => {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 3000);
          },
          onError: (error) => {
            console.error("Failed to save changes:", error);
            setError(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (
      input ===
      (dueType === "date" ? purchaseItem.due_date || "" : purchaseItem.due_date_condition || "")
    )
      return;
    setError(false);
    setSuccess(false);
    updatePurchasePaymentCondition(input);
  }, [input]);

  const handleSelectChange = (value: string | number) => {
    setDueType(value.toString());
    if (value === "date") {
      updatePurchasePayment({ id: purchaseItem.id, due_date_condition: null });
    } else if (value === "custom") {
      updatePurchasePayment({ id: purchaseItem.id, due_date: null });
    }

    setInput(
      value === "date" ? purchaseItem.due_date || "" : purchaseItem.due_date_condition || ""
    );
  };

  return (
    <Flex direction="row" flex="1">
      <Box>
        <Select
          isDisabled={!editable}
          options={[
            { label: "Date", value: "date" },
            { label: "Custom", value: "custom" },
          ]}
          size="sm"
          value={dueType}
          onChange={handleSelectChange}
        />
      </Box>
      <Flex w={{ base: 40, sm: 36, md: 40 }} align="center">
        {dueType === "date" ? (
          <Box flexShrink={0} w="100%">
            <Input
              type="date"
              isDisabled={!editable}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onBlur={handleBlur}
              size="sm"
            />
          </Box>
        ) : (
          <Box flexShrink={0} w="100%">
            <Input
              position="relative"
              size="sm"
              isDisabled={!editable}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onBlur={handleBlur}
            />
          </Box>
        )}
        <Box ml={2}>
          {success && (
            <Text color="green.500" fontStyle="italic" display="flex">
              <CheckIcon mr={1} /> Success
            </Text>
          )}
          {error && (
            <Text color="red.500" fontStyle="italic" display="flex" align="center">
              <CloseIcon mr={1} /> Error
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
