import _ from "lodash";
import React, { useMemo, useState } from "react";
import {
  Flex,
  Text,
  Heading,
  HStack,
  Avatar,
  useToast,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  IconButton,
  Input,
  VStack,
  Button,
  FormControl,
  FormLabel,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import { CollectionTable, Column } from "@sciencecorp/helix-components";
import { SmallCloseIcon, SmallAddIcon } from "@chakra-ui/icons";
import { UserMinimalData, useActiveUsersQuery } from "../../../api/user";
import {
  BudgetHeadcountPanelistShowData,
  useDeleteBudgetHeadcountPanelist,
  useSearchBudgetHeadcountPanelist,
  useUpdateBudgetHeadcountPanelist,
  useCreateBudgetHeadcountPanelist,
} from "../../../api/budget_headcount_panelist";
import { humanize, titleize } from "inflection";
import { UserServiceRequestPill } from "../../Requests/utils";
import { orderBy } from "lodash";

type HiringTeamTableProps = {
  budgetHeadcountId: number;
  editable: boolean;
};

const ROLES = [
  "interviewer",
  "greenhouse_access",
  "scheduling_coordinators",
  "application_reviewer",
];

const getUserOptions = (allUsers: UserMinimalData[], assignedUsers: UserMinimalData[] = []) => {
  const assignedUserIds = new Set(assignedUsers.map((user) => user.id));
  return allUsers.reduce((acc: { label: string; value: number; user: UserMinimalData }[], user) => {
    if (!assignedUserIds.has(user.id)) {
      acc.push({
        label: user.name,
        value: user.id,
        user,
      });
    }
    return orderBy(acc, ["label"], ["asc"]);
  }, []);
};

const groupByRole = (
  panelists: BudgetHeadcountPanelistShowData[]
): Record<string, BudgetHeadcountPanelistShowData[]> => {
  return panelists.reduce((acc: Record<string, BudgetHeadcountPanelistShowData[]>, panelist) => {
    if (!acc[panelist.hiring_role]) {
      acc[panelist.hiring_role] = [];
    }
    acc[panelist.hiring_role].push(panelist);
    return acc;
  }, {});
};

export const HiringTeamTable: React.FC<HiringTeamTableProps> = ({
  budgetHeadcountId,
  editable,
}) => {
  const { data: allUsers, isLoading: isUsersLoading } = useActiveUsersQuery();
  const { data, isLoading } = useSearchBudgetHeadcountPanelist({
    term: "*",
    filters: { budget_headcount_id: budgetHeadcountId },
    pagination: { per_page: -1 },
  });

  const { mutate: createPanelist, isLoading: isCreating } = useCreateBudgetHeadcountPanelist();
  const { mutate: updatePanelist } = useUpdateBudgetHeadcountPanelist();
  const { mutate: deletePanelist } = useDeleteBudgetHeadcountPanelist();

  const toast = useToast();

  const columns: Column<{
    key: string;
    role: string;
    panelists: BudgetHeadcountPanelistShowData[];
  }>[] = useMemo(
    () => [
      {
        label: "Role",
        weight: 1,
        render: (row: { role }) => <Text>{titleize(humanize(row.role))}</Text>,
      },
      {
        label: "Users",
        weight: 3,
        render: (row: { role: string; panelists: BudgetHeadcountPanelistShowData[] }) => {
          const [searchTerm, setSearchTerm] = useState("");
          return (
            <Flex direction="row" flexWrap="wrap" gap={2}>
              {row.panelists.map((panelist) => (
                <HStack spacing={0} key={panelist.id}>
                  <Tooltip label={panelist.user.name}>
                    <Avatar size="xs" name={panelist.user.name} src={panelist.user.picture_uri} />
                  </Tooltip>
                  <IconButton
                    colorScheme="red"
                    variant="ghost"
                    size="xs"
                    isDisabled={!editable}
                    aria-label="Remove user from hiring team"
                    icon={<SmallCloseIcon />}
                    onClick={() => {
                      deletePanelist(panelist.id, {
                        onSuccess: () => {
                          toast({
                            title: "User removed",
                            description: `${panelist.user.name} has been removed from the hiring team role: ${panelist.hiring_role}.`,
                            status: "success",
                          });
                        },
                        onError: (error: any) => {
                          toast({
                            title: "Error removing user",
                            description: `Could not remove ${panelist.user.name}: ${error.message}`,
                            status: "error",
                          });
                        },
                      });
                    }}
                  />
                </HStack>
              ))}
              {editable && (
                <Popover placement="bottom-end" closeOnBlur={true} key={`${row.role}`}>
                  <PopoverTrigger>
                    <IconButton
                      isDisabled={!editable}
                      bg="transparent"
                      aria-label="add-link"
                      size="xs"
                      icon={<SmallAddIcon />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody maxH={52} overflowY="scroll">
                      <VStack align="start">
                        <FormControl isRequired>
                          <FormLabel py={2}>Add Employee to Role</FormLabel>
                          <Box pb={2}>
                            <Input
                              placeholder="Search for a user..."
                              size="sm"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </Box>
                          {getUserOptions(
                            allUsers || [],
                            row.panelists.map((p) => p.user)
                          )
                            .filter((option) =>
                              option.label.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((option) => (
                              <Button
                                key={option.value}
                                cursor="pointer"
                                bg="transparent"
                                width="100%"
                                justifyContent="start"
                                onClick={() => {
                                  createPanelist(
                                    {
                                      budget_headcount_id: budgetHeadcountId,
                                      user_id: option.value,
                                      hiring_role: row.role,
                                    },
                                    {
                                      onSuccess: () => {
                                        toast({
                                          title: "User added",
                                          description: `${
                                            option.label
                                          } has been added to the hiring team role: ${humanize(
                                            row.role
                                          )}.`,
                                          status: "success",
                                        });
                                      },
                                      onError: (error: any) => {
                                        toast({
                                          title: "Error adding user",
                                          description: `Could not add ${option.label}: ${error.message}`,
                                          status: "error",
                                        });
                                      },
                                    }
                                  );
                                }}>
                                <UserServiceRequestPill user={option.user} />
                              </Button>
                            ))}
                        </FormControl>
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </Flex>
          );
        },
      },
    ],
    [editable, updatePanelist, deletePanelist, toast, allUsers, createPanelist]
  );

  const groupedData = useMemo(() => {
    const grouped = _.groupBy(
      data?.results,
      (item: BudgetHeadcountPanelistShowData) => item.hiring_role
    );
    return ROLES.map((role) => ({ key: role, role, panelists: grouped[role] || [] }));
  }, [data?.results]);

  if (isLoading || isUsersLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Flex
      border="1px"
      direction="column"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      px={6}
      py={5}
      borderRadius="md"
      gap={4}
      width="100%">
      <Flex direction="row" justify="space-between" align="center" pb={2}>
        <Heading size="md">Hiring Team</Heading>
      </Flex>
      <CollectionTable columns={columns} items={groupedData} />
    </Flex>
  );
};
