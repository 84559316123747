import {
  Box,
  Button,
  Checkbox,
  Text,
  HStack,
  Heading,
  Stack,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  Avatar,
  Badge,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  VStack,
  Flex,
  Tabs,
  Tab,
  TabList,
  Spinner,
} from "@chakra-ui/react";
import {
  CollectionTable,
  ConfirmationModal,
  EmptyState,
  RecordLink,
} from "@sciencecorp/helix-components";
import React, { useMemo, useState } from "react";
import { MoneyText } from "../../../../MoneyText";
import { TbLetterM } from "react-icons/tb";
import { DeleteIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  BudgetableTypeData,
  BudgetIndexItemData,
  useRemoveBudgets,
  useSearchBudgets,
} from "../../../../../api/budget";
import { MoveBudgetModal, useMoveBudgetsModal } from "./MoveBudgetModal";
import { BudgetGroupShowData } from "../../../../../api/budget_groups";
import { inflect } from "inflection";
import { AddBudgetModal } from "./AddBudgetModal";
import * as _ from "lodash";
import { Money } from "../../../../../helpers/Money";
import { useCurrentUserQuery } from "../../../../../api/user";
import { ManageFundingAllocation } from "./ManageFundingAllocation";

export const BudgetsList = ({ budgetsQuery, columns }) => {
  if (budgetsQuery.isSuccess) {
    if (budgetsQuery.data.results.length === 0) {
      return <EmptyState size="sm" title={`No items added. Add a budget to start`} />;
    }

    return <CollectionTable items={budgetsQuery.data.results || []} columns={columns} />;
  } else if (budgetsQuery.isLoading || budgetsQuery.isRefetching) {
    return <Spinner />;
  } else {
    return <Text>Error loading associated budgets</Text>;
  }
};
interface BudgetExpenseCardProps {
  budgetGroup: BudgetGroupShowData;
  isFinanceAdmin: boolean;
}

export const AssociatedBudgetsTable = ({ budgetGroup, isFinanceAdmin }: BudgetExpenseCardProps) => {
  const [selectedBudgets, setSelectedBudgets] = useState<BudgetIndexItemData[]>([]);
  const [budgetableType, setBudgetableType] = useState<BudgetableTypeData>("Team");
  const handleTabsChange = (index: number) => {
    if (index === 0) {
      setBudgetableType("Team");
    } else if (index === 1) {
      setBudgetableType("Project");
    } else if (index === 2) {
      setBudgetableType("CapitalEquipment");
    } else if (index === 3) {
      setBudgetableType("User");
    }
  };
  const { data: currentUser } = useCurrentUserQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutate: removeBudgets, isLoading: isLoadingRemoveBudgets } = useRemoveBudgets();

  const handleSelectBudget = (budget: BudgetIndexItemData) => {
    setSelectedBudgets((prev) =>
      prev.some((selectedBudget) => selectedBudget.id === budget.id)
        ? prev.filter((selectedBudget) => selectedBudget.id !== budget.id)
        : [...prev, budget]
    );
  };

  const isBudgetGroupLead = currentUser?.id === budgetGroup.lead.id;

  const budgetsQuery = useSearchBudgets({
    include_summaries: true,
    term: "*",
    pagination: { per_page: -1 },
    filters: {
      budget_group_id: budgetGroup.id,
      budgetable_type: budgetableType,
      moved_from_group: null,
      status: { not: ["abandoned"] },
    },
    order: { name: "asc" },
  });

  const budgetsForAllocationQuery = useSearchBudgets({
    include_summaries: true,
    term: "*",
    pagination: { per_page: -1 },
    filters: {
      budget_group_id: budgetGroup.id,
      moved_from_group: null,
      status: { not: ["abandoned"] },
    },
    order: { name: "asc" },
  });

  const historicalBudgetsQuery = useSearchBudgets({
    include_summaries: true,
    term: "*",
    pagination: { per_page: -1 },
    filters: { budget_group_id: budgetGroup.id, moved_from_group: { not: [null] } },
    order: { name: "asc" },
  });

  const moveBudgetProps = useMoveBudgetsModal({ selectedBudgets, setSelectedBudgets });

  const columns = useMemo(
    () =>
      [
        {
          label: "",
          weight: 0.25,
          render: (budget: BudgetIndexItemData) => (
            <Checkbox
              colorScheme="teal"
              isChecked={selectedBudgets.some((selectedBudget) => selectedBudget.id === budget.id)}
              onChange={() => handleSelectBudget(budget)}
            />
          ),
          disabled: !isFinanceAdmin,
        },
        {
          label: "Budget",
          weight: 2,
          render: (budget: BudgetIndexItemData) => (
            <RecordLink
              disabled={
                !isFinanceAdmin && !isBudgetGroupLead && budget.lead?.id !== currentUser?.id
              }
              maxWidth="15ch"
              identifier={budget.budgetable.name}
              type={budget.budgetable.type}
              link={budget.budgetable.app_href}
            />
          ),
        },
        {
          label: "Allocation",
          render: (budget: BudgetIndexItemData) => (
            <MoneyText
              fontWeight="bold"
              money={budget.allocated_amount}
              formatOptions={{ compact: "never" }}
            />
          ),
        },
        {
          label: "Spent",
          render: (budget: BudgetIndexItemData) => (
            <MoneyText
              fontWeight="bold"
              money={budget.summary?.spent_amount}
              formatOptions={{ compact: "never" }}
            />
          ),
        },
        {
          label: "Allocation Remaining",
          hideOnBreakpoint: "md",
          render: (budget: BudgetIndexItemData) => {
            const amount = budget.allocated_amount.subtract(
              budget.summary?.spent_amount || Money.zero(budget.allocated_amount.currency)
            );
            return (
              <MoneyText fontWeight="bold" money={amount} formatOptions={{ compact: "never" }} />
            );
          },
        },
        {
          label: "Committed",
          render: (budget: BudgetIndexItemData) => {
            const fontColor = budget.summary?.committed_amount?.isPos() ? "yellow.500" : "auto";
            return (
              <MoneyText
                color={fontColor}
                money={budget.summary?.committed_amount}
                formatOptions={{ compact: "never" }}
              />
            );
          },
        },
        {
          label: "Pending Approval",
          hideOnBreakpoint: "md",
          render: (budget: BudgetIndexItemData) => {
            const fontColor = budget.summary?.pending_approval_amount.isPos() ? "red.500" : "auto";
            return (
              <HStack>
                <MoneyText
                  color={fontColor}
                  money={budget.summary?.pending_approval_amount}
                  formatOptions={{ compact: "never" }}
                />
                {(budget.summary?.pending_approval_records_count || 0) > 0 && (
                  <Badge colorScheme="red">
                    {budget.summary?.pending_approval_records_count || 0}
                  </Badge>
                )}
              </HStack>
            );
          },
        },
        {
          label: "Added",
          hideOnBreakpoint: "md",
          weight: 1,
          render: (budget: BudgetIndexItemData) =>
            budget.added_to_group?.toFormat("LLL dd yyyy") || "N/A",
        },
        {
          label: "Lead",
          weight: 0.5,
          render: (budget: BudgetIndexItemData) => (
            <HStack>
              <Tooltip label={budget.lead?.name}>
                <Avatar size="sm" src={budget.lead?.picture_uri} name={budget.lead?.name} />
              </Tooltip>
            </HStack>
          ),
        },
      ].filter((column) => !column.disabled),
    [budgetsQuery?.data, selectedBudgets]
  );
  const historicalColumns = useMemo(
    () => [
      {
        label: "Budget",
        weight: 2,
        render: (budget: BudgetIndexItemData) => (
          <RecordLink
            disabled={true}
            identifier={budget.budgetable.name}
            type={budget.budgetable.type}
            link={budget.budgetable.app_href}
          />
        ),
      },
      {
        label: "Spent",
        render: (budget: BudgetIndexItemData) => (
          <MoneyText
            fontWeight="bold"
            money={budget.summary?.spent_amount}
            formatOptions={{ compact: "never" }}
          />
        ),
      },

      {
        label: "Added",
        weight: 1,
        render: (budget: BudgetIndexItemData) =>
          budget.added_to_group?.toFormat("LLL dd yyyy") || "N/A",
      },
      {
        label: "Moved",
        weight: 1,
        render: (budget: BudgetIndexItemData) =>
          budget.moved_from_group?.toFormat("LLL dd yyyy") || "N/A",
      },
      {
        label: "Lead",
        weight: 0.5,
        render: (budget: BudgetIndexItemData) => (
          <HStack>
            <Tooltip label={budget.lead?.name}>
              <Avatar size="sm" src={budget.lead?.picture_uri} name={budget.lead?.name} />
            </Tooltip>
          </HStack>
        ),
      },
    ],
    [historicalBudgetsQuery?.data]
  );

  const historicalBudgetSum = useMemo(
    () =>
      Money.sum(
        Money.zero("USD"),
        ...(historicalBudgetsQuery.data?.results || []).map(
          (stb) => stb.summary?.spent_amount || Money.zero("USD")
        )
      ),
    [historicalBudgetsQuery.data?.results]
  );

  const fontColor = useColorModeValue("gray.500", "gray.200");

  return (
    <Box>
      <VStack
        spacing={4}
        w={"100%"}
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        p={6}
        borderRadius="md">
        <Stack
          w={"100%"}
          direction={["column", "row"]}
          spacing={["4", "auto"]}
          justifyContent={"space-between"}>
          <HStack>
            <Heading size="md">Associated Budgets</Heading>
            <Badge>{budgetsQuery.data?.pagination.total}</Badge>
          </HStack>
          <Flex gap={4}>
            <ManageFundingAllocation
              fundingAmount={budgetGroup.summary.funding_amount}
              currency={budgetGroup.currency}
              data={budgetsForAllocationQuery.data?.results || []}
              location="group"
            />
            {isFinanceAdmin && <AddBudgetModal budgetGroup={budgetGroup} />}
          </Flex>
        </Stack>
        {isFinanceAdmin && (
          <Stack
            w={"100%"}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
            p={6}
            spacing={4}
            borderRadius={"md"}
            borderWidth={"1px"}>
            <Box>
              {selectedBudgets.length > 0 ? (
                <Text fontWeight="semibold">
                  {selectedBudgets.length} {inflect("Item", selectedBudgets.length)} Selected
                </Text>
              ) : (
                <Text color={fontColor}>Select a budget</Text>
              )}
            </Box>
            <Tooltip label={!selectedBudgets.length ? "Select budgets to move" : ""}>
              <Button
                leftIcon={<TbLetterM />}
                isLoading={moveBudgetProps.isLoading}
                variant={"outline"}
                colorScheme={selectedBudgets.length ? "teal" : "gray"}
                color={selectedBudgets.length ? "teal.500" : "gray.500"}
                size="sm"
                isDisabled={selectedBudgets.length === 0}
                onClick={moveBudgetProps.onOpen}>
                Move Selected
              </Button>
            </Tooltip>
            <Tooltip label={!selectedBudgets.length ? "Select budgets to move" : ""}>
              <Button
                leftIcon={<DeleteIcon />}
                isLoading={isLoadingRemoveBudgets}
                variant={"outline"}
                colorScheme={selectedBudgets.length ? "red" : "gray"}
                color={selectedBudgets.length ? "red.500" : "gray.500"}
                size="sm"
                isDisabled={selectedBudgets.length === 0}
                onClick={onOpen}>
                Remove Selected
              </Button>
            </Tooltip>
          </Stack>
        )}

        <Tabs colorScheme="teal" onChange={handleTabsChange} w="100%">
          <TabList
            whiteSpace={"nowrap"}
            overflowX={"auto"}
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}>
            <Tab>Teams</Tab>
            <Tab>Projects</Tab>
            <Tab>Capital Equipments</Tab>
            <Tab>User Budget</Tab>
          </TabList>
        </Tabs>

        <BudgetsList budgetsQuery={budgetsQuery} columns={columns} />
        <Box
          w="100%"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          p={3}
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Accordion w="100%" defaultIndex={[]} allowToggle>
            <AccordionItem w="100%" border="0px">
              <AccordionButton display="flex" gap={2} justifyContent="space-between">
                <Flex gap={2}>
                  <AccordionIcon />
                  <Text fontWeight="semibold">Historical Budgets</Text>
                  <Box>
                    <Badge>{historicalBudgetsQuery.data?.pagination.total}</Badge>
                  </Box>
                </Flex>
                <MoneyText
                  as="span"
                  fontWeight="semibold"
                  money={historicalBudgetSum}
                  formatOptions={{ compact: "never" }}
                />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <BudgetsList budgetsQuery={historicalBudgetsQuery} columns={historicalColumns} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        <ConfirmationModal
          colorScheme="red"
          isOpen={isOpen}
          onClose={onClose}
          header="Remove Budgets"
          children="Are you sure you want to remove the selected budgets?"
          onClick={() => {
            removeBudgets(
              { budgetIds: selectedBudgets.map((budget) => budget.id) },
              {
                onSuccess: () => {
                  setSelectedBudgets([]);
                  onClose();
                },
              }
            );
          }}
        />
        <MoveBudgetModal
          selectedBudgets={selectedBudgets}
          isOpen={moveBudgetProps.isOpen}
          isLoading={moveBudgetProps.isLoading}
          handleClose={moveBudgetProps.handleClose}
          formValues={moveBudgetProps.formValues}
          control={moveBudgetProps.control}
          handleSubmit={moveBudgetProps.handleSubmit}
          currentBudgetGroup={budgetGroup}
        />
      </VStack>
    </Box>
  );
};
