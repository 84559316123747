import React, { useMemo } from "react";
import {
  Flex,
  Heading,
  VStack,
  HStack,
  AvatarGroup,
  Avatar,
  Text,
  useColorModeValue,
  Stack,
  LinkOverlay,
  LinkBox,
} from "@chakra-ui/react";
import { TrainingSessionMinimalData } from "../../../api/training_sessions";
import { NextTraining } from "./NextTraining";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { EmptyState } from "@sciencecorp/helix-components";

export const UpcomingTrainings = ({
  location,
  trainingSessions,
}: {
  location?: string;
  trainingSessions: TrainingSessionMinimalData[];
}) => {
  const nextTraining = _.first(trainingSessions);
  const upcomingTrainings = useMemo(
    () => (location === "me" ? trainingSessions.slice(1) : trainingSessions),
    [location, trainingSessions]
  );

  return (
    <VStack
      p={6}
      maxH={trainingSessions.length > 0 ? "sm" : "auto"}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      align="start"
      spacing={4}
      w="100%"
      flex="1">
      <Heading size="sm">Upcoming Trainings</Heading>
      {trainingSessions.length > 0 ? (
        <Stack w="100%" direction={{ base: "column", lg: "row" }} overflow="auto" gap={4}>
          {nextTraining && location !== "hub" && <NextTraining trainingSession={nextTraining} />}
          <VStack
            spacing={4}
            w="100%"
            align="stretch"
            flex="1"
            maxH={location !== "hub" ? 32 : 80}
            overflow="auto">
            {upcomingTrainings.map((trainingSession) => (
              <OtherUpcomingTrainings
                key={trainingSession.id}
                trainingSession={trainingSession}
                location={location}
              />
            ))}
          </VStack>
        </Stack>
      ) : (
        <Flex w="100%" align="center" h="100%">
          <EmptyState size="xs" title="No upcoming trainings" />
        </Flex>
      )}
    </VStack>
  );
};

const OtherUpcomingTrainings = ({
  trainingSession,
  location,
}: {
  trainingSession: TrainingSessionMinimalData;
  location?: string;
}) => {
  const cardBg = useColorModeValue("gray.50", "gray.700");
  const cardHoverBg = useColorModeValue("gray.200", "gray.600");
  return (
    <LinkBox _hover={{ cursor: "pointer", bg: cardHoverBg }} bg={cardBg} w="100%">
      <LinkOverlay
        as={Link}
        to={`/trainings/${trainingSession.training.id}/training_sessions/${trainingSession.id}`}>
        <Flex
          key={trainingSession.id}
          px={4}
          py={3}
          align="center"
          justify="space-between"
          boxShadow="sm"
          borderRadius="base"
          borderLeft="4px solid"
          borderColor="gray.400">
          <HStack>
            {/* Date Section */}
            <VStack align="start" pr={4} spacing={0}>
              <Text fontSize="md" textColor={useColorModeValue("gray.500", "auto")}>
                {trainingSession.scheduled_date.toFormat("dd")}
              </Text>
              <Text fontSize="md" textColor={useColorModeValue("gray.500", "auto")}>
                {trainingSession.scheduled_date.toFormat("LLL")}
              </Text>
            </VStack>
            <VStack align="start" spacing={1}>
              <Text fontWeight="semibold" fontSize="sm">
                {trainingSession.training.name}
              </Text>
              <Text fontSize="xs" textColor={useColorModeValue("gray.500", "auto")}>
                {trainingSession.scheduled_date.toFormat("hh:mm a")}
              </Text>
            </VStack>
          </HStack>
          {location === "hub" && (
            <HStack spacing={2}>
              <AvatarGroup size="sm" max={3}>
                {trainingSession.users.slice(0, 3).map((user, index) => (
                  <Avatar key={index} src={user.picture_uri} />
                ))}
              </AvatarGroup>
            </HStack>
          )}
        </Flex>
      </LinkOverlay>
    </LinkBox>
  );
};
