import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { userMinimalSchema } from "./user";
import { createSearchResponseSchema } from "./shared";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";

export const TIMELINE_EVENT_BASE_URL = "timeline_events";

export const templateElementSchema = z.object({
  elementType: z.enum([
    "ChangeSummary",
    "Comment",
    "KeyringMessageChangeSummary",
    "Money",
    "Ref",
    "Self",
    "Text",
    "Timestamp",
    "UserLink",
    "Tag",
    "Memo",
    "IconTag",
    "Log",
  ]),
  valuePath: z.array(z.string()).optional(),
  options: z
    .object({
      fontWeight: z.string().optional(),
      label: z.string().optional(),
      refType: z.string().optional(),
      fieldTypes: z.record(z.string(), z.enum(["string", "money", "date"])).optional(),
      textTransform: z.enum(["capitalize"]).optional(),
    })
    .optional(),
});
export type TemplateElement = z.infer<typeof templateElementSchema>;

export const templateTextSchema = z
  .string()
  .transform((val) => ({ elementType: "LiteralText", value: val, valuePath: undefined }));
export type TemplateText = z.infer<typeof templateTextSchema>;

export const templateValueSchema = z.union([templateTextSchema, templateElementSchema]);
export type TemplateValue = TemplateText | TemplateElement;
export const isTemplateElement = (value: TemplateValue): value is TemplateElement => {
  return value.elementType !== "LiteralText";
};
export type Changes = {
  [key: string]: [any, any];
};

export const timelineEventSchema = z.object({
  id: z.number(),
  timelineable_id: z.number(),
  timelineable_type: z.string(),
  timelineable_app_href: z.string(),
  user: z.lazy(() => userMinimalSchema).nullable(),
  event_type: z.string(),
  content: z.string().nullable(),
  event_data: z.any({}).nullable(),
  event_data_type: z.string().nullable(),
  timeline_template: z.array(templateValueSchema).nullable(),
  notification_template: z.array(templateValueSchema).nullable(),
  call_to_action_template: z.array(templateValueSchema).nullable(),
  rendered_content: z.array(z.any()).nullable(),
  created_at: dateTimeSchema,
  updated_at: dateTimeSchema,
  slack_message: z.string().nullable(),
});

export const timelineEventArraySchema = z.array(timelineEventSchema);

export const timelineEventShowSchema = timelineEventSchema
  .omit({
    timelineable_id: true,
    timelineable_type: true,
    slack_message: true,
    content: true,
    user: true,
  })
  .extend({
    rendered_content: z.array(
      z.object({
        type: z.enum(["text", "user_name", "comment"]),
        content: z.string(),
        style: z.string().nullable(),
      })
    ),
  });

export const timelineEventSearchResponseSchema = createSearchResponseSchema(timelineEventSchema);

export type TimelineEventData = z.infer<typeof timelineEventSchema>;
export type TimelineEventShowData = Omit<
  z.infer<typeof timelineEventShowSchema>,
  "timeline_template" | "notification_template" | "call_to_action_template"
> & {
  timeline_template: TemplateValue[];
  notification_template: TemplateValue[];
  call_to_action_template: TemplateValue[];
};
export type TimelineEventSearchResponse = z.infer<typeof timelineEventSearchResponseSchema>;

export type TimelineEventCreateParams = Omit<
  TimelineEventData,
  | "id"
  | "created_at"
  | "user"
  | "event_data_type"
  | "updated_at"
  | "timeline_template"
  | "notification_template"
  | "call_to_action_template"
  | "rendered_content"
  | "timelineable_app_href"
> & {
  user_id: number;
};

export const createTimelineEvent = async (timeline_event: TimelineEventCreateParams) => {
  const result = await api.post(TIMELINE_EVENT_BASE_URL, { timeline_event });
  return result.data;
};

export const searchTimelineEvents = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams): Promise<TimelineEventSearchResponse> => {
  const path = [TIMELINE_EVENT_BASE_URL, "search"].join("/");
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const response = await api.post(path, { ...index, ...search });
  return zodParse(createSearchResponseSchema(timelineEventSchema), response.data);
};

export const updateTimelineEvent = async (timeline_event: Partial<TimelineEventData>) => {
  const result = await api.put(`${TIMELINE_EVENT_BASE_URL}/${timeline_event.id}`, {
    timeline_event,
  });
  return result.data;
};

export const deleteTimelineEvent = async (id: number) => {
  await api.delete(`${TIMELINE_EVENT_BASE_URL}/${id}`);
};

export const useCreateTimelineEvent = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: createTimelineEvent,
    onSuccess: onSuccessCallback ? onSuccessCallback() : undefined,
  });
};

export const useSearchTimelineEvents = (params: SearchParams) => {
  return useQuery({
    queryKey: [TIMELINE_EVENT_BASE_URL, params],
    queryFn: () => searchTimelineEvents(params),
  });
};

export const useUpdateTimelineEvent = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updateTimelineEvent,
    onSuccess: onSuccessCallback ? onSuccessCallback() : undefined,
  });
};

export const useDeleteTimelineEvent = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: deleteTimelineEvent,
    onSuccess: onSuccessCallback ? onSuccessCallback() : undefined,
  });
};
