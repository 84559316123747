import { InfoIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useCreateServiceRequestEvent } from "../../../api/service_request_events";
import { ServiceRequestShowData, useUpdateServiceRequest } from "../../../api/service_requests";
import { UserLoggedInData } from "../../../api/user";
import { Money } from "../../../helpers/Money";
import { MoneyText } from "../../MoneyText";

type PurchaseRequestActionProps = {
  serviceRequest: ServiceRequestShowData;
  cost: Money;
  currentUser: UserLoggedInData;
};

export const PurchaseRequestAction = ({
  serviceRequest,
  cost,
  currentUser,
}: PurchaseRequestActionProps) => {
  let component: JSX.Element | null = null;
  const { mutate: updateServiceRequest } = useUpdateServiceRequest(serviceRequest.id);

  const { mutate: createServiceRequestEvent } = useCreateServiceRequestEvent(serviceRequest.id);

  return (
    <Box display="flex" flexDirection="column" borderRadius="lg">
      <HStack
        bg={useColorModeValue("orange.100", "orange.400")}
        width="100%"
        py={3}
        px={6}
        borderTopRadius="lg"
        border="1px"
        borderColor={useColorModeValue("orange.200", "orange.200")}>
        <InfoIcon color={useColorModeValue("orange.500", "orange.200")} />
        <Text fontWeight="semibold">Action Required</Text>
      </HStack>
      <HStack
        justify="space-between"
        py={3}
        px={6}
        bg={useColorModeValue("orange.50", "gray.600")}
        borderBottomRadius="lg"
        border="1px"
        borderTop="none"
        borderColor={useColorModeValue("orange.200", "gray.50")}>
        <Text fontSize="sm" fontWeight="medium" color={useColorModeValue("gray.600", "gray.200")}>
          Approval is required to bill a service cost of{" "}
          <MoneyText
            as="span"
            fontWeight="semibold"
            color="gray.800"
            money={serviceRequest.service_cost}
          />{" "}
          to {serviceRequest.requesting_team?.name}
        </Text>
        <Button
          size="sm"
          colorScheme={useColorModeValue("teal", "blue")}
          onClick={() => {
            createServiceRequestEvent(
              {
                service_request_id: serviceRequest.id,
                user_id: currentUser.id,
                event_type: "charge",
                event_info: {
                  description: "Approval was requested to bill",
                  cost: cost,
                  type: "requesting_user",
                },
                slack_message: `has requested approval to charge ${cost.format()} to ${
                  serviceRequest.requesting_team?.name
                }`,
              },
              {
                onSuccess: () => {
                  updateServiceRequest({
                    id: serviceRequest.id,
                    status: "needs_approval",
                  });
                },
              }
            );
          }}>
          Get Approval
        </Button>
      </HStack>
    </Box>
  );
};
