import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { compensationOfferSchema } from "./compensation_offer/schema";
import { teamSchema } from "./team";
import { paginationSchema, PaginationData, Order, createSearchResponseSchema } from "./shared";
import { userMinimalSchema, userPartialSchema } from "./user";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { reviewFeedbackIndexSchema } from "./review_feedback";
import { zodParse } from "./zodParse";
import { timelineEventArraySchema } from "./timeline_events";

export const employeeEquityMilestoneIncentive = z.object({
  number_of_shares: z.number(),
  triggering_event: z.string(),
  vesting_schedule: z.string(),
});

export const employeePartialSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  name: z.string(),
  email: z.string().email(),
  picture_uri: z.string().url(),
  public_key: z.string().nullable(),
  joined_at: z.string().nullable(),
  employee_status: z.string().nullable(),
  employee_type: z.string().nullable(),
  employee_title: z.string().nullable(),
  professional_experienced_started_year: z.number().nullable(),
  level_of_education: z.string().nullable(),
  gender: z.string().nullable(),
  birth_date: z.string().nullable(),
  us_work_authorization: z.string().nullable(),
  us_visa_type: z.string().nullable(),
  us_work_authorization_expiration: z.string().nullable(),
  performance_score: z.number().nullable(),
  leads: z.array(userMinimalSchema),
  last_review_feedback_date: z.string().nullable(),
  onboarding_lead: userMinimalSchema.nullable(),
  is_reviewable: z.boolean(),
  teams: z.array(
    teamSchema.pick({
      id: true,
      name: true,
      team_type: true,
      description: true,
    })
  ),
  sites: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

export const EmployeeIndexItem = z.object({
  total: z.number(),
  results: z.array(employeePartialSchema),
  pagination: paginationSchema,
});

export type EmployeePartialData = z.infer<typeof employeePartialSchema>;

export const BASE_URL = "/hr/employees";
export const EMPLOYEES_QUERY_KEY = "employees";

/** queries */
export const getEmployee = async (id: number) => {
  const result = await api.get(`${BASE_URL}/${id}`);
  return zodParse(employeePartialSchema, result.data); // TODO:  Re-add after fixing zod-schema
};

export const getEmployeeTimelineEvents = async (id: number) => {
  const result = await api.get(`${BASE_URL}/${id}/events`);
  return zodParse(timelineEventArraySchema, result.data);
};

export const getEmployees = async (params?: object | null) => {
  const result = await api.get(BASE_URL, { params });
  return zodParse(EmployeeIndexItem, result.data);
};
export const searchEmployees = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(
    createSearchResponseSchema(employeePartialSchema).extend({ total: z.number() }),
    result.data
  );
};

export const getEmployeeOnboardingFeedback = async (id: number) => {
  const result = await api.get(`${BASE_URL}/${id}/onboarding_feedback`);
  return zodParse(reviewFeedbackIndexSchema, result.data);
};

export const getEmployeeCSV = async () => {
  const result = await api.get(`${BASE_URL}/csv/employees`, {
    responseType: "blob",
  });
  return result.data;
};

export const getTeamCSV = async () => {
  const result = await api.get(`${BASE_URL}/csv/teams`, {
    responseType: "blob",
  });
  return result.data;
};

/** hooks */

export const invalidateEmployee = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [EMPLOYEES_QUERY_KEY];
  if (id) queryKey.push(id);
  return () => queryClient.invalidateQueries({ queryKey });
};

export const useEmployeeQuery = (id: number) => {
  return useQuery({
    queryKey: [EMPLOYEES_QUERY_KEY, EMPLOYEES_QUERY_KEY + id],
    queryFn: () => getEmployee(id),
  });
};

export const useGetEmployeeTimelineQuery = (id: number) => {
  return useQuery({
    queryKey: [EMPLOYEES_QUERY_KEY, id, "events"],
    queryFn: () => getEmployeeTimelineEvents(id),
  });
};
export const useEmployeeListQuery = (indexParams?: Object | null) => {
  return useQuery({
    queryKey: [EMPLOYEES_QUERY_KEY, indexParams],
    queryFn: () => getEmployees(indexParams),
  });
};

export const useEmployeeSearchQuery = (params: SearchParams) => {
  return useQuery({
    queryKey: [EMPLOYEES_QUERY_KEY, params],
    queryFn: () => searchEmployees(params),
  });
};

export const useGetEmployeeOnboardingFeedback = (id: number) => {
  return useQuery({
    queryKey: [EMPLOYEES_QUERY_KEY, id, "onboarding_feedback"],
    queryFn: () => getEmployeeOnboardingFeedback(id),
  });
};
