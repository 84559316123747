import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { OmnisearchBar } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { useOminiSearch } from "../../api/omni_search/omni_search";

import { useDebouncedOmniSearch } from "../hooks/useDebouncedSearch";
import { OmniFilters } from "./OmniFilters";
import { OminisearchResults } from "./OmnisearchResults";
import { OmniOrderKey } from "./types";
export const OmniSearch = () => {
  const [showingCount, setShowingCount] = useState<number>(0);
  const [modelTypeFilters, setModelTypeFilters] = useState<string[]>([]);
  const { debouncedSearch, search, term } = useDebouncedOmniSearch();
  const [omniOrderKey, setOmniOrderKey] = useState<OmniOrderKey>("_score");

  const { data, isLoading } = useOminiSearch({
    term: term || search || "",
    model_type: !!modelTypeFilters.length ? modelTypeFilters : undefined,
    order: { [omniOrderKey]: "desc" },
  });

  return (
    <Box p="6">
      <Stack direction={["column-reverse", "column-reverse", "row"]} gap={5}>
        <Stack w={"full"}>
          <Box
            position={"sticky"}
            py={3}
            pt={[3, 0]}
            top={0}
            backgroundColor={useColorModeValue("white", "gray.800")}>
            <OmnisearchBar onChange={debouncedSearch} onClose={() => {}} defaultValue={term} />
            <Box display={["block", "block", "none"]} marginTop={5} w={"full"}>
              <Popover placement="bottom-start">
                <PopoverTrigger>
                  <Button leftIcon={<ChevronDownIcon />} w={"full"}>
                    Filter
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody w="100%">
                    <OmniFilters
                      modelTypeFilters={modelTypeFilters}
                      setModelTypeFilters={setModelTypeFilters}
                      omniOrderKey={omniOrderKey}
                      setOmniOrderKey={setOmniOrderKey}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
            <Flex alignItems="center" paddingBlockStart={6} justifyContent="space-between">
              <Stack display={["block", "block"]}>
                {(term || search) && (
                  <Heading fontSize={["md", "lg", "4xl"]}>
                    Search results for "{term || search}"
                  </Heading>
                )}
                {!isLoading && !!showingCount && !!data?.results?.length && (
                  <Text color={useColorModeValue("gray.700", "gray.400")} fontSize={["sm", "md"]}>
                    Showing {showingCount} of {data?.results?.length} results.
                  </Text>
                )}
              </Stack>
            </Flex>
          </Box>
          <Box w={"full"}>
            <OminisearchResults
              isLoading={isLoading}
              results={data?.results}
              itemsPerPage={15}
              setShowingCount={setShowingCount}
            />
          </Box>
        </Stack>
        <Box display={["none", "none", "block"]}>
          <OmniFilters
            modelTypeFilters={modelTypeFilters}
            setModelTypeFilters={setModelTypeFilters}
            omniOrderKey={omniOrderKey}
            setOmniOrderKey={setOmniOrderKey}
          />
        </Box>
      </Stack>
    </Box>
  );
};
