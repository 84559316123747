import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import {
  Collection,
  Column,
  EmptyState,
  Keyable,
  Order,
  Pagination,
} from "@sciencecorp/helix-components";
import { UseQueryResult } from "@tanstack/react-query";
import React, { Dispatch, useEffect } from "react";
import { Filters } from "../api/collection_types";
import { AggregationResultData, SearchResponse } from "../api/shared";
import { TableFilterState } from "./useTableReducer";

export type TabbedTableTabProps = {
  currency: string;
  term: string;
  aggs: string[] | undefined;
  filters: Filters;
  pagination: Pagination;
  order: Order;
  onPagination: (pagination: Pagination) => void;
  onOrder: (order: Order) => void;
  tableDispatch: Dispatch<TableFilterState>;
  active: boolean;
};

export type TabComponent = React.FC<TabbedTableTabProps>;

type TabBuilderArgs<TColl extends Keyable> = {
  label: string;
  columns: Column<TColl>[];
  query: (props: TabbedTableTabProps) => UseQueryResult<SearchResponse<TColl>>;
};

type TabBuilderResult = {
  label: string;
  component: TabComponent;
};

export type TabbedTableTab = {
  label: string;
  component: TabComponent;
  id: number;
  overrides: Partial<TabbedTableTabProps>;
};

export function buildTabComponent<TColl extends Keyable>(
  builderArgs: TabBuilderArgs<TColl>
): TabBuilderResult {
  const { label, columns, query } = builderArgs;

  return {
    label,
    component: (tabProps: TabbedTableTabProps) => {
      const searchQuery = query(tabProps);

      useEffect(() => {
        if (tabProps.active) {
          tabProps.tableDispatch({
            aggregations: searchQuery.data?.aggregations || null,
            pagination: searchQuery.data?.pagination || tabProps.pagination,
            filters: tabProps.filters,
          });
        }
      }, [
        searchQuery.data?.aggregations,
        searchQuery.data?.pagination,
        tabProps.filters,
        tabProps.active,
      ]);

      if (searchQuery.isError) {
        return <Box>Error loading {label}</Box>;
      } else if (searchQuery.isSuccess && !searchQuery.data?.results.length) {
        return <EmptyState title={`No ${label.toLowerCase()} yet`} size="2xs" />;
      } else {
        return (
          <Flex direction="column" gap={4}>
            <Collection
              columns={columns}
              isLoading={searchQuery.isLoading}
              items={searchQuery.data?.results || []}
              pagination={searchQuery.data?.pagination || tabProps.pagination}
              order={tabProps.order}
              onPagination={tabProps.onPagination}
              onOrder={tabProps.onOrder}
            />
          </Flex>
        );
      }
    },
  };
}

export type TabbedTableProps = {
  tabs: TabbedTableTab[];
  handleTabChange: (index: number) => void;
  defaultTabProps: TabbedTableTabProps;
  activeTab: number;
};

export const TabbedTable: React.FC<TabbedTableProps> = ({
  tabs,
  handleTabChange,
  defaultTabProps,
  activeTab,
}) => {
  return (
    <Tabs colorScheme="teal" onChange={handleTabChange}>
      <TabList overflowX={["auto", "unset"]} whiteSpace={["nowrap", "none"]}>
        {tabs.map((type, index) => (
          <Tab key={`${type}-${index}`}>{type.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel key={`tab-${tab.label}`}>
            {tab.component({
              ...defaultTabProps,
              ...tab.overrides,
              active: activeTab === index,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
