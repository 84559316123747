import { useEffect, useState } from "react";

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // OS Detection

    const isWindows = /Windows/.test(userAgent);

    const isTouchScreen = navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

    // Special case for windows touch devices
    if (isTouchScreen && isWindows) {
      setDeviceType("desktop");
    } else if (isTouchScreen) {
      setDeviceType("mobileOrTablet");
    }
  }, []);

  return deviceType;
};
