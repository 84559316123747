import React, { useMemo, useState } from "react";
import {
  Collection,
  RecordLink,
  useCollection,
  ConfirmationModal,
} from "@sciencecorp/helix-components";
import { CredentialData, useUpdateCredential } from "../../../api/credentials";
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  useColorModeValue,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiUndo } from "react-icons/ci";
import { Search2Icon } from "@chakra-ui/icons";

export const ArchivedCredentialsTable = ({ data, isLoading, onPagination, pagination }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOrder, order } = useCollection();
  const { mutate: updateCredential } = useUpdateCredential();
  const [activeCredential, setActiveCredential] = useState<CredentialData | null>(null);

  const columns = useMemo(
    () => [
      {
        label: "Name",
        render: (credential: CredentialData) => (
          <RecordLink
            type=""
            identifier={credential.name}
            link={`/credentials/all/${credential.id}`}
          />
        ),
      },
      {
        label: "Visibility",
        render: (credential: CredentialData) => (credential.is_public ? "Public" : "Private"),
      },
      {
        label: "Expires",
        render: (credential: CredentialData) =>
          credential.expiration_months ? `${credential?.expiration_months} Months` : "N/A",
      },
      {
        label: "Archived",
        render: (credential: CredentialData) => credential.assigned_users,
      },
      {
        label: "",
        render: (credential: CredentialData) => (
          <ActionsMenu
            credential={credential}
            onOpen={onOpen}
            setActiveCredential={setActiveCredential}
          />
        ),
      },
    ],
    [data]
  );

  return data?.results?.length ? (
    <Box width="100%">
      <Collection
        isLoading={isLoading}
        items={data?.results || []}
        columns={columns}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        onOrder={onOrder}
        order={order}
      />
      <ConfirmationModal
        colorScheme="teal"
        isOpen={isOpen}
        onClose={onClose}
        header="Restore Credential"
        children="Restoring this credential will place it back on the credentials list and reassign it to all previously linked employees."
        confirmText="Continue"
        onClick={() => updateCredential({ id: activeCredential?.id, is_archived: false })}
      />
    </Box>
  ) : (
    <VStack
      width="100%"
      minHeight="xs"
      justify="center"
      borderRadius="md"
      bg={useColorModeValue("gray.50", "gray.700")}
      opacity="0.7"
      spacing={5}>
      <Search2Icon boxSize={6} />
      <VStack spacing={0}>
        <Text fontWeight="semibold" fontSize="sm">
          It’s empty here.
        </Text>
        <Text fontWeight="medium" fontSize="xs">
          There are no archived credentials.
        </Text>
      </VStack>
    </VStack>
  );
};

export const ActionsMenu = ({ credential, onOpen, setActiveCredential }) => {
  const { mutate: updateCredential } = useUpdateCredential();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          gap={3}
          onClick={() => {
            onOpen();
            setActiveCredential(credential);
          }}>
          <Icon as={CiUndo} />
          Restore
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
