import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Header,
  SplitPage,
  Facets,
  useCollection,
  buildFacets,
} from "@sciencecorp/helix-components";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import { IoCubeOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useSearchInventoryLocations } from "../../api/inventory_location";
import { InventoryIndexData, useSearchInventories } from "../../api/inventory";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { Inventories } from "./Inventories";
import { Locations } from "./Locations";
import { userHasRole, useCurrentUserQuery } from "../../api/user";
import { NewInventoryModal } from "./components/NewInventoryModal";
import { FiShoppingCart } from "react-icons/fi";
import { CgScreen, CgNotes } from "react-icons/cg";
import {
  CapitalEquipmentSearchResponse,
  useSearchCapitalEquipments,
} from "../../api/capital_equipment";
import { CapitalEquipments } from "../CapitalEquipments";
import { AddIcon, ChevronDownIcon, DownloadIcon, SmallAddIcon } from "@chakra-ui/icons";
import { NewCapitalEquipment } from "../CapitalEquipments/components/NewCapitalEquipmentModal";
import { SearchResponse } from "../../api/shared";
import { Boms } from "./BOMs";
import { useSearchInventoryBoms } from "../../api/inventory_boms";
import { NewBOMModal } from "./BOMs/NewBOMModal";
import { BackgroundReportButton } from "../shared/BackgroundReportButton";

export const Inventory = () => {
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || "inventory");
  const navigate = useNavigate();
  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const {
    pagination,
    order,
    onPagination,
    onOrder,
    facets,
    onFacets,
    filters,
    resetFacets,
    resetPagination,
  } = useCollection();

  const {
    isOpen: isOpenNewCapitalEquipment,
    onOpen: onOpenNewCapitalEquipment,
    onClose: onCloseNewCapitalEquipment,
  } = useDisclosure();
  const { search, debouncedSearch } = useDebouncedSearch();

  const { isOpen: isOpenNewBom, onOpen: onOpenNewBom, onClose: onCloseNewBom } = useDisclosure();

  const tabs = [
    {
      label: "Items",
      value: "items",
      icon: <IoCubeOutline />,
    },
    { label: "Equipment", value: "equipment", icon: <CgScreen /> },
    { label: "Locations", value: "locations", icon: <HiOutlineLocationMarker /> },
    { label: "Bill of Materials", value: "boms", icon: <CgNotes /> },
  ];

  const INVENTORY_AGGREGATIONS_QUERY = [
    "category",
    "status",
    "is_archived",
    "is_consumable",
    "users",
    "sites",
    "locations",
  ];

  const capitalEquipmentBodyOptions = {
    Acquisition_Cost: {
      range: {
        field: "acquisition_cost",
        ranges: [
          { from: 0, to: 100000, key: "$0 to $1000" },
          { from: 100001, to: 500000, key: "$1000 to $5000" },
          { from: 500001, to: 1000000, key: "$5000 to $10000" },
          { from: 1000001, to: 1500000, key: "$10000 to $15000" },
          { from: 1500001, key: "More than $15000" },
        ],
      },
    },
    Total_Cost: {
      range: {
        field: "total_cost",
        ranges: [
          { from: 0, to: 100000, key: "$0 to $1000" },
          { from: 100001, to: 500000, key: "$1000 to $5000" },
          { from: 500001, to: 1000000, key: "$5000 to $10000" },
          { from: 1000001, to: 1500000, key: "$10000 to $15000" },
          { from: 1500001, key: "More than $15000" },
        ],
      },
    },
  };

  const { data, isLoading, isSuccess } =
    activeTab === "locations"
      ? useSearchInventoryLocations({
          term: search || "*",
          pagination: { per_page: -1, page: 1 },
          filters,
          order,
        })
      : activeTab === "equipment"
      ? useSearchCapitalEquipments({
          term: search || "*",
          aggs: ["team_name", "wafer_services_tool_id", "is_archived"],
          bodyOptions: capitalEquipmentBodyOptions,
          pagination,
          order,
          filters: { ...filters, is_archived: false },
        })
      : activeTab === "items"
      ? useSearchInventories({
          term: search || "*",
          pagination,
          order,
          filters: { ...filters, is_archived: false },
          aggs: INVENTORY_AGGREGATIONS_QUERY,
        })
      : useSearchInventoryBoms({
          term: search || "*",
          pagination,
          order,
          filters: { ...filters, status: ["active", "draft"] },
          aggs: ["status", "owner"],
        });

  useEffect(() => {
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
    resetFacets();
    resetPagination();
  }, [tab]);

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  return (
    <Flex direction="column">
      <NewCapitalEquipment
        isOpen={isOpenNewCapitalEquipment}
        onClose={onCloseNewCapitalEquipment}
      />
      <NewBOMModal isOpen={isOpenNewBom} onClose={onCloseNewBom} />
      <Header
        title="Inventory"
        actions={
          isInventoryManager
            ? [
                tab === "equipment" ? (
                  <BackgroundReportButton
                    leftIcon={<DownloadIcon />}
                    jobName="Reports::CapitalEquipmentCalibration"
                    args={[]}
                    colorScheme="teal"
                    variant="outline"
                    title="Export Calibration Logs"
                    buttonName="Export Calibration Logs"
                    loadingText="Generating Report..."
                  />
                ) : (
                  <></>
                ),
                <Button
                  as={RouterLink}
                  leftIcon={<FiShoppingCart />}
                  to={"/inventory/items/checkout"}>
                  Checkout Items
                </Button>,
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        leftIcon={<SmallAddIcon />}
                        rightIcon={<ChevronDownIcon />}
                        colorScheme="teal"
                        as={Button}
                        size="md"
                        aria-label="More actions"
                        isActive={isOpen}>
                        New
                      </MenuButton>
                      <MenuList>
                        <NewInventoryModal />
                        <MenuItem onClick={onOpenNewCapitalEquipment}>
                          <SmallAddIcon />
                          New Equipment
                        </MenuItem>
                        <MenuItem onClick={onOpenNewBom}>
                          <SmallAddIcon />
                          New Bill of Materials
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>,
              ]
            : [
                tab === "boms" ? (
                  <Button leftIcon={<AddIcon />} onClick={onOpenNewBom}>
                    Create Bill of Materials
                  </Button>
                ) : (
                  <></>
                ),
                <Button
                  as={RouterLink}
                  leftIcon={<FiShoppingCart />}
                  colorScheme="teal"
                  to={"checkout"}>
                  Checkout Items
                </Button>,
              ]
        }
      />
      <Box overflow="auto">
        <SplitPage
          sidebarWidth="250px"
          sidebarWidthXL="300px"
          sidebar={
            <Flex direction="column" gap={2}>
              {tabs.map((tabInfo) => (
                <Button
                  key={tabInfo.value}
                  leftIcon={tabInfo.icon}
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === tabInfo.value ? "teal" : "gray"}
                  bg={activeTab === tabInfo.value ? "teal" : "transparent"}
                  onClick={() => {
                    navigate(`/inventory/${tabInfo.value}`);
                    setActiveTab(tabInfo.value);
                  }}>
                  {tabInfo.label}
                </Button>
              ))}
              <Box display={["flex", "flex", "none"]} alignSelf="end">
                <Facets
                  variant="button"
                  defaultIndex={[]}
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
              <Box display={["none", "none", "inline"]} width="100%" alignSelf="start">
                <Facets
                  defaultIndex={activeTab === "items" ? [0, 4] : []}
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
            </Flex>
          }
          main={
            <>
              {activeTab === "items" && data && (
                <Inventories
                  data={data as SearchResponse<InventoryIndexData>}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  onOrder={onOrder}
                  order={order}
                  pagination={pagination}
                />
              )}
              {activeTab === "equipment" && (
                <CapitalEquipments
                  data={data as CapitalEquipmentSearchResponse}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  onOrder={onOrder}
                  order={order}
                  pagination={pagination}
                  isInventoryManager={isInventoryManager}
                />
              )}
              {activeTab === "locations" && (
                <Locations
                  data={data}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isInventoryManager={isInventoryManager}
                />
              )}
              {activeTab === "boms" && (
                <Boms
                  data={data}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  pagination={pagination}
                  order={order}
                  onOrder={onOrder}
                />
              )}
            </>
          }
        />
      </Box>
    </Flex>
  );
};
