import React from "react";
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryContainer,
  VictoryTooltip,
} from "victory";
import { Flex, Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";

type BarChartProps = {
  data: any;
  barLabel: (number | string)[];
  axisYLabel: string;
  tickFormat: (number | string)[];
  isPresentationMode: boolean;
};

export const BarChart = ({
  data,
  barLabel,
  axisYLabel,
  tickFormat,
  isPresentationMode,
}: BarChartProps) => {
  const barColor = useColorModeValue("#319795", "rgba(72, 187, 120, 0.6)");
  const textColor = useColorModeValue("#4A5568", "#E2E8F0");
  const axisTitleColor = useColorModeValue("#718096", "white");
  const gridColor = useColorModeValue("rgba(200, 200, 200, 0.3)", "rgba(100, 100, 100, 0.3)");

  const color = barLabel[0] === 1 ? barColor : "#718096";

  return (
    <VictoryChart
      theme={VictoryTheme.grayscale}
      domainPadding={{ x: 25 }}
      padding={{ top: 20, bottom: 30, left: 40, right: 20 }}
      containerComponent={<VictoryContainer responsive={true} />}>
      <VictoryAxis
        label="Scores"
        style={{
          axisLabel: {
            padding: 21,
            fontSize: isPresentationMode ? 10 : 6,
            fill: axisTitleColor,
            fontFamily: "inherit",
          },
          grid: { stroke: "transparent" },
          tickLabels: {
            fontSize: isPresentationMode ? 10 : 6,
            fontWeight: "bold",
            fill: textColor,
            fontFamily: "inherit",
          },
        }}
        tickFormat={barLabel}
      />
      <VictoryAxis
        dependentAxis
        label={axisYLabel}
        style={{
          grid: { stroke: gridColor, strokeWidth: 1 },
          axis: { stroke: "transparent" },
          axisLabel: {
            padding: 30,
            fontSize: isPresentationMode ? 10 : 6,
            fill: axisTitleColor,
            fontFamily: "inherit",
          },
          tickLabels: {
            fontSize: isPresentationMode ? 10 : 6,
            fill: textColor,
            fontFamily: "inherit",
          },
        }}
        tickFormat={tickFormat}
      />
      <VictoryBar
        x="x"
        y="y"
        labels={({ datum }) => {
          if (datum.y) return `${datum.y.toFixed(2)}`;
          else return "";
        }}
        labelComponent={
          <VictoryTooltip
            cornerRadius={0}
            pointerOrientation="bottom"
            pointerLength={0}
            dy={-10}
            style={{
              fontSize: isPresentationMode ? 8 : 6,
              fill: "white",
              fontFamily: "inherit",
            }}
            flyoutStyle={{
              stroke: "transparent",
              fill: "black",
              borderRadius: 0,
              padding: 5,
            }}
          />
        }
        data={data}
        style={{
          data: { fill: color },
        }}
      />
    </VictoryChart>
  );
};

type BarChartContainerProps = {
  data: any;
  title: string;
  question: string;
  barLabels: (number | string)[];
  axisYLabel: string;
  tickFormat: (number | string)[];
  isPresentationMode: boolean;
};

export const BarChartContainer = ({
  data,
  title,
  question,
  barLabels,
  axisYLabel,
  tickFormat,
  isPresentationMode,
}: BarChartContainerProps) => (
  <VStack
    align="left"
    p={8}
    width={isPresentationMode ? "50%" : ["100%", "100%", "50%"]}
    border={isPresentationMode ? "none" : "1px"}
    borderColor={isPresentationMode ? "transparent" : useColorModeValue("gray.200", "gray.600")}
    borderRadius="md"
    bg={useColorModeValue("white", "gray.700")}>
    {!isPresentationMode && <Heading size="md">{title}</Heading>}
    <Text
      textAlign={isPresentationMode ? "center" : "left"}
      fontSize={isPresentationMode ? "2xl" : "md"}
      w={isPresentationMode ? "100%" : "auto"}
      fontWeight={isPresentationMode ? "semibold" : "normal"}>
      {isPresentationMode ? `${question}` : question}
    </Text>
    <BarChart
      data={data}
      barLabel={barLabels}
      axisYLabel={axisYLabel}
      tickFormat={tickFormat}
      isPresentationMode={isPresentationMode}
    />
    {title === "Data Roundup" && !isPresentationMode && (
      <Text fontSize="sm" align="center">
        Candidate rating
      </Text>
    )}
  </VStack>
);
