import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Spinner,
  useColorModeValue,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useCurrentUserQuery } from "../../api/user";
import { useNavigate } from "react-router";
import { useNewsPostsPreviewsQuery } from "../../api/news_post";
import { NewsPostCard } from "../News/NewsPostCard";
import { PersonalProjectList } from "./components/PersonalProjectList";
import Calendar from "./Calendar/Calendar";
import EventsList from "./Calendar/EventsList";
import NewHiresComponent from "./People/NewHires";
import PeopleComponent from "./People/PeopleComponent";
import { DateTime } from "luxon";
import { Greeting } from "./components/Greeting/Greeting";
import { getWeather } from "../../api/weather";
import { useCalendarEventsQuery } from "../../api/calendar_event";
import { InProgressList } from "./components/InProgressList";
import { Link as RouterLink } from "react-router-dom";

export const Home = () => {
  const query = useCurrentUserQuery();
  const { data: userQuery, isLoading, isError } = useCurrentUserQuery();

  const { data: calendarEvents } = useCalendarEventsQuery();

  const todayEvents = (calendarEvents || []).filter((event) => {
    return event.start.hasSame(DateTime.local(), "day");
  });

  const navigate = useNavigate();
  const { data: newsPosts = [] } = useNewsPostsPreviewsQuery();
  const [showEventsList, setShowEventsList] = useState(false);
  const [selectedDate, setSelectedDate] = useState(DateTime.local());

  const handleShowCalendar = () => {
    setShowEventsList(false);
  };
  const handleDayClick = (date: DateTime) => {
    setSelectedDate(date);
  };
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async (latitude, longitude, timeZone) => {
      const data = await getWeather(latitude, longitude, timeZone);
      setWeatherData(data);
    };

    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      fetchWeatherData(latitude, longitude, timeZone);
      localStorage.setItem("location", JSON.stringify({ latitude, longitude, timeZone }));
    };

    const handleError = (error) => {
      console.warn(`ERROR(${error.code}): ${error.message}`);
      // Default location to Alameda, CA if error occurs or permission is denied
      const defaultLatitude = 37.7652;
      const defaultLongitude = -122.2416;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      fetchWeatherData(defaultLatitude, defaultLongitude, timeZone);
    };

    const cachedLocation = JSON.parse(localStorage.getItem("location") as string);
    if (cachedLocation) {
      fetchWeatherData(cachedLocation.latitude, cachedLocation.longitude, cachedLocation.timeZone);
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      console.log("Geolocation is not supported by this browser.");
      const defaultLatitude = 37.7652;
      const defaultLongitude = -122.2416;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      fetchWeatherData(defaultLatitude, defaultLongitude, timeZone);
    }
  }, []);

  if (isLoading)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );

  if (isError) return <Text>Error Loading User </Text>;

  return (
    <>
      <Flex direction={["column", "column", "row"]} gap={6}>
        <Flex
          flexDir="column"
          height="auto"
          width={["100%", "100%", "37.5%"]}
          borderRadius={"md"}
          borderWidth={[0, "1px", "1px"]}
          borderColor={useColorModeValue("gray.100", "gray.700")}
          p={[0, 4, 6]}>
          <VStack align="start" width="100%">
            <Greeting
              firstName={query.data?.first_name}
              weatherData={weatherData}
              calendarEvents={todayEvents}
              birthday={userQuery.birth_date}
              workAnniversary={userQuery.joined_at ?? ""}
            />
          </VStack>
          <Box marginBlock={6} w="100%">
            <Divider opacity={1} color={useColorModeValue("gray.100", "gray.700")} />
          </Box>
          <HStack justify="space-between" w="100%" mb={6}>
            <Heading
              textColor={useColorModeValue("gray.600", "default")}
              size="sm"
              fontWeight="semibold">
              News
            </Heading>
            <Button size="sm" onClick={() => navigate("/news")} variant="ghost" colorScheme="teal">
              View All
            </Button>
          </HStack>
          <Flex display="column" width="100%">
            {newsPosts.slice(0, 3).map((post) => (
              <NewsPostCard post={post} key={post.id} />
            ))}
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          width={["100%", "100%", "37.5%"]}
          borderRadius={"md"}
          borderWidth={[0, "1px", "1px"]}
          borderColor={useColorModeValue("gray.100", "gray.700")}
          p={[2, 4, 6]}>
          <Heading size="sm" fontWeight="semibold">
            Calendar
          </Heading>
          <Box width="100%">
            <Box width="100%">
              {showEventsList ? (
                <EventsList selectedDate={selectedDate} onShowCalendar={handleShowCalendar} />
              ) : (
                <Calendar
                  onDayClick={(date) => {
                    handleDayClick(date);
                    setShowEventsList(true);
                  }}
                />
              )}
            </Box>
          </Box>
        </Flex>
        <PeopleComponent />
      </Flex>
      <Flex
        marginInline={-4}
        paddingInline={10}
        paddingTop={8}
        pl={5}
        pr={5}
        gap={8}
        flexDir={["column", "column", "row"]}>
        <Flex direction="column" gap={6} width="100%" h="auto">
          <Flex
            width="100%"
            h="100%"
            borderRadius="md"
            direction="column"
            border="1px"
            gap={4}
            borderColor={useColorModeValue("gray.100", "gray.700")}
            padding={6}>
            <HStack justify="space-between" w="100%">
              <Heading size="sm" fontWeight="semibold">
                Affiliated Projects
              </Heading>
              <Link as={RouterLink} to={"/planning?facet=status%3Aapproved"} color="teal.500">
                View All
              </Link>
            </HStack>
            <PersonalProjectList userData={userQuery} />
          </Flex>
          <NewHiresComponent />
        </Flex>
        <InProgressList />
      </Flex>
    </>
  );
};
