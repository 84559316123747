import React, { useMemo } from "react";
import { Box, Tag } from "@chakra-ui/react";
import { Collection, RecordLink } from "@sciencecorp/helix-components";

import { titleize, humanize } from "inflection";
import { SubscriptionShowData } from "../../api/subscription";
import { subscriptionStatusColor } from "./Subscriptions/util";

export const SubscriptionsTab = ({ data, isLoading, pagination, onPagination, order, onOrder }) => {
  const columns = useMemo(
    () => [
      {
        label: "Subscriptions",
        orderOptions: { orderKey: "id" },
        render: (subscription: SubscriptionShowData) => (
          <Box data-testid={`subscription-record-link-${subscription.id}`}>
            <RecordLink
              link={`/services/subscriptions/${subscription.id}`}
              identifier={`${subscription.name}`}
              size="small"
            />
          </Box>
        ),
      },
      {
        label: "Subscription Interval",
        render: (subscription: SubscriptionShowData) => titleize(subscription.interval),
      },
      {
        label: "Amount",
        render: (subscription: SubscriptionShowData) =>
          subscription.max_amount_per_payment.format({ compact: "never" }),
      },
      {
        label: "Status",
        orderOptions: { orderKey: "status" },
        render: (subscription: SubscriptionShowData) => (
          <Tag
            colorScheme={subscriptionStatusColor(subscription.status)}
            flexShrink={0}
            whiteSpace="nowrap"
            size={["sm", "md"]}>
            {titleize(humanize(subscription.status))}
          </Tag>
        ),
      },
    ],
    [data?.results]
  );

  return (
    <Collection
      columns={columns}
      items={data?.results || []}
      isLoading={isLoading}
      onPagination={onPagination}
      pagination={data?.pagination || pagination}
      order={order}
      onOrder={onOrder}
    />
  );
};
