import {
  Box,
  HStack,
  Text,
  Stack,
  useDisclosure,
  Tag,
  IconButton,
  useToast,
  Heading,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableSelect,
  EditableText,
  RecordLink,
} from "@sciencecorp/helix-components";
import { ArrowForwardIcon, CheckIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import { RiEdit2Line } from "react-icons/ri";
import { humanize } from "inflection";
import React, { useState, useEffect } from "react";
import {
  CapitalEquipmentShowData,
  useUpdateCapitalEquipmentLocation,
  useUpdateCapitalEquipment,
} from "../../../../api/capital_equipment";
import {
  LocationId,
  LocationKeyId,
  useTreeSelectInventoryLocations,
} from "../../../../api/inventory_location";
import { useTeamOptions } from "../../../../api/options";
import { findPathByLocationId, TreeSelect } from "../../../shared/TreeSelect";
import { LocationHistoryModal } from "./LocationHistoryModal";
import { CapitalEquipmentCategoryOptions } from "../../utils";

export type CapitalEquipmentInfoProps = {
  capitalEquipment: CapitalEquipmentShowData;
  isDisabled?: boolean;
};

export const CapitalEquipmentInfo = ({
  capitalEquipment,
  isDisabled,
}: CapitalEquipmentInfoProps) => {
  const team = useTeamOptions();

  const [locationSearch, setLocationSearch] = useState<string | undefined>();
  const [location, setLocation] = useState<LocationId | null>(null);
  const [pathToLocation, setPathToLocation] = useState<LocationKeyId[] | undefined>();
  const { mutateAsync: updateLocation, isLoading: isLoadingUpdateLocation } =
    useUpdateCapitalEquipmentLocation(Number(capitalEquipment.id));
  const [editLocation, setEditLocation] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const toast = useToast();

  const treeSelectInventoryLocationsQuery = useTreeSelectInventoryLocations(locationSearch);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: updateTool, isLoading: isLoadingUpdateTool } = useUpdateCapitalEquipment(
    Number(capitalEquipment.id)
  );

  useEffect(() => {
    const locationId = location?.id || capitalEquipment.inventory_location_id;
    if (locationId) {
      const path = findPathByLocationId(treeSelectInventoryLocationsQuery.data || [], locationId);
      setPathToLocation(path.slice(1));
    }
  }, [capitalEquipment.inventory_location_id, treeSelectInventoryLocationsQuery.data, location]);

  return (
    <>
      <LocationHistoryModal
        isOpen={isOpen}
        onClose={onClose}
        capitalEquipmentId={capitalEquipment.id}
      />
      <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6} height={"100%"}>
        <Heading size="md" mb={4} fontWeight="medium">
          Basic Information
        </Heading>
        <AttributesTable
          attributes={[
            {
              label: "Asset Id",
              value: (
                <EditableText
                  preview={capitalEquipment.asset_id}
                  onSubmit={(value) => {
                    updateTool({
                      id: capitalEquipment.id,
                      asset_id: String(value),
                    });
                  }}
                  isLoading={isLoadingUpdateTool}
                  disabled={isDisabled}
                />
              ),
            },
            {
              label: "Category",
              value: (
                <EditableSelect
                  options={CapitalEquipmentCategoryOptions}
                  preview={capitalEquipment.category ? humanize(capitalEquipment.category) : ""}
                  onSubmit={(value) => {
                    updateTool({
                      id: capitalEquipment.id,
                      category: String(value),
                    });
                  }}
                  isLoading={isLoadingUpdateTool}
                  disabled={isDisabled}
                />
              ),
            },
            {
              label: "Team",
              value: (
                <EditableSelect
                  options={team}
                  preview={
                    capitalEquipment.team ? (
                      <RecordLink identifier={capitalEquipment.team.name || ""} />
                    ) : (
                      <Text>Unknown</Text>
                    )
                  }
                  onSubmit={(value) => {
                    updateTool({
                      id: capitalEquipment.id,
                      team_id: Number(value),
                    });
                  }}
                  isLoading={isLoadingUpdateTool}
                  disabled={isDisabled}
                />
              ),
            },
            {
              label: "Wafer tool id",
              value: (
                <EditableText
                  preview={capitalEquipment.wafer_services_tool_id}
                  onSubmit={(value) => {
                    updateTool({
                      id: capitalEquipment.id,
                      wafer_services_tool_id: String(value),
                    });
                  }}
                  isLoading={isLoadingUpdateTool}
                  disabled={isDisabled}
                />
              ),
            },
            {
              label: "Location",
              value: (
                <>
                  <HStack>
                    {showPreview ? (
                      <HStack>
                        <Stack
                          direction="row"
                          flexWrap="wrap"
                          px={4}
                          py={2}
                          border="1px"
                          borderRadius="md"
                          borderColor="chakra-border-color">
                          {!pathToLocation?.length ? (
                            <Text>Click to set location</Text>
                          ) : (
                            pathToLocation?.map((location, idx) => {
                              const isLast = idx === pathToLocation?.length - 1;
                              return (
                                <Box key={`destination-${location.label}-${location.value?.id}`}>
                                  <HStack>
                                    <Text
                                      color={isLast ? "teal.500" : "auto"}
                                      fontWeight={isLast ? "semibold" : "normal"}>
                                      {location.label}
                                    </Text>
                                    {idx < (pathToLocation?.length || 1) - 1 && (
                                      <ArrowForwardIcon />
                                    )}
                                  </HStack>
                                </Box>
                              );
                            })
                          )}
                        </Stack>
                      </HStack>
                    ) : (
                      <Box>
                        <TreeSelect
                          options={treeSelectInventoryLocationsQuery.data || []}
                          placeholder="Select Location"
                          isLoading={treeSelectInventoryLocationsQuery.isLoading}
                          onSearchChanged={(value) => {
                            setLocationSearch(value);
                          }}
                          defaultValue={location}
                          onChange={(value) => {
                            if (value) {
                              setLocation(value);
                              setShowPreview(true);
                            }
                          }}
                        />
                      </Box>
                    )}
                    {!editLocation ? (
                      <IconButton
                        icon={<RiEdit2Line />}
                        isDisabled={isDisabled}
                        onClick={() => {
                          setShowPreview(false);
                          setEditLocation(true);
                        }}
                        aria-label="edit location"
                        size="xs"
                      />
                    ) : (
                      <>
                        <IconButton
                          size="xs"
                          icon={<CheckIcon />}
                          aria-label="Save"
                          onClick={() => {
                            updateLocation({
                              id: capitalEquipment.id,
                              inventory_location_id: location?.id,
                            }).then(() => {
                              setEditLocation(false);
                              toast({
                                title: "Location updated",
                                status: "success",
                              });
                            });
                          }}
                        />
                        <IconButton
                          size="xs"
                          icon={<CloseIcon />}
                          aria-label="Cancel"
                          onClick={() => {
                            setEditLocation(false);
                            setShowPreview(true);
                          }}
                        />
                      </>
                    )}
                  </HStack>

                  <Tag cursor="pointer" size="sm" onClick={onOpen} marginTop={2}>
                    Location History <ChevronRightIcon />
                  </Tag>
                </>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
};
