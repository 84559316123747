import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { invalidatePurchases } from "./purchase";
import { zodParse } from "./zodParse";
import { Money, moneySchema } from "../helpers/Money";
import { dateTimeSchema } from "../helpers/dateTime";
import { userMinimalSchema } from "./user";

export const PURCHASE_LINE_ITEMS_BASE_URL = "purchase_line_items";

export const purchaseLineItemSchema = z.object({
  id: z.number(),
  purchase_id: z.number(),
  item_name: z.string(),
  quantity: z.number(),
  accounting_category: z.string().nullable(),
  unit_amount: moneySchema,
  url: z.string().nullable(),
  return_line_item_id: z.number().nullable(),
  inventory_id: z.number().nullable(),
});

export const purchaseLineItemShowSchema = purchaseLineItemSchema
  .omit({
    purchase_id: true,
  })
  .extend({
    has_inventory_items: z.boolean(),
  });

export const purchaseLineItemPendingInventorySchema = purchaseLineItemSchema
  .omit({
    accounting_category: true,
    url: true,
    return_line_item_id: true,
    inventory_id: true,
    item_name: true,
  })
  .extend({
    estimated_delivery_date: dateTimeSchema.nullable(),
    user: z.lazy(() => userMinimalSchema),
    status: z.string(),
  });

export type PurchaseLineItemData = z.infer<typeof purchaseLineItemSchema>;
export type PurchaseLineItemShowData = z.infer<typeof purchaseLineItemShowSchema>;
export type PurchaseLineItemPendingInventoryData = z.infer<
  typeof purchaseLineItemPendingInventorySchema
>;

export type PurchaseLineItemCreateParams = {
  purchase_id: number;
  item_name?: string;
  quantity?: number;
  unit_amount?: Money;
  url?: string | null;
};

export type PurchaseLineItemBulkUpdateParams = {
  purchase_line_item: Array<PurchaseLineItemCreateParams>;
};

export const createPurchaseLineItem = async (
  purchase_line_item: PurchaseLineItemCreateParams
): Promise<Pick<PurchaseLineItemData, "id">> => {
  const result = await api.post(PURCHASE_LINE_ITEMS_BASE_URL, { purchase_line_item });
  return zodParse(purchaseLineItemSchema.pick({ id: true }), result.data);
};

export const updatePurchaseLineItem = async (
  purchase_line_item: Partial<PurchaseLineItemData> & Pick<PurchaseLineItemData, "id">
) => {
  const result = await api.put(`${PURCHASE_LINE_ITEMS_BASE_URL}/${purchase_line_item.id}`, {
    purchase_line_item,
  });
  return result.data;
};

export const deletePurchaseLineItem = async (id: number) => {
  await api.delete(`${PURCHASE_LINE_ITEMS_BASE_URL}/${id}`);
};

export const bulkCreatePurchaseLineItems = async (params: PurchaseLineItemBulkUpdateParams) => {
  const result = await api.post(`${PURCHASE_LINE_ITEMS_BASE_URL}/bulk_create`, params);
  return result.data;
};

export const useCreatePurchaseLineItem = (onSuccessCallback?) => {
  return useMutation({
    mutationFn: createPurchaseLineItem,
    onSuccess: onSuccessCallback && onSuccessCallback(),
  });
};

export const useBulkCreatePurchaseLineItems = (purchaseId?: number) => {
  return useMutation({
    mutationFn: bulkCreatePurchaseLineItems,
    onSuccess: invalidatePurchases(purchaseId),
  });
};

export const useUpdatePurchaseLineItem = (purchaseId: number) => {
  return useMutation({
    mutationFn: updatePurchaseLineItem,
    onSuccess: invalidatePurchases(purchaseId),
  });
};

export const useDeletePurchaseLineItem = (purchaseId: number) => {
  return useMutation({
    mutationFn: deletePurchaseLineItem,
    onSuccess: invalidatePurchases(purchaseId),
  });
};
