import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { createSearchResponseSchema } from "./shared";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { userMinimalSchema } from "./user";
import { dateTimeSchema } from "../helpers/dateTime";
import { invalidateCredentialPackages } from "./credential_packages";

export const CREDENTIAL_PACKAGE_USERS_BASE_URL = "credential_package_users";

export const credentialPackageUserSchema = z.object({
  id: z.number(),
  credential_package_id: z.number(),
  user_id: z.number(),
});

export const trainingSummarySchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.string().nullable(),
  completed_at: dateTimeSchema.nullable(),
  next_session_at: dateTimeSchema.nullable(),
});

export const credentialSummarySchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.string().nullable(),
  trainings_count: z.number(),
  trainings_completed: z.number(),
  trainings: z.array(trainingSummarySchema),
});

export const credentialPackageUserShowSchema = z.object({
  id: z.number(),
  user: z.lazy(() => userMinimalSchema),
  team: z.array(z.object({ id: z.number(), name: z.string() })),
  progress: z.object({
    credentials_completed: z.number(),
    total_credentials: z.number(),
    credentials: z.array(credentialSummarySchema),
  }),
});

export const credentialPackageUserIndexSchema = createSearchResponseSchema(
  credentialPackageUserShowSchema
);

export type CredentialSummaryData = z.infer<typeof credentialSummarySchema>;
export type TrainingSummaryData = z.infer<typeof trainingSummarySchema>;
export type CredentialPackageUserData = z.infer<typeof credentialPackageUserSchema>;
export type CredentialPackageUserShowData = z.infer<typeof credentialPackageUserShowSchema>;
export type CredentialPackageUserCreateParams = {
  credential_package_id: number;
  user_ids: number[];
  team_ids: number[];
};

export const invalidateCredentialPackageUsers = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (number | string)[] = [CREDENTIAL_PACKAGE_USERS_BASE_URL];
  if (id) queryKey.push(id);
  return () => {
    queryClient.invalidateQueries({
      queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: ["credential_packages"],
    });
  };
};

export const searchCredentialPackageUsers = async ({
  aggs,
  filters,
  pagination,
  bodyOptions,
  order,
  term,
}: SearchParams) => {
  const path = [CREDENTIAL_PACKAGE_USERS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ bodyOptions, aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(credentialPackageUserIndexSchema, result.data);
};

export const useSearchCredentialPackageUsers = (params: SearchParams) => {
  return useQuery({
    queryKey: [CREDENTIAL_PACKAGE_USERS_BASE_URL, params],
    queryFn: () => searchCredentialPackageUsers(params),
  });
};

export const associateUsersToCredentialPackage = async (
  credential_package_user: CredentialPackageUserCreateParams
) => {
  const result = await api.post(CREDENTIAL_PACKAGE_USERS_BASE_URL, { credential_package_user });
  return result.data;
};

export const useAssociateUsersToCredentialPackage = () => {
  return useMutation({
    mutationFn: associateUsersToCredentialPackage,
    onSuccess: invalidateCredentialPackageUsers(),
  });
};

export const unassignCredentialPackageUsers = async (credential_package_user: {
  ids: number[];
}) => {
  await api.post(`${CREDENTIAL_PACKAGE_USERS_BASE_URL}/unassign`, { credential_package_user });
};

export const useUnassignCredentialPackageUsers = () => {
  return useMutation({
    mutationFn: unassignCredentialPackageUsers,
    onSuccess: invalidateCredentialPackageUsers(),
  });
};
