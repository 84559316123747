import {
  Modal,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalContent,
  ModalHeader,
  Box,
  Skeleton,
  Flex,
  HStack,
  Stack,
  ModalOverlay,
  Avatar,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { EmptyState } from "@sciencecorp/helix-components";
import { useGetCapitalEquipmentEvents } from "../../../../api/capital_equipment";
import { findPathByLocationId } from "../../../shared/TreeSelect";
import { ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import { useTreeSelectInventoryLocations } from "../../../../api/inventory_location";

export type LocationHistoryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  capitalEquipmentId: number;
};

export const LocationHistoryModal = ({
  isOpen,
  onClose,
  capitalEquipmentId,
}: LocationHistoryModalProps) => {
  const { data: events, isLoading } = useGetCapitalEquipmentEvents(capitalEquipmentId);
  const { data: locations } = useTreeSelectInventoryLocations();
  const reversedEvents = events ? [...events].reverse() : [];

  const getDatesBetweenEvents = (events: any[]) => {
    return events.map((event, index) => {
      const nextDate =
        index < events.length - 1 ? DateTime.fromISO(events[index + 1].created_at) : DateTime.now();
      return {
        ...event,
        next_created_at: nextDate.toISO(),
      };
    });
  };

  const eventsWithDuration = getDatesBetweenEvents(reversedEvents);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight="semibold">Location History</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box maxH="xs" overflowY="auto" w="100%">
            <Skeleton isLoaded={!isLoading}>
              {reversedEvents.length > 0 ? (
                reversedEvents.map((event, idx) => {
                  const path = findPathByLocationId(
                    locations || [],
                    event.event_data.location.id
                  ).slice(1);

                  return (
                    <VStack borderBottom="1px" borderColor="chakra-border-color">
                      <HStack key={event.id} w="100%" justify="space-between">
                        <Flex direction="column" gap={1}>
                          <Text
                            mt={2}
                            color={useColorModeValue("teal.600", "teal.400")}
                            fontWeight="semibold">
                            {event.event_data.location.name}
                          </Text>
                          <Stack direction="row">
                            {path?.map((location, idx) => {
                              const isLast = idx === path.length - 1;
                              return (
                                <Box key={`path-${location.label}`}>
                                  <HStack fontSize="sm">
                                    <Text
                                      fontWeight={isLast ? "medium" : "normal"}
                                      overflowWrap="break-word"
                                      whiteSpace="nowrap">
                                      {location.label}
                                    </Text>
                                    {!isLast && <ArrowForwardIcon />}
                                  </HStack>
                                </Box>
                              );
                            })}
                          </Stack>
                        </Flex>
                      </HStack>
                      <Stack
                        justify="space-between"
                        direction="row"
                        w="100%"
                        ml={4}
                        mr={4}
                        alignItems="center"
                        mb={2}>
                        <Text fontSize="xs" color="gray.500">
                          {event.created_at.toFormat("MMM d, yyyy")} -{" "}
                          {eventsWithDuration[idx - 1]
                            ? DateTime.fromISO(
                                eventsWithDuration[idx - 1].next_created_at
                              ).toFormat("MMM d, yyyy")
                            : "Present"}
                        </Text>
                        <Tooltip label={`Moved by ${event.user?.name}`}>
                          <Avatar size="xs" src={event.user?.picture_uri} />
                        </Tooltip>
                      </Stack>
                    </VStack>
                  );
                })
              ) : (
                <EmptyState title="No location history available." />
              )}
            </Skeleton>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
