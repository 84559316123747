import { Flex } from "@chakra-ui/react";
import React from "react";
import { CashBalanceChart } from "../ScheduledPayments/components/CashBalanceChart";

export const Dashboard = () => {
  return (
    <Flex direction="column" gap={4}>
      <CashBalanceChart />
    </Flex>
  );
};
