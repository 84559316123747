import React, { useMemo, useState } from "react";
import {
  Collection,
  ConfirmationModal,
  RecordLink,
  useCollection,
} from "@sciencecorp/helix-components";
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  VStack,
  useColorModeValue,
  Text,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiUndo } from "react-icons/ci";
import {
  TrainingData,
  TrainingIndexData,
  TrainingShowData,
  useUpdateTraining,
} from "../../../../api/trainings";
import { TbPresentationAnalytics } from "react-icons/tb";
import { Search2Icon } from "@chakra-ui/icons";
import { PaginationData } from "../../../../api/collection_types";

type ArchivedTrainingsTableProps = {
  data: TrainingIndexData;
  isLoading: boolean;
  onPagination: (pagination: PaginationData) => void;
  pagination: PaginationData;
};

export const ArchivedTrainingsTable = ({
  data,
  isLoading,
  onPagination,
  pagination,
}: ArchivedTrainingsTableProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOrder, order } = useCollection();
  const { mutate: updateTraining } = useUpdateTraining();
  const [activeTraining, setActiveTraining] = useState<TrainingData | null>(null);

  const columns = useMemo(
    () => [
      {
        label: "Training",
        render: (training: TrainingShowData) => (
          <RecordLink
            type=""
            icon={<Icon as={TbPresentationAnalytics} ml={2} />}
            identifier={training.name}
            link={`/trainings/${training.id}`}
          />
        ),
      },
      {
        label: "Awarded Credential",
        render: (training: TrainingShowData) =>
          training.credential_trainings.length > 0 ? (
            <HStack>
              <Tag>{training.credential_trainings[0].credential.name} </Tag>
              {training.credential_trainings.length > 1 && (
                <Text fontWeight="medium" fontSize="sm">
                  + {training.credential_trainings.length - 1} others
                </Text>
              )}
            </HStack>
          ) : (
            "N/A"
          ),
      },
      {
        label: "Actions",
        render: (training: TrainingShowData) => (
          <ActionsMenu training={training} onOpen={onOpen} setActiveTraining={setActiveTraining} />
        ),
      },
    ],
    [data]
  );

  return data?.results?.length ? (
    <Box width="100%">
      <Collection
        isLoading={isLoading}
        items={data?.results || []}
        columns={columns}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        onOrder={onOrder}
        order={order}
      />
      <ConfirmationModal
        colorScheme="teal"
        isOpen={isOpen}
        onClose={onClose}
        header="Restore Credential"
        children="Restoring this credential will place it back on the credentials list and reassign it to all previously linked employees."
        confirmText="Continue"
        onClick={() => updateTraining({ id: activeTraining?.id, is_archived: false })}
      />
    </Box>
  ) : (
    <VStack
      width="100%"
      minHeight="xs"
      justify="center"
      borderRadius="md"
      bg={useColorModeValue("gray.50", "gray.700")}
      opacity="0.7"
      spacing={5}>
      <Search2Icon boxSize={6} />
      <VStack spacing={0}>
        <Text fontWeight="semibold" fontSize="sm">
          It’s empty here.
        </Text>
        <Text fontWeight="medium" fontSize="xs">
          There are no archived trainings.
        </Text>
      </VStack>
    </VStack>
  );
};

export const ActionsMenu = ({ training, onOpen, setActiveTraining }) => (
  <Menu>
    <MenuButton
      as={IconButton}
      icon={<Icon as={BsThreeDotsVertical} />}
      variant="ghost"
      size="sm"
    />
    <MenuList fontSize="md">
      <MenuItem
        gap={3}
        onClick={() => {
          setActiveTraining(training);
          onOpen();
        }}>
        <Icon as={CiUndo} />
        Restore
      </MenuItem>
    </MenuList>
  </Menu>
);
