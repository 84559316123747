import { AddIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Alert,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  LinkBox,
  LinkOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableDate,
  EditableNumber,
  EditableSelect,
  RecordLink,
  SplitPage,
  useSecureMode,
} from "@sciencecorp/helix-components";
import { capitalize } from "inflection";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { DateTime } from "luxon";
import { invalidateEmployee, useEmployeeQuery } from "../../../api/hr";
import { usePayTypeOptions } from "../../../api/options";
import { UserPartialData, useUpdateUser, useUsersQuery } from "../../../api/user";
import {
  useGetCompensationForUser,
  UserCompensation,
  UserCompensationMessage,
  userCompensationMessageSchema,
  useUpdateUserCompensation,
} from "../../../api/user_compensation";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { KeyringZodHelper } from "../../../helpers/KeyringZodHelper";
import { Money } from "../../../helpers/Money";
import { EditableMoney } from "../../EditableMoney";
import { useDecryptOnSecureMode, useSecureModeModalWithUser } from "../../Encryption/hooks";
import { HrWarning } from "../warning";
import { NewCompensationButton } from "./components/NewCompensationButton";
import { OnboardingFeedbackTable } from "./components/OnboardingFeedbackTable";
import { TimelineTable } from "../../shared/TimelineTable";
import { useGetEmployeeTimelineQuery } from "../../../api/hr";

export const EmployeeDetail = () => {
  const { id } = useParams();
  if (!id) return null;
  const { data: employee, refetch } = useEmployeeQuery(+id);
  const compensationQuery = useGetCompensationForUser(+id);
  const { data: allUsers, isSuccess } = useUsersQuery();
  const { mutate: updateUser } = useUpdateUser(+id);
  const { data: timelineEvents, isLoading, isError } = useGetEmployeeTimelineQuery(+id);

  const [onboardingLeadOptions, setOnboardingLeadOptions] = useState<UserPartialData[]>([]);
  useEffect(() => {
    allUsers && setOnboardingLeadOptions(allUsers.filter((user) => user.id !== +id));
  }, [isSuccess]);

  const handleClick = (lead) => {
    updateUser({ id: +id, onboarding_lead_id: lead.id }, { onSuccess: () => refetch() });
    allUsers && setOnboardingLeadOptions(allUsers.filter((user) => user.id !== +id));
  };

  return (
    <>
      <HrWarning />
      <Breadcrumb color="teal.500" separator={<ChevronRightIcon color="gray.500" />} mb={2}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/hr/employees">
            HR
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/hr/employees">
            All Employees
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <SplitPage
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        breakpoint="md"
        sidebar={
          <VStack spacing={3} width="100%" align="start">
            <HStack
              width="100%"
              p={5}
              spacing={5}
              border="1px"
              bg={useColorModeValue("gray.50", "gray.700")}
              borderColor={useColorModeValue("gray.200", "gray.600")}
              borderRadius="md">
              <Avatar size="lg" src={employee?.picture_uri} />
              <VStack align="start" spacing={1}>
                <Heading size="md">{employee?.name}</Heading>
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  color={useColorModeValue("gray.500", "gray.400")}>
                  {employee?.employee_title}
                </Text>
                {employee?.employee_status && (
                  <Tag colorScheme={employee.employee_status === "active" ? "green" : "gray"}>
                    {capitalize(employee?.employee_status)}
                  </Tag>
                )}
              </VStack>
            </HStack>
            <Box
              width="100%"
              p={4}
              bg={useColorModeValue("gray.50", "gray.700")}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              borderRadius="md">
              <AttributesTable
                title="Basic Information"
                attributes={[
                  { label: "First Name", value: employee?.first_name },
                  { label: "Last Name", value: employee?.last_name },
                  {
                    label: "Date of Birth",
                    value: (
                      <EditableDate
                        defaultValue={
                          employee?.birth_date
                            ? DateTime.fromISO(employee.birth_date).toFormat("yyyy-MM-dd")
                            : ""
                        }
                        onSubmit={(value) => {
                          if (value !== undefined && value !== null) {
                            updateUser(
                              { id: employee?.id, birth_date: value },
                              { onSuccess: () => refetch() }
                            );
                          }
                        }}
                      />
                    ),
                  },
                  { label: "Gender", value: employee?.gender && capitalize(employee?.gender) },
                ]}
              />
            </Box>
            <VStack
              width="100%"
              px={4}
              py={2}
              align="start"
              bg={useColorModeValue("gray.50", "gray.700")}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              borderRadius="md">
              <HStack
                justify="space-between"
                width="100%"
                p={2}
                color={useColorModeValue("gray.600", "gray.400")}>
                <Text
                  fontSize="xs"
                  letterSpacing="wide"
                  fontWeight="bold"
                  textTransform="uppercase">
                  Onboarding Lead
                </Text>
                {!employee?.onboarding_lead && (
                  <Popover placement="bottom-end">
                    <PopoverTrigger>
                      <IconButton icon={<AddIcon />} aria-label="add onboarding lead" size="xs" />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverCloseButton />
                      <PopoverHeader>Choose Onboarding Lead</PopoverHeader>
                      <PopoverBody maxH="sm" overflowY="scroll">
                        <Input
                          mb={4}
                          bg={useColorModeValue("white", "gray.800")}
                          placeholder="Search..."
                          onChange={(e) =>
                            allUsers &&
                            setOnboardingLeadOptions(
                              allUsers.filter((ele) =>
                                ele.name.toLowerCase().includes(e.target.value.toLowerCase())
                              )
                            )
                          }
                        />
                        <VStack align="start" width="100%">
                          {onboardingLeadOptions.map((user) => (
                            <OnboardingLeadOptionCard user={user} handleClick={handleClick} />
                          ))}
                        </VStack>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </HStack>
              <Divider />
              {employee?.onboarding_lead && (
                <HStack justify="space-between" width="100%" py={1}>
                  <HStack>
                    <Avatar size="sm" src={employee?.onboarding_lead.picture_uri} />
                    <Text fontSize="sm" fontWeight="medium">
                      {employee?.onboarding_lead.name}
                    </Text>
                  </HStack>
                  <IconButton
                    icon={<CloseIcon />}
                    color="red.500"
                    size="xs"
                    bg="transparent"
                    aria-label="delete onboarding lead"
                    onClick={() => {
                      updateUser(
                        { id: +id, onboarding_lead_id: null },
                        { onSuccess: () => refetch() }
                      );
                    }}
                  />
                </HStack>
              )}
            </VStack>
          </VStack>
        }
        main={
          <VStack width="100%" spacing={6}>
            <VStack
              width="100%"
              align="start"
              p={5}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              borderRadius="md">
              <Heading size="md">Teams</Heading>
              <Stack
                direction={{ base: "column", lg: "row" }}
                width="100%"
                align="stretch"
                spacing={4}>
                {employee?.teams?.map((team) => (
                  <EmployeeTeamCard team={team} />
                ))}
              </Stack>
            </VStack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              width="100%"
              align="stretch"
              spacing={6}>
              <VStack
                align="start"
                p={5}
                border="1px"
                borderColor={useColorModeValue("gray.200", "gray.600")}
                borderRadius="md"
                width={{ base: "100%", lg: "50%" }}>
                <Heading size="md">Role Information</Heading>
                <AttributesTable
                  title=""
                  attributes={[
                    { label: "Role", value: employee?.employee_title },
                    { label: "Work Email", value: employee?.email },
                    {
                      label: "Lead",
                      value: (
                        <Stack
                          direction={{ base: "column", xl: "row" }}
                          spacing={2}
                          flexWrap="wrap">
                          {employee?.leads.map((lead) => (
                            <RecordLink
                              type=""
                              identifier={lead.name}
                              link={`/hr/employees/${lead.id}`}
                            />
                          ))}
                        </Stack>
                      ),
                    },
                    {
                      label: "Site",
                      value: employee?.sites.map((site) => site.name),
                    },
                    {
                      label: "Start Date",
                      value: (
                        <EditableDate
                          defaultValue={
                            employee?.joined_at
                              ? DateTime.fromISO(employee.joined_at).toFormat("yyyy-MM-dd")
                              : ""
                          }
                          onSubmit={(value) => {
                            if (value !== undefined && value !== null) {
                              updateUser(
                                { id: employee?.id, joined_at: value },
                                { onSuccess: () => refetch() }
                              );
                            }
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </VStack>
              <CompensationCard compensation={compensationQuery.data} userId={+id} />
            </Stack>
            <VStack
              width="100%"
              align="start"
              p={5}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              borderRadius="md">
              <Heading size="md">Onboarding Feedback</Heading>
              <OnboardingFeedbackTable employeeId={+id} />
            </VStack>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Alert status="error">Failed to load timeline events</Alert>
            ) : (
              <Flex direction="column" gap={4} w="100%">
                <Heading size="md">Comments</Heading>
                <TimelineTable
                  timelineable_id={+id}
                  timelineable_type="User"
                  events={timelineEvents}
                  onComment={invalidateEmployee(+id)}
                />
              </Flex>
            )}
          </VStack>
        }
      />
    </>
  );
};

type CompensationCardProps = {
  userId: number;
  compensation: UserCompensation | undefined | null;
};

const CompensationCardContents = ({ userId, compensation }: CompensationCardProps) => {
  const currency = useCurrency();
  useSecureModeModalWithUser("Enter your PIN to view employee compensation.");
  const [state, _dispatch] = useSecureMode();

  const { mutateAsync: updateCompensation } = useUpdateUserCompensation(userId);

  const payTypeOptions = usePayTypeOptions();

  const keyringWriteHelper = new KeyringZodHelper(userCompensationMessageSchema);

  const decryptedCompensationResult = useDecryptOnSecureMode(
    userCompensationMessageSchema,
    compensation?.keyring_message.content,
    [compensation]
  );

  if (decryptedCompensationResult.nothingToDecrypt || compensation === null) {
    return <Alert status="info">No compensation data available</Alert>;
  } else if (decryptedCompensationResult.isPending && !state.pinModal) {
    return <Alert status="warning">Secure mode is required to view employee compensation.</Alert>;
  } else if (decryptedCompensationResult.isPending) {
    return <Spinner />;
  } else if (decryptedCompensationResult.isError) {
    return <Alert status="error">{decryptedCompensationResult.errorMessage}</Alert>;
  } else if (decryptedCompensationResult.nothingToDecrypt) {
    return <Alert status="info">No compensation data available</Alert>;
  } else {
    const compensationMessage = decryptedCompensationResult.data.data;

    function updateEncryptedCompensation<T extends keyof UserCompensationMessage["data"]>(
      key: T,
      value: UserCompensationMessage["data"][T]
    ): string {
      let compData: UserCompensationMessage["data"];
      if (
        (key === "payType" && value === "hourly_v2") ||
        compensationMessage.payType === "hourly_v2"
      ) {
        compData = {
          ...compensationMessage,
          payType: "hourly_v2",
          yearlySalary: undefined,
          hourlyRate: compensationMessage.hourlyRate || Money.zero(currency),
          minHoursPerWeek: compensationMessage.minHoursPerWeek || 0,
          maxHoursPerWeek: compensationMessage.maxHoursPerWeek || 0,
          [key]: value,
        };
      } else {
        compData = {
          ...compensationMessage,
          payType: "salary_v2",
          yearlySalary: compensationMessage.yearlySalary || { currency: "USD", cents: 0 },
          hourlyRate: undefined,
          minHoursPerWeek: undefined,
          maxHoursPerWeek: undefined,
          [key]: value,
        };
      }

      return keyringWriteHelper.encrypt(
        {
          data: compData,
        },
        (compensation?.keyring_user_keys || []).map((key) => key.public_key)
      );
    }

    return (
      <AttributesTable
        title="Payroll Information"
        attributes={[
          {
            label: "Pay Type",
            value: (
              <EditableSelect
                options={payTypeOptions}
                onSubmit={(data) => {
                  if (
                    data !== "salary_v2" &&
                    data !== "hourly_v2" &&
                    data !== compensationMessage.payType
                  )
                    return;
                  const newKeyringMessageContent: UserCompensationMessage =
                    data === "hourly_v2"
                      ? {
                          data: {
                            payType: "hourly_v2",
                            yearlySalary: undefined,
                            hourlyRate: Money.zero(currency),
                            minHoursPerWeek: 0,
                            maxHoursPerWeek: 0,
                          },
                        }
                      : {
                          data: {
                            payType: "salary_v2",
                            yearlySalary: Money.zero(currency),
                            hourlyRate: undefined,
                            minHoursPerWeek: undefined,
                            maxHoursPerWeek: undefined,
                          },
                        };

                  updateCompensation({
                    userCompensationId: compensation?.id,
                    keyringMessageContent: keyringWriteHelper.encrypt(
                      newKeyringMessageContent,
                      compensation?.keyring_user_keys.map((key) => key.public_key)
                    ),
                  });
                }}
                selectedValue={compensationMessage.payType}
              />
            ),
            payType: "all",
          },
          {
            label: "Yearly Salary",
            value: (
              <EditableMoney
                defaultValue={compensationMessage.yearlySalary}
                onSubmit={(data) => {
                  if (compensationMessage.payType === "hourly_v2") return;
                  updateCompensation({
                    userCompensationId: compensation?.id,
                    keyringMessageContent: updateEncryptedCompensation("yearlySalary", data),
                  });
                }}
              />
            ),
            payType: "salary_v2",
          },
          {
            label: "Hourly Rate",
            value: (
              <EditableMoney
                defaultValue={compensationMessage.hourlyRate}
                onSubmit={(data) => {
                  if (compensationMessage.payType === "salary_v2") return;
                  updateCompensation({
                    userCompensationId: compensation?.id,
                    keyringMessageContent: updateEncryptedCompensation("hourlyRate", data),
                  });
                }}
              />
            ),
            payType: "hourly_v2",
          },
          {
            label: "Estimated Minimum Hours Per Week",
            value: (
              <EditableNumber
                defaultValue={compensationMessage.minHoursPerWeek}
                onSubmit={(data) => {
                  if (!data || parseInt(data) < 0) return;
                  updateCompensation({
                    userCompensationId: compensation?.id,
                    keyringMessageContent: updateEncryptedCompensation(
                      "minHoursPerWeek",
                      parseInt(data)
                    ),
                  });
                }}
              />
            ),
            payType: "hourly_v2",
          },
          {
            label: "Estimated Maximum Hours Per Week",
            value: (
              <EditableNumber
                defaultValue={compensationMessage.maxHoursPerWeek}
                onSubmit={(data) => {
                  if (!data || parseInt(data) < 0) return;
                  updateCompensation({
                    userCompensationId: compensation?.id,
                    keyringMessageContent: updateEncryptedCompensation(
                      "maxHoursPerWeek",
                      parseInt(data)
                    ),
                  });
                }}
              />
            ),
            payType: "hourly_v2",
          },
        ].flatMap((attr) => {
          if (attr.payType === "all" || attr.payType === compensationMessage.payType)
            return [_.omit(attr, ["payType"])];
          return [];
        })}
      />
    );
  }
};

const CompensationCard = ({ compensation, userId }: CompensationCardProps) => {
  return (
    <VStack
      width={{ base: "100%", lg: "50%" }}
      p={4}
      border="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      borderRadius="md"
      align="start">
      <HStack justify="space-between" width="100%">
        <Heading size="md">Compensation</Heading>
        {!compensation && <NewCompensationButton user_id={userId} />}
      </HStack>
      <CompensationCardContents compensation={compensation} userId={userId} />
    </VStack>
  );
};

const EmployeeTeamCard = ({ team }) => (
  <LinkBox flex="1" alignItems="stretch">
    <LinkOverlay as={Link} to={`/teams/${team.id}`}>
      <VStack
        align="start"
        height="100%"
        width="100%"
        spacing={1}
        p={4}
        bg={useColorModeValue("gray.50", "gray.600")}
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md">
        <Heading size="sm">{team.name}</Heading>
        <Text color={useColorModeValue("gray.500", "gray.400")}>
          {team.description ? team.description : "No description yet"}{" "}
        </Text>
      </VStack>
    </LinkOverlay>
  </LinkBox>
);

const OnboardingLeadOptionCard = ({ user, handleClick }) => {
  return (
    <>
      <Button onClick={() => handleClick(user)} bg="transparent">
        <HStack>
          <Avatar size="sm" src={user.picture_uri} />
          <VStack align="start">
            <Text fontSize="sm">{user.name}</Text>
          </VStack>
        </HStack>
      </Button>
    </>
  );
};
