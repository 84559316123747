import {
  Box,
  HStack,
  Icon,
  Link,
  Progress,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Collection, Column, RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ContractShowData } from "../../api/contracts";

import { MdOutlineStorefront } from "react-icons/md";
import { LinkifyText } from "./util";

import { MoneyText } from "../MoneyText";
import { Money } from "../../helpers/Money";

export const ContractsTab = ({ data, isLoading, pagination, onPagination, order, onOrder }) => {
  const columns = useMemo(
    (): Column<ContractShowData>[] => [
      {
        label: "ID",
        weight: 0.5,
        orderOptions: { orderKey: "id" },
        render: (contract) => <Text>{contract.id}</Text>,
      },
      {
        label: "Contract",
        weight: 1.5,
        orderOptions: { orderKey: "name" },
        render: (contract) => (
          <RecordLink
            maxWidth="18ch"
            link={`/services/contracts/${contract.id}`}
            identifier={`${contract.name}`}
            size="small"
          />
        ),
      },
      {
        label: "Vendor",
        orderOptions: { orderKey: "vendor_name" },
        render: (contract) => (
          <RecordLink
            maxWidth="15ch"
            type=""
            identifier={contract.vendor.name}
            link={`/services/vendors/${contract.vendor.id}`}
            icon={
              <Box ml={3} mt={1}>
                <Icon as={MdOutlineStorefront} />
              </Box>
            }
          />
        ),
      },

      {
        label: "Details",
        orderOptions: { orderKey: "description" },
        render: (contract) => (
          <VStack align="start">
            <Text maxHeight={["10ch", "10ch", "5ch", "5ch", "5ch"]} isTruncated={true}>
              <LinkifyText
                text={contract.description || ""}
                maxWidth={["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"]}
                linkProps={{
                  maxWidth: ["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"],
                }}
                isTruncated={true}
              />
            </Text>
          </VStack>
        ),
      },

      {
        label: "Installment",
        render: (contract) =>
          contract.number_total_installments ? (
            <Text>
              {contract.number_paid_installments} of {contract.number_total_installments}
            </Text>
          ) : (
            <Text>N/A</Text>
          ),
      },
      {
        label: "Cost",
        render: (contract) =>
          contract.total_cost ? (
            <Stack direction={{ base: "row", md: "column" }} align="center">
              <Progress
                size="sm"
                w={20}
                colorScheme="teal"
                value={Money.pct(contract.total_paid, contract.total_cost).toNumber()}
              />
              <Text display="flex">
                <MoneyText money={contract.total_paid} fontWeight="semibold" /> /
                <MoneyText
                  money={contract.total_cost}
                  fontWeight="semibold"
                  color={useColorModeValue("gray.500", "gray.400")}
                />
              </Text>
            </Stack>
          ) : (
            <Text>N/A</Text>
          ),
      },
    ],
    [data]
  );

  return (
    <Box maxW="100%" overflowX="scroll">
      <Stack
        flexDir={{ base: "column", md: "column", lg: "row" }}
        justify="space-between"
        spacing={[6, 4]}
        mb={8}>
        <HStack
          py={3}
          px={6}
          flex="1"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Stat>
            <StatLabel>Archived Contracts</StatLabel>
            <StatNumber>{data?.archived_contract_count}</StatNumber>
          </Stat>
          <Link as={RouterLink} to="/services/contracts/archive" color="teal.500">
            View
          </Link>
        </HStack>
      </Stack>
      <Collection
        columns={columns}
        items={data?.results || []}
        isLoading={isLoading}
        onPagination={onPagination}
        pagination={data?.pagination || pagination}
        order={order}
        onOrder={onOrder}
      />
    </Box>
  );
};
