import React, { useState } from "react";
import {
  Box,
  HStack,
  Heading,
  Button,
  Grid,
  useColorModeValue,
  useDisclosure,
  Spinner,
  Stack,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { FaEye, FaEyeSlash, FaListUl } from "react-icons/fa";

import CredentialPackageCard from "./CredentialPackageCard";
import { buildFacets, EmptyState, Facets, useCollection } from "@sciencecorp/helix-components";
import { NewCredentialPackageModal } from "./NewCredentialPackageModal";
import { useSearchCredentialPackages } from "../../../../api/credential_packages";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export const CredentialPackagesGrid: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { search } = useDebouncedSearch();
  const { filters, facets, onFacets, order } = useCollection();

  const { data, isLoading } = useSearchCredentialPackages({
    term: search || "*",
    pagination: { per_page: -1 },
    order,
    filters,
  });
  const [isSmallScreen] = useMediaQuery("(max-width: 767px)");
  const [showArchived, setShowArchived] = useState(false);

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const filteredPackages =
    data?.results.filter((pkg) => (showArchived ? true : !pkg.is_archived)) || [];

  return (
    <Box border="1px" p={5} borderRadius="md" borderColor={borderColor} width="100%">
      <HStack justify="space-between" mb={4}>
        <Heading size="md">Credential Packages</Heading>
        <Stack spacing={4} direction={{ base: "column-reverse", md: "row" }}>
          <Tooltip label={showArchived ? "Hide Archived" : "Show Archived"}>
            <IconButton
              aria-label="Toggle archived"
              icon={showArchived ? <FaEyeSlash /> : <FaEye />}
              onClick={() => setShowArchived(!showArchived)}
            />
          </Tooltip>
          <Facets variant="button" onChange={onFacets} facets={populatedFacets} />
          <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme="teal">
            {isSmallScreen ? "New" : "Create New Package"}
          </Button>
        </Stack>
      </HStack>

      {isLoading ? (
        <Spinner />
      ) : (filteredPackages.length || 0) > 0 ? (
        <Grid templateColumns="repeat(auto-fit, minmax(var(--chakra-space-64), 1fr))" gap={6}>
          {filteredPackages.map((pkg) => (
            <CredentialPackageCard key={`${pkg.id} - ${pkg.name}`} credentialPackage={pkg} />
          ))}
        </Grid>
      ) : (
        <EmptyState title="No credential packages" size="2xs" />
      )}
      <NewCredentialPackageModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};
