import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmptyState, RichTextEditor } from "@sciencecorp/helix-components";
import { BsRecordCircle, BsCurrencyDollar, BsQuestionLg } from "react-icons/bs";
import { SmallAddIcon } from "@chakra-ui/icons";
import { TimelineEventData, useCreateTimelineEvent } from "../../api/timeline_events";
import { useCurrentUserQuery } from "../../api/user";
import { PiHandCoins } from "react-icons/pi";

import { TimelineEventText } from "../shared/TimelineEventText";

type TimelineTableProps = {
  timelineable_type: string;
  timelineable_id: number;
  events: TimelineEventData[];
  onComment: (action: "created" | "updated" | "deleted") => void;
  disableCommentBox?: boolean;
};

export const TimelineTable: React.FC<TimelineTableProps> = ({
  events,
  timelineable_id,
  timelineable_type,
  onComment,
  disableCommentBox = false,
}) => {
  const currentUser = useCurrentUserQuery();
  const [session, setSession] = useState(0);
  const [content, setContent] = useState("");
  const [plainText, setPlainText] = useState("");
  const { mutate: createTimelineEvent } = useCreateTimelineEvent(
    () => async (id?: string | undefined) => {
      onComment("created");
    }
  );

  const handleComment = () => {
    if (currentUser.isSuccess) {
      setSession((prev) => ++prev);
      createTimelineEvent(
        {
          user_id: currentUser.data.id,
          timelineable_id: timelineable_id,
          timelineable_type: timelineable_type,
          event_type: "comment",
          event_data: {
            rich_text: JSON.parse(content),
            plain_text: plainText,
          },
          content,
          slack_message: `has commented: "${plainText}"`,
        },
        {
          onSuccess: () => {
            setContent("");
            setPlainText("");
          },
        }
      );
    }
  };
  const bgColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Flex flexDir="column" w="100%">
      {events.length ? (
        <Stack py={2} width="100%">
          <Stack position="relative" mb={3}>
            <Box
              position="absolute"
              height="100%"
              bg={bgColor}
              width={0.5}
              left={3.5}
              top={2}
              zIndex={-1}
            />
            <VStack align="start" spacing={5} w="100%">
              {events.map((event: TimelineEventData, idx: number) => (
                <HStack gap={1} w="100%">
                  <TimelineEventLine
                    key={event.id}
                    showInitialIcon={idx === 0}
                    event={event}
                    timelineableId={timelineable_id}
                    timelineableType={timelineable_type}
                    onComment={onComment}
                  />
                </HStack>
              ))}
            </VStack>
          </Stack>
          <Divider />
        </Stack>
      ) : (
        <EmptyState title="No events added yet. Add a comment to get started" size="3xs" />
      )}
      {disableCommentBox ? (
        <></>
      ) : (
        <>
          <VStack spacing={0} align="start" minW={{ base: "", md: "2xl" }}>
            <TimelineTableCommentBox
              session={session}
              handleComment={handleComment}
              onChange={(raw, rich) => {
                setContent(raw);
                if (rich) {
                  setPlainText(rich);
                }
              }}
            />
          </VStack>
        </>
      )}
    </Flex>
  );
};

type TimelineTableCommentBoxProps = {
  session: number;
  onChange: (raw: string, rich: string | null | undefined) => void;
  handleComment: () => void;
};
export const TimelineTableCommentBox = ({
  session,
  handleComment,
  onChange,
}: TimelineTableCommentBoxProps) => {
  const currentUser = useCurrentUserQuery();

  return (
    <VStack spacing={0} w="100%">
      <Flex align="baseline" w="100%" gap={2}>
        <Avatar size="sm" src={currentUser.data?.picture_uri} />
        <Box flex="1">
          <RichTextEditor
            width="100%"
            session={session}
            onChange={(raw, rich) => {
              onChange(raw, rich);
            }}
            placeholder="Leave a comment..."
          />
        </Box>
      </Flex>
      <Button onClick={handleComment} alignSelf="end">
        Comment
      </Button>
    </VStack>
  );
};
type TimelineEventLineProps = {
  event: TimelineEventData;
  showInitialIcon: boolean;
  timelineableId: number;
  timelineableType: string;
  onComment: (action: "created" | "updated" | "deleted") => void;
};

const TimelineEventLine = ({
  event,
  timelineableId,
  timelineableType,
  showInitialIcon,
  onComment,
}: TimelineEventLineProps) => {
  let icon: JSX.Element | null = null;

  if (event.event_type.includes("created") && showInitialIcon) {
    icon = <Icon as={SmallAddIcon} color="gray.500" boxSize={5} />;
  } else if (event.event_type.includes("comment")) {
    icon = <Avatar size="sm" src={event.user?.picture_uri} />;
  } else if (event.event_type === "funded" || event.event_type === "reallocated_funds") {
    icon = <Icon as={BsCurrencyDollar} color="green.500" boxSize={5} />;
  } else if (event.event_type === "deallocated_funds") {
    icon = <Icon as={PiHandCoins} color="orange.500" boxSize={5} />;
  } else if (event.event_type === "requested_funding") {
    icon = <Icon as={BsQuestionLg} color="yellow.500" boxSize={5} />;
  } else {
    icon = <Icon as={BsRecordCircle} color="gray.500" boxSize={5} />;
  }

  const graybg = useColorModeValue("gray.100", "gray.900");
  const moneybg = useColorModeValue("green.100", "green.900");

  const requestedbg = useColorModeValue("orange.100", "orange.900");
  return (
    <HStack gap={1} w="100%">
      <Box
        borderRadius="full"
        bg={
          event.event_type === "funded" || event.event_type === "reallocated_funds"
            ? moneybg
            : event.event_type === "requested_funding" || event.event_type === "deallocated_funds"
            ? requestedbg
            : graybg
        }
        boxSize={8}
        display="flex"
        alignSelf="start"
        justifyContent="center"
        alignItems="center">
        {icon}
      </Box>
      <TimelineEventText
        event={event}
        timelineableId={timelineableId}
        timelineableType={timelineableType}
        onComment={onComment}
      />
    </HStack>
  );
};
