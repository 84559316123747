import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";
import { invalidateInventoryBom } from "./inventory_boms";

export const INVENTORY_BOM_USER_BASE_URL = "/inventory_bom_users";

export const inventoryBomUserSchema = z.object({
  id: z.number(),
  inventory_bom_id: z.number(),
  user: userMinimalSchema,
});

export const inventoryBomUserCreateSchema = z.object({
  inventory_bom_id: z.number(),
  user_id: z.number(),
});

export const inventoryBomUserBulkCreateSchema = z.object({
  inventory_bom_id: z.number(),
  user_ids: z.array(z.number()),
});

export type InventoryBomUserData = z.infer<typeof inventoryBomUserSchema>;
export type InventoryBomUserBulkCreateParams = z.infer<typeof inventoryBomUserBulkCreateSchema>;

export const createInventoryBomUser = async (
  inventory_bom_user: InventoryBomUserBulkCreateParams
) => {
  const result = await api.post(INVENTORY_BOM_USER_BASE_URL, { inventory_bom_user });
  return zodParse(inventoryBomUserSchema.pick({ id: true }), result.data);
};

export const bulkCreateInventoryBomUser = async (
  inventory_bom_users: InventoryBomUserBulkCreateParams
) => {
  const result = await api.post(`${INVENTORY_BOM_USER_BASE_URL}/bulk`, {
    inventory_bom_users,
  });
  return result.status === 201;
};

export const deleteInventoryBomUser = async (id: number) => {
  await api.delete(`${INVENTORY_BOM_USER_BASE_URL}/${id}`);
};

/** hooks */

export const useCreateInventoryBomUser = (id?: number) => {
  return useMutation({
    mutationFn: createInventoryBomUser,
    onSuccess: invalidateInventoryBom(id),
  });
};

export const useBulkCreateInventoryBomUser = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateInventoryBomUser,
    onSuccess: invalidateInventoryBom(id),
  });
};

export const useDeleteInventoryBomUser = (id?: number) => {
  return useMutation({
    mutationFn: deleteInventoryBomUser,
    onSuccess: invalidateInventoryBom(id),
  });
};
