import { InitialPagination } from "@sciencecorp/helix-components/dist/hooks/useCollection";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { budgetPartialSchema } from "./budget";
import { BUDGET_HEADCOUNTS_BASE_URL } from "./budget_headcounts";
import { budgetItemSummarySchema, budgetItemWithSummarySchema } from "./budget_items";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { milestonePartialSchema } from "./milestone";
import { purchaseIndexItemSchema } from "./purchase";
import { createSearchResponseSchema, paginationSchema } from "./shared";
import { userPartialSchema } from "./user";
import { moneySchema } from "../helpers/Money";

export const TEAMS_BASE_URL = "teams";
export const TEAM_MEMBERSHIPS_BASE_URL = "team_memberships";

/** team_memberships */
export const teamMembershipSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  team_id: z.number(),
  is_lead: z.boolean().optional().default(false),
});
export const teamMembershipPartialSchema = teamMembershipSchema
  .pick({
    id: true,
    is_lead: true,
  })
  .extend({
    user: z.lazy(() =>
      userPartialSchema.pick({
        id: true,
        name: true,
        picture_uri: true,
      })
    ),
  });

export const teamSummarySchema = z.object({
  team_name: z.string(),
  team_id: z.number(),
  summary: z.lazy(() => budgetItemSummarySchema),
  project_summaries: z.array(
    z.object({
      project_id: z.number(),
      project_name: z.string(),
      budget_items: z.array(z.lazy(() => budgetItemWithSummarySchema)),
      summary: z.lazy(() => budgetItemSummarySchema),
      project_members: z.number().nullable(),
      project_status: z.string(),
    })
  ),
  budget_items: z.array(z.lazy(() => budgetItemWithSummarySchema)),
  parent_team_id: z.number().nullable(),
  team_membership_count: z.number().nullable(),
});

export type TeamMembershipData = z.infer<typeof teamMembershipSchema>;
export type TeamMembershipPartialData = z.infer<typeof teamMembershipPartialSchema>;
export type TeamMembershipCreateParams = Pick<
  TeamMembershipData,
  "team_id" | "user_id" | "is_lead"
>;

export type teamSummarySchemaData = z.infer<typeof teamSummarySchema>;

/** team_metrics */
export const teamMetricSchema = z.object({
  id: z.number(),
  team_id: z.number(),
  label: z.string(),
  value: z.number(),
  effective_at: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});
export const teamMetricPartialSchema = teamMetricSchema.pick({
  id: true,
  label: true,
  value: true,
  effective_at: true,
});

export type TeamMetricData = z.infer<typeof teamMetricSchema>;
export type TeamMetricPartialData = z.infer<typeof teamMetricPartialSchema>;

/** team */
export const teamTypes: [string, ...string[]] = ["platform", "project", "operations"];
export const teamTypeSchema = z.enum(teamTypes);
const teamPartialNonRecursiveSchema = z.object({
  id: z.number(),
  name: z.string().default(""),
  description: z.string().default(""),
  autonomous_spending: z.boolean(),
  parent_team_id: z.number().nullable(),
  all_sub_team_ids: z.array(z.number()),
  team_type: teamTypeSchema.nullable(),
  top_level_team: z.object({ id: z.number(), name: z.string() }).nullable(),
  purchasing_approval_threshold: moneySchema.default({ cents: 0, currency_iso: "USD" }),
  created_at: z.string(),
  updated_at: z.string(),
  archived_at: z.string().nullable().optional(),
  can_act_as_lead: z.boolean(),
  can_act_as_member: z.boolean(),
  has_sub_teams: z.boolean(),
  all_memberships: z.array(z.lazy(() => teamMembershipPartialSchema)).optional(),
  can_be_deleted: z.boolean(),
  is_archived: z.boolean(),
  budget_id: z.number().nullable(),
});
export const teamPartialSchema = teamPartialNonRecursiveSchema.extend({
  sub_teams: z.array(z.lazy(() => teamPartialSchema)).optional(),
});
export const teamSchema = teamPartialSchema;

const teamShowNonRecursiveSchema = teamPartialNonRecursiveSchema.extend({
  asana_team_link: z.string().nullable(),
  github_team: z.string().nullable(),
  slack_channel: z.string().nullable(),
  readme: z.record(z.unknown()).nullable(),
  milestones: z.array(z.lazy(() => milestonePartialSchema)),
  metrics: z.array(z.lazy(() => teamMetricPartialSchema)),
  budget_id: z.number().nullable(),
  can_edit_autonomous_spending: z.boolean(),
});

export const teamShowSchema = teamShowNonRecursiveSchema.extend({
  parent_team: z.lazy(() => teamSchema.optional()),
  sub_teams: z.array(z.lazy(() => teamSchema)),
});

export const teamIndexSchema = z.object({
  results: z.array(teamPartialSchema),
  pagination: paginationSchema,
});

export const teamUnattributedPurchasesSchema = z.object({
  results: z.array(purchaseIndexItemSchema),
  total_amount: moneySchema,
});

export const teamMembershipsWithBudgetLeadSchema = z.object({
  team_memberships: z.array(z.lazy(() => teamMembershipPartialSchema)),
  budget_lead: z.lazy(() => teamMembershipPartialSchema).nullable(),
});

export type TeamData = z.infer<typeof teamPartialNonRecursiveSchema> & {
  sub_teams?: TeamData[] | undefined;
};

export type TeamShowData = z.infer<typeof teamShowNonRecursiveSchema> & {
  sub_teams: TeamShowData[] | undefined;
  parent_team?: TeamShowData;
};

export type TeamIndexData = z.infer<typeof teamIndexSchema> & { results: TeamData[] };

export type TeamCreateParams = Pick<TeamData, "name" | "team_type" | "parent_team_id">;
export type TeamType = z.infer<typeof teamTypeSchema>;
export type TeamUnattributedPurchasesData = z.infer<typeof teamUnattributedPurchasesSchema>;
/** api-queries */
export const getTeam = async (id: number): Promise<TeamShowData> => {
  const result = await api.get(`${TEAMS_BASE_URL}/${id}`);
  return zodParse(teamShowSchema, result.data);
};

export const getTeams = async (params?: Object | null): Promise<TeamIndexData> => {
  const result = await api.get(TEAMS_BASE_URL, { params });
  return zodParse(teamIndexSchema, result.data);
};

export const generatePurchaseCSV = async (team_id?: number) => {
  const result = await api.get(`${TEAMS_BASE_URL}/csv`, {
    params: { id: team_id },
    responseType: "blob",
  });

  return result.data;
};

export const getTeamBudget = async (id: number | null) => {
  const result = await api.get(`${TEAMS_BASE_URL}/${id}/budget`);
  return zodParse(budgetPartialSchema, result.data);
};

export const searchTeams = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [TEAMS_BASE_URL, "search"].join("/");
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const response = await api.post(path, { ...index, ...search });
  return zodParse(createSearchResponseSchema(teamPartialSchema), response.data);
};

export const getTeamMemberships = async (id: number | null) => {
  const result = await api.get(`${TEAMS_BASE_URL}/${id}/team_memberships`);
  return zodParse(teamMembershipsWithBudgetLeadSchema, result.data);
};

export const newTeam = async (team: TeamCreateParams) => {
  return api.post(TEAMS_BASE_URL, { team }).then((res) => res.data.id);
};
export const updateTeam = async (team: Partial<TeamShowData>) => {
  const result = await api.put(`${TEAMS_BASE_URL}/${team.id}`, { team: _.omit(team, "id") });
  return result.data;
};
export const deleteTeam = async (id: number) => {
  await api.delete(`${TEAMS_BASE_URL}/${id}`);
};
export const newTeamMembership = async (team_membership: TeamMembershipCreateParams) => {
  try {
    const result = await api.post(TEAM_MEMBERSHIPS_BASE_URL, { team_membership });
    return result.data.id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const updateTeamMembership = async (team_membership: Partial<TeamMembershipData>) => {
  const result = await api.put(`${TEAM_MEMBERSHIPS_BASE_URL}/${team_membership.id}`, {
    team_membership,
  });
  return result.data;
};
export const deleteTeamMembership = async (id: string | number) => {
  await api.delete(`${TEAM_MEMBERSHIPS_BASE_URL}/${id}`);
};
/** api-query-hooks */
export const invalidateTeams = (teamId?: number, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: any[] = [TEAMS_BASE_URL];
  if (teamId) queryKey.push(teamId);
  if (subQuery) queryKey.push(subQuery);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const invalidateTeamAndBudgetHeadcount = (teamId: number) => {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries([TEAMS_BASE_URL, teamId, "team_memberships"]);
    await queryClient.invalidateQueries([BUDGET_HEADCOUNTS_BASE_URL]);
  };
};

export const useTeamsQuery = (indexParams?: InitialPagination | null) => {
  return useQuery({
    queryKey: [TEAMS_BASE_URL, indexParams],
    queryFn: () => getTeams(indexParams),
  });
};

export const useAllTeamsQuery = () => {
  const teamsQuery = useTeamsQuery({ per_page: -1 });
  const teamsData: TeamData[] | undefined = teamsQuery.data?.results;
  return { teams: teamsData, ...teamsQuery };
};

export const useSearchTeams = (params: SearchParams) => {
  return useQuery({
    queryKey: [TEAMS_BASE_URL, params],
    queryFn: () => searchTeams(params),
  });
};

export const useTeamQuery = (id: number | undefined | null) => {
  return useQuery({
    queryKey: [TEAMS_BASE_URL, id],
    queryFn: () => getTeam(id as number),
    enabled: id !== undefined && id !== null,
  });
};

export const useGetTeamMemberships = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [TEAMS_BASE_URL, id, "team_memberships"],
    queryFn: () => getTeamMemberships(id as number),
    enabled: id !== undefined && id !== null,
  });
};

export const useGetTeamBudget = (id: number | null) => {
  return useQuery({
    queryKey: [TEAMS_BASE_URL, id, "budget"],
    queryFn: () => getTeamBudget(id),
    enabled: !!id,
  });
};

export const useNewTeam = () => {
  return useMutation({
    mutationFn: newTeam,
    onSuccess: invalidateTeams(),
  });
};
export const useUpdateTeam = (team_id?: number) => {
  return useMutation({
    mutationFn: updateTeam,
    onSuccess: invalidateTeams(),
  });
};
export const useDeleteTeam = (parentTeamId?: number) => {
  return useMutation({
    mutationFn: deleteTeam,
    onSuccess: invalidateTeams(),
  });
};
export const useNewTeamMembership = (team_id?: number) => {
  return useMutation({
    mutationFn: newTeamMembership,
    onSuccess: invalidateTeams(team_id),
  });
};
export const useUpdateTeamMembership = (team_id: number) => {
  return useMutation({
    mutationFn: updateTeamMembership,
    onSuccess: invalidateTeams(team_id, "team_memberships"),
  });
};
export const useDeleteTeamMembership = (team_id: number) => {
  return useMutation({
    mutationFn: deleteTeamMembership,
    onSuccess: invalidateTeams(team_id, "team_memberships"),
  });
};
