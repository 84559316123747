import React from "react";
import {
  VStack,
  Box,
  Text,
  HStack,
  Heading,
  Flex,
  UnorderedList,
  ListItem,
  Stack,
  useColorModeValue,
  Button,
  SkeletonText,
  SimpleGrid,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { sortBy } from "lodash";
import { EditableText, RecordLink } from "@sciencecorp/helix-components";
import { CandidateData, CandidateKeyringMessageData } from "../../../api/candidates";

type SummaryCandidateFeedbackProps = {
  candidate: CandidateData;
  section: "Summary";
  question?: string;
  editable?: boolean;
  finishedFeedback: CompletedCandidateFeedback[];
  onSummaryUpdatePressed: () => void;
  updateCandidate: (data: Partial<CandidateKeyringMessageData>) => void;
  isLoading: boolean;
  summary?: string;
  isPresentationMode: boolean;
};

type OtherCandidateFeedbackProps = {
  candidate: CandidateData;
  section: "Explanations" | "Strengths" | "Opportunity for Growth/Areas of Concern";
  question?: string;
  editable?: boolean;
  finishedFeedback: CompletedCandidateFeedback[];
  isLoading?: boolean;
  onSummaryUpdatePressed?: undefined;
  updateCandidate: (data: Partial<CandidateKeyringMessageData>) => void;
  summary?: undefined;
  isPresentationMode: boolean;
};

type CandidateFeedbackProps = SummaryCandidateFeedbackProps | OtherCandidateFeedbackProps;

export type CompletedCandidateFeedback = {
  id: number;
  candidate_id: number;
  user_id: number;
  user: { id: number; name: string; picture_uri: string };
  technical_score: number;
  culture_score: number;
  mission_score: number;
  communication_score: number;
  overall_score: number;
  overall_feedback: string;
  strengths: string;
  concerns: string;
};

export type PendingCandidateFeedback = {
  id: number;
  candidate_id: number;
  user_id: number;
  user: { id: number; name: string; picture_uri: string };
};
const sectionMap = {
  Explanations: "overall_feedback",
  Strengths: "strengths",
  "Opportunity for Growth/Areas of Concern": "concerns",
};

const colors = () => ({
  4: useColorModeValue("green.100", "green.700"),
  3: useColorModeValue("blue.100", "blue.700"),
  2: useColorModeValue("orange.100", "orange.700"),
  1: useColorModeValue("red.100", "red.700"),
});

export const CandidateFeedback: React.FC<CandidateFeedbackProps> = ({
  candidate,
  section,
  question,
  editable,
  finishedFeedback,
  onSummaryUpdatePressed,
  updateCandidate: updateCandidateKRData,
  isLoading = false,
  summary,
  isPresentationMode,
}) => {
  const sortedFeedback = sortBy(finishedFeedback, ["overall_score"]).reverse();

  return (
    <Box
      w={isPresentationMode ? "100%" : ["100%", "100%", "50%"]}
      bg={useColorModeValue("white", "gray.700")}
      p={isPresentationMode ? 0 : 8}
      border={isPresentationMode ? "none" : "1px"}
      borderRadius="md"
      borderColor={useColorModeValue("gray.200", "gray.600")}>
      <Box
        minH={400}
        height="100%"
        width="100%"
        pt={isPresentationMode ? 0 : 4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between">
        <Box>
          {!isPresentationMode && <Heading size="md">{section}</Heading>}
          {section === "Summary" && (
            <>
              <Stack
                direction={{ base: "column", md: "row" }}
                mt={3}
                justifyContent={"space-between"}
                alignItems={["start", "start", "center"]}
                spacing={{ base: 3, md: 6 }}>
                <Text>
                  <Flex alignItems="center" direction="row" gap={2}>
                    <Box as="span" fontWeight="bold">
                      Role:
                    </Box>{" "}
                    {candidate.budget_headcount ? (
                      <RecordLink
                        size="medium"
                        link={`/hiring/vacancies/${candidate.budget_headcount?.id}`}
                        type={candidate.budget_headcount?.budgetable.name}
                        identifier={candidate.budget_headcount?.archetype.name || candidate.role}
                      />
                    ) : (
                      <Text>{candidate.role}</Text>
                    )}
                  </Flex>
                </Text>
                <Text>
                  <Flex alignItems="center" direction="row" gap={2}>
                    <Box as="span" fontWeight="bold">
                      Hiring Manager:{" "}
                    </Box>{" "}
                    <Text as="span">{candidate.hiring_manager_name}</Text>
                  </Flex>
                </Text>
                <Flex alignItems="center" direction="row" gap={2}>
                  <Text fontWeight="bold" fontSize="md">
                    Test Score:
                  </Text>
                  <EditableText
                    persistentEdit={true}
                    preview={
                      <Text fontSize="md">
                        {candidate.test_score ? `${candidate.test_score}%` : "N/A"}
                      </Text>
                    }
                    defaultValue={candidate.test_score ? candidate.test_score.toString() : ""}
                    onSubmit={(value) => {
                      updateCandidateKRData({ test_score: Number(value) });
                    }}
                  />
                </Flex>
              </Stack>
              <Flex direction="column" w="100%" mt={2}>
                <Text fontWeight="bold" fontSize="md">
                  Evidence of Exceptional Ability:
                </Text>
                <Box flex="1">
                  <EditableText
                    persistentEdit={editable}
                    disabled={!editable}
                    multiline={true}
                    defaultValue={candidate.exceptional_ability ?? undefined}
                    placeholder="Add evidence of exceptional ability"
                    onSubmit={(value) => {
                      if (value) updateCandidateKRData({ exceptional_ability: value });
                    }}
                  />
                </Box>
              </Flex>
            </>
          )}
        </Box>
        {question && (
          <Text
            my={2}
            textAlign={isPresentationMode ? "center" : "left"}
            fontSize={isPresentationMode ? "2xl" : "md"}
            fontWeight={isPresentationMode ? "bold" : "normal"}>
            {isPresentationMode ? `${question}` : question}
          </Text>
        )}
        <Flex direction="column" flexGrow={1} height="100%">
          {section === "Summary" ? (
            <Flex direction="column" justify="space-between" height="100%" mt={6} mr={4}>
              {summary && summary.length ? (
                isLoading ? (
                  <SkeletonText noOfLines={5} skeletonHeight={6} />
                ) : (
                  <Text fontSize={isPresentationMode ? "2xl" : "md"}>{summary}</Text>
                )
              ) : isLoading ? (
                <SkeletonText noOfLines={5} skeletonHeight={6} />
              ) : (
                <EmptyFeedback />
              )}
              {editable && (
                <Button
                  onClick={() => {
                    if (finishedFeedback.length > 0) {
                      onSummaryUpdatePressed();
                    }
                  }}
                  isDisabled={isLoading || !finishedFeedback.length}>
                  Generate Summary
                </Button>
              )}
            </Flex>
          ) : sortedFeedback.length && section === "Explanations" ? (
            isPresentationMode ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                alignItems="center"
                gap={4}>
                <FeedbackQuotesKey />
                <SimpleGrid
                  templateColumns="repeat(auto-fit, minmax(min(100%, 400px), 1fr))"
                  spacing="4"
                  w={"100%"}>
                  {sortedFeedback.map((ele, idx) => (
                    <FeedbackQuotes
                      key={idx}
                      info={ele.overall_feedback}
                      score={ele.overall_score}
                      isPresentationMode={isPresentationMode}
                    />
                  ))}
                </SimpleGrid>
              </Box>
            ) : (
              <VStack spacing={4} align="stretch" w="100%" mt={4}>
                {sortedFeedback.map((ele, idx) => (
                  <FeedbackQuotes
                    key={idx}
                    info={ele.overall_feedback}
                    score={ele.overall_score}
                    isPresentationMode={isPresentationMode}
                  />
                ))}
                <FeedbackQuotesKey />
              </VStack>
            )
          ) : finishedFeedback.length ? (
            <FeedbackList
              info={finishedFeedback.map((ele) => ele[sectionMap[section]])}
              isPresentationMode={isPresentationMode}
            />
          ) : (
            <EmptyFeedback />
          )}
        </Flex>
      </Box>
    </Box>
  );
};
export const EmptyFeedback = () => (
  <Box opacity="0.7" display="flex" height="100%" width="100%" justifyContent="center">
    <VStack height="100%" justify="center" align="center">
      <Box bg={"RGBA(0, 0, 0, 0.08)"} p={2} borderRadius="full">
        <Search2Icon boxSize={6} />
      </Box>
      <Heading size="sm">It's Empty Here</Heading>
      <Text align="center" maxWidth={80}>
        This section will be populated after a few submissions have been made.
      </Text>
    </VStack>
  </Box>
);

export const FeedbackQuotes = ({ info, score, isPresentationMode }) => {
  const c = colors();
  return (
    <Box bg={c[score]} minW={["50%", "30%"]} flexGrow={1} borderRadius="md" p={2.5}>
      <Text
        color={useColorModeValue("gray.700", "gray.300")}
        fontSize={isPresentationMode ? "2xl" : "md"}>
        "{info}"
      </Text>
    </Box>
  );
};

export const FeedbackList = ({ info, isPresentationMode }) => {
  const hasContent = info.some((str: string) => str.length > 0);
  return hasContent ? (
    <UnorderedList mt={2}>
      {info.map((bullet: string) => {
        return bullet.length ? (
          <ListItem key={bullet} fontSize={isPresentationMode ? "2xl" : "md"}>
            {bullet}
          </ListItem>
        ) : null;
      })}
    </UnorderedList>
  ) : (
    <EmptyFeedback />
  );
};

const FeedbackQuotesKey = () => {
  const colorKey: JSX.Element[] = [];
  const c = colors();
  for (const key in c) {
    colorKey.push(
      <HStack key={key}>
        <Box
          bg={c[key]}
          my={2}
          borderRadius="md"
          p={2.5}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}></Box>
        <Text>{Number(key)}</Text>
      </HStack>
    );
  }

  return (
    <HStack>
      <Text>Overall Score: </Text>
      {colorKey.reverse()}
    </HStack>
  );
};
