import { AddIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack, IconButton, Spinner, useDisclosure } from "@chakra-ui/react";
import { buildFacets, EmptyState, Facets, useCollection } from "@sciencecorp/helix-components";
import React from "react";
import { MaintenanceModal } from "./MaintenanceModal";
import { CapitalEquipmentShowData } from "../../../../api/capital_equipment";
import { useSearchCapitalEquipmentLog } from "../../../../api/capital_equipment_log";
import { TimelineLog } from "./TimelineLog";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export type MaintenanceLogsProps = {
  capitalEquipment: CapitalEquipmentShowData;
};

export const MaintenanceLogs = ({ capitalEquipment }: MaintenanceLogsProps) => {
  const { pagination, filters, facets, onFacets } = useCollection({
    pagination: { per_page: 100 },
  });
  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: logs, isLoading } = useSearchCapitalEquipmentLog({
    term: search || "*",
    filters: {
      ...filters,
      capital_equipment_id: [capitalEquipment.id],
    },
    aggs: ["log_type", "vendor", "user", "start_date", "end_date"],
    pagination,
  });
  const populatedFacets = buildFacets(logs?.aggregations || {}, facets);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      border="1px"
      borderRadius="md"
      borderColor="chakra-border-color"
      p={6}
      w={"100%"}
      height={"100%"}>
      <MaintenanceModal isOpen={isOpen} onClose={onClose} capitalEquipment={capitalEquipment} />
      <HStack justifyContent={"space-between"} alignItems={"center"} mb={4}>
        <Heading size="md" fontWeight="medium">
          Log
        </Heading>
        <Box display={"flex"} justifyContent="flex-end" w="100%">
          <Facets
            variant="button"
            facets={populatedFacets}
            onChange={onFacets}
            background
            debouncedSearch={debouncedSearch}
          />
        </Box>
        <Box>
          <IconButton size={"sm"} aria-label="add-log" icon={<AddIcon />} onClick={onOpen} />
        </Box>
      </HStack>
      {logs?.results && logs.results.length > 0 ? (
        <TimelineLog
          events={logs.results.flatMap((log) => log.timeline_events)}
          allData={logs.results}
          timelineable_id={logs.results[0].id}
          timelineable_type="CapitalEquipmentLog"
        />
      ) : (
        <EmptyState title="Add a log to set reminders" />
      )}
    </Box>
  );
};
