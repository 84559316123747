import React, { useState, useEffect } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Switch,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  VStack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import {
  TrainingSessionData,
  TrainingSessionShowData,
  useNewTrainingSession,
  useUpdateTrainingSession,
} from "../../../../api/training_sessions";
import { useUserOptions } from "../../../../api/options";
import { invalidateTraining } from "../../../../api/trainings";
import { trainingSessionInPast } from "../../util";

type NewTrainingSessionProps = {
  isOpen: boolean;
  onClose: () => void;
  trainingId?: number;
  trainingSession?: TrainingSessionShowData;
};

export const NewTrainingSession = ({
  trainingId,
  isOpen,
  onClose,
  trainingSession,
}: NewTrainingSessionProps) => {
  const [trainingDate, setTrainingDate] = useState<string>("");
  const [facilitator, setFacilitator] = useState<string | null>(null);
  const [limitAttendees, setLimitAttendees] = useState<boolean>(false);
  const [maxAttendees, setMaxAttendees] = useState<number>(0);
  const [pastTraining, setPastTraining] = useState<boolean>(false);
  const userOptions = useUserOptions();
  const { mutate: newTrainingSession, isLoading } = useNewTrainingSession();
  const { mutate: updateTrainingSession } = useUpdateTrainingSession(() =>
    invalidateTraining(trainingId)
  );

  useEffect(() => {
    if (trainingSession) {
      setTrainingDate(trainingSession.scheduled_date.toLocal().toFormat("yyyy-LL-dd'T'HH:mm"));
      setFacilitator(trainingSession.facilitator.id.toString());
      setLimitAttendees(trainingSession.max_people ? true : false);
      setMaxAttendees(trainingSession.max_people || 0);
    }
  }, [trainingSession]);

  const handleSubmit = () => {
    if (trainingDate && facilitator) {
      const utcDate = DateTime.fromISO(trainingDate, { zone: "America/Los_Angeles" }).toUTC();
      trainingSession
        ? updateTrainingSession(
            {
              id: trainingSession.id,
              scheduled_date: utcDate!,
              facilitator_id: +facilitator,
              max_people: maxAttendees ? maxAttendees : null,
            },
            {
              onSuccess: () => {
                onClose();
                setFacilitator(null);
                setLimitAttendees(false);
                setMaxAttendees(0);
                setTrainingDate("");
              },
            }
          )
        : trainingId &&
          newTrainingSession(
            {
              training_id: trainingId,
              scheduled_date: utcDate!,
              facilitator_id: +facilitator,
              max_people: maxAttendees ? maxAttendees : null,
            },
            {
              onSuccess: () => {
                onClose();
                setFacilitator(null);
                setLimitAttendees(false);
                setMaxAttendees(0);
                setTrainingDate("");
              },
            }
          );
    }
  };

  const handleClose = () => {
    setFacilitator(null);
    setLimitAttendees(false);
    setMaxAttendees(0);
    setTrainingDate("");
    onClose();
  };

  return (
    <FormModal
      title="Create New Training Session"
      submitButtonTitle="Done"
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
      handleSubmit={handleSubmit}
      submitButtonDisabled={
        !trainingDate || (!pastTraining && !facilitator) || (limitAttendees && !maxAttendees)
      }
      isLoading={isLoading}
      submitButtonColorSchema="teal">
      <VStack width="100%" align="start" spacing={6}>
        <FormControl>
          <FormLabel>When will the training happen?</FormLabel>
          <Input
            type="datetime-local"
            value={trainingDate}
            onChange={(e) => {
              setTrainingDate(e.target.value);
              if (trainingSessionInPast(DateTime.fromISO(e.target.value))) setPastTraining(true);
            }}
          />
        </FormControl>
        {pastTraining && (
          <Alert status="warning">
            <AlertIcon />
            You chose a date in the past. Please proceed if you are consolidating attendance data
            for a past session that wasn't recorded in Helix
          </Alert>
        )}
        <FormControl>
          <FormLabel>Add Facilitator</FormLabel>
          <Box width="100%">
            <Select
              placeholder="Search and add employee"
              options={userOptions}
              value={facilitator}
              onChange={(value) => {
                setFacilitator(value.toString());
              }}
            />
          </Box>
        </FormControl>
        <HStack justify="space-between" width="100%">
          <FormLabel>Limit Attendee Capacity</FormLabel>
          <Switch
            id="limit attendees"
            colorScheme="teal"
            isChecked={limitAttendees}
            onChange={() => setLimitAttendees((prev) => !prev)}
          />
        </HStack>
        {limitAttendees && (
          <FormControl>
            <FormLabel>
              Max Number of Attendees{" "}
              <Box as="span" fontWeight="normal" color={useColorModeValue("gray.500", "gray.300")}>
                (optional)
              </Box>
            </FormLabel>
            <NumberInput>
              <NumberInputField
                value={maxAttendees}
                onChange={(e) => setMaxAttendees(+e.target.value)}
              />
            </NumberInput>
          </FormControl>
        )}
      </VStack>
    </FormModal>
  );
};
