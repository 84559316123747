import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { createSearchResponseSchema } from "./shared";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { userCredentialShowSchema } from "./user_credentials";

export const CREDENTIALS_BASE_URL = "credentials";

export const credentialSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  is_public: z.boolean(),
  expiration_months: z.number().nullable(),
  is_archived: z.boolean(),
  is_draft: z.boolean(),
  training_location: z.string(),
  has_training: z.boolean(),
  training_id: z.number().optional(),
  assigned_users: z.number(),
  trainings: z.array(z.object({ id: z.number(), name: z.string() })),
});

export const credentialIndexSchema = createSearchResponseSchema(credentialSchema).extend({
  archived_credentials: z.number(),
  total: z.number(),
  unassigned_credentials: z.number(),
});

export const credentialHubStatSchema = z.object({
  granted_user_credentials: z.number(),
  assigned_user_credentials: z.number(),
  upcoming_trainings: z.number(),
  archived_credentials: z.number(),
});

export type CredentialData = z.infer<typeof credentialSchema>;
export type CredentialCreateParams = Pick<
  CredentialData,
  "name" | "is_public" | "expiration_months" | "training_location" | "has_training"
>;

// api queries

export const getCredentials = async () => {
  const result = await api.get(CREDENTIALS_BASE_URL);
  return zodParse(credentialSchema.array(), result.data);
};

export const getCredentialNames = async () => {
  const result = await api.get(`${CREDENTIALS_BASE_URL}/names`);
  return result.data;
};

export const getCredentialHubStats = async () => {
  const result = await api.get(`${CREDENTIALS_BASE_URL}/stats`);
  return zodParse(credentialHubStatSchema, result.data);
};

export const getCredential = async (id: number) => {
  const result = await api.get(`${CREDENTIALS_BASE_URL}/${id}`);
  return zodParse(credentialSchema, result.data);
};

export const searchCredentials = async ({
  aggs,
  filters,
  pagination,
  bodyOptions,
  order,
  term,
}: SearchParams) => {
  const path = [CREDENTIALS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ bodyOptions, aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(credentialIndexSchema, result.data);
};

export const getUserCredentialUserIds = async (id: number) => {
  const result = await api.get(`${CREDENTIALS_BASE_URL}/${id}/user_credentials`);
  return result.data;
};

export const searchAssociatedUserCredentials = async ({
  id,
  aggs,
  filters,
  pagination,
  order,
  term,
}) => {
  const path = [CREDENTIALS_BASE_URL, id, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search, id });
  return zodParse(createSearchResponseSchema(userCredentialShowSchema), result.data);
};

export const newCredential = async (credential: CredentialCreateParams) => {
  const result = await api.post(CREDENTIALS_BASE_URL, { credential });
  return result.data;
};

export const updateCredential = async (credential: Partial<CredentialData>) => {
  const result = await api.put(`${CREDENTIALS_BASE_URL}/${credential.id}`, { credential });
  return result.data;
};

export const deleteCredential = async (id: number) => {
  await api.delete(`${CREDENTIALS_BASE_URL}/${id}`);
};

export const deleteAssociatedUserCredentials = async (id: number) => {
  await api.delete(`${CREDENTIALS_BASE_URL}/${id}/user_credentials`);
};

// query hooks

export const invalidateCredentials = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: id ? [CREDENTIALS_BASE_URL, id] : [CREDENTIALS_BASE_URL],
    });
};

export const useGetCredentials = () => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL],
    queryFn: () => getCredentials(),
  });
};

export const useGetCredentialHubStats = () => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL, "hub"],
    queryFn: () => getCredentialHubStats(),
  });
};

export const useGetCredentialNames = () => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL, "names"],
    queryFn: () => getCredentialNames(),
  });
};

export const useGetCredential = (id: number) => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL, id],
    queryFn: () => getCredential(id),
    enabled: !!id,
  });
};

export const useSearchCredentials = (params: SearchParams) => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL, params],
    queryFn: () => searchCredentials(params),
  });
};

export const useGetUserCredentialUserIds = (id: number) => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL, id, "user_credentials"],
    queryFn: () => getUserCredentialUserIds(id),
  });
};

export const useSearchAssociatedUserCredentials = (params) => {
  return useQuery({
    queryKey: [CREDENTIALS_BASE_URL, params.id, params],
    queryFn: () => searchAssociatedUserCredentials(params),
  });
};

export const useNewCredential = () => {
  return useMutation({
    mutationFn: newCredential,
    onSuccess: invalidateCredentials(),
  });
};

export const useUpdateCredential = (id?: number) => {
  return useMutation({
    mutationFn: updateCredential,
    onSuccess: invalidateCredentials(id),
  });
};

export const useDeleteCredential = () => {
  return useMutation({
    mutationFn: deleteCredential,
    onSuccess: invalidateCredentials(),
  });
};

export const useDeleteAssociatedUserCredentials = () => {
  return useMutation({
    mutationFn: deleteAssociatedUserCredentials,
    onSuccess: invalidateCredentials(),
  });
};
