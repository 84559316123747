import { Box, Heading } from "@chakra-ui/react";
import { EditableText } from "@sciencecorp/helix-components";
import React from "react";

export type CapitalEquipmentNotesProps = {
  notes: string;
  updateNotes: (notes: string) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const CapitalEquipmentNotes = ({
  notes,
  updateNotes,
  isLoading,
  isDisabled,
}: CapitalEquipmentNotesProps) => {
  return (
    <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6} height={"100%"}>
      <Heading size="md" mb={4} fontWeight="medium">
        Notes
      </Heading>
      <EditableText
        multiline
        onSubmit={(value) => {
          if (value) {
            updateNotes(value);
          }
        }}
        preview={notes}
        isLoading={isLoading}
        disabled={isDisabled}
      />
    </Box>
  );
};
