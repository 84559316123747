import React, { useState } from "react";
import {
  useDisclosure,
  StatGroup,
  Stat,
  StatNumber,
  StatLabel,
  useColorModeValue,
  HStack,
  Link,
  Box,
  StatHelpText,
  Flex,
  Text,
  VStack,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import { useGetCredentialHubStats } from "../../../api/credentials";

import { Link as RouterLink } from "react-router-dom";
import { UserCredentialFlags } from "./components/UserCredentialFlags";
import { UpcomingTrainings } from "../components/UpcomingTrainings";
import { CredentialPackagesGrid } from "./components/CredentialPackagesGrid";
import { useGetTrainingSessions } from "../../../api/training_sessions";

export const Hub = () => {
  const { data: hubStats, isLoading } = useGetCredentialHubStats();
  const {
    data: trainingSessions,
    isLoading: isLoadingUpcoming,
    isError,
  } = useGetTrainingSessions();

  return (
    <VStack justify="space-between" w="100%" gap={8}>
      <Flex gap={6} direction={{ base: "column", md: "row" }} w="100%">
        <StatGroup
          w="100%"
          flexDir={{ base: "column", md: "row" }}
          p={6}
          justifyContent="center"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md">
          <Stat>
            <StatLabel>Granted Credentials</StatLabel>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <StatNumber>{hubStats?.granted_user_credentials}</StatNumber>
                <StatHelpText>of {hubStats?.assigned_user_credentials} assigned</StatHelpText>
              </>
            )}
          </Stat>
          <Stat>
            <StatLabel w="max-content" fontSize="xs">
              Upcoming Trainings
            </StatLabel>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <StatNumber>{hubStats?.upcoming_trainings || 0}</StatNumber>
                <StatHelpText>in the next 6 months</StatHelpText>
              </>
            )}
          </Stat>
        </StatGroup>
        <HStack
          p={6}
          flex="1"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          gap={16}
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Stat>
            <StatLabel w="max-content" fontSize="xs">
              Archived Credentials
            </StatLabel>
            {isLoading ? (
              <Spinner />
            ) : (
              <StatNumber>{hubStats?.archived_credentials || 0}</StatNumber>
            )}
          </Stat>
          <Link
            as={RouterLink}
            to="/credentials/archive/credentials"
            color="teal.500"
            fontWeight="bold">
            View
          </Link>
        </HStack>
      </Flex>
      <Flex direction={{ base: "column", md: "row" }} gap={4} w="100%">
        <UserCredentialFlags />
        {isLoadingUpcoming ? (
          <Spinner />
        ) : isError ? (
          <Text>Error loading trainings</Text>
        ) : (
          <UpcomingTrainings location="hub" trainingSessions={trainingSessions} />
        )}
      </Flex>
      <CredentialPackagesGrid />
    </VStack>
  );
};
