import {
  Stack,
  VStack,
  Heading,
  Tag,
  Divider,
  HStack,
  Avatar,
  Icon,
  Button,
  Text,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React from "react";
import { SlPeople } from "react-icons/sl";
import { MdPersonAddAlt } from "react-icons/md";

import { useUpdateTrainingSession } from "../../../api/training_sessions";
import {
  useDeleteUserTrainingSession,
  useUpdateUserTrainingSession,
} from "../../../api/user_training_sessions";
import { TrainingSessionImage } from "./components/TrainingImages";
import { invalidateTraining, useGetNextTrainingSession } from "../../../api/trainings";
import { useNavigate } from "react-router";

export const TrainingScheduleWidget = ({ training, onOpenRSVP }) => {
  const { mutate: deleteUserTrainingSession } = useDeleteUserTrainingSession(() =>
    invalidateTraining(training.id)
  );
  const { mutate: updateUserTrainingSession } = useUpdateUserTrainingSession(() =>
    invalidateTraining(training.id)
  );

  const { mutate: updateTrainingSession } = useUpdateTrainingSession(() =>
    invalidateTraining(training.id)
  );

  const { data: nextSession } = useGetNextTrainingSession(training.id);
  const navigate = useNavigate();

  const scheduleWidgetBg =
    (nextSession?.user_training_session &&
      nextSession?.user_training_session.status !== "skipped") ||
    training?.is_next_session_facilitator
      ? useColorModeValue("teal.50", "gray.700")
      : useColorModeValue("gray.50", "gray.700");
  const scheduleWidgetBorderColor =
    (nextSession?.user_training_session &&
      nextSession?.user_training_session.status !== "skipped") ||
    training?.is_next_session_facilitator
      ? useColorModeValue("teal.200", "gray.600")
      : "chakra-border-color";
  const textColor = useColorModeValue("gray.600", "gray.300");

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      width="100%"
      align="center"
      border="1px"
      bg={scheduleWidgetBg}
      borderColor={scheduleWidgetBorderColor}
      borderRadius="md"
      spacing={6}
      p={4}>
      <TrainingSessionImage boxSize={24} />
      <VStack flex="1" align="start">
        <Stack direction={{ base: "column", md: "row" }} width="100%" textAlign="center">
          <Heading size="sm">Next Training Session</Heading>
          <Text color={textColor} fontSize="sm">
            {nextSession?.training_session?.scheduled_date
              ? `${nextSession?.training_session.scheduled_date.toFormat(
                  "LLL dd yyyy"
                )} • ${nextSession?.training_session.scheduled_date.toFormat("hh:mm a")}`
              : "N/A"}
          </Text>
          {nextSession?.user_training_session &&
          nextSession?.user_training_session.status !== "skipped" ? (
            <Box>
              <Tag colorScheme="green">Registered</Tag>
            </Box>
          ) : null}
        </Stack>
        <Divider />
        <HStack>
          <Text fontSize="sm" color={textColor}>
            Facilitated By:
          </Text>
          {nextSession?.training_session?.facilitator ? (
            <HStack>
              <Avatar
                size="sm"
                src={nextSession.training_session.facilitator.picture_uri}
                aria-label="facilitator"
              />
              <VStack align="start">
                <Text fontSize="sm" fontWeight="semibold">
                  {nextSession.training_session.facilitator?.name}
                </Text>
              </VStack>
            </HStack>
          ) : (
            <Text>N/A</Text>
          )}
        </HStack>
      </VStack>
      {nextSession?.training_session?.spots_remaining ? (
        <HStack fontSize="sm">
          <Icon as={SlPeople} />
          <Text>{nextSession.training_session.spots_remaining} seats left</Text>
        </HStack>
      ) : null}
      {nextSession?.training_session ? (
        training?.is_next_session_facilitator ? (
          !nextSession.training_session.started_at ? (
            <Button
              size="sm"
              colorScheme="teal"
              onClick={() => {
                if (nextSession.training_session) {
                  updateTrainingSession(
                    {
                      id: nextSession.training_session.id,
                      started_at: DateTime.now(),
                    },
                    {
                      onSuccess: () => {
                        nextSession.training_session &&
                          navigate(
                            `/trainings/${training.id}/training_sessions/${nextSession.training_session.id}`
                          );
                      },
                    }
                  );
                }
              }}
              isDisabled={
                DateTime.now().toISODate() !==
                nextSession.training_session.scheduled_date.toISODate()
              }>
              Start Session
            </Button>
          ) : !nextSession.training_session.ended_at ? (
            <Button
              size="sm"
              colorScheme="teal"
              onClick={() =>
                nextSession.training_session &&
                navigate(
                  `/trainings/${training.id}/training_sessions/${nextSession.training_session.id}`
                )
              }>
              Continue Session
            </Button>
          ) : null
        ) : !nextSession?.user_training_session ||
          nextSession?.user_training_session.status === "skipped" ? (
          <Button
            size="sm"
            colorScheme="teal"
            leftIcon={<Icon as={MdPersonAddAlt} />}
            onClick={() => onOpenRSVP()}>
            Register
          </Button>
        ) : nextSession?.user_training_session &&
          nextSession.user_training_session.status === "registered" ? (
          <Button
            size="sm"
            colorScheme="teal"
            variant="outline"
            onClick={() =>
              nextSession.user_training_session &&
              deleteUserTrainingSession(nextSession.user_training_session.id)
            }>
            Cancel Registration
          </Button>
        ) : (
          <ConfirmationButton
            size="sm"
            colorScheme="teal"
            label="Skip Session"
            confirmationButtonLabel="Skip"
            variant="Button"
            buttonVariant="outline"
            confirmationHeader="Skip This Session"
            children="If you skip this session, you will be automatically assigned to the next session."
            onConfirm={() =>
              nextSession.user_training_session &&
              updateUserTrainingSession({
                id: nextSession.user_training_session.id,
                status: "skipped",
              })
            }
          />
        )
      ) : null}
    </Stack>
  );
};
