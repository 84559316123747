import { Box, VStack } from "@chakra-ui/react";
import {
  AttributesTable,
  EditableSelect,
  EditableText,
  StatusSelect,
} from "@sciencecorp/helix-components";
import { titleize } from "inflection";
import React from "react";
import { useTeamOptions } from "../../../../api/options";
import { TeamShowData, TeamType, teamTypes, useUpdateTeam } from "../../../../api/team";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { EditableMoney } from "../../../EditableMoney";
import { AsanaLink, GithubLink, SlackLink } from "../../util";

export const TeamSettings: React.FC<{ team: TeamShowData }> = ({ team }) => {
  const { mutate: updateTeam } = useUpdateTeam();
  const currentUserQuery = useCurrentUserQuery();
  const subTeamIds = (team.sub_teams || []).map((team) => team.id);
  const teamOptions = useTeamOptions([...subTeamIds, team.id]);
  const teamTypeOptions = teamTypes.map((teamType) => ({
    label: titleize(teamType),
    value: teamType,
  }));

  return (
    <VStack mt={4} border="1px" borderColor="chakra-border-color" borderRadius="md" p={6}>
      <AttributesTable
        title="General"
        attributes={[
          {
            label: "Name",
            value: (
              <EditableText
                defaultValue={team.name}
                preview={team.name}
                onSubmit={(name) => {
                  name && updateTeam({ ...team, name });
                }}
              />
            ),
          },
          {
            label: "Type",
            value: (
              <Box width={"30ch"}>
                <EditableSelect
                  options={teamTypeOptions}
                  selectedValue={team.team_type}
                  onSubmit={(value) => {
                    value &&
                      teamTypes.includes(value.toString()) &&
                      updateTeam({ ...team, team_type: value as TeamType });
                  }}
                />
              </Box>
            ),
          },
          {
            label: "Objective",
            value: (
              <EditableText
                defaultValue={team.description}
                onSubmit={(description) => {
                  description && updateTeam({ ...team, description });
                }}
                preview={team.description}
              />
            ),
          },
          {
            label: "Asana Link",
            value: (
              <EditableText
                defaultValue={team.asana_team_link || ""}
                preview={<AsanaLink asanaLink={team.asana_team_link} />}
                onSubmit={(asana_team_link) => {
                  updateTeam({ ...team, asana_team_link });
                }}
              />
            ),
          },
          {
            label: "Slack Team",
            value: (
              <EditableText
                defaultValue={team.slack_channel || ""}
                preview={<SlackLink slackChannel={team.slack_channel} />}
                onSubmit={(slack_channel) => {
                  updateTeam({ ...team, slack_channel });
                }}
              />
            ),
          },
          {
            label: "Github Team",
            value: (
              <EditableText
                defaultValue={team.github_team || ""}
                preview={<GithubLink githubTeam={team.github_team} />}
                onSubmit={(github_team) => {
                  updateTeam({ ...team, github_team });
                }}
              />
            ),
          },
          {
            label: "Autonomous Spending",
            value: (
              <StatusSelect
                isDisabled={!team.can_edit_autonomous_spending}
                variant="tag"
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
                status={
                  team.autonomous_spending
                    ? { label: "Yes", value: "yes", color: "green" }
                    : { label: "No", value: "no", color: "red" }
                }
                onSubmit={(value) => {
                  updateTeam({ id: team.id, autonomous_spending: value === "yes" });
                }}
              />
            ),
          },
          {
            label: "Purchase Approval Threshold",
            value: (
              <EditableMoney
                defaultValue={team.purchasing_approval_threshold}
                onSubmit={(purchasing_approval_threshold) => {
                  purchasing_approval_threshold &&
                    updateTeam({
                      ...team,
                      purchasing_approval_threshold: purchasing_approval_threshold,
                    });
                }}
              />
            ),
          },
          {
            label: "Parent Team",
            value: (
              <EditableSelect
                selectedValue={team.parent_team_id}
                options={[...teamOptions, { label: "No Parent Team", value: -1 }]}
                disabled={!currentUserQuery.isSuccess || !userHasRole(currentUserQuery, "hr_admin")}
                onSubmit={(value) => {
                  const teamId = value !== -1 ? Number(value) : null;
                  updateTeam({
                    id: team.id,
                    parent_team_id: teamId,
                  });
                }}
              />
            ),
          },
        ]}
      />
    </VStack>
  );
};
