import { Avatar, Box, HStack, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { AttributesTable, EditableDate, StatusSelect } from "@sciencecorp/helix-components";
import React from "react";
import { UserLoggedInData } from "../../../../api/user";
import { EditableSpendingAuthoritySelect } from "../../SpendingAuthoritySelectTree";
import { titleize } from "inflection";
import {
  ReimbursementShowData,
  useChangeReimbursementCurrency,
  useUpdatePaidReimbursement,
  useUpdateReimbursement,
  useUpdateUnPaidReimbursement,
} from "../../../../api/reimbursement";
import { DateTime } from "luxon";
import { EditableCurrencySelect } from "../../../EditableCurrencySelect";
import { UpdateCurrencyButton } from "../../../UpdateCurrencyButton";

type ReimbursementAttributesTableProps = {
  reimbursement: ReimbursementShowData;
  isPurchasingAdmin?: boolean;
  isCeo?: boolean;
  currentUser?: UserLoggedInData;
};

export const ReimbursementAttributesTable = ({
  reimbursement,
  isPurchasingAdmin,
  currentUser,
}: ReimbursementAttributesTableProps) => {
  const { mutateAsync: updateReimbursement, isLoading: isUpdateLoading } = useUpdateReimbursement(
    reimbursement.id
  );

  const { mutateAsync: changeReimbursementCurrency, isLoading: isChangeCurrencyLoading } =
    useChangeReimbursementCurrency(reimbursement.id);

  const { mutateAsync: updatePaidReimbursement, isLoading: isUpdatePaidLoading } =
    useUpdatePaidReimbursement(reimbursement.id);
  const { mutateAsync: updateUnPaidReimbursement, isLoading: isUpdateUnPaidLoading } =
    useUpdateUnPaidReimbursement(reimbursement.id);
  const editable = () => {
    if (reimbursement.approval_status === "draft") {
      return true;
    }
    if (isPurchasingAdmin) {
      return true;
    }
    if (currentUser?.id === reimbursement.user.id) {
      return true;
    }
  };

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.700")}
      borderRadius="md"
      maxWidth={"100%"}
      border="1px"
      borderColor="chakra-border-color"
      p={4}
      marginTop={4}>
      <AttributesTable
        attributes={[
          {
            label: "Requested By",
            value: (
              <Link href={`/users/${reimbursement.user.id}`}>
                <HStack>
                  <Avatar src={reimbursement.user.name} name={reimbursement.user.name} size="sm" />
                  <Text fontWeight="semibold" fontSize="sm">
                    {reimbursement?.user.name}
                  </Text>
                </HStack>
              </Link>
            ),
          },
          {
            label: "Spending Authority",
            value: (
              <EditableSpendingAuthoritySelect
                onSubmit={(spendingAuthority) => {
                  updateReimbursement({
                    id: reimbursement.id,
                    spending_authority_type: spendingAuthority?.type,
                    spending_authority_id: spendingAuthority?.id,
                  });
                }}
                spendingAuthority={reimbursement.spending_authority}
                disabled={!editable()}
              />
            ),
          },
          {
            label: "Request Type",
            value: <Text>{titleize(reimbursement.reimbursement_type)} Reimbursement</Text>,
          },
          {
            label: "Requested On",
            value: reimbursement.submitted_at
              ? reimbursement.submitted_at.toFormat("MMMM dd, yyyy")
              : "Not submitted",
          },
          {
            label: "Currency",
            value: (
              <UpdateCurrencyButton
                total={reimbursement.amount}
                title="Update Currency"
                model={"Reimbursement"}
                isDisabled={!editable()}
                previousCurrency={reimbursement.currency}
                isLoading={isChangeCurrencyLoading}
                handleSubmit={(newCurrency: string, onClose) => {
                  changeReimbursementCurrency({ id: reimbursement.id, currency: newCurrency }).then(
                    onClose
                  );
                }}
              />
            ),
          },
          {
            label: "Paid",
            value: (
              <StatusSelect
                status={
                  reimbursement.paid
                    ? { label: "Yes", value: "true", color: "green" }
                    : { label: "No", value: "false", color: "red" }
                }
                options={[
                  { label: "No", value: "false" },
                  { label: "Yes", value: "true" },
                ]}
                onSubmit={(value) => {
                  value === "true"
                    ? updatePaidReimbursement({
                        id: reimbursement.id,
                        paid: true,
                      })
                    : updateUnPaidReimbursement({
                        id: reimbursement.id,
                        paid: false,
                      });
                }}
                variant="tag"
                isDisabled={
                  (reimbursement.approval_status !== "approved" &&
                    reimbursement.approval_status !== "completed") ||
                  !isPurchasingAdmin
                }
                type="subtle"
              />
            ),
          },
          {
            label: "Paid at",
            value: (
              <EditableDate
                disabled={reimbursement.approval_status !== "completed" || !isPurchasingAdmin}
                defaultValue={reimbursement.paid_at?.toString()}
                onSubmit={(value) => {
                  if (value)
                    updateReimbursement({ id: reimbursement.id, paid_at: DateTime.fromISO(value) });
                }}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};
