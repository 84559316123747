import {
  Flex,
  Text,
  HStack,
  VStack,
  Divider,
  IconButton,
  useDisclosure,
  Avatar,
  LinkBox,
  useColorModeValue,
  Box,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MoneyText } from "../../../MoneyText";
import { EditIcon } from "@chakra-ui/icons";
import { EditOwnerModal } from "./UpdateOwnerModal";
import {
  InventoryBomUserData,
  useDeleteInventoryBomUser,
} from "../../../../api/inventory_bom_user";
import { Link as RouterLink } from "react-router-dom";
import { InventoryBomData } from "../../../../api/inventory_boms";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";

type CostAndOwnerBoxProps = {
  bom: InventoryBomData;
  inventoryBomUsers: InventoryBomUserData[];
  isEditable: boolean;
};

export const CostAndOwnerBox = ({ bom, inventoryBomUsers, isEditable }: CostAndOwnerBoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: deleteInventoryBomUser, isLoading } = useDeleteInventoryBomUser(bom.id);

  return (
    <>
      <EditOwnerModal
        inventoryBomId={bom.id}
        isOpen={isOpen}
        onClose={onClose}
        inventoryBomUsers={inventoryBomUsers}
      />
      <Flex
        border="1px solid"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={4}
        width="100%">
        <HStack gap={6}>
          <VStack align="flex-start" spacing={0}>
            <Text
              fontSize="sm"
              fontWeight="medium"
              textColor={useColorModeValue("gray.700", "gray.100")}
              w={{ base: "auto", xl: "max-content" }}>
              Total Estimated Cost
            </Text>
            {bom.total_estimated_cost ? (
              <MoneyText
                money={bom.total_estimated_cost}
                formatOptions={{ compact: "never" }}
                as="span"
                fontWeight="semibold"
                fontSize="2xl"
              />
            ) : (
              <Text>-</Text>
            )}
            <Text fontSize="sm" textColor={useColorModeValue("gray.600", "gray.400")}>
              {bom.inventory_bom_components?.length ?? 0} Components
            </Text>
          </VStack>
          <Divider orientation="vertical" />
          <VStack align="flex-start" spacing={3}>
            <Text
              textAlign={"start"}
              fontSize="sm"
              textColor={useColorModeValue("gray.600", "gray.400")}
              fontWeight="medium">
              Owner
            </Text>
            <HStack w="100%" gap={8} justify="space-between">
              <PreviewUserSelect
                addedUsers={inventoryBomUsers}
                handleDelete={deleteInventoryBomUser}
                isLoading={isLoading}
                isDisabled={!isEditable || inventoryBomUsers.length === 1}
                avatarGroupSize={2}
                additionalUsersTitle="Owners"
              />
              <IconButton
                icon={<EditIcon />}
                aria-label={"Edit owners"}
                size="xs"
                onClick={onOpen}
                isDisabled={!isEditable}
              />
            </HStack>
          </VStack>
        </HStack>
      </Flex>
    </>
  );
};
