import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  VStack,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Divider,
} from "@chakra-ui/react";
import {
  ConfirmationButton,
  Facets,
  Header,
  Searchbar,
  SplitPage,
  useCollection,
} from "@sciencecorp/helix-components";
import React from "react";
import { DescriptionBox } from "./DescriptionBox";
import { CostAndOwnerBox } from "./CostAndOwnerBox";
import { ChevronRightIcon, ArrowBackIcon, ArrowForwardIcon, DeleteIcon } from "@chakra-ui/icons";
import { CgNotes } from "react-icons/cg";
import { BsArchive, BsThreeDotsVertical } from "react-icons/bs";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import {
  useDeleteInventoryBom,
  useGetInventoryBom,
  useSearchInventoryBoms,
  useUpdateInventoryBom,
} from "../../../../api/inventory_boms";
import { useNavigate, useParams } from "react-router-dom";
import { ComponentsTable } from "./ComponentsTable";

export const updateStatusColor = (status: string) => {
  switch (status) {
    case "active":
      return "green";
    case "archived":
      return "red";
    default:
      return "gray";
  }
};

export const BomDetailPage = () => {
  const { search, debouncedSearch } = useDebouncedSearch();
  const { facets, onFacets, pagination, order, filters, resetFacets } = useCollection();
  const { id } = useParams();
  const { data: bom, isLoading, isSuccess } = useGetInventoryBom(Number(id));
  const { mutateAsync: updateInventoryBom, isLoading: isUpdating } = useUpdateInventoryBom(
    Number(id)
  );
  const { mutateAsync: deleteInventoryBom } = useDeleteInventoryBom(Number(id));
  const { data: inventoryBom } = useSearchInventoryBoms({
    term: search || "*",
    pagination,
    order,
    filters: {
      ...filters,
      status: "active",
    },
    aggs: [],
  });
  const isEditable = bom?.is_editable;
  const navigate = useNavigate();
  const toast = useToast();
  const bgColor = useColorModeValue("gray.50", "gray.800");
  if (isLoading) return <Spinner />;

  const handlePrevInventoryBom = () => {
    navigate(`/inventory/boms/${bom?.previous_active_inventory_bom_id}`);
  };

  const handleNextInventoryBom = () => {
    navigate(`/inventory/boms/${bom?.next_active_inventory_bom_id}`);
  };

  return (
    <>
      {bom?.status === "draft" && (
        <Alert status="warning" width="100%" marginBottom={4}>
          <AlertIcon />
          <VStack align="start" gap={0}>
            <AlertTitle>Draft Mode (Only owners will be able to see drafts)</AlertTitle>
            <AlertDescription>
              Please define all requirements before publishing this bill of materials.
            </AlertDescription>
          </VStack>
        </Alert>
      )}
      {isSuccess && (
        <Header
          isEditable={isEditable}
          editableTextProps={{
            preview: bom.name,
            isLoading: isUpdating,
            onSubmit: (value) => {
              if (value) updateInventoryBom({ name: value, id: bom.id });
              else
                toast({
                  title: "Title cannot be empty",
                  status: "error",
                });
            },
          }}
          title={bom.name}
          crumbs={[{ url: "/inventory/boms", label: "Bill of Materials" }]}
          crumbsColor="teal.500"
          badge={{ label: bom.status, colorScheme: updateStatusColor(bom.status) }}
          actions={[
            <>
              {bom.status == "draft" && (
                <ConfirmationButton
                  label="Publish"
                  confirmationButtonLabel="Publish"
                  children="Publishing this bill of materials will make it active and visible to all employees. Are you sure you want to proceed?"
                  colorScheme="teal"
                  variant="Button"
                  isLoading={isUpdating}
                  isDisabled={bom.inventory_bom_components?.length === 0 || !isEditable}
                  onConfirm={() =>
                    updateInventoryBom({ status: "active", id: bom.id })
                  }></ConfirmationButton>
              )}

              <Popover>
                {({ isOpen }) => (
                  <>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="other-boms"
                        colorScheme={isOpen ? "teal" : undefined}
                        icon={<CgNotes />}
                        isDisabled={!isEditable}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverCloseButton />
                      <PopoverBody overflowY={"scroll"} height={"60vh"}>
                        <Text fontWeight={"semibold"} p={2} mb={2}>
                          Other Bill of Materials
                        </Text>
                        <HStack marginBottom={2}>
                          <Searchbar
                            onChange={(value) => {
                              debouncedSearch(value);
                            }}
                            onSelect={() => {}}
                            width="100%"
                          />
                          <Box display={"flex"} justifyContent="flex-end" w="100%">
                            <Facets
                              variant="button"
                              facets={facets}
                              onChange={onFacets}
                              background
                            />
                          </Box>
                        </HStack>
                        {inventoryBom?.results.map((bom) => (
                          <Flex
                            direction={"row"}
                            alignItems={"center"}
                            _hover={{ bgColor: bgColor }}
                            cursor={"pointer"}
                            justifyContent={"space-between"}
                            onClick={() => {
                              resetFacets();
                              navigate(`/inventory/boms/${bom.id}`);
                            }}>
                            <Box p={4} w={"100%"}>
                              {bom.name}
                            </Box>
                            <ChevronRightIcon />
                          </Flex>
                        ))}
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>

              <IconButton
                aria-label="move-left-bom"
                icon={<ArrowBackIcon />}
                onClick={handlePrevInventoryBom}
                isDisabled={!bom?.previous_active_inventory_bom_id}
              />
              <IconButton
                aria-label="move-right-bom"
                icon={<ArrowForwardIcon />}
                onClick={handleNextInventoryBom}
                isDisabled={!bom?.next_active_inventory_bom_id}
              />
              <Divider orientation="vertical" color="gray.200" height="40px" />
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      as={IconButton}
                      icon={<BsThreeDotsVertical />}
                      size="md"
                      aria-label="More actions"
                      isActive={isOpen}
                      isDisabled={!isEditable}
                    />
                    <MenuList>
                      {bom.status !== "draft" ? (
                        <MenuItem
                          onClick={() => {
                            updateInventoryBom({ status: "archived", id: bom.id }).then(() => {
                              navigate("/inventory/boms");
                              toast({
                                title: "Bill of Materials archived successfully",
                                status: "success",
                              });
                            });
                          }}>
                          <BsArchive style={{ marginRight: "6" }} />
                          Archive Bill of Materials
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => {
                            deleteInventoryBom(Number(id)).then(() => {
                              toast({
                                title: "Bill of Materials deleted successfully",
                                status: "success",
                              });
                              navigate("/inventory/boms");
                            });
                          }}>
                          <DeleteIcon style={{ marginRight: "6" }} />
                          Delete Bill of Materials
                        </MenuItem>
                      )}
                    </MenuList>
                  </>
                )}
              </Menu>
            </>,
          ]}
        />
      )}
      <SplitPage
        sidebarWidth={"max-content"}
        breakpoint="xl"
        sidebar={
          <VStack spacing={4}>
            {bom && (
              <>
                <CostAndOwnerBox
                  bom={bom}
                  inventoryBomUsers={bom.inventory_bom_users ?? []}
                  isEditable={isEditable ?? false}
                />
                <DescriptionBox description={bom.description || ""} bom={bom} />
              </>
            )}
          </VStack>
        }
        main={
          <>
            <ComponentsTable bomId={bom?.id ?? 0} isEditable={isEditable ?? false} />
          </>
        }
      />
    </>
  );
};
