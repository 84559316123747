import React, { useMemo, useState } from "react";
import {
  buildFacets,
  CollectionPage,
  Column,
  ConfirmationModal,
  Header,
  RecordLink,
  useCollection,
} from "@sciencecorp/helix-components";
import {
  Box,
  Text,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  MenuButton,
  Menu,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { titleize, humanize } from "inflection";
import {
  CapitalEquipmentData,
  CapitalEquipmentShowData,
  useSearchCapitalEquipments,
  useUnarchiveCapitalEquipment,
} from "../../../../api/capital_equipment";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { FaEllipsisV } from "react-icons/fa";
import { RepeatIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";

export const ArchivedEquipmentPage = () => {
  const { pagination, order, onPagination, onOrder, facets, onFacets } = useCollection();
  const [currentEquipmentId, setCurrentEquipmentId] = useState<number | null>(null);
  const { search, debouncedSearch } = useDebouncedSearch();
  const CAPITAL_EQUIPMENT_AGGREGATIONS_QUERY = ["name", "team_name", "category_name"];
  const toast = useToast();
  const { data, isLoading, isSuccess } = useSearchCapitalEquipments({
    term: search || "*",
    filters: { is_archived: true },
    aggs: CAPITAL_EQUIPMENT_AGGREGATIONS_QUERY,
    pagination: { per_page: -1, page: 1 },
    order,
  });
  const navigate = useNavigate();

  const { mutateAsync: unarchiveTool, isLoading: isLoadingUnarchiveTool } =
    useUnarchiveCapitalEquipment(Number(currentEquipmentId));
  const { isOpen, onOpen, onClose } = useDisclosure();

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const handleRestoreClick = () => {
    unarchiveTool(Number(currentEquipmentId)).then(() => {
      toast({
        title: "Capital Equipment restored",
        status: "success",
        duration: 3000,
      });
      navigate("/inventory/equipment");
    });
  };

  const columns: Column<CapitalEquipmentShowData>[] = useMemo(
    () => [
      {
        label: "Name",
        render: (tool) => (
          <RecordLink type="" identifier={tool.name} link={`/equipment/${tool.id}/details`} />
        ),
      },
      {
        label: "Team",
        render: (tool) =>
          tool.team ? (
            <RecordLink
              type=""
              identifier={tool.team.name || ""}
              link={`/teams/${tool.team.id}/details`}
            />
          ) : (
            <Text>Unknown</Text>
          ),
      },
      {
        label: "Wafer Service Tool Id",
        render: (tool) => tool.wafer_services_tool_id,
      },
      {
        label: "Category",
        render: (tool) => (tool.category ? titleize(humanize(tool.category)) : "-"),
      },
      {
        label: "Archived at",
        render: (tool) => (
          <Text>
            {tool.archived_at ? (
              DateTime.fromISO(tool.archived_at).toFormat("MMM dd, yyyy")
            ) : (
              <Text>Unknown</Text>
            )}
          </Text>
        ),
      },
      {
        label: "",
        render: (tool) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisV />}
              variant="ghost"
              colorScheme="gray"
              size="sm"
              aria-label="Options"
            />
            <MenuList>
              <MenuItem
                icon={<RepeatIcon />}
                onClick={() => {
                  onOpen();
                  setCurrentEquipmentId(tool.id);
                }}>
                Restore
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    [data]
  );

  if (isLoading) {
    return <Spinner />;
  } else if (isSuccess) {
    return (
      <Flex direction="column" gap={4}>
        <Box w="100%" overflowX="scroll">
          <Header
            title="Archive"
            crumbs={[{ url: "/inventory/equipment", label: "Equipment" }]}
            crumbsColor="teal.500"
          />
          <CollectionPage
            columns={columns}
            items={data?.results || []}
            pagination={data?.pagination || pagination}
            isLoading={isLoading}
            order={order}
            onPagination={onPagination}
            onOrder={onOrder}
            facets={populatedFacets}
            onFacets={onFacets}
            facetsProps={{ debouncedSearch: debouncedSearch, background: true, search: true }}
          />
        </Box>
        <ConfirmationModal
          colorScheme="teal"
          isOpen={isOpen}
          onClose={onClose}
          confirmText="Continue"
          header="Restore Equipment"
          children="Restoring this equipment will place it back on the equipment list"
          onClick={handleRestoreClick}
        />
      </Flex>
    );
  } else {
    return <Text>Error loading equipment.</Text>;
  }
};
