import { Box, Flex, HStack, Text, Tooltip, useColorModeValue, VStack } from "@chakra-ui/react";
import Big from "big.js";
import React from "react";
import { BudgetItemSummary } from "../../api/budget_items";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { MoneyText } from "../MoneyText";
import { MultiProgressBar } from "../shared/MultiProgressBar";

export const BudgetItemProgressBar = ({ summary }: { summary: BudgetItemSummary | undefined }) => {
  const currency = useCurrency();

  const gray = useColorModeValue("gray.100", "gray.400");
  const orange = useColorModeValue("orange.200", "orange.500");
  const teal = useColorModeValue("teal.500", "teal.300");
  const pink = useColorModeValue("pink.500", "pink.400");
  const red = useColorModeValue("red.500", "red.400");

  const totalSpent = summary?.spent_amount || Money.zero(currency);
  const totalCommitted = summary?.committed_amount || Money.zero(currency);
  const totalPendingApproval = summary?.pending_approval_amount || Money.zero(currency);
  const totalRemaining = summary?.remaining_amount || Money.zero(currency);
  const totalBalance = Money.max(Money.zero(currency), totalRemaining);
  const totalFunded = summary?.funding_amount || Money.zero(currency);

  const totalSpentPercentage = Money.pct(
    totalSpent,
    totalFunded || Money.zero(currency)
  ).toNumber();
  const totalCommittedPercentage = Money.pct(
    totalCommitted,
    totalFunded || Money.zero(currency)
  ).toNumber();
  const totalPendingApprovalPercentage = Money.pct(
    totalPendingApproval,
    totalFunded || Money.zero(currency)
  ).toNumber();

  const remainingBudgetPercentage = Math.max(
    0,
    new Big(100)
      .sub(totalSpentPercentage)
      .sub(totalCommittedPercentage)
      .sub(totalPendingApprovalPercentage)
      .toNumber()
  );

  const overBudget = totalSpent.gt(totalFunded);

  const spendingProgressBarElements = [
    {
      value: totalSpentPercentage,
      color: overBudget ? red : teal,
    },
    {
      value: totalCommittedPercentage,
      color: orange,
    },
    { value: totalPendingApprovalPercentage, color: pink },
    {
      value: remainingBudgetPercentage,
      color: gray,
    },
  ];

  return (
    <VStack width="100%" align="start" position="relative">
      <Flex
        gap={1}
        top={2.5}
        left={totalSpentPercentage <= 50 ? `${totalSpentPercentage}%` : "auto"}
        right={totalSpentPercentage > 50 ? `${100 - totalSpentPercentage}%` : "auto"}
        position="absolute"
        direction={totalSpentPercentage > 50 ? "row-reverse" : "row"}>
        <Box borderLeft="1px" borderStyle="dashed" borderColor={overBudget ? red : teal} />
        <Flex align="center" gap={1} direction={totalSpentPercentage > 50 ? "row-reverse" : "row"}>
          <Box boxSize={2} borderRadius="full" bg={overBudget ? red : teal} />
          <Text color="gray.500" fontSize="xs">
            <MoneyText
              as="span"
              fontWeight="semibold"
              color={useColorModeValue("black", "gray.100")}
              money={totalSpent}
              formatOptions={{ compact: "never" }}
            />{" "}
            spent
          </Text>
        </Flex>
      </Flex>
      <Box h="100%" w="100%" mt={7}>
        <MultiProgressBar
          segments={spendingProgressBarElements}
          boxProps={{ height: 2, width: "100%" }}
        />
      </Box>

      <HStack
        spacing={4}
        align="center"
        color="gray.500"
        w="100%"
        justify="space-between"
        fontSize="xs">
        <Tooltip label="Total amount requested for this spending authority, representing all pending transactions.">
          <HStack spacing={2}>
            <Box boxSize={2} borderRadius="full" bg={orange} />
            <Text>
              Committed{" "}
              <MoneyText as={"span"} money={totalCommitted} formatOptions={{ compact: "never" }} />
            </Text>
          </HStack>
        </Tooltip>
        <Tooltip label="Total amount pending approval.">
          <HStack spacing={2}>
            <Box boxSize={2} borderRadius="full" bg={pink} />
            <Text>
              Pending Approval{" "}
              <MoneyText
                as={"span"}
                money={totalPendingApproval}
                formatOptions={{ compact: "never" }}
              />
            </Text>
          </HStack>
        </Tooltip>
        <Tooltip label="Remaining budget for this spending authority, reflecting funds still available for future expenses or allocations.">
          <HStack spacing={2}>
            <Box boxSize={2} borderRadius="full" bg={gray} />
            <Text>
              Available{" "}
              <MoneyText as={"span"} money={totalBalance} formatOptions={{ compact: "never" }} />
            </Text>
          </HStack>
        </Tooltip>
      </HStack>
    </VStack>
  );
};
