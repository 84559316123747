import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { paginationSchema } from "./shared";
import { SearchParams, aggregationSchema, indexParams, searchParams } from "./collection_types";
import { vendorContactIndexSchema } from "./vendor_contacts";
import { zodParse } from "./zodParse";

export const VENDORS_BASE_URL = "vendors";

export const vendorSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  website: z.string(),
  address: z.string(),
  email: z.string(),
  status: z.string(),
  is_virtual_card_enabled: z.boolean(),
  archived_at: z.string().nullable(),
  payment_terms: z.string().nullable(),
  can_be_deleted: z.boolean(),
  tax_id: z.string().nullable(),
  category: z.string().nullable(),
});

export const vendorIndexSchema = z.object({
  results: z.array(vendorSchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable(),
});

export const vendorIndexSchemaWithStats = vendorIndexSchema.extend({
  total_vendors: z.number(),
  card_enabled_vendors: z.number(),
  vendors_with_no_transactions: z.number(),
  archived_vendors: z.number(),
});

export type VendorData = z.infer<typeof vendorSchema>;
export type VendorIndexData = z.infer<typeof vendorIndexSchema>;
export type VendorCreateParams = Partial<
  Pick<
    VendorData,
    "website" | "payment_terms" | "tax_id" | "category" | "description" | "address" | "status"
  >
> & {
  name: string;
};

export type VendorsIndexData = z.infer<typeof vendorIndexSchema>;
export type VendorIndexDataWithStats = z.infer<typeof vendorIndexSchemaWithStats>;

/** queries */
export const getVendors = async () => {
  const result = await api.get(VENDORS_BASE_URL);
  return zodParse(z.array(vendorSchema), result.data);
};

export const searchVendors = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [VENDORS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(vendorIndexSchemaWithStats, result.data);
};

export const searchArchivedVendors = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [VENDORS_BASE_URL, "search", "archive"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(vendorIndexSchema, result.data);
};
export const generateVendorCsv = async (): Promise<any> => {
  const result = await api.get(`${VENDORS_BASE_URL}/csv`, {
    responseType: "blob",
  });
  return result.data;
};

export const getVendor = async (id: number): Promise<VendorData> => {
  const result = await api.get(`${VENDORS_BASE_URL}/${id}`);
  return zodParse(vendorSchema, result.data);
};

export const getVendorContacts = async (id: number) => {
  const result = await api.get(`${VENDORS_BASE_URL}/${id}/contacts`);
  return zodParse(vendorContactIndexSchema, result.data);
};

export const newVendor = async (vendor: VendorCreateParams) => {
  const result = await api.post(VENDORS_BASE_URL, { vendor });
  return result.data;
};

export const updateVendor = async (vendor: Partial<VendorData>) => {
  await api.put(`${VENDORS_BASE_URL}/${vendor.id}`, { vendor });
};

export const mergeVendor = async (
  sourceVendorId: number,
  targetVendorId: number
): Promise<void> => {
  await api.put(`${VENDORS_BASE_URL}/${sourceVendorId}/merge`, {
    target_vendor_id: targetVendorId,
  });
};

export const deleteVendor = async (id: number) => {
  await api.delete(`${VENDORS_BASE_URL}/${id}`);
};

/** hooks */
export const invalidateVendors = (id?: number, subquery?: string) => {
  const queryKey: (string | number)[] = [VENDORS_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useVendorsQuery = () => {
  return useQuery({
    queryKey: [VENDORS_BASE_URL],
    queryFn: () => getVendors(),
  });
};

export const useGetVendorContacts = (id: number) => {
  return useQuery({
    queryKey: [VENDORS_BASE_URL, id, "contacts"],
    queryFn: () => getVendorContacts(id),
  });
};

export const useSearchVendors = (searchParams: SearchParams) => {
  return useQuery({
    queryKey: [VENDORS_BASE_URL, searchParams],
    queryFn: () => searchVendors(searchParams),
  });
};

export const useSearchArchivedVendors = (searchParams: SearchParams) => {
  return useQuery({
    queryKey: [VENDORS_BASE_URL, searchParams, "archive"],
    queryFn: () => searchArchivedVendors(searchParams),
  });
};

export const useGetVendor = (id: number) => {
  return useQuery({
    queryKey: [VENDORS_BASE_URL, id],
    queryFn: () => getVendor(id),
  });
};

export const useNewVendor = () => {
  return useMutation({
    mutationFn: newVendor,
    onSuccess: invalidateVendors(),
  });
};
export const useUpdateVendor = (id?: number) => {
  return useMutation({
    mutationFn: updateVendor,
    onSuccess: invalidateVendors(id),
  });
};
export const useDeleteVendor = () => {
  return useMutation({
    mutationFn: deleteVendor,
    onSuccess: invalidateVendors(),
  });
};

export const useMergeVendor = () => {
  return useMutation({
    mutationFn: (value: { sourceVendorId: number; targetVendorId: number }) => {
      return mergeVendor(value.sourceVendorId, value.targetVendorId);
    },
    onSuccess: invalidateVendors(),
  });
};
