import React, { useMemo } from "react";
import {
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  LinkOverlay,
  LinkBox,
  Icon,
  Progress,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { IoMedalOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { UserCredentialUnfulfilledShowData } from "../../../api/user_credentials";

export const UnfulfilledCredentialCard = ({
  userCredential,
}: {
  userCredential: UserCredentialUnfulfilledShowData;
}) => {
  const hoverColor = useColorModeValue("gray.100", "gray.700");

  const progressValue = useMemo(
    () =>
      Math.floor(
        (userCredential.trainings_completed_count / (userCredential.trainings_total_count || 1)) *
          100
      ),
    [userCredential]
  );

  return (
    <LinkBox _hover={{ cursor: "pointer", bg: hoverColor }}>
      <LinkOverlay as={Link} to={`/credentials/me/${userCredential.id}`}>
        <HStack
          spacing={4}
          width="100%"
          borderBottom="1px"
          px={2}
          py={4}
          borderColor="chakra-border-color">
          <HStack justify="space-between" align={"center"} w="100%">
            <HStack spacing={2}>
              <Icon as={IoMedalOutline} />
              <Heading size="xs" textColor={useColorModeValue("gray.700", "auto")}>
                {userCredential.credential?.name}
              </Heading>
            </HStack>
            {userCredential.trainings_total_count > 0 ? (
              <HStack spacing={4}>
                <Progress
                  value={progressValue}
                  w={20}
                  colorScheme="teal"
                  size="sm"
                  hasStripe
                  borderRadius="md"
                />
                <Text fontSize="xs" textAlign={"end"} mr={-3}>
                  {userCredential.trainings_completed_count} of{" "}
                  {userCredential.trainings_total_count} trainings completed
                </Text>
                <ChevronRightIcon />
              </HStack>
            ) : (
              <HStack>
                <Text fontSize="xs" w="100%" textAlign="end" as="i">
                  No training affiliated
                </Text>
                <ChevronRightIcon />
              </HStack>
            )}
          </HStack>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};
