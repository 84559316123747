import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { moneySchema } from "../helpers/Money";
import { zodParse } from "./zodParse";

export const FINANCE_BASE_URL = "finance";

export const monthlyBalanceSchema = z
  .object({
    balances: z.record(moneySchema),
    current_balance: moneySchema,
    monthly_payments: z.record(moneySchema),
  })
  .describe("monthlyBalanceSchema");

export const getCashBalanceSummary = async () => {
  const result = await api.get(`${FINANCE_BASE_URL}/summaries/cash_balance_summary`);
  return zodParse(monthlyBalanceSchema, result.data);
};

export const useGetCashBalanaceSummary = () => {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, "summaries/cash_balance_summary"],
    queryFn: () => getCashBalanceSummary(),
  });
};
