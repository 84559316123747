import React, { useMemo, useState } from "react";
import { Collection, Column, Order, Pagination, RecordLink } from "@sciencecorp/helix-components";
import {
  CapitalEquipmentData,
  CapitalEquipmentSearchResponse,
  CapitalEquipmentShowData,
} from "../../api/capital_equipment";
import {
  Button,
  Checkbox,
  Flex,
  HStack,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
  Box,
  Icon,
} from "@chakra-ui/react";
import { humanize } from "inflection";
import { useNavigate } from "react-router";
import { TbLetterM } from "react-icons/tb";
import { FiArchive } from "react-icons/fi";
import { DateTime } from "luxon";
import { ArchiveEquipmentModal } from "./components/ArchivedPage/ArchiveEquipmentModal";
import { MoveEquipmentModal } from "./components/MoveEquipmentModal";
import { SmallCloseIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { GrPowerCycle } from "react-icons/gr";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { updateStatusColor } from "./components/DetailsPage/EquipmentStatus";
import { GoServer } from "react-icons/go";
export type CapitalEquipmentsProps = {
  isLoading: boolean;
  data: CapitalEquipmentSearchResponse;
  onPagination: (pagination: Pagination) => void;
  pagination: Pagination;
  order: Order;
  onOrder: (order: Order) => void;
  isInventoryManager: boolean;
};

export const CapitalEquipments = ({
  isLoading,
  data,
  onPagination,
  pagination,
  order,
  onOrder,
  isInventoryManager,
}: CapitalEquipmentsProps) => {
  const navigate = useNavigate();
  const archivedCount =
    data?.aggregations?.is_archived.buckets.find((bucket) => bucket.key === 1)?.doc_count || 0;
  const [selectedEquipment, setSelectedEquipment] = useState<CapitalEquipmentShowData[]>([]);
  const toolIsOverdue = (tool: CapitalEquipmentShowData) =>
    tool.is_maintenance_overdue || tool.is_calibration_overdue;

  const {
    isOpen: isOpenArchiveModal,
    onOpen: onOpenArchiveModal,
    onClose: onCloseArchiveModal,
  } = useDisclosure();

  const {
    isOpen: isOpenMoveModal,
    onOpen: onOpenMoveModal,
    onClose: onCloseMoveModal,
  } = useDisclosure();

  const columns: Column<CapitalEquipmentShowData>[] = useMemo(
    () => [
      ...(isInventoryManager
        ? [
            {
              label: "",
              render: (tool) => (
                <Checkbox
                  colorScheme="teal"
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setSelectedEquipment((prev) =>
                      isChecked ? [...prev, tool] : prev.filter((t) => t !== tool)
                    );
                  }}
                  isChecked={selectedEquipment.includes(tool)}
                  size="md"
                />
              ),
            },
          ]
        : []),
      {
        label: "Name",
        render: (tool) => (
          <HStack alignItems="center">
            <RecordLink
              link={`/equipment/${tool.id}/details`}
              colorScheme={toolIsOverdue(tool) ? "orange" : "gray"}
              identifier={tool.name}
              icon={<GoServer />}
              iconPlacement="left"
              rightEl={toolIsOverdue(tool) ? <WarningTwoIcon /> : null}
            />
            {tool.is_calibration_overdue ? (
              <Tooltip label="Calibration overdue" placement="right">
                <Box>
                  <Icon as={GrPowerCycle} color="orange.600" boxSize={5} />
                </Box>
              </Tooltip>
            ) : null}
            {tool.is_maintenance_overdue ? (
              <Tooltip label="Maintenance overdue" placement="right">
                <Box>
                  <Icon as={HiOutlineWrenchScrewdriver} color="orange.600" boxSize={5} />
                </Box>
              </Tooltip>
            ) : null}
          </HStack>
        ),
      },
      {
        label: "Team",
        render: (tool) => (
          <RecordLink link={`/teams/${tool.team?.id}/readme`} identifier={tool.team?.name || "-"} />
        ),
      },
      {
        label: "Wafer Services ID",
        render: (tool) => <Text>{tool.wafer_services_tool_id || "-"}</Text>,
      },
      {
        label: "Asset ID",
        render: (tool) => <Text>{tool.asset_id || "-"}</Text>,
      },
      {
        label: "Asset ID",
        render: (tool) => <Text>{tool.asset_id || "-"}</Text>,
      },
      {
        label: "Category",
        render: (tool) => <Text>{humanize(tool.category || "-")}</Text>,
      },
      {
        label: "Next Maintenance",
        render: (tool) => (
          <Text>
            {tool.next_maintenance_date
              ? DateTime.fromISO(tool.next_maintenance_date || "-").toFormat("MMM dd, yyyy")
              : "-"}
          </Text>
        ),
      },
      {
        label: "Next Calibration",
        render: (tool) => (
          <Text>
            {tool.next_calibration_date
              ? DateTime.fromISO(tool.next_calibration_date || "-").toFormat("MMM dd, yyyy")
              : "-"}
          </Text>
        ),
      },
      {
        label: "Status",
        render: (tool) => (
          <Tag colorScheme={updateStatusColor(tool.status)}>{humanize(tool.status)}</Tag>
        ),
      },
    ],
    [isInventoryManager, selectedEquipment]
  );

  return (
    <>
      <ArchiveEquipmentModal
        isOpen={isOpenArchiveModal}
        onClose={onCloseArchiveModal}
        selectedEquipment={selectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
      />
      <MoveEquipmentModal
        isOpen={isOpenMoveModal}
        onClose={onCloseMoveModal}
        selectedEquipment={selectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
      />
      <Flex width="100%" direction="column">
        <Stack py={2} gap={4} width="100%">
          <StatGroup display="flex" flexDirection={{ base: "column", md: "row" }} gap={6}>
            <Stat
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              px={6}
              py={4}
              flex="2">
              <StatLabel>Total Equipment Count</StatLabel>
              <StatNumber>{data?.pagination.total || "-"}</StatNumber>
            </Stat>
            <Stat
              border="1px"
              backgroundColor={useColorModeValue("gray.100", "gray.700")}
              borderColor="chakra-border-color"
              borderRadius="md"
              px={6}
              py={4}
              justifyContent={"space-between"}>
              <HStack justifyContent={"space-between"}>
                <VStack align="start" gap={0}>
                  <StatLabel>Archived Equipment</StatLabel>
                  <StatNumber>{archivedCount}</StatNumber>
                </VStack>
                <Button
                  size="sm"
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => navigate("/equipment/archived")}>
                  View
                </Button>
              </HStack>
            </Stat>
          </StatGroup>
          {isInventoryManager && (
            <Flex
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              justifyContent="space-between"
              alignItems="center"
              gap={4}
              direction="row">
              <Flex p={4} alignItems="center" gap={4}>
                {selectedEquipment?.length > 0 ? (
                  <Text> {selectedEquipment?.length} equipment selected</Text>
                ) : (
                  <Text>Select an Equipment</Text>
                )}
                {selectedEquipment?.length !== 0 && (
                  <>
                    <Button
                      variant="outline"
                      onClick={onOpenArchiveModal}
                      leftIcon={<FiArchive />}
                      colorScheme="teal"
                      size="sm">
                      Archive
                    </Button>
                    <Button
                      variant="outline"
                      onClick={onOpenMoveModal}
                      leftIcon={<TbLetterM />}
                      colorScheme="red"
                      size="sm">
                      Move
                    </Button>
                  </>
                )}
              </Flex>
              <HStack gap={4} px={4}>
                <Button
                  onClick={() => {
                    setSelectedEquipment(data?.results || []);
                  }}
                  variant="outline"
                  size="sm"
                  colorScheme="teal"
                  leftIcon={<RiCheckboxMultipleLine />}>
                  Select All
                </Button>
                <Button
                  isDisabled={selectedEquipment?.length === 0}
                  size={"sm"}
                  colorScheme="gray"
                  variant={"outline"}
                  leftIcon={<SmallCloseIcon />}
                  onClick={() => {
                    setSelectedEquipment([]);
                  }}>
                  Deselect All
                </Button>
              </HStack>
            </Flex>
          )}
          <Collection
            items={data?.results || []}
            columns={columns}
            isLoading={isLoading}
            pagination={data?.pagination || pagination}
            onPagination={onPagination}
            order={order}
            onOrder={onOrder}
          />
        </Stack>
      </Flex>
    </>
  );
};
