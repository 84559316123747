import React, { useState } from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  VStack,
  HStack,
  Heading,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Divider,
  useColorModeValue,
  ButtonGroup,
  Button,
  Flex,
  Tag,
  Icon,
  useDisclosure,
  Text,
  Link,
  Box,
  LinkOverlay,
} from "@chakra-ui/react";
import { EmptyState, RecordLink, RichTextEditor } from "@sciencecorp/helix-components";
import { userHasRole, useUpdateUser, useUserUserCredentialQuery } from "../../api/user";
import { DeleteModal } from "../Hiring/components/DeleteModal";
import { TeamCard } from "../Teams/teamCard";
import { BsThreeDots } from "react-icons/bs";
import { IoMedalOutline } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";

export const ProfileTab = ({ user, currentUser }) => {
  const { mutate: updateUser } = useUpdateUser(user.id);
  const { data: userCredentials } = useUserUserCredentialQuery(user.id);

  const [edit, setEdit] = useState(false);
  const [rich, setRich] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack align="start">
      <HStack justify="space-between" width="100%">
        <Heading size="sm" textTransform="uppercase">
          Readme
          {currentUser?.id === user.id && !edit && (
            <Menu>
              <MenuButton
                as={IconButton}
                size="sm"
                icon={<BsThreeDots />}
                aria-label="open edit menu"
                bg="transparent"
              />
              <MenuList>
                <MenuItem onClick={() => setEdit(true)}>Edit README</MenuItem>
                <MenuItem onClick={() => onOpen()} isDisabled={user && !user.biography}>
                  Delete README
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Heading>
      </HStack>
      <Divider />
      <Box width="100%">
        {!user.biography && !edit ? (
          <Text fontSize="lg" color={useColorModeValue("gray.500", "gray.400")} pb={4}>
            {user?.first_name} hasn't written anything here yet.
          </Text>
        ) : (
          <>
            <RichTextEditor
              editable={edit}
              defaultValue={user.biography ? JSON.stringify(user.biography) : null}
              placeholder={"Tell us about yourself!"}
              minHeight="100px"
              border={edit}
              onChange={(rich) => setRich(rich)}
            />
            {edit && (
              <ButtonGroup justifyContent="flex-end" width="100%" spacing={4}>
                <Button
                  variant="outline"
                  onClick={() => {
                    setRich("");
                    setEdit(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  colorScheme="teal"
                  onClick={() => {
                    updateUser({ id: user.id, biography: JSON.parse(rich) });
                    setRich("");
                    setEdit(false);
                  }}>
                  Save
                </Button>
              </ButtonGroup>
            )}
          </>
        )}
      </Box>
      <Flex direction={["column", "column", "row"]} gap={4} w="100%">
        <VStack
          width={{ base: "100%", md: "50%" }}
          border="1px"
          borderColor="chakra-border-color"
          p={6}
          spacing={4}
          align="start"
          flex="1"
          borderRadius="md">
          <HStack justify="space-between" width="100%">
            <Heading size="md"> Credentials</Heading>
            <Link as={RouterLink} to="/credentials/me" isExternal fontSize="sm" color="teal.500">
              Go to Credentials <ExternalLinkIcon mx="2px" />
            </Link>
          </HStack>
          <Divider />
          {Object.values(userCredentials || []).some((value) => value.length > 0) ? (
            <>
              <VStack width="100%" align="start">
                <Text
                  ml={2}
                  textTransform="uppercase"
                  fontSize="sm"
                  fontWeight="bold"
                  color={useColorModeValue("gray.500", "gray.300")}>
                  Active
                </Text>
                <Flex gap={4} wrap="wrap">
                  {userCredentials?.active_credentials?.map((credential) => (
                    <RecordLink
                      identifier={credential.credential.name}
                      icon={<IoMedalOutline />}
                      link={
                        userHasRole(currentUser, "credentials_admin")
                          ? `/credentials/all/${credential.credential.id}`
                          : `/credentials/me/${credential.id}`
                      }
                    />
                  ))}
                </Flex>
              </VStack>
              {(userCredentials?.expired_credentials?.length || 0) > 0 && (
                <>
                  <Divider />
                  <VStack width="100%" align="start">
                    <Text
                      ml={2}
                      textTransform="uppercase"
                      fontSize="sm"
                      fontWeight="bold"
                      color={useColorModeValue("gray.500", "gray.300")}>
                      Expired
                    </Text>
                    <Flex gap={4} wrap="wrap">
                      {userCredentials?.expired_credentials?.map((credential) => (
                        <RecordLink
                          identifier={credential.credential.name}
                          icon={<IoMedalOutline />}
                          link={
                            userHasRole(currentUser, "credentials_admin")
                              ? `/credentials/all/${credential.credential.id}`
                              : `/credentials/me/${credential.id}`
                          }
                        />
                      ))}
                    </Flex>
                  </VStack>
                </>
              )}

              {(userCredentials?.unfulfilled_credentials.length || 0) > 0 && (
                <>
                  <Divider />
                  <VStack width="100%" align="start">
                    <Text
                      ml={2}
                      textTransform="uppercase"
                      fontSize="sm"
                      fontWeight="bold"
                      color={useColorModeValue("gray.500", "gray.300")}>
                      Unfulfilled
                    </Text>
                    <Flex gap={4} wrap="wrap">
                      {userCredentials?.unfulfilled_credentials?.map((credential) => (
                        <RecordLink
                          identifier={credential.credential.name}
                          icon={<IoMedalOutline />}
                          link={
                            userHasRole(currentUser, "credentials_admin")
                              ? `/credentials/all/${credential.credential.id}`
                              : `/credentials/me/${credential.id}`
                          }
                        />
                      ))}
                    </Flex>
                  </VStack>
                </>
              )}
            </>
          ) : (
            <EmptyState title="User has no credentials." size="2xs" />
          )}
        </VStack>

        <VStack
          flex="1"
          width={{ base: "100%", md: "50%" }}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          p={6}
          spacing={6}
          align="start"
          borderRadius="md">
          <Heading size="md">Team</Heading>

          {user?.team_memberships.length ? (
            <VStack w="100%" align="start">
              {user?.team_memberships.map((membership) => (
                <TeamCard team={membership.team} location="teams" />
              ))}
            </VStack>
          ) : (
            <EmptyState title="User is not on a team." size="2xs" />
          )}
        </VStack>
      </Flex>
      <DeleteModal
        title="Delete README?"
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          updateUser({ id: user.id, biography: null });
          onClose();
        }}
      />
    </VStack>
  );
};
