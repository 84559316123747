import { WarningIcon } from "@chakra-ui/icons";
import { Flex, HStack, Tag, Text, useColorModeValue, VStack, Box } from "@chakra-ui/react";
import { humanize } from "inflection";
import { DateTime } from "luxon";
import React from "react";

export type ScheduledDateProps = {
  type: "calibration" | "maintenance";
  date: string;
  overdue?: boolean;
};

export const ScheduledDate = ({ type, date, overdue }: ScheduledDateProps) => {
  let cardBg = useColorModeValue("teal.50", "teal.700");
  let cardHoverBg = useColorModeValue("teal.100", "teal.600");
  if (type === "calibration") {
    cardBg = useColorModeValue("purple.50", "purple.700");
    cardHoverBg = useColorModeValue("purple.100", "purple.600");
  }
  let overdueColor = useColorModeValue("red.500", "red.300");
  let overdueHoverBg = useColorModeValue("red.100", "red.600");

  return (
    <Box
      flex="1"
      bg={overdue ? overdueHoverBg : cardBg}
      p={4}
      borderLeft="4px solid"
      marginBottom={2}
      borderColor={overdue ? overdueColor : type === "calibration" ? "purple.400" : "teal.400"}>
      <Flex
        px={4}
        h="100%"
        align="center"
        justify="space-between"
        w="100%"
        borderRadius="base"
        direction={["column", "row"]}>
        <HStack>
          <VStack
            align="start"
            pr={4}
            spacing={0}
            fontSize="md"
            fontWeight="medium"
            textColor={useColorModeValue(
              overdue ? overdueColor : type === "calibration" ? "purple.500" : "teal.500",
              "auto"
            )}>
            <Text>{DateTime.fromISO(date).toFormat("MMM")}</Text>
            <Text
              fontSize="md"
              fontWeight="medium"
              textColor={useColorModeValue(
                overdue ? overdueColor : type === "calibration" ? "purple.500" : "teal.500",
                "auto"
              )}>
              {DateTime.fromISO(date).toFormat("dd")}
            </Text>
          </VStack>
          <VStack align="start" spacing={1}>
            {overdue ? (
              <Tag colorScheme="red" size="sm" variant="solid">
                <WarningIcon mr={2} />
                Overdue {humanize(type)}
              </Tag>
            ) : (
              <Tag
                colorScheme={type === "calibration" ? "purple" : "teal"}
                size="sm"
                variant="solid">
                Next {humanize(type)}
              </Tag>
            )}
          </VStack>
        </HStack>
      </Flex>
    </Box>
  );
};
