import axios from "axios";

// Used for testing vs non testing purposes
const csrfToken =
  typeof document !== "undefined"
    ? document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")
    : null;

export const api = axios.create({
  baseURL: "/api",
  headers: {
    ...(csrfToken && { "X-CSRF-TOKEN": csrfToken }),
  },
});

export const handleLogin = () => {
  window.location.href = "/api/auth/login";
};

export const handleLogout = () => {
  window.location.href = "/api/auth/logout";
};
