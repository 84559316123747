import { Flex, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CapitalEquipmentUserData,
  useBulkCreateCapitalEquipmentUser,
} from "../../../../api/capital_equipment_user";
import { UserSelect } from "../../../Users/shared/UserSelect";

type EditRecipientsModalProps = {
  capitalEquipmentId: number;
  isOpen: boolean;
  onClose: () => void;
  capitalEquipmentUsers: CapitalEquipmentUserData[];
};

type EditRecipientsFormValuesType = {
  recipients: number[];
};

const defaultFormValues: EditRecipientsFormValuesType = {
  recipients: [],
};

export const EditRecipientsModal = ({
  capitalEquipmentId,
  isOpen,
  onClose,
  capitalEquipmentUsers,
}: EditRecipientsModalProps) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: defaultFormValues,
  });
  const toast = useToast();
  const alreadyAddedRecipients = useMemo(
    () => capitalEquipmentUsers?.map((user) => user.user),
    [capitalEquipmentUsers]
  );
  const {
    mutateAsync: bulkCreateCapitalEquipmentUser,
    isLoading: isLoadingBulkCreateCapitalEquipmentUser,
  } = useBulkCreateCapitalEquipmentUser(capitalEquipmentId);
  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = () => {
    if (formValues.recipients) {
      bulkCreateCapitalEquipmentUser({
        capital_equipment_id: capitalEquipmentId,
        user_ids: formValues.recipients,
      }).then(() => {
        toast({
          title: "Recipients updated",
          status: "success",
        });
        handleClose();
      });
    }
  };
  return (
    <FormModal
      size={"md"}
      title={"Edit Recipients"}
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      isLoading={isLoadingBulkCreateCapitalEquipmentUser}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="recipients"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Who should receive the reminders?</FormLabel>
              <UserSelect
                setValue={(value) => setValue("recipients", value)}
                alreadyAddedUsers={alreadyAddedRecipients}
              />
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};
