import React, { useState } from "react";
import {
  Box,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Icon,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import {
  TrainingMaterialData,
  TrainingMaterialShowData,
  useUpdateTrainingMaterial,
} from "../../../../api/training_materials";
import { CiGlobe } from "react-icons/ci";

type NewMaterialProps = {
  isOpen: boolean;
  onClose: () => void;
  activeMaterial: TrainingMaterialShowData;
};

export const NewMaterial = ({ isOpen, onClose, activeMaterial }: NewMaterialProps) => {
  const { mutate: updateTrainingMaterial } = useUpdateTrainingMaterial(activeMaterial.id);
  const [title, setTitle] = useState<string>(activeMaterial.title || "");
  const [link, setLink] = useState<string>(activeMaterial.link || "");
  const handleSubmit = () => {
    updateTrainingMaterial({ id: activeMaterial.id, title, link });
    onClose();
  };

  return (
    <FormModal
      title="Edit Training Material Link"
      submitButtonTitle="Done"
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitButtonColorSchema="teal">
      <VStack width="100%" align="start" spacing={6}>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input placeholder="Add title" onChange={(e) => setTitle(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Link to the Material</FormLabel>
          <InputGroup>
            <InputLeftAddon children={<Icon as={CiGlobe} boxSize={6} />} />
            <Input placeholder="Add link" onChange={(e) => setLink(e.target.value)} />
          </InputGroup>
        </FormControl>
      </VStack>
    </FormModal>
  );
};
