import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  ListItem,
  OrderedList,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import { DeleteIcon, LinkIcon } from "@chakra-ui/icons";
import { useGetTrainingMaterials } from "../../../../api/trainings";
import {
  TrainingMaterialData,
  TrainingMaterialShowData,
  useCreateTrainingMaterial,
  useDeleteTrainingMaterial,
} from "../../../../api/training_materials";
import { RiEdit2Line } from "react-icons/ri";

type TrainingMaterialsProps = {
  trainingId: number;
  editable: boolean;
  onOpen: () => void;
  setActiveMaterial: React.Dispatch<React.SetStateAction<TrainingMaterialShowData | null>>;
};

export const TrainingMaterials = ({
  trainingId,
  editable,
  onOpen,
  setActiveMaterial,
}: TrainingMaterialsProps) => {
  const { data: trainingMaterials } = useGetTrainingMaterials(trainingId);
  const { mutate: createTrainingMaterial } = useCreateTrainingMaterial(trainingId);
  const { mutate: deleteTrainingMaterial } = useDeleteTrainingMaterial(trainingId);
  return (
    <VStack
      p={5}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      align="start"
      spacing={4}
      width={{ base: "100%", lg: "50%" }}>
      <HStack>
        <Box
          bg={useColorModeValue("purple.100", "purple.500")}
          p={2}
          borderRadius="md"
          display="flex">
          <LinkIcon color={useColorModeValue("purple.700", "purple.200")} />
        </Box>
        <Heading size="md">
          Training Materials{" "}
          <Box as="span" fontWeight="light">
            ({trainingMaterials?.length})
          </Box>
        </Heading>
      </HStack>
      <Divider />
      <OrderedList width="100%" spacing={4}>
        {trainingMaterials?.map((file) => (
          <Box key={file.id}>
            <ListItem>
              <HStack justify="space-between" width="100%" mb={2}>
                {file.title ? (
                  <Link href={file.link} color="teal.500" isExternal>
                    {file.title}
                  </Link>
                ) : (
                  <Text as="i" color="gray.500">
                    Add training material...{" "}
                  </Text>
                )}
                {editable && (
                  <ButtonGroup>
                    <IconButton
                      size="xs"
                      aria-label="edit material"
                      icon={<RiEdit2Line />}
                      onClick={() => {
                        onOpen();
                        setActiveMaterial(file);
                      }}
                    />
                    <ConfirmationButton
                      label="Delete Training Material"
                      aria-label="delete material"
                      size="xs"
                      variant="IconButton"
                      buttonVariant="outline"
                      confirmationButtonLabel="Yes"
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      children="Are you sure you want to delete this training material? You can’t undo this action afterwards."
                      confirmationHeader="Delete Training Material"
                      onConfirm={() => deleteTrainingMaterial(file.id)}
                    />
                  </ButtonGroup>
                )}
              </HStack>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </OrderedList>

      {editable ? (
        <Button
          width="100%"
          onClick={() => createTrainingMaterial({ training_id: trainingId, title: "", link: "" })}>
          Add Link
        </Button>
      ) : null}
    </VStack>
  );
};
