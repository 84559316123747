import { Flex, HStack, Link, Text } from "@chakra-ui/react";
import { Collection, Order, Pagination, RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { TimelineEventData, TimelineEventSearchResponse } from "../../../api/timeline_events";
import { EmptyState } from "../../shared/EmptyState";
import { RenderTemplateValue } from "../../shared/TimelineEventText";

export type AuditTrailProps = {
  data: TimelineEventSearchResponse;
  isLoading: boolean;
  pagination: Pagination;
  onPagination: (pagination: Pagination) => void;
  order: Order;
  onOrder: (order: Order) => void;
};

export const AuditTrail: React.FC<AuditTrailProps> = ({
  data,
  isLoading,
  pagination,
  onPagination,
  order,
  onOrder,
}) => {
  const columns = [
    {
      label: "Record",
      render: (event: TimelineEventData) => {
        return (
          <RecordLink
            type={event.timelineable_type}
            identifier={event.timelineable_id}
            link={event.timelineable_app_href}
          />
        );
      },
    },
    {
      label: "Event",
      render: (event: TimelineEventData) => <TimelineEvent event={event} />,
    },
    {
      label: "User",
      render: (event: TimelineEventData) =>
        event.user ? (
          <Link as={RouterLink} to={`/users/${event.user?.id}/profile`}>
            {event.user?.name}
          </Link>
        ) : (
          <Text as={"span"}>System</Text>
        ),
    },
    {
      label: "Created At",
      render: (event: TimelineEventData) => event.created_at.toFormat("LLL dd yyyy"),
    },
  ];

  return (
    <Flex flexDir="column">
      {data?.results.length ? (
        <Collection
          columns={columns}
          items={data?.results || []}
          isLoading={isLoading}
          onPagination={onPagination}
          pagination={data?.pagination || pagination}
          onOrder={onOrder}
          order={order}
        />
      ) : (
        <EmptyState title="No timeline events found" size="3xs" />
      )}
    </Flex>
  );
};

type TimelineEventProps = {
  event: TimelineEventData;
};

const TimelineEvent = ({ event }: TimelineEventProps) => {
  return (
    <HStack gap={1}>
      <Text as={"span"} gap={1} pl={2}>
        {event.timeline_template ? (
          event.timeline_template.map((templateValue, idx) => (
            <>
              <Text as={"span"} key={`${event.id}-template-${idx}`} gap={1}>
                <RenderTemplateValue
                  shouldCapitalize={idx === 0}
                  key={`${event.id}-template-${idx}`}
                  event={event}
                  templateValue={templateValue}
                />
              </Text>
            </>
          ))
        ) : (
          <Text>{event.content}</Text>
        )}
      </Text>
    </HStack>
  );
};
