import React from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  Box,
  Button,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import { useRestockInventoryModal } from "./RestockModalComponents/util";
import { AddStockAndLocation } from "./RestockModalComponents/AddStockAndLocation";
import { inflect } from "inflection";

export const PendingPurchaseBanner = ({ inventory }) => {
  return (
    <Alert
      status="info"
      variant="subtle"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      flexDir="row">
      <HStack>
        <AlertIcon />
        <Box>
          <AlertTitle>
            Add {inventory.pending_delivered_purchase_line_item.quantity}{" "}
            {inflect(
              inventory.unit_of_measurement || "unit",
              inventory.pending_delivered_purchase_line_item.quantity
            )}{" "}
            to stock
          </AlertTitle>
          <AlertDescription>
            Purchase #{inventory.pending_delivered_purchase_line_item.purchase_id} has been
            delivered.
          </AlertDescription>
        </Box>
      </HStack>

      <AddDeliveryToStockModal inventory={inventory} />
    </Alert>
  );
};

const AddDeliveryToStockModal = ({ inventory }) => {
  const restockInventoryProps = useRestockInventoryModal(inventory);

  return (
    <>
      <Button colorScheme="blue" onClick={restockInventoryProps.onOpen}>
        Add to Stock Now
      </Button>
      <FormModal
        size="2xl"
        title={`Add ${inventory.pending_delivered_purchase_line_item.quantity} ${inflect(
          inventory.unit_of_measurement || "unit",
          inventory.pending_delivered_purchase_line_item.quantity
        )} to Stock`}
        isOpen={restockInventoryProps.isOpen}
        onClose={restockInventoryProps.onClose}
        handleSubmit={restockInventoryProps.handleSubmit}>
        <AddStockAndLocation
          inventory={inventory}
          setPage={restockInventoryProps.setPage}
          setValue={restockInventoryProps.setValue}
          control={restockInventoryProps.control}
          formValues={restockInventoryProps.formValues}
          showPreview={restockInventoryProps.showPreview}
          pathToLocation={restockInventoryProps.pathToLocation}
          setShowPreview={restockInventoryProps.setShowPreview}
          setPathToLocation={restockInventoryProps.setPathToLocation}
          setSearch={restockInventoryProps.setSearch}
          treeSelectInventoryLocationsQuery={
            restockInventoryProps.treeSelectInventoryLocationsQuery
          }
        />
      </FormModal>
    </>
  );
};
