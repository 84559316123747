import {
  Box,
  FormControl,
  FormLabel,
  Input,
  MenuItem,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { useCreateContract } from "../../../../api/contracts";
import { useVendorOptions } from "../../../../api/options";
import { MinimalSpendingAuthority } from "../../../../api/spending_authority";
import { useNewVendor } from "../../../../api/vendor";
import { SpendingAuthoritySelect } from "../../SpendingAuthoritySelectTree";
import { useForm, Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";

const defaultContractFormValues = {
  name: "",
  vendor_id: null,
  description: "",
  spending_authority: null,
  rate: "",
  renewal_date: "",
};

type FormValuesType = {
  name: string;
  vendor_id: number | null;
  description: string;
  spending_authority: MinimalSpendingAuthority | null;
  rate: string;
  renewal_date: string;
};

export const NewContractModal = () => {
  const vendorOptions = useVendorOptions();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleSubmit, control, reset, watch, setValue } = useForm<FormValuesType>({
    defaultValues: defaultContractFormValues,
  });

  const [encodedFiles, setEncodedFiles] = useState<{ name: string; file: string }[]>([]);
  const navigate = useNavigate();

  const { mutate: createContract, isLoading } = useCreateContract();
  const { mutate: createVendor } = useNewVendor();

  const formValues = watch();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) => setEncodedFiles(encodedFileObjects))
        .catch((error) => console.log(error));
    }
  };

  const handleClose = () => {
    reset();
    setEncodedFiles([]);
    onClose();
  };

  const disableSubmit = !formValues.name || !formValues.vendor_id || !formValues.spending_authority;

  const onSubmit = (data: FormValuesType) => {
    if (data.vendor_id && data.spending_authority && data.name) {
      createContract(
        {
          name: data.name,
          vendor_id: data.vendor_id,
          spending_authority_id: data.spending_authority.id,
          spending_authority_type: data.spending_authority.type,
          description: data.description,
          renewal_date: DateTime.fromISO(data.renewal_date),
          rate: data.rate,
          files: encodedFiles,
        },
        {
          onSuccess: (data) => {
            handleClose();
            navigate(`/services/contracts/${data.id}`);
          },
        }
      );
    }
  };

  return (
    <>
      <MenuItem onClick={onOpen} fontWeight="semibold" p={3} pl={6} icon={<FaRegFileAlt />}>
        Contract
      </MenuItem>

      <FormModal
        title="New Contract"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={isLoading}
        submitButtonDisabled={disableSubmit}
        handleSubmit={handleSubmit(onSubmit)}
        submitButtonColorSchema="teal"
        submitButtonTitle="Create Contract"
        size="2xl">
        <Stack direction={{ base: "column", md: "row" }} w="100%">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input {...field} />
              </FormControl>
            )}
          />
          <Controller
            name="vendor_id"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Vendor</FormLabel>
                <Select
                  {...field}
                  options={vendorOptions}
                  creatable
                  onCreate={(value) => {
                    createVendor(
                      {
                        name: value.toString(),
                        status: "approved",
                      },
                      {
                        onSuccess: (data) => {
                          setValue("vendor_id", data.id);
                        },
                      }
                    );
                  }}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Stack direction={{ base: "column", md: "row" }} w="100%">
          <Controller
            name="renewal_date"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Renewal Date</FormLabel>
                <Input {...field} type="date" />
              </FormControl>
            )}
          />
          <Controller
            name="rate"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Rate</FormLabel>
                <Input {...field} />
              </FormControl>
            )}
          />
        </Stack>

        <Controller
          name="spending_authority"
          control={control}
          rules={{ required: true }}
          render={() => (
            <FormControl data-testid="select-spending-authority" isRequired>
              <FormLabel>Spending Authority</FormLabel>
              <SpendingAuthoritySelect
                excludeTypes={["Service"]}
                onChange={(spendingAuthority) => {
                  setValue("spending_authority", spendingAuthority);
                }}
                spendingAuthority={formValues.spending_authority}
              />
            </FormControl>
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Additional Information</FormLabel>
              <Textarea {...field} />
            </FormControl>
          )}
        />

        <FormControl>
          <FormLabel>Attach Documents</FormLabel>
          <Box
            width="100%"
            border="1px"
            p={1}
            borderColor={useColorModeValue("gray.200", "gray.500")}
            borderRadius="base">
            <input type="file" multiple={true} onChange={(e) => handleFileChange(e)} />
          </Box>
        </FormControl>
      </FormModal>
    </>
  );
};
