import { useDisclosure, useToast } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  InventoryShowData,
  useCreateInventory,
  useUpdateInventory,
} from "../../../../../api/inventory";
import { useCreateInventoryItem } from "../../../../../api/inventory_item";
import {
  LocationTreeKeyValue,
  LocationTreeValue,
  useTreeSelectInventoryLocations,
} from "../../../../../api/inventory_location";
import { useBulkCreateInventoryVendor } from "../../../../../api/inventory_vendor";
import {
  PurchaseLineItemPendingInventoryData,
  PurchaseLineItemShowData,
} from "../../../../../api/purchase_line_item";
import { Money } from "../../../../../helpers/Money";
import { NetworkError } from "../../../../types";

const scienceIconSVG = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0.0998535081744194 0.6176760196685791 81.23884582519531 96.0000228881836" fill="none"><path d="M68.8652 15.0394C68.8652 8.50906 65.7256 4.17813 59.511 2.21632C54.4876 0.617676 47.7944 0.617676 40.6904 0.617676C33.5865 0.617676 26.917 0.617676 21.8835 2.21632C15.7163 4.19171 12.5801 8.50906 12.5801 15.0394C12.5801 23.6436 23.6993 36.1069 31.5568 43.8455L32.463 44.9486L10.2823 40.8315V46.2621L26.8966 49.3474L0.0998535 56.0848V61.5833L31.1088 53.7768L31.0239 53.8684C23.2004 61.641 12.5496 73.7649 12.5496 82.1892C12.5496 88.7229 15.6858 93.0504 21.897 95.0122C26.9238 96.6177 33.6136 96.6177 40.7176 96.6177C47.8215 96.6177 54.4978 96.6177 59.5381 95.0122C65.746 93.0368 68.8924 88.7229 68.8924 82.1892C68.8924 73.7038 58.0311 61.4272 50.2008 53.6783L71.2954 57.5918V52.1612L51.9047 48.5634L81.3387 41.1505V35.652L50.2755 43.4586C58.1092 35.7199 68.8652 23.501 68.8652 15.0394ZM18.0651 15.0394C18.0651 6.89345 24.6327 6.09922 40.721 6.09922C56.8092 6.09922 63.3837 6.91042 63.3837 15.0564C63.3837 21.5052 52.4953 33.9142 43.4227 42.4946H43.3888V20.392L38.0498 18.7899V42.4844H37.9887C28.9263 33.8735 18.0752 21.5018 18.0651 15.0462V15.0394ZM63.3667 82.2197C63.3667 90.3656 56.8058 91.1768 40.704 91.1768C24.6022 91.1768 18.0481 90.3656 18.0481 82.2197C18.0481 75.7708 28.9094 63.3788 37.9887 54.8018H38.0498V75.8455L43.3888 77.4475V54.778H43.4227C52.4953 63.3517 63.3667 75.7403 63.3667 82.2197Z" fill="black"/></svg>
`;

export type RestockFormValues = {
  purchase_id?: number;
  is_consumable: string;
  inventory_id: number | null;
  quantity: number;
  inventory_location_id: number | null;
  option: LocationTreeValue | null;
  lot_number: string;
  expiration_date: string;
  purchase_line_item: PurchaseLineItemShowData | PurchaseLineItemPendingInventoryData | null;
  amount: Money;
  total_estimated_cost: Money;
  is_backfill: boolean;
  total_quantity: string;
  unit_of_measurement: string;
};

const defaultFormValues: RestockFormValues = {
  is_consumable: "durable",
  inventory_id: null,
  quantity: 0,
  inventory_location_id: null,
  option: null,
  lot_number: "",
  expiration_date: "",
  purchase_line_item: null,
  amount: Money.zero(),
  total_estimated_cost: Money.zero(),
  is_backfill: false,
  total_quantity: "1.0",
  unit_of_measurement: "",
};

export const useRestockInventoryModal = (
  inventory?: InventoryShowData,
  extraHandleClose?: () => void
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: createInventoryItem, isLoading: isLoadingInventoryItem } = useCreateInventoryItem(
    inventory?.id
  );

  const [showPreview, setShowPreview] = useState(false);
  const [pathToLocation, setPathToLocation] = useState<LocationTreeKeyValue[] | undefined>();
  const [search, setSearch] = useState<string | undefined>();

  const treeSelectInventoryLocationsQuery = useTreeSelectInventoryLocations(search, "Store Here");
  const [page, setPage] = useState(0);
  const { handleSubmit, control, reset, watch, setValue } = useForm<RestockFormValues>({
    defaultValues: defaultFormValues,
  });

  const formValues = watch();
  const toast = useToast();

  useEffect(() => {
    if (inventory?.pending_delivered_purchase_line_item && !formValues.purchase_line_item) {
      const lineItem = inventory.pending_delivered_purchase_line_item;
      setValue("purchase_line_item", lineItem);
      setValue("quantity", lineItem.quantity);
      setValue("amount", lineItem.unit_amount);
      setValue("total_estimated_cost", lineItem.unit_amount.times(lineItem.quantity));
    }
  }, [inventory]);

  const handleClose = () => {
    setPathToLocation(undefined);
    setSearch(undefined);
    setPage(0);
    setShowPreview(false);
    reset();
    onClose();
    extraHandleClose && extraHandleClose();
  };

  const onSubmit = async (data: RestockFormValues) => {
    const inventoryId = inventory?.id || data.inventory_id;

    if (data.inventory_location_id && inventoryId) {
      createInventoryItem(
        {
          inventory_id: inventoryId,
          quantity: data.quantity,
          inventory_location_id: data.inventory_location_id,
          lot_number: data.lot_number.length ? data.lot_number : null,
          expiration_date: data.expiration_date ? DateTime.fromISO(data.expiration_date) : null,
          amount: data.amount,
          purchase_line_item_id: data.purchase_line_item?.id || null,
          is_backfill: data.is_backfill,
          total_quantity: parseFloat(data.total_quantity),
        },
        {
          onSuccess: (response) => {
            const barcodeItems = response.map((item) => ({
              barcode_code: item.barcode_code || "",
              id: item.id.toString(),
            }));

            if (barcodeItems.length > 0) {
              printBarcodes(barcodeItems);
            }

            handleClose();
          },

          onError: (e) => {
            const error = e as NetworkError;
            toast({
              title: "Error",
              description: error.response.data.error.message || "An error occurred",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    }
  };

  return {
    isOpen,
    onOpen,
    onClose,
    createInventoryItem,
    isLoadingInventoryItem,
    showPreview,
    setShowPreview,
    pathToLocation,
    setPathToLocation,
    setSearch,
    treeSelectInventoryLocationsQuery,
    page,
    setPage,
    handleSubmit: handleSubmit(onSubmit),
    control,
    setValue,
    formValues,
    handleClose,
  };
};
export const printBarcodes = (barcodeItems: { barcode_code: string; id: string }[]) => {
  const printWindow = window.open(
    "",
    "PrintWindow",
    "width=500,height=500,left=0,top=0,menubar=no,toolbar=no,location=no,status=no,scrollbars=no,resizable=no"
  );

  if (printWindow) {
    printWindow.document.open();
    const html = `
      <html>
        <head>
          <title>Print QR Codes</title>
          <style>
            @media print {
              @page {
                size: 1.25in 1.25in;
                margin: 0;
              }
              body {
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .page-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 1.25in;
                height: 1.25in;
                page-break-after: always;
                position: relative;
                left: 0;
                top: 0;
              }
              .qr-wrapper {
                position: relative;
                width: 80%;
                height: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              .icon-wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
              }
              .icon-wrapper svg {
                width: 32px;
                height: 32px;
              }
              .id-text {
                margin-top: 2px;
                font-size: 1rem;
                font-family: sans-serif;
                text-align: center;
              }
            }
          </style>
        </head>
        <body>
          ${barcodeItems
            .map(
              (item) => `
            <div class="page-container">
              <div class="qr-wrapper">
                <img src="data:image/svg+xml;base64,${btoa(item.barcode_code)}" alt="QR Code" />
                <div class="icon-wrapper">
                  ${scienceIconSVG}
                </div>
              </div>
              <div class="id-text">${item.id}</div>
            </div>
          `
            )
            .join("")}
        </body>
      </html>
    `;
    printWindow.document.write(html);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  } else {
    alert("Please allow popups for this site");
  }
};

export type InventoryFormValues = {
  name: string;
  sku: string;
  category: string | null;
  vendors: { label: string; value: string | number }[] | null;
  track_lots: boolean;
  track_expiration_dates: boolean;
  track_reorder_threshold: boolean;
  reorder_threshold: number;
  is_consumable: string | null;
  unit_of_measurement: string | null;
};

const defaultInventoryFormValues = {
  name: "",
  sku: "",
  category: null,
  vendors: null,
  track_lots: false,
  track_expiration_dates: false,
  track_reorder_threshold: false,
  reorder_threshold: 0,
  is_consumable: null,
  unit_of_measurement: null,
};

export const useNewInventoryModal = (inventory?: InventoryShowData, onNewInventoryCreated?) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: createInventory, isLoading: isLoadingCreateInventory } = useCreateInventory();
  const { mutate: updateInventory, isLoading: isLoadingUpdateInventory } = useUpdateInventory();
  const { mutate: createInventoryVendor } = useBulkCreateInventoryVendor();

  const { handleSubmit, control, reset, watch, setValue } = useForm<InventoryFormValues>({
    defaultValues: defaultInventoryFormValues,
  });

  const toast = useToast();

  const formValues = watch();
  useEffect(() => {
    const initialInventoryValues = inventory
      ? {
          name: inventory.name || "",
          sku: inventory.sku || "",
          category: inventory.category || null,
          vendors: inventory.vendors
            ? inventory.vendors.map((vendor) => ({
                label: vendor.vendor.name,
                value: vendor.vendor.id.toString(),
              }))
            : null,
          track_lots: inventory.track_lots || false,
          track_expiration_dates: inventory.track_expiration_dates || false,
          track_reorder_threshold: inventory.track_reorder_threshold || false,
          reorder_threshold: inventory.reorder_threshold || 0,
          is_consumable: inventory.is_consumable ? "consumable" : "durable",
          unit_of_measurement: inventory.unit_of_measurement,
        }
      : defaultInventoryFormValues;

    reset(initialInventoryValues);
  }, [inventory]);

  useEffect(() => {
    // If the switch is off, set reorder_threshold to 0
    if (!formValues.track_reorder_threshold && !inventory?.track_reorder_threshold) {
      setValue("reorder_threshold", 0);
    }
  }, [formValues.reorder_threshold]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: InventoryFormValues) => {
    const vendor_ids = data.vendors?.map((vendor) => Number(vendor.value)) || [];

    if (inventory) {
      updateInventory(
        {
          id: inventory.id,
          name: data.name,
          category: data.category!,
          track_lots: data.track_lots,
          track_expiration_dates: data.track_expiration_dates,
          track_reorder_threshold: data.track_reorder_threshold,
          reorder_threshold: data.reorder_threshold,
          is_consumable: data.is_consumable === "consumable",
          unit_of_measurement: data.unit_of_measurement,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      createInventory(
        {
          name: data.name,
          sku: data.sku,
          category: data.category!,
          track_lots: data.track_lots,
          track_expiration_dates: data.track_expiration_dates,
          track_reorder_threshold: data.track_reorder_threshold,
          reorder_threshold: data.reorder_threshold,
          is_consumable: data.is_consumable === "consumable",
          unit_of_measurement: data.unit_of_measurement,
        },
        {
          onSuccess: (inventory) => {
            if (inventory && vendor_ids.length) {
              createInventoryVendor({
                inventory_vendorable_id: inventory.id,
                inventory_vendorable_type: "Inventory",
                vendor_ids: vendor_ids,
              });
            }
            if (onNewInventoryCreated) {
              onNewInventoryCreated(inventory);
            }
            handleClose();
          },
          onError: (e) => {
            const error = e as NetworkError;
            toast({
              title: "Error",
              description: error.response.data.error.message || "An error occurred",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    }
  };

  const allFieldsFilledIn = () => {
    const trackReorderValid =
      (formValues.track_reorder_threshold && formValues.reorder_threshold > 0) ||
      !formValues.track_reorder_threshold;
    return formValues.name && formValues.category && trackReorderValid;
  };

  return {
    isOpen,
    onOpen,
    isLoadingCreateInventory,
    isLoadingUpdateInventory,
    handleSubmit: handleSubmit(onSubmit),
    control,
    formValues,
    handleClose,
    allFieldsFilledIn,
    setValue,
  };
};

export const unitOfMeasurementOptions = [
  { label: "Aliquots", value: "aliquots" },
  { label: "Assays", value: "assays" },
  { label: "Bottles", value: "bottles" },
  { label: "Boxes", value: "boxes" },
  { label: "Cases", value: "cases" },
  { label: "Chips", value: "chips" },
  { label: "Gallons (gal)", value: "gal" },
  { label: "Grams (g)", value: "g" },
  { label: "Kilograms (kg)", value: "kg" },
  { label: "Kits", value: "kits" },
  { label: "Liters (L)", value: "L" },
  { label: "Micrograms (ug)", value: "ug" },
  { label: "Milligrams (mg)", value: "mg" },
  { label: "Milliliters (mL)", value: "mL" },
  { label: "Ounces (oz)", value: "oz" },
  { label: "Package", value: "packages" },
  { label: "Pieces", value: "pieces" },
  { label: "Pellets", value: "pellets" },
  { label: "Plates", value: "plates" },
  { label: "Pounds (lb)", value: "lb" },
  { label: "Quarts (qt)", value: "qt" },
  { label: "Set", value: "sets" },
  { label: "Strips", value: "strips" },
  { label: "Tips", value: "tips" },
  { label: "Tubes", value: "tubes" },
  { label: "Units", value: "units" },
  { label: "Vials", value: "vials" },
  { label: "Wafers", value: "wafers" },
];
