import { Box, Heading } from "@chakra-ui/react";
import { AttributesTable, EditableText, ToggleButton } from "@sciencecorp/helix-components";
import React from "react";
import {
  CapitalEquipmentShowData,
  useUpdateCapitalEquipment,
} from "../../../../api/capital_equipment";

export type CapitalEquipmentInfoProps = {
  capitalEquipment: CapitalEquipmentShowData;
  isDisabled?: boolean;
};

export const ServiceAndUtilityRequirements = ({
  capitalEquipment,
  isDisabled,
}: CapitalEquipmentInfoProps) => {
  const { mutate: updateTool, isLoading: isLoadingUpdateTool } = useUpdateCapitalEquipment(
    Number(capitalEquipment.id)
  );
  return (
    <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6} height={"100%"}>
      <Heading size="md" mb={4} fontWeight="medium">
        Service and Utility Requirements
      </Heading>
      <AttributesTable
        attributes={[
          {
            label: "Power Requirement",
            value: (
              <EditableText
                preview={capitalEquipment.power_requirements}
                onSubmit={(value) => {
                  if (value) {
                    updateTool({ power_requirements: value, id: Number(capitalEquipment.id) });
                  }
                }}
                isLoading={isLoadingUpdateTool}
                disabled={isDisabled}
              />
            ),
          },
          {
            label: "Gas Requirement",
            value: (
              <EditableText
                preview={capitalEquipment.gas_requirements}
                onSubmit={(value) => {
                  if (value) {
                    updateTool({ gas_requirements: value, id: Number(capitalEquipment.id) });
                  }
                }}
                isLoading={isLoadingUpdateTool}
                disabled={isDisabled}
              />
            ),
          },
          {
            label: "Exhaust Required",
            value: (
              <ToggleButton
                value={capitalEquipment.exhaust_required}
                onToggle={() => {
                  const newValue = !capitalEquipment.exhaust_required;
                  updateTool({ exhaust_required: newValue, id: Number(capitalEquipment.id) });
                }}
                isDisabled={isLoadingUpdateTool || isDisabled}
              />
            ),
          },
          {
            label: "Compressed Air Required",
            value: (
              <ToggleButton
                value={capitalEquipment.compressed_air_required}
                onToggle={() => {
                  const newValue = !capitalEquipment.compressed_air_required;
                  updateTool({
                    compressed_air_required: newValue,
                    id: Number(capitalEquipment.id),
                  });
                }}
                isDisabled={isLoadingUpdateTool || isDisabled}
              />
            ),
          },
          {
            label: "Di water required",
            value: (
              <ToggleButton
                value={capitalEquipment.distilled_water_required}
                onToggle={() => {
                  const newValue = !capitalEquipment.distilled_water_required;
                  updateTool({
                    distilled_water_required: newValue,
                    id: Number(capitalEquipment.id),
                  });
                }}
                isDisabled={isLoadingUpdateTool || isDisabled}
              />
            ),
          },
          {
            label: "chilled water required",
            value: (
              <ToggleButton
                value={capitalEquipment.chilled_water_required}
                onToggle={() => {
                  const newValue = !capitalEquipment.chilled_water_required;
                  updateTool({ chilled_water_required: newValue, id: Number(capitalEquipment.id) });
                }}
                isDisabled={isLoadingUpdateTool || isDisabled}
              />
            ),
          },
          {
            label: "drain required",
            value: (
              <ToggleButton
                value={capitalEquipment.drain_required}
                onToggle={() => {
                  const newValue = !capitalEquipment.drain_required;
                  updateTool({ drain_required: newValue, id: Number(capitalEquipment.id) });
                }}
                isDisabled={isLoadingUpdateTool || isDisabled}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};
