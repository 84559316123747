import { Tag, VStack } from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import React from "react";
import { useSearchReimbursements } from "../../../../../../api/reimbursement";
import { MoneyText } from "../../../../../MoneyText";
import { reimbursementStatusColor } from "../../../../../Purchasing/Reimbursements/components/utils";
import { UserRequestPill } from "../../../../../Requests/utils";
import { buildTabComponent } from "../../../../../TabbedTable";

export const ReimbursementsTab = buildTabComponent({
  label: "Reimbursements",
  columns: [
    {
      label: "Paid at",
      orderOptions: { orderKey: "paid_at" },
      render: (reimbursement) =>
        reimbursement.paid_at ? reimbursement.paid_at.toFormat("LLL dd yyyy") : "-",
    },
    {
      label: "Reimbursement",
      orderOptions: { orderKey: "id" },
      render: (reimbursement) => (
        <RecordLink
          link={`/services/reimbursements/${reimbursement.id}`}
          identifier={`${reimbursement.id}`}
          size="small"
        />
      ),
    },
    {
      label: "Requested By",
      render: (reimbursement) => <UserRequestPill user={reimbursement.user} />,
    },
    {
      label: "Status",
      render: (reimbursement) => (
        <VStack align="start" marginTop={["2", "0"]}>
          <Tag
            colorScheme={reimbursementStatusColor(reimbursement.approval_status)}
            flexShrink={0}
            whiteSpace="nowrap"
            size={["sm", "md"]}>
            {titleize(humanize(reimbursement.approval_status))}
          </Tag>
        </VStack>
      ),
    },
    {
      label: "Amount",
      render: (reimbursement) => (
        <MoneyText money={reimbursement.amount} formatOptions={{ compact: "never" }} />
      ),
    },
  ],
  query: useSearchReimbursements,
});
