import React from "react";
import {
  Box,
  HStack,
  Text,
  useColorModeValue,
  useTheme,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Icon,
  StatHelpText,
  Flex,
  PopoverBody,
  PopoverHeader,
  PopoverArrow,
  Heading,
} from "@chakra-ui/react";
import Big from "big.js";
import { MoneyText } from "../../../MoneyText";
import { Money } from "../../../../helpers/Money";
import { BudgetItemSpendByModelData, BudgetItemSummary } from "../../../../api/budget_items";
import { BiSolidCircle } from "react-icons/bi";
import { BarElement, CategoryScale, Chart, LinearScale, Title, Tooltip, Legend } from "chart.js";
import { DateTime } from "luxon";
import { MultiProgressBar } from "../../../shared/MultiProgressBar";
import { HighestSpendAndCommittedRecordData } from "../../../../api/budget_groups";

Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export const BudgetActivityLinearBar = ({
  summary,
  spendByModel,
  highestRecords,
}: {
  summary?: BudgetItemSummary;
  spendByModel?: BudgetItemSpendByModelData;
  highestRecords?: HighestSpendAndCommittedRecordData;
}) => {
  const currency = "USD";

  // Colors
  const gray = useColorModeValue("gray.100", "gray.400");
  const orange = useColorModeValue("orange.200", "orange.500");
  const teal = useColorModeValue("teal.500", "teal.300");
  const pink = useColorModeValue("pink.500", "pink.400");
  const blue = useColorModeValue("blue.500", "blue.400");

  // Summary Data
  const totalSpent = summary?.spent_amount || Money.zero(currency);
  const totalCommitted = summary?.committed_amount || Money.zero(currency);
  const totalPendingApproval = summary?.pending_approval_amount || Money.zero(currency);
  const totalFunded = summary?.funding_amount || Money.zero(currency);

  // Expenditure Data
  const totalPurchases = spendByModel?.purchases.total_spent || Money.zero(currency);
  const totalContracts = spendByModel?.contracts.total_spent || Money.zero(currency);
  const totalServiceRequests = spendByModel?.service_requests.total_spent || Money.zero(currency);
  const totalReimbursements = spendByModel?.reimbursements.total_spent || Money.zero(currency);

  // Calculating percentages
  const totalSpentPercentage = Money.pct(
    totalSpent,
    totalFunded || Money.zero(currency)
  ).toNumber();
  const totalCommittedPercentage = Money.pct(
    totalCommitted,
    totalFunded || Money.zero(currency)
  ).toNumber();

  const totalPurchasesPercentage = Money.pct(
    totalPurchases,
    totalSpent || Money.zero(currency)
  ).toNumber();

  const totalContractsPercentage = Money.pct(
    totalContracts,
    totalSpent || Money.zero(currency)
  ).toNumber();

  const totalServiceRequestsPercentage = Money.pct(
    totalServiceRequests,
    totalSpent || Money.zero(currency)
  ).toNumber();
  const totalReimbursementsPercentage = Money.pct(
    totalReimbursements,
    totalSpent || Money.zero(currency)
  ).toNumber();

  const remainingBudgetPercentage = Math.max(
    0,
    new Big(100).sub(totalSpentPercentage).sub(totalCommittedPercentage).toNumber()
  );

  const spendingProgressBarElements = [
    {
      value: totalSpentPercentage,
      color: teal,
      popoverContent: highestRecords ? (
        <MultiBarPopover data={highestRecords?.spent} title="Spent" titleKeyColor={teal} />
      ) : null,
      showPopover: true,
    },
    {
      value: totalCommittedPercentage,
      color: orange,
      popoverContent: highestRecords ? (
        <MultiBarPopover
          data={highestRecords?.committed}
          title="Committed"
          titleKeyColor={orange}
        />
      ) : null,
      showPopover: true,
    },
    { value: remainingBudgetPercentage, color: gray },
  ];

  const expenditureTypeBreakdown = [
    { value: totalPurchasesPercentage, color: teal, label: "Purchases" },
    { value: totalContractsPercentage, color: orange, label: "Contracts" },
    { value: totalServiceRequestsPercentage, color: blue, label: "Service Requests" },
    { value: totalReimbursementsPercentage, color: pink, label: "Reimbursements" },
  ];

  return (
    <Tabs colorScheme="teal">
      <TabList>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          w="100%"
          gap={{ base: "4", md: "none" }}>
          <HStack w="100%">
            <Tab>Overview</Tab>
            <Tab whiteSpace="nowrap">By Expenditure</Tab>
          </HStack>
          <HStack>
            <Icon as={BiSolidCircle} color={gray} mr="1" boxSize={2} />
            <Text
              textColor={useColorModeValue("gray.500", "auto")}
              display={{ base: "none", md: "inline" }}>
              Balance
            </Text>
            <MoneyText
              fontWeight="bold"
              money={summary?.remaining_amount}
              formatOptions={{ compact: "never" }}
            />
            <Text>/</Text>
            <MoneyText money={summary?.funding_amount} formatOptions={{ compact: "never" }} />
            <Text
              textColor={useColorModeValue("gray.500", "auto")}
              display={{ base: "none", md: "inline" }}>
              Funded
            </Text>
          </HStack>
        </Flex>
      </TabList>

      <TabPanels>
        {/* Overview Tab */}
        <TabPanel>
          <VStack width="100%" align="start" position="relative" spacing={6}>
            <Box h="100%" w="100%" position="relative">
              <MultiProgressBar
                segments={spendingProgressBarElements}
                boxProps={{ height: 14, width: "100%" }}
              />
            </Box>

            {/* Stat Group for Overview */}
            <StatGroup w="100%" py={4}>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={teal} mr="1" boxSize={2} />
                  Total Spend
                </StatLabel>
                <StatNumber>
                  <MoneyText money={totalSpent} formatOptions={{ compact: "never" }} />
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={orange} mr="1" boxSize={2} />
                  Committed
                </StatLabel>
                <StatNumber>
                  <MoneyText money={totalCommitted} formatOptions={{ compact: "never" }} />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <Text>
                    over the next{" "}
                    {DateTime.now().endOf("year").diff(DateTime.now(), "months").months.toFixed()}{" "}
                    months
                  </Text>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={pink} mr="1" boxSize={2} />
                  Pending Approval
                </StatLabel>
                <StatNumber>
                  <MoneyText money={totalPendingApproval} formatOptions={{ compact: "never" }} />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <Text>for {summary?.pending_approval_records_count} expenditures</Text>
                </StatHelpText>
              </Stat>
            </StatGroup>
          </VStack>
        </TabPanel>

        {/* By Expenditure Type Tab */}
        <TabPanel>
          <VStack width="100%" align="start" position="relative" spacing={6}>
            {/* Progress Bar */}
            <Box h="100%" w="100%" position="relative">
              <MultiProgressBar
                segments={expenditureTypeBreakdown}
                boxProps={{ height: 14, width: "100%" }}
              />
            </Box>

            {/* Stat Group for By Expenditure Type */}
            <StatGroup w="100%" py={4}>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={teal} mr="1" boxSize={2} />
                  Purchases
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.purchases.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={spendByModel?.purchases.total_unpaid_committed || Money.zero(currency)}
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={orange} mr="1" boxSize={2} />
                  Contracts
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.contracts.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={spendByModel?.contracts.total_unpaid_committed || Money.zero(currency)}
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={blue} mr="1" boxSize={2} />
                  Service Requests
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.service_requests.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={
                        spendByModel?.service_requests.total_unpaid_committed ||
                        Money.zero(currency)
                      }
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={pink} mr="1" boxSize={2} />
                  Reimbursements
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.reimbursements.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={
                        spendByModel?.reimbursements.total_unpaid_committed || Money.zero(currency)
                      }
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
            </StatGroup>
          </VStack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

type MultiBarPopoverProps = {
  data: { name: string; amount: Money }[];
  title: string;
  titleKeyColor: string;
};
export const MultiBarPopover = ({ data, title, titleKeyColor }: MultiBarPopoverProps) => {
  if (!data || data.length === 0) return null;
  return (
    <>
      <PopoverArrow />
      <PopoverBody>
        <Flex direction="column">
          <HStack mb={2}>
            <Box boxSize={4} bg={titleKeyColor} borderRadius="full" />
            <Heading size="sm">{title}</Heading>
          </HStack>
          {data.map((item, index) => (
            <HStack
              key={index}
              w="100%"
              fontSize="sm"
              justify="space-between"
              borderTop={index === 0 ? "none" : "1px"}
              borderColor="chakra-border-color"
              py={2}>
              <Text
                maxW="20ch"
                isTruncated
                overflow="clip"
                color={useColorModeValue("gray.600", "auto")}>
                {item.name}
              </Text>
              <MoneyText
                money={item.amount}
                formatOptions={{ compact: "never" }}
                fontWeight="semibold"
              />
            </HStack>
          ))}
        </Flex>
      </PopoverBody>
    </>
  );
};
