import {
  CollectionTable,
  Column,
  EditableNumber,
  EmptyState,
  RecordLink,
} from "@sciencecorp/helix-components";
import {
  Button,
  Center,
  Text,
  Tag,
  IconButton,
  Flex,
  useDisclosure,
  HStack,
  useColorModeValue,
  Tooltip,
  Skeleton,
  Box,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { AddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  InventoryBomComponentData,
  useUpdateInventoryBomComponent,
  useDeleteInventoryBomComponent,
} from "../../../../api/inventory_bom_components";
import { useGetInventoryBom } from "../../../../api/inventory_boms";
import { NewComponentModal } from "./NewComponentModal";
import { humanize } from "inflection";
import { inventoryStatusColor } from "../../util";
import { MoneyText } from "../../../MoneyText";
import { BsBoxSeam } from "react-icons/bs";

export const ComponentsTable = ({ bomId, isEditable }: { bomId: number; isEditable: boolean }) => {
  const { data: inventoryBom, isLoading } = useGetInventoryBom(bomId);
  const { mutateAsync: deleteInventoryBomComponent } = useDeleteInventoryBomComponent();
  const { mutateAsync: updateInventoryBomComponent, isLoading: isUpdating } =
    useUpdateInventoryBomComponent();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const selectedInventoryIds = inventoryBom?.inventory_bom_components?.map(
    (component) => component.inventory.id
  );
  const columns: Column<InventoryBomComponentData>[] = useMemo(
    () => [
      {
        label: "Components Needed",
        render: (inventoryBomComponent: InventoryBomComponentData) => (
          <RecordLink
            icon={<BsBoxSeam />}
            iconPlacement="left"
            link={`/inventory/items/${inventoryBomComponent.inventory.id}/details`}
            identifier={inventoryBomComponent.inventory.name}
            width="max-content"
            maxWidth={["20ch", "100%"]}
          />
        ),
      },
      {
        label: "Location",
        render: (inventoryBomComponent: InventoryBomComponentData) => (
          <Text noOfLines={1}>
            {inventoryBomComponent.inventory.inventory_locations
              ?.map((location) => location.name)
              .join(", ") || "Not Assigned"}
          </Text>
        ),
      },
      {
        label: "Quantity",
        render: (inventoryBomComponent: InventoryBomComponentData) => (
          <HStack alignItems="center" gap={0}>
            <EditableNumber
              defaultValue={inventoryBomComponent.quantity}
              preview={
                <Text>
                  {inventoryBomComponent.quantity}{" "}
                  {inventoryBomComponent.inventory.unit_of_measurement}
                </Text>
              }
              onSubmit={(value) => {
                if (!value || parseInt(value) < 0) return;
                updateInventoryBomComponent({
                  id: inventoryBomComponent.id,
                  quantity: parseInt(value),
                });
              }}
            />
          </HStack>
        ),
      },
      {
        label: "Item Status",
        hideOnBreakpoint: "md",
        render: (inventoryBomComponent: InventoryBomComponentData) => (
          <Tag
            colorScheme={inventoryStatusColor(inventoryBomComponent.inventory.status ?? "")}
            w="max-content">
            {humanize(inventoryBomComponent.inventory.status ?? "")}
          </Tag>
        ),
      },
      {
        label: "Estimated Value",
        render: (inventoryBomComponent: InventoryBomComponentData) => {
          return inventoryBomComponent.inventory.amount.cents.toNumber() > 0 ? (
            <MoneyText
              money={inventoryBomComponent.inventory.amount}
              formatOptions={{ compact: "never" }}
              as="span"
              fontWeight="semibold"
              color={useColorModeValue("gray.800", "gray.300")}
            />
          ) : (
            <Tooltip label="Please add stock to inventory items to calculate costs.">
              <Text>--</Text>
            </Tooltip>
          );
        },
      },
      {
        label: "",
        render: (inventoryBomComponent: InventoryBomComponentData) => (
          <IconButton
            icon={<SmallCloseIcon />}
            w={"max-content"}
            aria-label="Delete"
            size="sm"
            isDisabled={!isEditable}
            colorScheme="red"
            onClick={() => deleteInventoryBomComponent(inventoryBomComponent.id)}
          />
        ),
      },
    ],
    [inventoryBom?.inventory_bom_components]
  );
  return (
    <>
      <NewComponentModal
        isOpen={isOpen}
        onClose={onClose}
        bomId={bomId}
        selectedInventoryIds={selectedInventoryIds}
      />
      <Flex
        direction="column"
        border="1px solid"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={4}
        width="100%">
        <Skeleton isLoaded={!isLoading && !isUpdating}>
          {inventoryBom?.inventory_bom_components &&
          inventoryBom.inventory_bom_components.length > 0 ? (
            <CollectionTable
              columns={columns}
              items={inventoryBom?.inventory_bom_components ?? []}
              onOrder={() => {}}
            />
          ) : (
            <EmptyState title="No components added yet." />
          )}
        </Skeleton>
        {isEditable && (
          <Center marginTop={4}>
            <Button size="sm" leftIcon={<AddIcon />} onClick={onOpen}>
              Add New
            </Button>
          </Center>
        )}
      </Flex>
    </>
  );
};
