import { CheckCircleIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ConfirmationModal,
  EditableText,
  Header,
  RichTextEditor,
  SplitPage,
} from "@sciencecorp/helix-components";
import React, { useCallback, useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useParams } from "react-router";
import { useDeleteFile } from "../../api/blob_files";
import { useCreateServiceRequestEvent } from "../../api/service_request_events";
import {
  invalidateServiceRequest,
  useGetServiceRequestEventsQuery,
  useGetServiceRequestPurchases,
  useGetServiceRequestQuery,
  usePostComment,
  useUpdateServiceRequest,
} from "../../api/service_requests";
import { useGetServiceQuery } from "../../api/services";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { LinkifyText } from "../Purchasing/util";
import { BlobUploadButton } from "../shared/BlobUploadButton";
import { DeleteableFileDownload } from "../shared/DeleteableFileDownload";
import { SpendingAuthorityInfo } from "../shared/SpendingAuthorityInfo";
import { ServiceRequestDetailsSidebar } from "./components/ServiceRequestDetailsSidebar";
import { ServiceRequestPurchases } from "./components/ServiceRequestPurchases";
import { ServiceRequestStatus } from "./components/ServiceRequestStatus";
import { LinkToPurchase } from "./LinkToPurchase";
import { serviceStatusMap } from "./utils";
import { TimelineTable } from "../shared/TimelineTable";

const closeablePurchaseStatuses = ["new", "needs_approval", "declined", "awaiting_purchase"];

export const ServiceRequestDetails = () => {
  const currency = useCurrency();
  const { id } = useParams();
  if (!id) return null;
  const {
    data: serviceRequest,
    isLoading: isLoadingServiceRequest,
    isRefetching: isRefetchingServiceRequest,
    isError,
  } = useGetServiceRequestQuery(Number(id));
  const { data: service, isLoading: isLoadingService } = useGetServiceQuery(
    serviceRequest?.service_id!
  );
  const currentUserQuery = useCurrentUserQuery();
  const isServiceAdmin = userHasRole(currentUserQuery, "services_admin") || false;
  const currentUser = currentUserQuery?.data;
  const { data: purchases } = useGetServiceRequestPurchases(serviceRequest?.id!);
  const { mutate: createServiceEvent } = useCreateServiceRequestEvent(+id);
  const eventsQuery = useGetServiceRequestEventsQuery(+id);
  const { mutate: updateServiceRequest, isLoading: isLoadingUpdate } = useUpdateServiceRequest(+id);
  const { mutate: deleteFile } = useDeleteFile(() => invalidateServiceRequest(+id));
  const [content, setContent] = useState("");
  const [plainText, setPlainText] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: postComment } = usePostComment(+id);

  const createComment = useCallback(() => {
    postComment({ richText: content, plainText: plainText }).then(() =>
      setSession((prev) => prev + 1)
    );
  }, [content, plainText, postComment]);

  const purchasesInCloseableState =
    purchases?.every((purchase) => closeablePurchaseStatuses.includes(purchase.status)) || false;

  const canClose =
    serviceRequest?.user_in_fulfilling_team ||
    currentUser?.id === serviceRequest?.requesting_user.id ||
    isServiceAdmin;
  const [session, setSession] = useState(0);

  if (isLoadingServiceRequest || isLoadingService) {
    return <Spinner />;
  }

  if (isError) {
    return <Text>Failed to load service request</Text>;
  }
  return (
    <Box pb={40}>
      <Header
        title={`${service?.name} #${serviceRequest.id}`}
        badge={{
          label: serviceRequest.status || "",
          colorScheme:
            serviceStatusMap.find((ele) => ele.value === serviceRequest.status)?.color || "gray",
        }}
        crumbs={[
          { label: "Teams", url: "/teams" },
          {
            label: `${service?.team.name}`,
            url: `/teams/${service?.team.id}/services`,
          },
        ]}
        crumbsColor="teal.500"
      />
      <Box overflow="auto">
        <SplitPage
          sidebarWidth="350px"
          sidebarWidthXL="450px"
          breakpoint="lg"
          sidebar={
            <>
              {serviceRequest.purchase_id && (
                <LinkToPurchase purchaseId={serviceRequest.purchase_id} />
              )}
              {serviceRequest && currentUser && service && (
                <ServiceRequestDetailsSidebar
                  serviceRequest={serviceRequest}
                  isServiceAdmin={isServiceAdmin}
                  currentUser={currentUser}
                  service={service}
                />
              )}
            </>
          }
          main={
            <Stack>
              <Flex
                gap={4}
                direction={{ base: "column", lg: "row" }}
                width="100%"
                marginBottom={"5"}>
                <VStack
                  flex="1"
                  align="flex-start"
                  border={"1px"}
                  borderRadius={"md"}
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  padding={"4"}>
                  <VStack align="flex-start" w="100%">
                    <Heading size="md">Description</Heading>
                    <Box w="100%">
                      <EditableText
                        preview={
                          <LinkifyText
                            text={serviceRequest.request_description || ""}
                            maxLinkChars={50}
                          />
                        }
                        multiline
                        defaultValue={serviceRequest.request_description || ""}
                        onSubmit={(value) => {
                          updateServiceRequest({
                            id: serviceRequest.id,
                            request_description: value || "",
                          });
                        }}
                        disabled={currentUser?.id !== serviceRequest.requesting_user.id}
                      />
                    </Box>
                  </VStack>
                  {currentUser?.id === serviceRequest.requesting_user.id ? (
                    <VStack align="start">
                      <Heading size="md">Attachments</Heading>
                      <HStack maxW="100%" flexWrap="wrap">
                        {serviceRequest.uploaded_files.map((file) => (
                          <DeleteableFileDownload
                            key={file.filename}
                            file={file}
                            deleteFile={deleteFile}
                          />
                        ))}
                        <BlobUploadButton
                          fileableColumn="uploaded_files"
                          recordId={serviceRequest.id}
                          recordType={"ServiceRequest"}
                          onSuccessCallback={() => invalidateServiceRequest(+id)}
                        />
                      </HStack>
                    </VStack>
                  ) : serviceRequest.uploaded_files?.length ? (
                    <VStack align="start">
                      <Heading size="md">Attachments</Heading>
                      <HStack maxW="100%" flexWrap="wrap">
                        {serviceRequest.uploaded_files.map((file) => (
                          <DeleteableFileDownload
                            key={file.filename}
                            file={file}
                            deleteFile={deleteFile}
                          />
                        ))}
                      </HStack>
                    </VStack>
                  ) : null}
                </VStack>

                <SpendingAuthorityInfo
                  spendingAuthority={serviceRequest.spending_authority}
                  pendingAmount={serviceRequest.total_cost}
                  session={session}
                  rootSpendingAuthority={serviceRequest.root_spending_authority}
                />
              </Flex>
              {serviceRequest && (
                <>
                  {eventsQuery.isSuccess ? (
                    <TimelineTable
                      timelineable_id={serviceRequest.id}
                      timelineable_type={"ServiceRequest"}
                      events={eventsQuery.data || []}
                      onComment={invalidateServiceRequest(serviceRequest.id, "events")}
                      disableCommentBox={true}
                    />
                  ) : (
                    <Spinner />
                  )}
                  {currentUser && (
                    <>
                      <ServiceRequestPurchases
                        serviceRequest={serviceRequest}
                        currentUser={currentUser}
                        purchases={purchases || []}
                        isServicesAdmin={isServiceAdmin}
                      />
                      <ServiceRequestStatus
                        serviceRequest={serviceRequest}
                        isServicesAdmin={isServiceAdmin}
                        currentUser={currentUser}
                        purchaseCost={Money.sum(
                          Money.zero(currency),
                          ...(purchases || []).map((p) => p.line_items_sum)
                        )}
                      />
                    </>
                  )}
                </>
              )}
              <Stack direction="column" width="100%">
                <HStack align="baseline" width="100%">
                  <Avatar size="sm" src={currentUser?.picture_uri} />
                  <Box flex="1" key={`comment-${session}`}>
                    <RichTextEditor
                      onChange={(raw, rich) => {
                        setContent(raw);
                        setPlainText(rich ?? "");
                      }}
                      placeholder="Leave a comment..."
                    />
                  </Box>
                </HStack>
                <HStack justify="flex-end">
                  {serviceRequest.status !== "complete" && serviceRequest.status !== "abandoned" ? (
                    <Menu placement="bottom-end">
                      <MenuButton
                        isLoading={isLoadingUpdate || isRefetchingServiceRequest}
                        as={Button}
                        isDisabled={!canClose}
                        leftIcon={<FiCheckCircle />}
                        rightIcon={<ChevronDownIcon />}>
                        Close Request
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          isDisabled={isLoadingUpdate}
                          icon={<CheckCircleIcon color="green.600" boxSize={4} />}
                          onClick={() => {
                            updateServiceRequest({
                              id: serviceRequest.id,
                              status: "complete",
                            });
                          }}>
                          Close request as complete
                        </MenuItem>
                        <MenuItem
                          isDisabled={isLoadingUpdate}
                          icon={<CheckCircleIcon color="orange.600" boxSize={4} />}
                          onClick={() => onOpen()}>
                          Close request as abandoned
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    <Button
                      leftIcon={<FiCheckCircle />}
                      isLoading={isLoadingUpdate || isRefetchingServiceRequest}
                      onClick={() => {
                        updateServiceRequest({
                          id: serviceRequest.id,
                          status: "review",
                        });
                      }}>
                      Reopen Request
                    </Button>
                  )}
                  <Button
                    onClick={createComment}
                    isDisabled={
                      serviceRequest.status === "completed" || serviceRequest.status === "abandoned"
                    }>
                    Comment
                  </Button>
                </HStack>
              </Stack>
              <ConfirmationModal
                colorScheme="red"
                isOpen={isOpen}
                onClose={onClose}
                header="Close Request"
                confirmText={purchasesInCloseableState ? "Abandon Request" : "Review Purchases"}
                onClick={() => {
                  if (purchasesInCloseableState) {
                    updateServiceRequest(
                      {
                        id: serviceRequest.id,
                        status: "abandoned",
                      },
                      { onSuccess: onClose }
                    );
                  } else {
                    onClose();
                  }
                }}>
                {purchasesInCloseableState ? (
                  <Text>
                    Are you sure you want to abandon this request? Abandoning the request will also
                    cancel any associated purchase requests.
                  </Text>
                ) : (
                  <Text>
                    To abandon this request, please review all associated purchases and reassign
                    them to a different spending authority.
                  </Text>
                )}
              </ConfirmationModal>
            </Stack>
          }
        />
      </Box>
    </Box>
  );
};
