import { Pagination } from "@sciencecorp/helix-components";
import { useState } from "react";
import { Filters } from "../api/collection_types";
import { AggregationResultData } from "../api/shared";

export type TableFilterState = {
  aggregations: AggregationResultData;
  pagination: Pagination;
  filters: Filters;
};

export const useTableState = (
  initialState: TableFilterState
): [TableFilterState, (state: TableFilterState) => void] => {
  return useState(initialState);
};
