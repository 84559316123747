import _ from "lodash";
import { z } from "zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from ".";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";

const BASE_URL = "background_reports";

const backgroundReportSchema = z.object({
  id: z.number(),
  job_name: z.string(),
  args: z.array(z.any()),
  status: z.enum(["pending", "running", "completed", "failed"]),
  error: z.string().nullable(),
  created_at: dateTimeSchema,
  started_at: dateTimeSchema.nullable(),
  completed_at: dateTimeSchema.nullable(),
  failed_at: dateTimeSchema.nullable(),
});

export type BackgroundReport = z.infer<typeof backgroundReportSchema>;
export type BackgroundReportScheduleParams = Pick<BackgroundReport, "job_name" | "args">;

export const useScheduleReport = () => {
  return useMutation({
    mutationFn: async (data: BackgroundReportScheduleParams): Promise<BackgroundReport> => {
      const result = await api.post(BASE_URL + "/schedule", data);
      return zodParse(backgroundReportSchema, result.data);
    },
  });
};

const getBackgroundReport = async (id: number): Promise<BackgroundReport> => {
  const result = await api.get(`${BASE_URL}/${id}`);
  return zodParse(backgroundReportSchema, result.data);
};

export const useReport = (id: number | null) => {
  return useQuery({
    queryKey: ["background_report", id],
    queryFn: () => getBackgroundReport(id!),
    enabled: !!id,
  });
};

export const downloadBackgroundReport = async (id: number, args: any[]) => {
  const result = await api.get(`${BASE_URL}/${id}/download`, {
    responseType: "blob",
  });
  return result.data;
};
