import React, { useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Heading,
  Text,
  VStack,
  Icon,
  Stack,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { BarChartContainer } from "./BarChart";
import { CandidateFeedback, EmptyFeedback } from "./CandidateFeedback";
import { EditableText, ScienceIcon } from "@sciencecorp/helix-components";
import { useSearchParams } from "react-router-dom";

const InfoItem = ({ label, value }) => (
  <Flex gap={2}>
    <Text fontWeight="bold" fontSize="2xl">
      {label}:
    </Text>
    <Text fontSize="2xl">{value}</Text>
  </Flex>
);

export const PresentationMode = ({
  onClose,
  candidateOverallData,
  candidate,
  candidateDataRoundUpData,
  finishedFeedback,
  updateCandidateData,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [searchParams] = useSearchParams();
  const isPresentationMode = searchParams.get("presentation") === "true";

  if (!candidate) {
    return <Text>No candidate data available</Text>;
  }

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

  const slides = [
    {
      title: `${candidate.name}'s Interview Debrief`,
      content: (
        <VStack w="100%" align="center" gap={6}>
          <Stack
            direction={{ base: "column", md: "row" }}
            mt={3}
            spacing={{ base: 3, md: 6 }}
            justify="center"
            align="center">
            <InfoItem
              label="Role"
              value={candidate.budget_headcount?.archetype.name || candidate.role}
            />
            <InfoItem label="Hiring Manager" value={candidate.hiring_manager_name} />
            <InfoItem
              label="Test Score"
              value={candidate.test_score ? `${candidate.test_score}%` : "N/A"}
            />
          </Stack>

          <Text fontSize="2xl" fontWeight="light">
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Text>
          <Icon boxSize={10} as={ScienceIcon} aria-label="Science Logo" />
        </VStack>
      ),
    },
    {
      title: "Evidence of Exceptional Ability",
      content: (
        <Text fontSize="2xl" textAlign="center" maxW="1200px">
          {candidate.exceptional_ability}
        </Text>
      ),
    },
    {
      title: "Summary of Feedback",
      content: (
        <Text fontSize="2xl" textAlign="center" maxW="1200px">
          {candidate.summary}
        </Text>
      ),
    },
    {
      title: "Propensity to Extend Job Offer",
      content: (
        <BarChartContainer
          title="Propensity to Extend Job Offer"
          question="Based on your individual experience with the candidate, how interested are you in extending an offer?"
          data={candidateOverallData}
          barLabels={[1, 2, 3, 4]}
          axisYLabel="No. of Interviewers"
          tickFormat={[0, 1, 2, 3, 4, 5, 6]}
          isPresentationMode={true}
        />
      ),
    },
    {
      title: "Explanations",
      content: (
        <CandidateFeedback
          candidate={candidate}
          section={"Explanations"}
          question={"What was your reason for the given score?"}
          finishedFeedback={finishedFeedback}
          updateCandidate={updateCandidateData}
          isPresentationMode={true}
        />
      ),
    },
    {
      title: "Data Roundup",
      content: (
        <BarChartContainer
          title="Data Roundup"
          question="How would you rate the candidate across these aspects?"
          data={candidateDataRoundUpData}
          barLabels={["technical", "culture", "mission", "communication"]}
          axisYLabel="Average Scores"
          tickFormat={[0, 1, 2, 3, 4]}
          isPresentationMode={true}
        />
      ),
    },
    {
      title: "Strengths & Opportunities for Growth",
      content: (
        <HStack w="100%" justifyContent={"space-between"} gap={8} mt={8}>
          <VStack align="stretch" flex={1}>
            <Text fontSize="2xl" fontWeight="bold">
              Strengths
            </Text>
            <CandidateFeedback
              candidate={candidate}
              section={"Strengths"}
              finishedFeedback={finishedFeedback}
              updateCandidate={updateCandidateData}
              isPresentationMode={true}
            />
          </VStack>
          <VStack align="stretch" flex={1}>
            <Text fontSize="2xl" fontWeight="bold">
              Opportunity for Growth/Areas of Concern
            </Text>
            <CandidateFeedback
              candidate={candidate}
              section={"Opportunity for Growth/Areas of Concern"}
              finishedFeedback={finishedFeedback}
              updateCandidate={updateCandidateData}
              isPresentationMode={true}
            />
          </VStack>
        </HStack>
      ),
    },
  ];
  return (
    <Modal isOpen={true} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" top={4} right={4} />
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={16}>
          <VStack spacing={8} width="100%" justifyContent="space-between" height="100%">
            <Heading size="2xl" fontWeight={["regular", "light"]}>
              {slides[currentSlide].title}
            </Heading>
            <Flex width="100%" justifyContent="center" alignItems="center" flex={1}>
              {slides[currentSlide].content}
            </Flex>
          </VStack>

          <VStack position="fixed" bottom={8} alignItems="center" gap={4}>
            <HStack>
              <IconButton
                icon={<ArrowBackIcon />}
                onClick={prevSlide}
                aria-label="Previous slide"
              />
              <Text>
                {currentSlide + 1} of {slides.length}
              </Text>
              <IconButton icon={<ArrowForwardIcon />} onClick={nextSlide} aria-label="Next slide" />
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
