import { Text, Flex, Box, Stack, HStack, Divider, useToast } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import {
  CapitalEquipmentData,
  CapitalEquipmentShowData,
  useBulkArchiveCapitalEquipment,
} from "../../../../api/capital_equipment";
import { useForm } from "react-hook-form";
import { findPathByLocationId } from "../../../shared/TreeSelect";
import { useTreeSelectInventoryLocations } from "../../../../api/inventory_location";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export type ArchiveEquipmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedEquipment: CapitalEquipmentShowData[];
  setSelectedEquipment: (equipment: CapitalEquipmentShowData[]) => void;
};

export const ArchiveEquipmentModal = ({
  isOpen,
  onClose,
  selectedEquipment,
  setSelectedEquipment,
}: ArchiveEquipmentModalProps) => {
  const { reset, handleSubmit } = useForm();
  const handleClose = () => {
    onClose();
  };
  const toast = useToast();
  const { data: locations } = useTreeSelectInventoryLocations();
  const { mutateAsync: bulkArchiveCapitalEquipment, isLoading } = useBulkArchiveCapitalEquipment();
  const ids = selectedEquipment?.map((equipment) => equipment.id);
  const onSubmit = () => {
    bulkArchiveCapitalEquipment(ids).then(() => {
      toast({
        title: "Equipment archived successfully",
        status: "success",
      });
      setSelectedEquipment([]);
      reset();
      handleClose();
    });
  };
  return (
    <FormModal
      size={"md"}
      title="Archive Equipment"
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      submitButtonDisabled={isLoading}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Text maxH="56h">Are you sure you want to archive these equipment?</Text>
        <Box
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          p={4}
          overflow="auto"
          maxH="50vh">
          {selectedEquipment?.map((equipment) => {
            const path = findPathByLocationId(
              locations || [],
              equipment.inventory_location_id || 0
            ).slice(1);
            return (
              <>
                <Flex direction="column" gap={1} p={2}>
                  <Text fontWeight="semibold">{equipment.name}</Text>
                  <Stack direction="row">
                    {path?.map((location, idx) => {
                      const isLast = idx === path.length - 1;
                      return (
                        <>
                          <Box key={`path-${location.label}`}>
                            <HStack fontSize="sm">
                              <Text
                                fontWeight={isLast ? "medium" : "normal"}
                                color={isLast ? "teal.500" : "auto"}>
                                {location.label}
                              </Text>
                              {!isLast && <ArrowForwardIcon />}
                            </HStack>
                          </Box>
                        </>
                      );
                    })}
                  </Stack>
                </Flex>
                <Divider />
              </>
            );
          })}
        </Box>
      </Flex>
    </FormModal>
  );
};
