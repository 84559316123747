import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  HStack,
  IconButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  AttributesTable,
  CollectionTable,
  ConfirmationModal,
  EditableText,
  EmptyState,
  Searchbar,
} from "@sciencecorp/helix-components";
import React from "react";
import {
  CapitalEquipmentShowData,
  useUpdateCapitalEquipment,
} from "../../../../api/capital_equipment";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { NewCapitalEquipmentPartModal } from "./NewCapitalEquipmentPartModal";
import {
  CapitalEquipmenPartData,
  useDeleteCapitalEquipmentPart,
  useSearchCapitalEquipmentParts,
} from "../../../../api/capital_equipment_part";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export type ManufacturerInfoProps = {
  capitalEquipment: CapitalEquipmentShowData;
  isDisabled?: boolean;
};
export const ManufacturerInfo = ({ capitalEquipment, isDisabled }: ManufacturerInfoProps) => {
  const { mutate: updateTool, isLoading: isLoadingUpdateTool } = useUpdateCapitalEquipment(
    Number(capitalEquipment.id)
  );
  const {
    isOpen: isOpenNewCapitalEquipmentPartModal,
    onOpen: onOpenNewCapitalEquipmentPartModal,
    onClose: onCloseNewCapitalEquipmentPartModal,
  } = useDisclosure();
  const { search, debouncedSearch } = useDebouncedSearch();
  const { data, isLoading: isLoadingParts } = useSearchCapitalEquipmentParts({
    filters: {
      capital_equipment_id: Number(capitalEquipment.id),
    },
    term: search || "*",
  });

  const { mutate: deletePart, isLoading: isLoadingDeletePart } = useDeleteCapitalEquipmentPart();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [partId, setPartId] = React.useState<number | null>(null);
  const columns = [
    {
      label: "Part",
      render: (part: CapitalEquipmenPartData) => part.part_name || "",
    },
    {
      label: "MPN",
      render: (part: CapitalEquipmenPartData) => part.manufacturer_part_number || "",
    },
    {
      label: "Serial Number",
      render: (part: CapitalEquipmenPartData) => part.serial_number || "",
    },
    {
      label: "",
      render: (part: CapitalEquipmenPartData) => (
        <>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={IconButton}
                  variant={"ghost"}
                  icon={<BsThreeDotsVertical />}
                  size="xs"
                  aria-label="More actions"
                  isActive={isOpen}
                  isDisabled={isDisabled}
                />
                <MenuList width={"max-content"}>
                  <MenuItem
                    color="red.600"
                    onClick={() => {
                      onDeleteOpen();
                      setPartId(part.id);
                    }}>
                    <DeleteIcon marginRight="4" />
                    Delete Part
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </>
      ),
    },
  ];

  return (
    <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6} height={"100%"}>
      <NewCapitalEquipmentPartModal
        capitalEquipmentId={capitalEquipment.id}
        isOpen={isOpenNewCapitalEquipmentPartModal}
        onClose={onCloseNewCapitalEquipmentPartModal}
      />
      <ConfirmationModal
        colorScheme={"red"}
        confirmText={"Yes"}
        header={"Delete Part"}
        isOpen={isDeleteOpen}
        onClick={() => {
          if (partId) {
            deletePart(partId);
          }
        }}
        onClose={onDeleteClose}>
        {"Are you sure you want to delete this Part? This action cannot be undone."}
      </ConfirmationModal>
      <Heading size="md" mb={4} fontWeight="medium">
        Manufacturer Information
      </Heading>
      <Tabs colorScheme="teal" width={"100%"}>
        <TabList>
          <Tab>Equipment</Tab>
          <Tab>Parts</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AttributesTable
              attributes={[
                {
                  label: "original equipment manufacturer",
                  value: (
                    <EditableText
                      preview={capitalEquipment.manufacturer_name}
                      onSubmit={(value) => {
                        updateTool({
                          id: capitalEquipment.id,
                          manufacturer_name: String(value),
                        });
                      }}
                      isLoading={isLoadingUpdateTool}
                      disabled={isDisabled}
                    />
                  ),
                },
                {
                  label: "manufacturer part number",
                  value: (
                    <EditableText
                      preview={capitalEquipment.manufacturer_part_number}
                      onSubmit={(value) => {
                        updateTool({
                          id: capitalEquipment.id,
                          manufacturer_part_number: String(value),
                        });
                      }}
                      isLoading={isLoadingUpdateTool}
                      disabled={isDisabled}
                    />
                  ),
                },
                {
                  label: "model",
                  value: (
                    <EditableText
                      preview={capitalEquipment.manufacturer_model}
                      onSubmit={(value) => {
                        updateTool({
                          id: capitalEquipment.id,
                          manufacturer_model: String(value),
                        });
                      }}
                      isLoading={isLoadingUpdateTool}
                      disabled={isDisabled}
                    />
                  ),
                },
                {
                  label: "serial number",
                  value: (
                    <EditableText
                      preview={capitalEquipment.serial_number}
                      onSubmit={(value) => {
                        updateTool({
                          id: capitalEquipment.id,
                          serial_number: String(value),
                        });
                      }}
                      isLoading={isLoadingUpdateTool}
                      disabled={isDisabled}
                    />
                  ),
                },
              ]}
            />
          </TabPanel>
          <TabPanel>
            <HStack marginBottom={"4"} width={"100%"} justifyContent={"space-between"}>
              <Searchbar
                onChange={(value) => {
                  debouncedSearch(value);
                }}
                onSelect={() => {}}
                width={"90%"}
              />
              <IconButton
                size={"xs"}
                aria-label="add part"
                icon={<AddIcon />}
                onClick={onOpenNewCapitalEquipmentPartModal}
                isDisabled={isDisabled}
              />
            </HStack>
            {data?.results && data.results.length > 0 ? (
              <CollectionTable items={data.results} columns={columns} />
            ) : (
              <EmptyState size="3xs" title="Add a part to show." />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
