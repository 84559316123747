import React, { useState, useMemo } from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  Icon,
  IconButton,
  useDisclosure,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  CollectionPage,
  RecordLink,
  buildFacets,
  useCollection,
  ConfirmationModal,
  Column,
} from "@sciencecorp/helix-components";
import { BiStore } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiUndo } from "react-icons/ci";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";
import {
  ContractIndexData,
  useSearchContracts,
  useUpdateContract,
} from "../../../../api/contracts";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export const ContractArchive = () => {
  const { facets, onFacets, filters, pagination, onPagination, order, onOrder } = useCollection();
  const { search, debouncedSearch } = useDebouncedSearch();
  const { mutate: updateContract } = useUpdateContract();
  const [activeContract, setActiveContract] = useState<ContractIndexData | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading } = useSearchContracts({
    term: search || "*",
    pagination,
    order,
    aggs: [],
    filters: { ...filters, archived_at: { not: [null] } },
  });

  const columns: Column<ContractIndexData>[] = useMemo(
    () => [
      {
        label: "Contract",
        orderOptions: { orderKey: "name" },
        render: (contract) => (
          <RecordLink
            link={`/services/contracts/${contract.id}`}
            identifier={`${contract.name}`}
            size="small"
            icon={
              <Box ml={3}>
                <BiStore />
              </Box>
            }
          />
        ),
      },
      {
        label: "Description",
        render: (contract) => contract.description || "-",
      },
      {
        label: "Archived At",
        render: (contract) =>
          contract.archived_at && DateTime.fromISO(contract.archived_at).toFormat("LLL dd yyyy"),
      },
      {
        label: "",
        render: (contract) => (
          <ActionsMenu contract={contract} onOpen={onOpen} setActiveContract={setActiveContract} />
        ),
      },
    ],
    [data]
  );

  const populatedFacets = data?.aggregations ? buildFacets(data.aggregations, facets) : {};

  return (
    <VStack width="100%" align="start">
      <Breadcrumb>
        <BreadcrumbItem color="teal">
          <BreadcrumbLink as={RouterLink} to="/services/contracts">
            Contracts
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Archive</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="lg" mb={4}>
        Archive
      </Heading>
      <Box width="100%">
        <CollectionPage
          columns={columns}
          items={data?.results || []}
          isLoading={isLoading}
          pagination={pagination}
          facetsProps={{
            debouncedSearch: debouncedSearch,
            search: true,
            background: true,
          }}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
          facets={populatedFacets}
          onFacets={onFacets}
        />
      </Box>
      {activeContract && (
        <ConfirmationModal
          colorScheme="teal"
          isOpen={isOpen}
          onClose={onClose}
          header="Restore Contract"
          children="Restoring this contract will place it back on the contracts page."
          confirmText="Continue"
          onClick={() => updateContract({ id: activeContract.id, archived_at: null })}
        />
      )}
    </VStack>
  );
};

export const ActionsMenu = ({ contract, onOpen, setActiveContract }) => (
  <Menu>
    <MenuButton
      as={IconButton}
      icon={<Icon as={BsThreeDotsVertical} />}
      variant="ghost"
      size="sm"
    />
    <MenuList fontSize="md">
      <MenuItem
        gap={3}
        onClick={() => {
          setActiveContract(contract);
          onOpen();
        }}>
        <Icon as={CiUndo} />
        Restore
      </MenuItem>
    </MenuList>
  </Menu>
);
