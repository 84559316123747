import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useColorModeValue,
  Tag,
} from "@chakra-ui/react";
import { humanize } from "inflection";
import React from "react";
import { CapitalEquipmentShowData } from "../../../../api/capital_equipment";

export const updateStatusColor = (status: string) => {
  switch (status) {
    case "active":
      return "green";
    case "sold":
      return "orange";
    case "decomissioned":
      return "red";
    default:
      return "gray";
  }
};

export type EquipmentStatusProps = {
  capitalEquipment: CapitalEquipmentShowData;
  isLoadingUpdateTool: boolean;
  updateTool: (data: { status: string; id: number }) => void;
};

export const EquipmentStatus = ({ capitalEquipment, isLoadingUpdateTool, updateTool }) => {
  const bgColor = useColorModeValue(
    updateStatusColor(capitalEquipment.status) + ".100",
    updateStatusColor(capitalEquipment.status) + ".300"
  );
  return (
    <Popover placement="bottom-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              size={"sm"}
              isLoading={isLoadingUpdateTool}
              rightIcon={<ChevronDownIcon />}
              bgColor={bgColor}
              color={updateStatusColor(capitalEquipment.status) + ".800"}>
              {humanize(capitalEquipment.status)}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverCloseButton />
            <PopoverHeader>Change Status To</PopoverHeader>
            <PopoverBody>
              {["inactive", "active", "sold", "decomissioned"].map((status) => (
                <PopoverBody
                  cursor={"pointer"}
                  key={status}
                  onClick={() => {
                    updateTool({ status, id: capitalEquipment.id });
                    onClose();
                  }}
                  width={"100%"}
                  _hover={{ bgColor: useColorModeValue("gray.50", "gray.800") }}>
                  <Tag
                    marginLeft={"2"}
                    padding={["1", "2", "1", "2"]}
                    colorScheme={updateStatusColor(status)}
                    borderRadius={"md"}
                    width={"max-content"}
                    fontSize={"sm"}>
                    {humanize(status)}
                  </Tag>
                </PopoverBody>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
