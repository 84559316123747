import React, { useState, useEffect } from "react";
import {
  Heading,
  Badge,
  Tag,
  Flex,
  Box,
  Skeleton,
  Stack,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  InventoryShowData,
  InventoryLocationsWithInventoryData,
  useGetLocationsWithInventory,
} from "../../../../api/inventory";
import { InventoryItemsTable } from "./InventoryItemsTable";
import { EmptyState, Searchbar } from "@sciencecorp/helix-components";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { MoveStockModal } from "./MoveStockModal";
import { LocationTreePreview } from "./RestockModalComponents/AddStockAndLocation";
import { ArrowForwardIcon, ArrowRightIcon, ChevronRightIcon } from "@chakra-ui/icons";

export const StockLocations = ({ inventory }: { inventory: InventoryShowData }) => {
  const { search, debouncedSearch } = useDebouncedSearch();
  const { data, isLoading } = useGetLocationsWithInventory(inventory.id, search);
  const [selectedLocation, setSelectedLocation] =
    useState<InventoryLocationsWithInventoryData | null>(null);

  return (
    <Flex
      minH="xs"
      direction="column"
      p={4}
      gap={4}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      w="100%">
      {!selectedLocation ? (
        <>
          <Flex
            direction={["column", "row"]}
            gap={2}
            align="center"
            w="100%"
            justify="space-between">
            <Heading size="md" fontWeight="medium">
              Stock Locations
            </Heading>
            <MoveStockModal inventory={inventory} />
          </Flex>
          <Box>
            <Searchbar
              onSelect={() => {}}
              size="sm"
              placeholder="Search for location"
              onChange={(value) => debouncedSearch(value)}
            />
          </Box>
          <Box maxH="xs" overflowY="auto">
            <Skeleton isLoaded={!isLoading}>
              {data?.length ? (
                data.map((location) => {
                  const childrenWithStock = findAllWithStock(location);
                  return childrenWithStock.map((child) => (
                    <StockLocationRow
                      key={child.id}
                      location={child}
                      data={data}
                      setSelectedLocation={setSelectedLocation}
                    />
                  ));
                })
              ) : (
                <EmptyState title="No inventory yet." />
              )}
            </Skeleton>
          </Box>
        </>
      ) : (
        <InventoryItemsTable
          key={`location${selectedLocation.id}-table`}
          inventory={inventory}
          location={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      )}
    </Flex>
  );
};

export const StockLocationRow = ({ location, data, setSelectedLocation }) => {
  const pathToStock = findSelectedOption(data, location.id);

  return (
    <HStack
      w="100%"
      justify="space-between"
      borderBottom="1px"
      borderColor="chakra-border-color"
      _hover={{ bg: useColorModeValue("gray.50", "gray.700"), cursor: "pointer" }}
      onClick={() => setSelectedLocation(location)}
      px={2}
      py={3}>
      <Flex direction="column" gap={1}>
        <Text color={useColorModeValue("teal.600", "teal.400")} fontWeight="semibold">
          {location.name}
        </Text>
        <Stack direction="row">
          {pathToStock?.map((location, idx) => {
            const isLast = idx === pathToStock?.length - 1;
            return (
              <Box key={`destination-${location.name}-${location.id}`}>
                <HStack fontSize="sm">
                  <Text fontWeight={isLast ? "medium" : "normal"}>{location.name}</Text>
                  {idx < (pathToStock?.length || 1) - 1 && <ArrowForwardIcon />}
                </HStack>
              </Box>
            );
          })}
        </Stack>
      </Flex>
      <ChevronRightIcon />
    </HStack>
  );
};

export const findSelectedOption = (
  options: InventoryLocationsWithInventoryData[],
  value: number
): InventoryLocationsWithInventoryData[] => {
  return options.map((option) => _findSelectedOption(option, value)).filter(Boolean)[0];
};

export const _findSelectedOption = (
  option: InventoryLocationsWithInventoryData,
  value: number,
  path: InventoryLocationsWithInventoryData[] = []
) => {
  if (option.id === value) {
    return [...path, option];
  } else if (option.children.length) {
    return option.children
      .map((child) => _findSelectedOption(child, value, [...path, option]))
      .filter(Boolean)[0];
  } else {
    return undefined;
  }
};

export const findAllWithStock = (data: InventoryLocationsWithInventoryData) => {
  const result: InventoryLocationsWithInventoryData[] = [];

  // If the current location has stock, add it to the result
  if (data.has_stock) {
    result.push(data);
  }

  // If there are children, recursively search through them
  if (data.children && data.children.length > 0) {
    for (let child of data.children) {
      result.push(...findAllWithStock(child));
    }
  }

  return result;
};
