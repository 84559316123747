import { DeleteIcon } from "@chakra-ui/icons";
import { VStack, Box, Divider, Flex, HStack, Heading } from "@chakra-ui/react";
import { ConfirmationButton, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import {
  CapitalEquipmentShowData,
  invalidateCapitalEquipments,
} from "../../../../api/capital_equipment";
import { InventoryShowData, invalidateInventories } from "../../../../api/inventory";
import {
  useDeleteInventoryVendor,
  useUpdateInventoryVendor,
} from "../../../../api/inventory_vendor";
import { InventoryVendorModal } from "../../../Inventory/Inventories/components/InventoryVendorModal";
import { EditableLink } from "../../../Purchasing/Purchase/components/EditableLink";

export type CapitalEquipmentVendorProps = {
  data: CapitalEquipmentShowData | InventoryShowData;
  type: "CapitalEquipment" | "inventory";
  isManager?: boolean;
  isDisabled?: boolean;
};

export const CapitalEquipmentVendorInformation = ({ data, type, isManager, isDisabled }) => {
  const vendors = data.vendors;
  const { mutateAsync: deleteInventoryVendor } = useDeleteInventoryVendor(() => {
    return type === "CapitalEquipment"
      ? invalidateCapitalEquipments(data.id)
      : invalidateInventories(data.id);
  });
  const { mutateAsync: updateInventoryVendor } = useUpdateInventoryVendor(() => {
    return type === "CapitalEquipment"
      ? invalidateCapitalEquipments(data.id)
      : invalidateInventories(data.id);
  });

  return (
    <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6} height={"100%"}>
      <HStack justify="space-between" mb={4}>
        <Heading size="md" fontWeight="medium">
          Supplier Information
        </Heading>
        {isManager && !isDisabled && <InventoryVendorModal data={data} type={type} />}
      </HStack>
      <Flex direction="column" gap={3}>
        {vendors && vendors.length > 0 ? (
          vendors.map((vendor) => (
            <VStack key={`inventory-vendor-${vendor.vendor.id}`} align="start" spacing={3} w="100%">
              <HStack w="100%" justify="space-between">
                <RecordLink
                  identifier={vendor.vendor.name}
                  link={`/services/vendors/${vendor.vendor.id}`}
                />
                <HStack>
                  <EditableLink
                    url={vendor.link || ""}
                    onSubmit={(value) => {
                      if (!value) return;
                      updateInventoryVendor({ id: vendor.id, link: String(value) }).then(() => {
                        type === "CapitalEquipment"
                          ? invalidateCapitalEquipments(data.id)
                          : invalidateInventories(data.id);
                      });
                    }}
                    disabled={isDisabled}
                  />
                  <ConfirmationButton
                    size="xs"
                    aria-label="delete supplier"
                    icon={<DeleteIcon />}
                    variant="IconButton"
                    label="Delete Supplier"
                    children="Are you sure you want to delete this supplier?"
                    confirmationButtonLabel="Delete"
                    isDisabled={isDisabled}
                    onConfirm={() => {
                      deleteInventoryVendor(vendor.id)
                        .then(() => {
                          if (type === "CapitalEquipment") {
                            invalidateCapitalEquipments(data.id);
                          } else {
                            invalidateInventories(data.id);
                          }
                        })
                        .catch((error) => {
                          console.error("Deletion failed:", error);
                        });
                    }}
                  />
                </HStack>
              </HStack>
              <Divider />
            </VStack>
          ))
        ) : (
          <EmptyState title="Add a supplier to show here." />
        )}
      </Flex>
    </Box>
  );
};
