import React, { useState } from "react";
import { Text, Spinner, useToast } from "@chakra-ui/react";
import {
  AttributesTable,
  EditableText,
  EditableSelect,
  RecordLink,
} from "@sciencecorp/helix-components";

import { useGetArchetype, useUpdateArchetype } from "../../../api/archetype";
import { userHasRole, useCurrentUserQuery } from "../../../api/user";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { useNavigate } from "react-router";

const reviewedForIpIssuesOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const roleTypeOptions = [
  { label: "Exempt", value: "exempt" },
  { label: "Non-Exempt - Full Time", value: "non_exempt_full_time" },
  { label: "Non-Exempt - Part Time", value: "non_exempt_part_time" },
];

export const ArchetypeAttributes = ({ archetypeId, editable }) => {
  const { search, debouncedSearch } = useDebouncedSearch();

  const {
    data: archetype,
    isLoading: isArchetypeLoading,
    isError: isArchetypeError,
  } = useGetArchetype(archetypeId);
  const { mutate: updateArchetype, isLoading: isUpdateLoading } = useUpdateArchetype(archetypeId);
  const [loadingField, setLoadingField] = useState("");
  const currentUserQuery = useCurrentUserQuery();
  const isRecruitingAdmin = userHasRole(currentUserQuery, "recruiting_admin");
  const isCeo = userHasRole(currentUserQuery, "ceo");
  const toast = useToast();

  const navigate = useNavigate();
  if (currentUserQuery.isSuccess && !isRecruitingAdmin && !isCeo) {
    navigate("/hiring/candidates");
    return <Text>Unauthorized. Redirecting</Text>;
  }

  const isLoadingField = (field: string) => {
    return loadingField === field && isUpdateLoading;
  };

  if (isArchetypeLoading) {
    return <Spinner />;
  }

  if (isArchetypeError) {
    return <Text>Error loading archetype</Text>;
  }

  return (
    <>
      <AttributesTable
        title="Role Defaults"
        attributes={[
          {
            label: "Role Name",
            value: editable ? (
              <EditableText
                defaultValue={archetype.name}
                onSubmit={(value) => {
                  if (value) {
                    setLoadingField("name");
                    updateArchetype(
                      { id: archetype.id, name: value },
                      { onSuccess: () => setLoadingField("") }
                    );
                  }
                }}
                persistentEdit={isLoadingField("name")}
                isLoading={isLoadingField("name")}
                disabled={!editable}
              />
            ) : (
              <RecordLink identifier={archetype.name} link={`/hiring/roles/${archetype.id}`} />
            ),
          },
          {
            label: "Recruiting Source",
            value: (
              <EditableText
                defaultValue={archetype.source}
                onSubmit={(value) => {
                  if (value) {
                    setLoadingField("source");
                    updateArchetype(
                      { id: archetype.id, source: value },
                      { onSuccess: () => setLoadingField("") }
                    );
                  }
                }}
                persistentEdit={isLoadingField("source")}
                isLoading={isLoadingField("source")}
                disabled={!editable}
              />
            ),
          },
          {
            label: "Role Type",
            value: (
              <EditableSelect
                options={roleTypeOptions}
                selectedValue={archetype.role_type}
                onSubmit={(value) => {
                  if (value) {
                    setLoadingField("roleType");
                    updateArchetype(
                      { id: archetype.id, role_type: value.toString() },
                      { onSuccess: () => setLoadingField("") }
                    );
                  }
                }}
                persistentEdit={isLoadingField("roleType")}
                isLoading={isLoadingField("roleType")}
                disabled={!editable}
              />
            ),
          },
          {
            label: "Onsite Process",
            value: (
              <EditableText
                defaultValue={archetype.on_site_interview_process}
                multiline
                onSubmit={(value) => {
                  if (value) {
                    setLoadingField("interviewProcess");
                    updateArchetype(
                      { id: archetype.id, on_site_interview_process: value },
                      { onSuccess: () => setLoadingField("") }
                    );
                  }
                }}
                persistentEdit={isLoadingField("interviewProcess")}
                isLoading={isLoadingField("interviewProcess")}
                disabled={!editable}
              />
            ),
          },
        ]}
      />
    </>
  );
};
