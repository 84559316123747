import { Avatar, Box, HStack, Link, Tag, VStack } from "@chakra-ui/react";
import { Collection, EmptyState, ReactRouterLink, RecordLink } from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import React, { useMemo } from "react";
import { ReimbursementIndexItemData } from "../../api/reimbursement";
import { MoneyText } from "../MoneyText";
import { reimbursementStatusColor } from "./Reimbursements/components/utils";

export const MyReimbursementsTab = ({
  data,
  isLoading,
  pagination,
  onPagination,
  order,
  onOrder,
}) => {
  const columns = useMemo(
    () => [
      {
        label: "Requests",
        orderOptions: { orderKey: "id" },
        render: (reimbursement: ReimbursementIndexItemData) => (
          <VStack align="start" data-testid={`reimbursement-record-link-${reimbursement.id}`}>
            <RecordLink
              link={`/services/reimbursements/${reimbursement.id}`}
              identifier={`#${reimbursement.id}`}
              size="small"
            />
          </VStack>
        ),
      },
      {
        label: "Requested By",
        render: (reimbursement: ReimbursementIndexItemData) => (
          <HStack marginTop={["2", "0"]}>
            <Avatar src={reimbursement.user?.picture_uri} size="sm" />
            <Box>
              <Link
                as={ReactRouterLink}
                to={`/users/${reimbursement.user.id}`}
                fontSize={["xs", "sm"]}>
                {reimbursement.user?.name}
              </Link>
            </Box>
          </HStack>
        ),
      },
      {
        label: "Reimbursement Type",
        render: (reimbursement: ReimbursementIndexItemData) =>
          titleize(reimbursement.reimbursement_type),
      },
      {
        label: "Requested On",
        orderOptions: { orderKey: "submitted_at" },
        render: (reimbursement: ReimbursementIndexItemData) => {
          if (!reimbursement.submitted_at) {
            return "--";
          }
          return reimbursement.submitted_at.toFormat("MMM dd, yyyy");
        },
      },
      {
        label: "Paid At",
        orderOptions: { orderKey: "paid_at" },
        render: (reimbursement: ReimbursementIndexItemData) =>
          reimbursement.paid_at ? reimbursement.paid_at.toFormat("MMM dd, yyyy") : "--",
      },
      {
        label: "Amount",
        render: (reimbursement: ReimbursementIndexItemData) => (
          <MoneyText money={reimbursement.amount} formatOptions={{ compact: "never" }} />
        ),
      },
      {
        label: "Status",
        render: (reimbursement: ReimbursementIndexItemData) => (
          <VStack align="start" marginTop={["2", "0"]}>
            <Tag
              colorScheme={reimbursementStatusColor(reimbursement.approval_status)}
              flexShrink={0}
              whiteSpace="nowrap"
              size={["sm", "md"]}>
              {titleize(humanize(reimbursement.approval_status))}
            </Tag>
          </VStack>
        ),
      },
    ],
    [data?.results]
  );

  return (
    <Box width="100%">
      {data?.results.length || isLoading ? (
        <Collection
          columns={columns}
          tableProps={{
            layout: "fixed long",
          }}
          items={data?.results || []}
          isLoading={isLoading}
          onPagination={onPagination}
          pagination={data?.pagination || pagination}
          order={order}
          onOrder={onOrder}
        />
      ) : (
        <EmptyState title="No reimbursements, create a reimbursement to get started" size="md" />
      )}
    </Box>
  );
};
