import { Button, ButtonGroup, Link, useToast } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { getRecordPath } from "../util";
import { UserCredentialIssuedModal } from "./UserCredentialIssuedModal";
import { useGetBudgetItem } from "../../../api/budget_items";

export const getActionButtons = ({
  notification,
  currentUser,
  approvePurchase,
  declinePurchase,
  approveReimbursement,
  declineReimbursement,
  updateSubscriptionApproval,
  updateCandidate,
  updateNotification,
  approveSACR,
  declineSACR,
}) => {
  const toast = useToast();
  const reviewPromptTypes = [
    "peer_review_prompt_created",
    "lead_review_prompt_created",
    "self_review_prompt_created",
  ];

  if (reviewPromptTypes.includes(notification.notification_type)) {
    return (
      <Link href={getRecordPath(notification)} isExternal>
        <Button as="span" colorScheme="teal" size="sm" minW={20}>
          View
        </Button>
      </Link>
    );
  }

  // TODO (michelle): once we transfer everything to events, should switch based off of notifiable_type, then notification type
  switch (notification.notification_type) {
    case "candidate_feedback_created":
      return (
        <Link href={getRecordPath(notification)} isExternal>
          <Button as="span" colorScheme="teal" size="sm" minW={20}>
            Submit
          </Button>
        </Link>
      );
    case "candidate_feedback_all_completed":
      return (
        <Link href={getRecordPath(notification)} isExternal>
          <Button as="span" colorScheme="teal" size="sm" minW={20}>
            View
          </Button>
        </Link>
      );
    case "candidate_references_assigned":
      return (
        <Link href={getRecordPath(notification)} isExternal>
          <Button as="span" colorScheme="teal" size="sm" minW={20}>
            View
          </Button>
        </Link>
      );

    case "candidate_ceo_decision":
      return (
        <ButtonGroup size="sm">
          <Button
            isLoading={updateCandidate.isLoading}
            variant="outline"
            colorScheme="red"
            onClick={() =>
              updateCandidate.mutate(
                {
                  id: notification.notifiable_id,
                  decision: "ceo_rejected",
                },
                {
                  onSuccess: () => updateNotification({ id: notification.id, read: true }),
                  onError: () =>
                    toast({
                      status: "error",
                      title: "Error",
                      description: "An error occurred while declining the candidate.",
                    }),
                }
              )
            }>
            Decline
          </Button>
          <Button
            isLoading={updateCandidate.isLoading}
            colorScheme="teal"
            onClick={() =>
              updateCandidate(
                {
                  id: notification.notifiable_id,
                  decision: "ceo_approved",
                },
                {
                  onSuccess: () => updateNotification({ id: notification.id, read: true }),
                  onError: () =>
                    toast({
                      status: "error",
                      title: "Error",
                      description: "An error occurred while approving the candidate.",
                    }),
                }
              )
            }>
            Approve
          </Button>
        </ButtonGroup>
      );
    case "submitted":
    case "needs_approval":
    case "approved":
      if (notification.notifiable_type === "Purchase") {
        return (
          <ButtonGroup size="sm">
            <Button
              isLoading={declinePurchase.isLoading}
              isDisabled={approvePurchase.isLoading}
              variant="outline"
              colorScheme="red"
              onClick={() =>
                declinePurchase.mutate(notification.notifiable_id, {
                  onSuccess: () => updateNotification({ id: notification.id, read: true }),
                  onError: () =>
                    toast({
                      status: "error",
                      title: "Error",
                      description: "An error occurred while declining the purchase.",
                    }),
                })
              }>
              Decline
            </Button>
            <Button
              isLoading={approvePurchase.isLoading}
              isDisabled={declinePurchase.isLoading}
              colorScheme="teal"
              onClick={() =>
                approvePurchase.mutate(notification.notifiable_id, {
                  onSuccess: () => updateNotification({ id: notification.id, read: true }),
                  onError: () =>
                    toast({
                      status: "error",
                      title: "Error",
                      description: "An error occurred while approving the purchase.",
                    }),
                })
              }>
              Approve
            </Button>
          </ButtonGroup>
        );
      } else
        return (
          <ButtonGroup size="sm">
            <Button
              isLoading={declineReimbursement.isLoading}
              isDisabled={approveReimbursement.isLoading}
              variant="outline"
              colorScheme="red"
              onClick={() =>
                declineReimbursement.mutate(notification.notifiable_id, {
                  onSuccess: () => updateNotification({ id: notification.id, read: true }),
                  onError: () =>
                    toast({
                      status: "error",
                      title: "Error",
                      description: "An error occurred while declining the reimbursement.",
                    }),
                })
              }>
              Decline
            </Button>
            <Button
              isLoading={approveReimbursement.isLoading}
              isDisabled={declineReimbursement.isLoading}
              colorScheme="teal"
              onClick={() =>
                approveReimbursement.mutate(notification.notifiable_id, {
                  onSuccess: () => updateNotification({ id: notification.id, read: true }),
                  onError: () =>
                    toast({
                      status: "error",
                      title: "Error",
                      description: "An error occurred while approving the reimbursement.",
                    }),
                })
              }>
              Approve
            </Button>
          </ButtonGroup>
        );

    case "service_request_submitted_purchases":
      return (
        <Link href={getRecordPath(notification)} isExternal>
          <Button as="span" colorScheme="teal" size="sm" minW={20}>
            View
          </Button>
        </Link>
      );
    // TODO(le): Make this work with the new approval system
    case "service_request_approval_created":
      return (
        <Link href={getRecordPath(notification)} isExternal>
          <Button as="span" colorScheme="teal" size="sm" minW={20}>
            View
          </Button>
        </Link>
      );
    case "user_credential_issued":
      return <UserCredentialIssuedModal notification={notification} />;
    case "subscription_approval_created":
      return (
        <ButtonGroup size="sm">
          <Button
            isLoading={updateSubscriptionApproval.isLoading}
            variant="outline"
            colorScheme="red"
            onClick={() =>
              updateSubscriptionApproval.mutate(
                {
                  id: notification.notifiable_id,
                  declined_at: DateTime.now().toISO(),
                  user_id: currentUser.id,
                },
                { onSuccess: () => updateNotification({ id: notification.id, read: true }) }
              )
            }>
            Decline
          </Button>
          <Button
            isLoading={updateSubscriptionApproval.isLoading}
            colorScheme="teal"
            onClick={() =>
              updateSubscriptionApproval.mutate(
                {
                  id: notification.notifiable_id,
                  approved_at: DateTime.now().toISO(),
                  user_id: currentUser.id,
                },
                { onSuccess: () => updateNotification({ id: notification.id, read: true }) }
              )
            }>
            Approve
          </Button>
        </ButtonGroup>
      );
    case "requested_funding":
      return <BudgetItemLinkButton notification={notification} />;

    case "requested":
      return (
        <Link href={getRecordPath(notification)} isExternal>
          <Button as="span" colorScheme="teal" size="sm" minW={20}>
            View Purchase Return
          </Button>
        </Link>
      );

    case "created":
      return (
        <ButtonGroup size="sm">
          <Button
            isLoading={declineSACR.isLoading}
            isDisabled={approveSACR.isLoading}
            variant="outline"
            colorScheme="red"
            onClick={() =>
              declineSACR.mutate(notification.notifiable_id, {
                onSuccess: () => updateNotification({ id: notification.id, read: true }),
                onError: () =>
                  toast({
                    status: "error",
                    title: "Error",
                    description:
                      "An error occurred while declining the spending authority change request.",
                  }),
              })
            }>
            Decline
          </Button>
          <Button
            isLoading={approveSACR.isLoading}
            isDisabled={declineSACR.isLoading}
            colorScheme="teal"
            onClick={() =>
              approveSACR.mutate(notification.notifiable_id, {
                onSuccess: () => updateNotification({ id: notification.id, read: true }),
                onError: () =>
                  toast({
                    status: "error",
                    title: "Error",
                    description:
                      "An error occurred while approving the spending authority change request.",
                  }),
              })
            }>
            Approve
          </Button>
        </ButtonGroup>
      );
    default:
      return null;
  }
};

const BudgetItemLinkButton = ({ notification }) => {
  const { data: budgetItem } = useGetBudgetItem(notification.notifiable_id);

  return (
    <Link href={budgetItem?.app_href || ""} isExternal>
      <Button as="span" colorScheme="teal" size="sm" minW={20}>
        View
      </Button>
    </Link>
  );
};
