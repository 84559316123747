import { Select as MultiSelect } from "chakra-react-select";
import React from "react";
import { useActiveUsersQuery, UserMinimalData } from "../../../api/user";
import {
  HStack,
  useBreakpointValue,
  Text,
  LinkBox,
  Avatar,
  Flex,
  useColorModeValue,
  AvatarGroup,
  Tooltip,
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Portal,
  Heading,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { SmallCloseIcon } from "@chakra-ui/icons";

export type UserSelectProps = {
  setValue: (value: any) => void;
  alreadyAddedUsers?: UserMinimalData[];
};

export const UserSelect: React.FC<UserSelectProps> = ({ setValue, alreadyAddedUsers }) => {
  const { data: users } = useActiveUsersQuery();
  const options =
    users?.map((user) => ({
      label: user.name,
      value: user.id,
    })) || [];

  return (
    <MultiSelect
      isMulti
      placeholder="Choose Users"
      focusBorderColor="teal.400"
      useBasicStyles
      defaultValue={alreadyAddedUsers?.map((user) => ({
        label: user.name,
        value: user.id,
      }))}
      options={options}
      onChange={(selectedOptions) => {
        const listOfUserIds = selectedOptions.map((option: { value: any }) => option.value);
        setValue(listOfUserIds);
      }}
    />
  );
};

type PreviewUserSelectProps = {
  addedUsers: { id: number; user: UserMinimalData }[];
  handleDelete?: (id: number) => void;
  additionalUsersTitle?: string; // title for popover
  isDisabled?: boolean; // deletion disabled
  isLoading?: boolean;
  avatarGroupSize?: number;
};

export const PreviewUserSelect: React.FC<PreviewUserSelectProps> = ({
  handleDelete,
  addedUsers,
  isDisabled = true,
  additionalUsersTitle = "Users",
  isLoading = false,
  avatarGroupSize = useBreakpointValue({ base: 1, sm: 3, xl: 4 }) ?? 3,
}) => {
  const visibleUsers = addedUsers.slice(0, avatarGroupSize) || [];
  const remainingUsers = addedUsers.slice(avatarGroupSize) || [];
  const remainingCount = remainingUsers.length;
  const deleteButtonBackground = useColorModeValue("white", "gray.800");
  const gradiantColor = useColorModeValue("100", "700");
  const textColor = useColorModeValue("gray.600", "auto");
  const deleteIconColor = useColorModeValue("red.50", "auto");
  const gradiant = `linear-gradient(to right, var(--chakra-colors-transparent) 0, var(--chakra-colors-gray-${gradiantColor}) 80%)`;

  return (
    <HStack w="100%" position="relative">
      {visibleUsers.map((addedUser) => (
        <Tooltip key={addedUser.id} label={addedUser.user.name}>
          <Box position="relative">
            {!isDisabled && handleDelete && (
              <IconButton
                zIndex={2}
                size="2xs"
                isRound={true}
                position="absolute"
                right={-4}
                top={-1.5}
                isLoading={isLoading}
                icon={<SmallCloseIcon />}
                colorScheme="red"
                bg={deleteButtonBackground}
                variant="outline"
                onClick={() => handleDelete(addedUser.id)}
                aria-label="handle delete"
              />
            )}
            <LinkBox
              marginInlineEnd={-3}
              key={addedUser.id}
              marginTop={2}
              marginBottom={2}
              as={RouterLink}
              to={`/users/${addedUser.user.id}/profile`}>
              <Avatar name={addedUser.user.name} src={addedUser.user.picture_uri} boxSize={"8"} />
            </LinkBox>
          </Box>
        </Tooltip>
      ))}
      {remainingCount > 0 ? (
        <Popover trigger="hover" placement="bottom-end">
          <PopoverTrigger>
            <Flex
              zIndex={5}
              width="min-content"
              height="100%"
              align="center"
              background={gradiant}
              lineHeight="shorter"
              boxSize={8}
              borderRadius="100%"
              justifyContent="center"
              alignItems="center"
              p={4}>
              <Text fontSize="xs">+{remainingCount}</Text>
            </Flex>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverBody>
                <Heading size="md">{additionalUsersTitle}</Heading>
                <PopoverCloseButton />
                <Flex direction="column" w="100%" maxH="2xs" overflow="auto">
                  {remainingUsers.map((addedUser) => (
                    <Flex
                      key={`${addedUser.id} - ${addedUser.user.name}`}
                      justifyContent={"space-between"}
                      py={2}>
                      <LinkBox
                        key={addedUser.id}
                        as={RouterLink}
                        onClick={(e) => e.stopPropagation()}
                        to={`/users/${addedUser.user.id}/profile`}>
                        <HStack>
                          <Avatar
                            size="sm"
                            name={addedUser.user.name}
                            src={addedUser.user.picture_uri}
                          />
                          <Text color={textColor}>{addedUser.user.name}</Text>
                        </HStack>
                      </LinkBox>
                      {!isDisabled && handleDelete && (
                        <IconButton
                          size="xs"
                          colorScheme="red"
                          bg={deleteIconColor}
                          aria-label="delete"
                          variant="ghost"
                          icon={<SmallCloseIcon />}
                          isLoading={isLoading}
                          onClick={() => handleDelete(addedUser.id)}
                        />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ) : null}
    </HStack>
  );
};
