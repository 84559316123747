import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";

export const GENERAL_LEDGER_CODES_BASE_URL = "general_ledger_codes";

export const generalLedgerCodeSchema = z.object({
  id: z.number(),
  title: z.string(),
  account_number: z.string(),
});

export type GeneralLedgerData = z.infer<typeof generalLedgerCodeSchema>;

/** queries */
export const getGeneralLedgerCodes = async () => {
  const result = await api.get(GENERAL_LEDGER_CODES_BASE_URL);
  return zodParse(generalLedgerCodeSchema.array(), result.data);
};

/** hooks */
export const useGetGeneralLedgerCodes = () => {
  return useQuery({
    queryKey: [GENERAL_LEDGER_CODES_BASE_URL],
    queryFn: getGeneralLedgerCodes,
  });
};
