import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tag,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { CollectionTable, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { PurchaseIndexItemData } from "../../../../api/purchase";
import { MoneyText } from "../../../MoneyText";
import { LinkifyText, purchaseRequestStatusColor } from "../../../Purchasing/util";

export const UnfulfilledPurchases = ({ data, sumPurchases, totalPaid }) => {
  const columns = useMemo(
    () => [
      {
        label: "Requests",
        orderOptions: { orderKey: "id" },
        render: (purchase: PurchaseIndexItemData) => (
          <VStack align="start">
            <RecordLink
              link={`/services/purchasing/${purchase.id}`}
              identifier={`#${purchase.id}`}
              size="small"
            />
          </VStack>
        ),
      },
      {
        label: "Details",
        orderOptions: { orderKey: "description" },
        render: (purchase: PurchaseIndexItemData) => (
          <VStack align="start">
            {purchase.purchase_type === "purchase_order" && (
              <Tag colorScheme="teal">Purchase Order</Tag>
            )}
            <LinkifyText
              text={purchase.description || ""}
              maxWidth={["50ch", "20ch", "20ch", "30ch", "40ch", "60ch"]}
              linkProps={{
                maxWidth: ["50ch", "20ch", "20ch", "30ch", "40ch", "60ch"],
              }}
              isTruncated={true}
            />
          </VStack>
        ),
      },
      {
        label: "Payments Made",
        orderOptions: { orderKey: "purchase_payments_paid_cents" },
        render: (purchase: PurchaseIndexItemData) => (
          <MoneyText money={purchase.purchase_payments_paid} formatOptions={{ compact: "never" }} />
        ),
      },
      {
        label: "Cost",
        orderOptions: { orderKey: "amount_cents" },
        render: (purchase: PurchaseIndexItemData) => (
          <MoneyText money={purchase.amount} formatOptions={{ compact: "never" }} />
        ),
      },
      {
        label: "Created",
        orderOptions: { orderKey: "created_at" },
        render: (purchase: PurchaseIndexItemData) => (
          <Text>{purchase.created_at.toFormat("LLL dd yyyy")}</Text>
        ),
      },

      {
        label: "Status",
        render: (purchase: PurchaseIndexItemData) => (
          <VStack align="start" marginTop={["2", "0"]}>
            <Tag
              colorScheme={purchaseRequestStatusColor(purchase.status)}
              flexShrink={0}
              whiteSpace="nowrap"
              size={["sm", "md"]}>
              {titleize(humanize(purchase.status))}
            </Tag>
          </VStack>
        ),
      },
    ],
    [data]
  );

  return (
    <AccordionItem border="none" key="unallocated-purchases">
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        p={4}
        borderBottom="1px"
        borderColor="chakra-border-color">
        <Text fontSize="sm" fontWeight="medium">
          Unallocated Purchases
        </Text>
        <HStack>
          <MoneyText
            fontSize="sm"
            fontWeight="semibold"
            money={sumPurchases.subtract(totalPaid)}
            formatOptions={{ compact: "never" }}
          />

          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <AccordionPanel p={6} bg={useColorModeValue("gray.100", "gray.700")}>
        {data?.results.length ? (
          <Box bg="chakra-body-bg" p={4} borderRadius="md">
            <CollectionTable columns={columns} items={data.results} />
          </Box>
        ) : (
          <EmptyState title="All purchases paid for" />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};
