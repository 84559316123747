import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { invalidateInventoryBom } from "./inventory_boms";
import { inventoryLocationSchema } from "./inventory_location";
import { moneySchema } from "../helpers/Money";

export const INVENTORY_BOM_COMPONENT_BASE_URL = "/inventory_bom_components";

export const bomInventorySchema = z.object({
  id: z.number(),
  name: z.string(),
  unit_of_measurement: z.string().nullable(),
  inventory_locations: z.array(inventoryLocationSchema).nullable(),
  status: z.string().nullable(),
  stock: z.number().nullable(),
  amount: moneySchema,
});

export const inventoryBomComponentSchema = z.object({
  id: z.number(),
  inventory: bomInventorySchema,
  quantity: z.number(),
});

export const inventoryBomCreateParams = z.object({
  inventory_bom_id: z.number(),
  inventory_id: z.number(),
  quantity: z.number(),
});

export type InventoryBomComponentData = z.infer<typeof inventoryBomComponentSchema>;
export type InventoryBomComponentCreateParams = z.infer<typeof inventoryBomCreateParams>;

export const createInventoryBomComponent = async (
  inventory_bom_component: InventoryBomComponentCreateParams
) => {
  const result = await api.post(INVENTORY_BOM_COMPONENT_BASE_URL, {
    inventory_bom_component,
  });
  return zodParse(inventoryBomComponentSchema, result.data);
};

export const updateInventoryBomComponent = async (
  inventory_bom_component: Partial<InventoryBomComponentData>
) => {
  const result = await api.put(
    `${INVENTORY_BOM_COMPONENT_BASE_URL}/${inventory_bom_component.id}`,
    {
      inventory_bom_component,
    }
  );
  return zodParse(inventoryBomComponentSchema.pick({ id: true }), result.data);
};

export const deleteInventoryBomComponent = async (id: number) => {
  await api.delete(`${INVENTORY_BOM_COMPONENT_BASE_URL}/${id}`);
};

/** hooks */

export const useCreateInventoryBomComponent = (id?: number) => {
  return useMutation({
    mutationFn: createInventoryBomComponent,
    onSuccess: invalidateInventoryBom(id),
  });
};

export const useUpdateInventoryBomComponent = (id?: number) => {
  return useMutation({
    mutationFn: updateInventoryBomComponent,
    onSuccess: invalidateInventoryBom(id),
  });
};

export const useDeleteInventoryBomComponent = (id?: number) => {
  return useMutation({
    mutationFn: deleteInventoryBomComponent,
    onSuccess: invalidateInventoryBom(id),
  });
};
