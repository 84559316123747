import {
  Button,
  VStack,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  HStack,
  MenuItem,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useVendorOptions } from "../../../../api/options";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { BiStore } from "react-icons/bi";
import {
  VendorCreateParams,
  VendorData,
  useUpdateVendor,
  useNewVendor,
} from "../../../../api/vendor";
import * as Errors from "../../../../helpers/errors";
import { required } from "../../../../helpers/validation";
import InputFormControl from "../../../shared/form/InputFormControl";
import TextareaFormControl from "../../../shared/form/TextareaFormControl";

const defaultValues: VendorCreateParams = {
  name: "",
  address: "",
  website: "",
  payment_terms: "",
  tax_id: "",
  category: null,
  status: "",
  description: "",
};

const PAYMENT_TERMS = [
  { label: "Due on Receipt", value: "due_on_receipt" },
  { label: "Net 10", value: "net_10" },
  { label: "Net 15", value: "net_15" },
  { label: "Net 30", value: "net_30" },
  { label: "Net 60", value: "net_60" },
  { label: "Net 90", value: "net_90" },
  { label: "EOM", value: "end_of_month" },
  { label: "COD", value: "cash_on_delivery" },
  { label: "CIA", value: "cash_in_advance" },
  { label: "Installments", value: "installments" },
  { label: "Staged Payments", value: "staged_payments" },
  { label: "Prepaid", value: "prepaid" },
];

const SUPPLIER_CATEGORIES = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "X", value: "X" },
];

type NewVendorModalProps = {
  vendor?: VendorData;
  buttonLabel: string;
  location: "menu" | "vendor";
};

export const NewVendorModal = ({ vendor, buttonLabel, location }: NewVendorModalProps) => {
  const currentUserQuery = useCurrentUserQuery();
  const vendors = useVendorOptions();
  const { mutate: createVendor, isLoading, isError } = useNewVendor();
  const { mutate: updateVendor, isLoading: isMutateLoading } = useUpdateVendor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<VendorCreateParams>({
    mode: "all",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (vendor) {
      reset({
        name: vendor.name,
        address: vendor.address,
        website: vendor.website,
        payment_terms: vendor.payment_terms || "",
        tax_id: vendor.tax_id || "",
        category: vendor.category,
      });
    }
  }, [vendor, isOpen]);

  const validateName = (name: string) => {
    if (vendor?.name === name) return undefined;
    const isTaken = vendors?.some(({ label }) => label.toLowerCase() === name.toLowerCase());
    return isTaken ? "Name has already been taken" : undefined;
  };

  const closeModal = () => {
    reset(defaultValues);
    onClose();
  };

  const toast = useToast();
  const onSubmit = handleSubmit((data) => {
    vendor
      ? updateVendor(
          { id: vendor.id, ...data },
          {
            onSuccess: () => {
              closeModal();
              toast({ title: "Vendor updated" });
            },
          }
        )
      : createVendor(
          { ...data, status: "approved" },
          {
            onSuccess: () => {
              closeModal();
              toast({ title: "Vendor added" });
            },
          }
        );
  });

  return (
    <>
      {location === "menu" ? (
        <MenuItem onClick={() => onOpen()} fontWeight="semibold" p={3} pl={6} icon={<BiStore />}>
          Vendor
        </MenuItem>
      ) : (
        <MenuItem onClick={onOpen}>{buttonLabel}</MenuItem>
      )}

      <FormModal
        size="xl"
        title={vendor ? "Edit Vendor" : "New Vendor"}
        isOpen={isOpen}
        onClose={closeModal}
        submitButtonTitle={vendor ? "Update" : "Create"}
        submitButtonDisabled={isLoading || !isDirty || Object.keys(errors).length > 0}
        submitButtonColorSchema="teal"
        handleSubmit={onSubmit}
        errorMessage={isError ? Errors.generic : undefined}
        isDirty={isDirty}>
        <VStack align="start" width="100%" spacing={6}>
          <HStack width="100%" align="start">
            <Controller
              name="name"
              control={control}
              rules={{
                required,
                validate: validateName,
              }}
              render={({ field }) => (
                <InputFormControl
                  label="Vendor Name"
                  placeholder="Type Vendor Name"
                  error={errors?.name?.message}
                  isRequired
                  {...field}
                />
              )}
            />
            <Controller
              name="website"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Website</FormLabel>
                  <Input {...field} placeholder="Type Website" />
                </FormControl>
              )}
            />
          </HStack>

          <Controller
            name="payment_terms"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Payment Terms</FormLabel>
                <Select {...field} options={PAYMENT_TERMS} placeholder="Select Payment Terms" />
              </FormControl>
            )}
          />
          <HStack width="100%" align="start">
            <Controller
              name="tax_id"
              control={control}
              render={({ field }) => (
                <InputFormControl
                  label="Tax ID"
                  placeholder="Type Tax ID"
                  error={errors?.tax_id?.message}
                  {...field}
                  value={field.value || ""}
                />
              )}
            />

            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Supplier Category</FormLabel>
                  <Select {...field} options={SUPPLIER_CATEGORIES} placeholder="Select Category" />
                </FormControl>
              )}
            />
          </HStack>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextareaFormControl
                label="Address"
                placeholder="Type Address"
                error={errors?.address?.message}
                {...field}
              />
            )}
          />
        </VStack>
      </FormModal>
    </>
  );
};
