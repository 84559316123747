import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { TreeSelectOption } from "../components/shared/TreeSelect";
import { InventoryItemAggregationData } from "./inventory_item";
import { InventoryLocationsWithInventoryData } from "./inventory";
import { zodParse } from "./zodParse";

export const INVENTORY_LOCATIONS_BASE_URL = "/inventory_locations";

export const inventoryLocationSchema = z.object({
  id: z.number(),
  name: z.string(),
  parent_inventory_location_id: z.number().nullable(),
  capacity_for_storage: z.boolean(),
});

export const inventoryLocationShowSchema = inventoryLocationSchema.extend({
  inventory_item_count: z.number(),
  has_inventory_items: z.boolean(),
});

export const inventoryLocationAvailableSchema = z.object({
  id: z.number(),
  name: z.string(),
  capacity_for_storage: z.boolean(),
  children: z.array(z.lazy(() => inventoryLocationAvailableSchema)),
});

export type InventoryLocationAvailableData = z.infer<typeof inventoryLocationAvailableSchema>;

export type InventoryLocationData = z.infer<typeof inventoryLocationSchema>;
export type InventoryLocationShowData = z.infer<typeof inventoryLocationShowSchema>;
export type InventoryLocationCreateParams = Pick<
  InventoryLocationData,
  "parent_inventory_location_id"
>;

/** queries */
export const getInventoryLocation = async (id: number | null | undefined) => {
  const result = await api.get(`${INVENTORY_LOCATIONS_BASE_URL}/${id}`);
  return zodParse(inventoryLocationShowSchema, result.data);
};

export const getInventoryLocations = async () => {
  const result = await api.get(INVENTORY_LOCATIONS_BASE_URL);
  return zodParse(z.array(inventoryLocationShowSchema), result.data);
};

export const getAvailableInventoryLocations = async (searchTerm?: string) => {
  const result = await api.get(
    `${INVENTORY_LOCATIONS_BASE_URL}/available_locations`,
    (searchTerm && { params: { search_term: searchTerm } }) || undefined
  );
  return zodParse(z.array(inventoryLocationAvailableSchema), result.data);
};

export const searchInventoryLocations = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [INVENTORY_LOCATIONS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(inventoryLocationShowSchema), result.data);
};

export const createInventoryLocation = async (
  inventory_location: InventoryLocationCreateParams
) => {
  const result = await api.post(INVENTORY_LOCATIONS_BASE_URL, { inventory_location });
  return result.data;
};

export const updateInventoryLocation = async (
  inventory_location: Partial<InventoryLocationData>
) => {
  await api.put(`${INVENTORY_LOCATIONS_BASE_URL}/${inventory_location.id}`, { inventory_location });
};

export const deleteInventoryLocation = async (id: number) => {
  await api.delete(`${INVENTORY_LOCATIONS_BASE_URL}/${id}`);
  return id;
};

/** hooks */

export const invalidateInventoryLocations = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [INVENTORY_LOCATIONS_BASE_URL];
  if (id) queryKey.push(id);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetInventoryLocations = () => {
  return useQuery({
    queryKey: [INVENTORY_LOCATIONS_BASE_URL],
    queryFn: () => getInventoryLocations(),
  });
};

export const useSearchInventoryLocations = (params: SearchParams) => {
  return useQuery({
    queryKey: [INVENTORY_LOCATIONS_BASE_URL, params],
    queryFn: () => searchInventoryLocations(params),
  });
};

export const useGetInventoryLocation = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [INVENTORY_LOCATIONS_BASE_URL, id],
    queryFn: () => getInventoryLocation(id),
    enabled: !!id,
  });
};

export const useCreateInventoryLocation = () => {
  return useMutation({
    mutationFn: createInventoryLocation,
    onSuccess: invalidateInventoryLocations(),
  });
};

export const useUpdateInventoryLocation = () => {
  return useMutation({
    mutationFn: updateInventoryLocation,
    onSuccess: invalidateInventoryLocations(),
  });
};

export const useDeleteInventoryLocation = () => {
  return useMutation({
    mutationFn: deleteInventoryLocation,
    onSuccess: invalidateInventoryLocations(),
  });
};

export const useTreeSelectInventoryLocations = (searchTerm?: string, emptyOption?: string) => {
  return useQuery({
    queryKey: [INVENTORY_LOCATIONS_BASE_URL + "available_locations", searchTerm],
    queryFn: async () => {
      {
        const availableLocations = await getAvailableInventoryLocations(searchTerm);
        return availableLocations.map((location) =>
          buildInventoryLocationOptions(
            searchTerm,
            location,
            emptyOption || "Select",
            "availableLocations"
          )
        );
      }
    },
  });
};

export type LocationTreeValue = {
  capacity_for_storage: boolean;
  storage_item: boolean;
  inventory_items: InventoryItemAggregationData[];
  quantity?: number;
  name: string;
  id: number;
};

export type LocationId = {
  id: number;
};

export type LocationKeyId = TreeSelectOption<LocationId>;

export type LocationTreeOption = {
  label: string;
  value: LocationTreeValue;
  children?: LocationTreeOption[];
};

export type LocationTreeKeyValue = TreeSelectOption<LocationTreeValue>;

export const buildInventoryLocationOptions = (
  searchTerm: string | undefined,
  tree: InventoryLocationAvailableData | InventoryLocationsWithInventoryData,
  selectorTitle: string,
  type: string
): LocationTreeOption => {
  const label = tree.name;
  const value = {
    name: tree.name,
    capacity_for_storage: tree.capacity_for_storage,
    inventory_items: tree?.inventory_items || [],
    storage_item: false,
    quantity: tree.quantity,
    id: tree.id,
  };

  const children = tree.children.length > 0 ? tree.children : null;

  const showSelector =
    type === "availableLocations" ? tree.capacity_for_storage : tree?.has_stock || false;

  return {
    label: label,
    value: value,
    children: [
      ...(children?.map((subTree) =>
        buildInventoryLocationOptions(searchTerm, subTree, selectorTitle, type)
      ) || []),
      ...(showSelector
        ? [
            {
              label: selectorTitle,
              value: {
                capacity_for_storage: false,
                storage_item: true,
                name: tree.name,
                quantity: tree.quantity,
                id: tree.id,
                inventory_items: tree?.inventory_items || [],
              },
            },
          ]
        : []),
    ],
  };
};
