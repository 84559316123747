import React, { useState } from "react";
import { useParams } from "react-router";
import { Flex, Text, Heading, Box, Center, Spinner, HStack, useToast } from "@chakra-ui/react";
import { EditableText, SplitPage } from "@sciencecorp/helix-components";

import { useGetArchetype, useSearchArchetypes, useUpdateArchetype } from "../../../api/archetype";
import { userHasRole, useCurrentUserQuery } from "../../../api/user";
import { SidebarList } from "../../Credentials/util";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { useNavigate } from "react-router";
import { ArchetypeAttributes } from "./ArchetypeAttributes";

export const Archetype = () => {
  const { id } = useParams();
  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: archetype, isLoading, isError } = useGetArchetype(Number(id));
  const { data: archetypeSearch } = useSearchArchetypes({
    term: search || "*",
    pagination: { per_page: 10 },
  });
  const { mutate: updateArchetype, isLoading: isUpdateLoading } = useUpdateArchetype(
    id ? +id : null
  );
  const currentUserQuery = useCurrentUserQuery();
  const isRecruitingAdmin = userHasRole(currentUserQuery, "recruiting_admin");
  const isCeo = userHasRole(currentUserQuery, "ceo");

  const navigate = useNavigate();
  if (currentUserQuery.isSuccess && !isRecruitingAdmin && !isCeo) {
    navigate("/hiring/candidates");
    return <Text>Unauthorized. Redirecting</Text>;
  }

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="lg" />
      </Center>
    );
  }

  if (isError) {
    return <Text>Failed to find role</Text>;
  }

  return (
    <SplitPage
      sidebar={
        <SidebarList
          title="Other Roles"
          data={archetypeSearch?.results || []}
          url="/hiring/roles"
          breadcrumbTitle="Back to Roles"
          selectedItemId={archetype.id}
          debouncedSearch={debouncedSearch}
        />
      }
      sidebarWidth="350px"
      sidebarWidthXL="450px"
      main={
        <Flex direction="column" gap={6}>
          <Heading>
            <EditableText
              defaultValue={archetype.name}
              onSubmit={(value) => {
                if (value) updateArchetype({ id: archetype.id, name: value.toString() });
              }}
            />
          </Heading>
          <HStack fontSize="sm">
            <Text>
              Hired:{" "}
              <Box as="span" fontWeight="medium">
                {archetype.hired_count}
              </Box>
            </Text>
          </HStack>
          <Flex
            direction="column"
            gap={4}
            border="1px"
            borderColor="chakra-border-color"
            borderRadius="md"
            p={6}>
            <ArchetypeAttributes archetypeId={archetype.id} editable={true} />
          </Flex>
        </Flex>
      }
    />
  );
};
