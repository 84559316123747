import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { createPaginatedResponseSchema, createSearchResponseSchema } from "./shared";
import { vendorSchema } from "./inventory_vendor";
import { INVENTORY_ITEM_BASE_URL, inventoryItemCheckoutSchema } from "./inventory_item";
import * as _ from "lodash";
import { buildInventoryLocationOptions, inventoryLocationSchema } from "./inventory_location";
import { dateTimeSchema } from "../helpers/dateTime";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";
import { PURCHASES_BASE_URL } from "./purchase";
import { blobFileSchema } from "./blob_files";
import { moneySchema } from "../helpers/Money";
import { timelineEventArraySchema } from "./timeline_events";
import { purchaseLineItemPendingInventorySchema } from "./purchase_line_item";
import { timelineEventSchema } from "./timeline_events";

export const INVENTORY_BASE_URL = "inventories";
export const INVENTORY_USERS_BASE_URL = "inventory_users";

export const inventorySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  sku: z.string(),
  category: z.string(),
  track_lots: z.boolean(),
  track_expiration_dates: z.boolean(),
  advanced_tracking: z.boolean(),
  unit_of_measurement: z.string().nullable(),
  reorder_threshold: z.number(),
  track_reorder_threshold: z.boolean(),
  is_archived: z.boolean(),
  archived_at: dateTimeSchema.nullable(),
  is_consumable: z.boolean(),
});

export const inventoryMinimalSchema = inventorySchema.pick({
  id: true,
  name: true,
  sku: true,
});

export const inventoryUserSchema = z.object({
  user_ids: z.number(),
  inventory_id: z.number(),
});

export const inventoryUserShowSchema = z.object({
  id: z.number(),
  user: z.lazy(() => userMinimalSchema),
});

export const inventoryIndexSchema = inventorySchema
  .pick({
    id: true,
    name: true,
    sku: true,
    category: true,
    is_archived: true,
    archived_at: true,
    advanced_tracking: true,
    is_consumable: true,
    unit_of_measurement: true,
  })
  .extend({
    total_quantity_stock: z.number(),
    stock: z.number(),
    inventory_locations: z.array(inventoryLocationSchema),
    total_inventory_items: z.number().optional(),
    status: z.string().nullable(),
    users: z.array(inventoryUserShowSchema),
  });

export const inventoryCheckoutSchema = inventoryIndexSchema
  .omit({
    is_archived: true,
    archived_at: true,
    advanced_tracking: true,
    is_consumable: true,
    users: true,
    stock: true,
    inventory_locations: true,
  })
  .extend({
    inventory_items: z.array(inventoryItemCheckoutSchema),
    has_multiple_lots: z.boolean(),
  });

export const inventoryShowSchema = inventorySchema.extend({
  pending_order_quantity: z.number(),
  total_quantity_stock: z.number(),
  vendors: z.array(vendorSchema),
  users: z.array(inventoryUserShowSchema),
  stock: z.number(),
  total_inventory_items: z.number().optional(),
  pending_delivered_purchase_line_item: z
    .lazy(() => purchaseLineItemPendingInventorySchema)
    .nullable(),
  inventory_item_barcodes: z
    .array(z.object({ id: z.number(), barcode_code: z.string().nullable() }))
    .optional(),
  status: z.string().nullable(),
  original_equipment_manufacturer: z.string(),
  manufacturing_part_number: z.string(),
  uploaded_files: z.array(blobFileSchema),
});

export const inventoryUserCreateSchema = z.object({
  user_ids: z.number().array(),
  inventory_id: z.number(),
});

export const inventoryItemOnLocationSchema = z.object({
  expiration_date: dateTimeSchema.nullable(),
  lot_number: z.string().nullable(),
  items: z.array(
    z.object({
      id: z.number(),
      amount: moneySchema,
      remaining_quantity: z.number(),
    })
  ),
  total_remaining_quantity: z.number(),
});

export const inventoryLocationsWithInventorySchema = z.object({
  id: z.number(),
  name: z.string(),
  capacity_for_storage: z.boolean(),
  quantity: z.number(),
  has_stock: z.boolean(),
  children: z.array(z.lazy(() => inventoryLocationsWithInventorySchema)),
});

export const inventoryUserBulkCreateSchema = z.object({
  inventory_id: z.number(),
  user_ids: z.array(z.number()),
});

export type InventoryData = z.infer<typeof inventorySchema>;
export type InventoryIndexData = z.infer<typeof inventoryIndexSchema>;
export type InventoryShowData = z.infer<typeof inventoryShowSchema>;
export type InventoryCheckoutData = z.infer<typeof inventoryCheckoutSchema>;
export type InventoryUserBulkCreateData = z.infer<typeof inventoryUserBulkCreateSchema>;
export type MoveInventoryParams = {
  id: number;
  starting_location_id: number;
  ending_location_id: number;
  quantity: number;
};

export type BulkCheckoutInventoryData = z.infer<typeof bulkCheckoutInventorySchema>;

export type InventoryLocationsWithInventoryData = z.infer<
  typeof inventoryLocationsWithInventorySchema
>;

export type InventoryCreateParams = Omit<
  InventoryData,
  "id" | "description" | "advanced_tracking" | "is_archived" | "archived_at"
>;
export type InventoryUserData = z.infer<typeof inventoryUserSchema>;
export type InventoryUserCreateData = z.infer<typeof inventoryUserCreateSchema>;

export const bulkCheckoutInventorySchema = z.object({
  items: z.array(
    z.object({
      id: z.number(),
      quantity_used: z.number(),
      inventory_location_id: z.number(),
      lot_number: z.string().optional(),
    })
  ),
  spending_authority_id: z.number().nullable(),
  spending_authority_type: z.enum(["BudgetItem", "ServiceRequest", "RevenueItem"]).nullable(),
  purpose: z.enum(["spending_authority", "used", "waste"]),
});

// api queries
export const createInventory = async (inventory: InventoryCreateParams) => {
  const result = await api.post(INVENTORY_BASE_URL, { inventory });
  return result.data;
};

export const getInventory = async (id: number | null | undefined) => {
  const result = await api.get(`${INVENTORY_BASE_URL}/${id}`);
  return zodParse(inventoryShowSchema, result.data);
};

export const getInventoryCheckoutHistoryEvents = async (id: number | null | undefined) => {
  const result = await api.get(`${INVENTORY_BASE_URL}/${id}/checkout_history`);
  return zodParse(z.array(timelineEventArraySchema), result.data);
};

export const getInventoryPendingItems = async (id: number | null | undefined) => {
  const result = await api.get(`${INVENTORY_BASE_URL}/${id}/pending`);
  return zodParse(z.array(purchaseLineItemPendingInventorySchema), result.data);
};

export const getInventoryLocationsWithInventory = async (id: number, searchTerm?: string) => {
  const result = await api.get(
    `${INVENTORY_BASE_URL}/${id}/locations`,
    (searchTerm && { params: { search_term: searchTerm } }) || undefined
  );
  return zodParse(z.array(inventoryLocationsWithInventorySchema), result.data);
};

export const getInventories = async () => {
  const result = await api.get(INVENTORY_BASE_URL);
  return zodParse(z.array(inventoryMinimalSchema), result.data);
};

export const getInventoryEvents = async (id: number) => {
  const result = await api.get(`${INVENTORY_BASE_URL}/${id}/events`);
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const getInventoryItemsOnLocation = async (id: number, location_id: number) => {
  const result = await api.get(`${INVENTORY_BASE_URL}/${id}/${location_id}/inventory_items`);
  return zodParse(z.array(inventoryItemOnLocationSchema), result.data);
};

export const searchInventories = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [INVENTORY_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(inventoryIndexSchema), result.data);
};

export const searchInventoryCheckout = async ({
  inventory_location_id,
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams & { inventory_location_id: number | null }) => {
  const path = [INVENTORY_BASE_URL, "search", "checkout"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(
    path.join("/"),
    _.omitBy({ ...index, ...search, inventory_location_id }, _.isNull)
  );
  return zodParse(createSearchResponseSchema(inventoryCheckoutSchema), result.data);
};

export const bulkCheckoutInventory = async (inventory: BulkCheckoutInventoryData) => {
  await api.post(`${INVENTORY_BASE_URL}/bulk_checkout`, { inventory });
};

export const updateInventory = async (inventory: Partial<InventoryShowData>) => {
  await api.put(`${INVENTORY_BASE_URL}/${inventory.id}`, { inventory });
};

export const deleteInventory = async (id: number) => {
  await api.delete(`${INVENTORY_BASE_URL}/${id}`);
  return id;
};

export const moveInventoryStock = async (inventory: MoveInventoryParams) => {
  await api.post(`${INVENTORY_BASE_URL}/${inventory.id}/move_stock`, inventory);
};

export const createInventoryUser = async (inventory_user: InventoryUserCreateData) => {
  const result = await api.post(`${INVENTORY_USERS_BASE_URL}`, { inventory_user });
  return result.data;
};

export const bulkCreateInventoryUser = async (inventory_users: InventoryUserBulkCreateData) => {
  const result = await api.post(`${INVENTORY_USERS_BASE_URL}/bulk`, { inventory_users });
  return result.status === 201;
};

export const deleteInventoryUser = async (id: number) => {
  await api.delete(`${INVENTORY_USERS_BASE_URL}/${id}`);
};

/** hooks */

export const invalidateInventories = (id?: number, params?) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [INVENTORY_BASE_URL];
  if (id) queryKey.push(id);
  if (params) queryKey.push(params);
  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: [INVENTORY_ITEM_BASE_URL],
    });
    queryClient.invalidateQueries({
      queryKey: [PURCHASES_BASE_URL],
    });
  };
};

export const useGetInventories = () => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL],
    queryFn: () => getInventories(),
  });
};

export const useGetInventory = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, id],
    queryFn: () => getInventory(id),
    enabled: !!id,
  });
};
export const useGetInventoryPendingItems = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, id, "pending"],
    queryFn: () => getInventoryPendingItems(id),
    enabled: !!id,
  });
};

export const useGetInventoryEvents = (id: number) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, id, "events"],
    queryFn: () => getInventoryEvents(id),
  });
};

export const useGetInventoryItemsOnLocation = (id: number, location_id: number) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, id, location_id, "inventory_items"],
    queryFn: () => getInventoryItemsOnLocation(id, location_id),
  });
};

export const useGetInventoryCheckoutHistoryEvents = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, id, "checkout_history"],
    queryFn: () => getInventoryCheckoutHistoryEvents(id),
    enabled: !!id,
  });
};

export const useSearchInventories = (params: SearchParams) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, params],
    queryFn: () => searchInventories(params),
  });
};

export const useSearchInventoriesCheckout = (
  params: SearchParams & { inventory_location_id: number | null }
) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, "checkout", params],
    queryFn: () => searchInventoryCheckout(params),
  });
};

export const useCreateInventory = () => {
  return useMutation({
    mutationFn: createInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useBulkCheckoutInventory = () => {
  return useMutation({
    mutationFn: bulkCheckoutInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useUpdateInventory = () => {
  return useMutation({
    mutationFn: updateInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useDeleteInventory = () => {
  return useMutation({
    mutationFn: deleteInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useMoveInventoryStock = (id: number) => {
  return useMutation({
    mutationFn: moveInventoryStock,
    onSuccess: invalidateInventories(id),
  });
};

export const useCreateInventoryUser = (inventoryId: number) => {
  return useMutation({
    mutationFn: createInventoryUser,
    onSuccess: invalidateInventories(inventoryId),
  });
};

export const useBulkCreateInventoryUser = () => {
  return useMutation({
    mutationFn: bulkCreateInventoryUser,
    onSuccess: invalidateInventories(),
  });
};

export const useDeleteInventoryUser = (inventoryId: number) => {
  return useMutation({
    mutationFn: deleteInventoryUser,
    onSuccess: invalidateInventories(inventoryId),
  });
};

export const useGetLocationsWithInventory = (inventoryId: number, searchTerm?: string) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, inventoryId, searchTerm],
    queryFn: () => getInventoryLocationsWithInventory(inventoryId, searchTerm),
  });
};

export const useInventoryLocationsWithInventoryOptions = (
  inventoryId: number,
  searchTerm?: string
) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, inventoryId, searchTerm, "option"],
    queryFn: async () => {
      const result = await getInventoryLocationsWithInventory(inventoryId, searchTerm);
      return result.map((location) =>
        buildInventoryLocationOptions(searchTerm, location, "Remove stock", "inventoryStock")
      );
    },
  });
};
