import { Flex, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useActiveUsersQuery } from "../../../../api/user";
import { Select as MultiSelect } from "chakra-react-select";
import {
  InventoryBomUserData,
  useBulkCreateInventoryBomUser,
} from "../../../../api/inventory_bom_user";

type EditOwnerModalProps = {
  inventoryBomId: number;
  isOpen: boolean;
  onClose: () => void;
  inventoryBomUsers?: InventoryBomUserData[];
};

type EditOwnerFormValuesType = {
  owners: number[];
};

const defaultFormValues: EditOwnerFormValuesType = {
  owners: [],
};

export const EditOwnerModal = ({
  inventoryBomId,
  isOpen,
  onClose,
  inventoryBomUsers,
}: EditOwnerModalProps) => {
  const { data: users } = useActiveUsersQuery();
  const alreadyAddedOwners = inventoryBomUsers?.map((user) => user.user);
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: defaultFormValues,
  });
  const toast = useToast();

  const {
    mutateAsync: bulkCreateCapitalEquipmentUser,
    isLoading: isLoadingBulkCreateInventoryBomUser,
  } = useBulkCreateInventoryBomUser(inventoryBomId);
  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = () => {
    if (formValues.owners) {
      bulkCreateCapitalEquipmentUser({
        inventory_bom_id: inventoryBomId,
        user_ids: formValues.owners,
      }).then(() => {
        toast({
          title: "Owners updated",
          status: "success",
        });
        handleClose();
      });
    }
  };

  return (
    <FormModal
      size={"md"}
      title={"Edit Owners"}
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      submitButtonDisabled={formValues.owners.length === 0}
      onClose={handleClose}
      isLoading={isLoadingBulkCreateInventoryBomUser}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="owners"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Who is the owner(s) of this BOM?</FormLabel>
              <MultiSelect
                isMulti
                placeholder="Choose Users"
                focusBorderColor="teal.400"
                useBasicStyles
                options={users?.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                defaultValue={alreadyAddedOwners?.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                onChange={(selectedOptions) => {
                  const listOfOwnerIds = [...selectedOptions.map((option) => option.value)];
                  setValue("owners", listOfOwnerIds);
                }}
              />
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};
