import React, { useMemo, useState, useCallback } from "react";
import { FormModal } from "@sciencecorp/helix-components";
import {
  Flex,
  IconButton,
  Text,
  Box,
  Td,
  Tr,
  Thead,
  Tbody,
  Th,
  Table,
  HStack,
  useColorModeValue,
  Icon,
  Tag,
  Progress,
} from "@chakra-ui/react";
import { IoMedalOutline } from "react-icons/io5";

import {
  CredentialPackageUserShowData,
  CredentialSummaryData,
  TrainingSummaryData,
} from "../../../../api/credential_package_users";

import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";

type PackageProgressModalProps = {
  credentialPackageUser: CredentialPackageUserShowData;
  isOpen: boolean;
  onClose: () => void;
};

export const PackageProgressModal = ({
  credentialPackageUser,
  isOpen,
  onClose,
}: PackageProgressModalProps) => {
  return (
    <FormModal
      title="Progress Details"
      submitButtonColorSchema="teal"
      submitButtonTitle="Okay"
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      handleSubmit={onClose}>
      <Flex direction="column" width="100%" gap={4}>
        <CredentialProgress credentialPackageUser={credentialPackageUser} />
      </Flex>
    </FormModal>
  );
};

export const CredentialProgress = ({ credentialPackageUser }) => {
  return (
    <Box w="100%">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Credential</Th>
            <Th textAlign={"end"}>Trainings Completed</Th>
          </Tr>
        </Thead>
        <Tbody>
          {credentialPackageUser.progress.credentials.map((credential) => (
            <CredentialRow
              key={`user-${credentialPackageUser.id}-credential-${credential.id}-${credential.name}`}
              credential={credential}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

interface CredentialRowProps {
  credential: CredentialSummaryData;
  depth?: number;
}

const CredentialRow = ({ credential, depth = 0 }: CredentialRowProps) => {
  const [isRowOpen, setIsRowOpen] = useState(false);

  const hasTrainings = credential.trainings.length > 0;

  const toggleExpand = useCallback(
    (value?: boolean) => {
      if (value !== undefined) setIsRowOpen(value);
      else setIsRowOpen(!isRowOpen);
    },
    [isRowOpen, setIsRowOpen]
  );

  const progress = useMemo(
    () => (credential.trainings_completed / credential.trainings_count) * 100,
    [credential]
  );

  return (
    <>
      <Tr key={`credential-${credential.id}`}>
        <Td
          p={0}
          onClick={hasTrainings ? () => toggleExpand() : undefined}
          _hover={{ cursor: hasTrainings ? "pointer" : "auto" }}>
          <Flex gap={1}>
            <IconButton
              visibility={hasTrainings ? "visible" : "hidden"}
              size="mg"
              variant="ghost"
              aria-label="Expand team"
              icon={isRowOpen ? <MdOutlineArrowDropDown /> : <MdOutlineArrowRight />}
              onClick={() => toggleExpand()}
            />
            <HStack
              w="max-content"
              p={2}
              borderRadius="md"
              key={credential.id}
              bg={useColorModeValue("gray.100", "gray.600")}>
              <Icon as={IoMedalOutline} />
              <Text fontSize="sm" fontWeight="semibold" maxW="15ch" isTruncated>
                {credential.name}
              </Text>
            </HStack>
          </Flex>
        </Td>

        <Td>
          {credential.status !== "unfulfilled" ? (
            <Text fontSize="sm" color="green.500">
              Requirements Met
            </Text>
          ) : (
            <HStack justify="end">
              <Progress w={32} colorScheme="teal" size="sm" value={progress} hasStripe />
              <Text fontSize="sm">
                {credential.trainings_completed} of {credential.trainings_count}
              </Text>
            </HStack>
          )}
        </Td>
      </Tr>

      {isRowOpen &&
        credential.trainings.map((training) => (
          <TrainingRow key={`training-${training.id}`} training={training} depth={depth + 1} />
        ))}
    </>
  );
};

type TrainingRowProps = {
  training: TrainingSummaryData;
  depth: number;
};

const TrainingRow = ({ training, depth }: TrainingRowProps) => {
  return (
    <Tr key={`training-${training.id}`} fontSize="sm">
      <Td pl={`${depth * 2}rem`}>
        <Text>{training.name}</Text>
      </Td>

      <Td textAlign="end">
        <HStack justify="end">
          {training.completed_at && <Tag colorScheme="green">Completed</Tag>}
          {training.completed_at ? (
            <Text fontWeight="medium">{training.completed_at?.toFormat("LLL dd yyyy")}</Text>
          ) : (
            <Text>Next Session: {training.next_session_at?.toFormat("LLL dd yyyy") || "N/A"}</Text>
          )}
        </HStack>
      </Td>
    </Tr>
  );
};
