import React from "react";
import {
  Box,
  Heading,
  HStack,
  VStack,
  Text,
  Divider,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Icon,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import { ConfirmationButton, EmptyState, Header } from "@sciencecorp/helix-components";
import { IoMedalOutline } from "react-icons/io5";
import { FiArchive } from "react-icons/fi";

import {
  CredentialPackageShowData,
  useDeleteCredentialPackage,
  useDeleteCredentialPackageCredential,
  useGetCredentialPackage,
  useSearchCredentialPackages,
  useUpdateCredentialPackage,
} from "../../../api/credential_packages";
import { UserCredentialPackageTable } from "./components/CredentialPackageUserTable";
import { AddCredentialPackageCredential } from "./components/AddCredentialtoPackage";

import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { CiUndo } from "react-icons/ci";
import { PopoverList } from "../../shared/PopoverList";

const statusAndColor = (credentialPackage: CredentialPackageShowData) => {
  if (credentialPackage.is_draft) return { status: "Draft", color: "gray" };
  else if (credentialPackage.is_archived) return { status: "Archived", color: "red" };
  else return { status: "Active", color: "green" };
};

export const CredentialPackage = () => {
  const { id } = useParams();

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: credentialPackages } = useSearchCredentialPackages({
    term: search || "*",
    pagination: { per_page: -1 },
    order: { name: "asc" },
  });

  const {
    data: credentialPackage,
    isLoading,
    isError,
    error,
  } = useGetCredentialPackage(Number(id));

  const { mutate: updateCredentialPackage, isLoading: isLoadingUpdate } =
    useUpdateCredentialPackage(Number(id));
  const { mutate: deleteCredentialPackageCredential, isLoading: isLoadingDelete } =
    useDeleteCredentialPackageCredential(Number(id));
  const { mutate: deleteCredentialPackage } = useDeleteCredentialPackage();

  const toast = useToast();
  const navigate = useNavigate();

  const credentialBg = useColorModeValue("gray.100", "gray.600");

  if (isLoading) return <Spinner />;
  if (isError)
    return (
      <Text>
        Error loading credential package:{" "}
        {error instanceof Error ? error.message : "An error occurred"}
      </Text>
    );

  const handlePublish = () => {
    updateCredentialPackage(
      { id: credentialPackage.id, is_draft: false },
      {
        onSuccess: () => {
          toast({
            title: "Credential package published.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error",
            description: "Failed to publish credential package",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      }
    );
  };

  const actions = credentialPackage.is_draft
    ? [
        <PopoverList
          key="popover"
          items={
            credentialPackages?.results.map((item) => ({
              id: item.id,
              name: item.name,
              url: `/credential_packages/${item.id}`,
              status: statusAndColor(item).status,
            })) || []
          }
          selectedItemId={credentialPackage.id}
          debouncedSearch={debouncedSearch}
          title="Other Credential Packages"
        />,
        <ConfirmationButton
          label="Publish"
          title="Publish Credential Package"
          variant="Button"
          colorScheme="teal"
          isLoading={isLoadingUpdate}
          onConfirm={handlePublish}
          children="Publishing this credential package will make it active and visible to all employees. Are you sure you want to proceed?"
        />,
        <ConfirmationButton
          label="Delete"
          title="Publish Credential Package"
          variant="Button"
          colorScheme="red"
          buttonVariant="outline"
          isLoading={isLoadingDelete}
          onConfirm={() =>
            deleteCredentialPackage(credentialPackage.id, {
              onSuccess: () => {
                navigate("/credentials/hub");
                toast({
                  title: "Credential package deleted.",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              },
            })
          }
          children="Are you sure you want to delete this credential package? This action cannot be undone."
        />,
      ]
    : credentialPackage.is_archived
    ? [
        <ConfirmationButton
          label="Restore"
          leftIcon={<CiUndo />}
          title="Restore Credential Package"
          variant="Button"
          isLoading={isLoadingUpdate}
          onConfirm={() =>
            updateCredentialPackage(
              { id: credentialPackage.id, is_archived: false },
              {
                onSuccess: () => {
                  toast({
                    title: "Credential package restored.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                },
              }
            )
          }
          children="Do you want to restore this credential package?"
        />,
      ]
    : [
        <PopoverList
          key="popover"
          items={
            credentialPackages?.results.map((item) => ({
              id: item.id,
              name: item.name,
              url: `/credential_packages/${item.id}`,
              status: statusAndColor(item).status,
            })) || []
          }
          selectedItemId={credentialPackage.id}
          debouncedSearch={debouncedSearch}
          title="Other Credential Packages"
        />,
        <ConfirmationButton
          label="Archive"
          leftIcon={<FiArchive />}
          title="Archive Credential Package"
          variant="Button"
          colorScheme="red"
          buttonVariant="outline"
          isLoading={isLoading}
          onConfirm={() =>
            updateCredentialPackage(
              { id: credentialPackage.id, is_archived: true },
              {
                onSuccess: () => {
                  navigate("/credentials/hub");
                  toast({
                    title: "Credential package archived.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                },
              }
            )
          }
          children="Do you want to archive this credential package? All users who have not completed the package will be unassigned."
        />,
      ];

  return (
    <Box w="100%">
      {credentialPackage.is_archived && (
        <Alert status="warning" mb={4}>
          <HStack>
            <AlertIcon />
            <VStack align="start" spacing={0}>
              <AlertTitle>Archived Credential Package</AlertTitle>
              <AlertDescription>
                Please restore this credential package to make it available to employees.
              </AlertDescription>
            </VStack>
          </HStack>
        </Alert>
      )}
      <Header
        title={credentialPackage.name}
        crumbs={[{ label: "Credential Packages Hub", url: "/credentials/hub" }]}
        crumbsColor="teal.500"
        badge={{
          label: statusAndColor(credentialPackage).status,
          colorScheme: statusAndColor(credentialPackage).color,
        }}
        actions={actions}
      />
      <VStack mt={8} gap={6} w="100%">
        <VStack
          p={5}
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          align="start"
          spacing={4}
          width="100%">
          <HStack justify="space-between" w="100%">
            <Heading size="md">Associated Credentials</Heading>
            <AddCredentialPackageCredential credentialPackage={credentialPackage} />
          </HStack>
          <Divider />
          {credentialPackage.credential_package_credentials.length > 0 ? (
            <Stack direction="row" spacing={3} wrap="wrap" w="100%">
              {credentialPackage.credential_package_credentials.map((c) => (
                <HStack
                  key={`${c.id} - ${c.credential.name}`}
                  w="max-content"
                  p={2}
                  borderRadius="md"
                  bg={credentialBg}>
                  <Icon as={IoMedalOutline} />
                  <Text fontSize="sm">{c.credential.name}</Text>
                  {credentialPackage.is_draft && (
                    <IconButton
                      size="xs"
                      variant="ghost"
                      aria-label="Remove Credential"
                      icon={<CloseIcon />}
                      onClick={() => deleteCredentialPackageCredential(c.id)}
                    />
                  )}
                </HStack>
              ))}
            </Stack>
          ) : (
            <EmptyState size="2xs" title="No associated credentials" />
          )}
        </VStack>
        <UserCredentialPackageTable credentialPackage={credentialPackage} />
      </VStack>
    </Box>
  );
};
