import React, { useState } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Input,
  VStack,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Text,
  HStack,
  Stack,
  Box,
  Button,
} from "@chakra-ui/react";
import { useNewUserCredential } from "../../../api/user_credentials";
import { useUserOptions } from "../../../api/options";
import { UserCredentialCard } from "../util";
import { DateTime } from "luxon";
import { CredentialData } from "../../../api/credentials";

type NewCredentialModalProps = {
  credential: CredentialData;
  isOpen: boolean;
  onClose: () => void;
  userCredentialUserIds: number[];
};

export const NewUserCredentialModal = ({
  credential,
  isOpen,
  onClose,
  userCredentialUserIds,
}: NewCredentialModalProps) => {
  const { mutate: newUserCredential } = useNewUserCredential(credential.id);
  const [date, setDate] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("unfulfilled");
  const [userIds, setUserIds] = useState<number[]>([]);
  const userOptions = useUserOptions([...userIds, ...userCredentialUserIds]);

  const handleClose = () => {
    setUserIds([]);
    setDate(null);
    setStatus("unfulfilled");
    onClose();
  };

  const handleSubmit = () => {
    const utcDate = date
      ? DateTime.fromISO(date, { zone: "America/Los_Angeles" }).toUTC()
      : DateTime.now();

    newUserCredential(
      {
        user_ids: userIds,
        credential_id: credential.id,
        date_issued: status === "active" ? utcDate : null,
      },
      { onSuccess: () => handleClose() }
    );
  };

  return (
    <FormModal
      title="Add Employees"
      submitButtonColorSchema="teal"
      submitButtonTitle="Done"
      size="2xl"
      submitButtonDisabled={
        userIds.length === 0 ||
        (status === "active" && !date) ||
        (credential.has_training && !credential.trainings.length)
      }
      errorMessage={
        credential.has_training && !credential.trainings.length
          ? "This credential requires training. Please add training to this credential before assigning it to employees."
          : ""
      }
      isOpen={isOpen}
      onClose={handleClose}
      handleSubmit={handleSubmit}>
      <VStack width="100%" align="start" spacing={4}>
        <Stack width="100%" align="start" direction={{ base: "column", md: "row" }}>
          <FormControl>
            <FormLabel>Status to Assign</FormLabel>
            <RadioGroup
              display="flex"
              colorScheme="teal"
              gap={6}
              value={status}
              onChange={(value) => setStatus(value)}>
              <Radio value="active">Active</Radio>
              <Radio value="unfulfilled">Unfulfilled</Radio>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>When did the training happen?</FormLabel>
            <Input
              type="date"
              disabled={status !== "active"}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormControl>
        </Stack>
        <VStack
          align="start"
          width="100%"
          border="1px"
          borderRadius="md"
          borderColor="chakra-border-color"
          p={5}>
          <HStack width="100%">
            <Box width="100%">
              <Select
                placeholder="Search employees"
                options={userOptions}
                onChange={(value) => setUserIds((prev) => [...prev, +value])}
              />
            </Box>
            <Button variant="ghost" size="sm" colorScheme="red" onClick={() => setUserIds([])}>
              Clear all
            </Button>
          </HStack>
          <Text>{userIds.length} Employees Added</Text>
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6} width="100%">
            {userIds.map((id) => (
              <UserCredentialCard userId={id} setUserIds={setUserIds} />
            ))}
          </Grid>
        </VStack>
      </VStack>
    </FormModal>
  );
};
