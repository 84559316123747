import React from "react";
import {
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  Box,
  LinkOverlay,
  LinkBox,
  useColorModeValue,
  Icon,
  Progress,
  Flex,
  Badge,
} from "@chakra-ui/react";
import { IoMedalOutline } from "react-icons/io5";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { Link } from "react-router-dom";
import { TimeIcon } from "@chakra-ui/icons";
import { credentialColorScheme } from "../util";
import { UserCredentialShowData } from "../../../api/user_credentials";

export const CredentialCard = ({ userCredential }: { userCredential: UserCredentialShowData }) => {
  return (
    <LinkBox
      borderRadius="lg"
      bg={credentialColorScheme(userCredential.status).background}
      _hover={{ bg: credentialColorScheme(userCredential.status).hover }}
      width="100%"
      p={4}>
      <LinkOverlay as={Link} to={`/credentials/me/${userCredential.id}`}>
        <HStack align="start" spacing={4} width="100%">
          <VStack spacing={2} width="100%" align="start">
            <Box
              aspectRatio={1}
              bg={credentialColorScheme(userCredential.status).icon}
              borderRadius="md"
              mb={4}>
              <Flex align="center" justify="center" h="100%" p={2}>
                <Icon as={IoMedalOutline} color={useColorModeValue("white", "black")} />
              </Flex>
            </Box>
            <HStack justify="space-between" width="100%">
              <Heading size="sm" fontWeight="bold">
                {userCredential.credential.name}
              </Heading>
            </HStack>

            {userCredential.days_until_expiration && userCredential.days_until_expiration <= 0 ? (
              <>
                <HStack width="100%">
                  <Badge
                    colorScheme="red"
                    borderRadius="md"
                    fontWeight="medium"
                    textTransform="none">
                    Expired
                  </Badge>
                </HStack>
              </>
            ) : userCredential.expiration_date ? (
              <>
                <HStack width="100%">
                  <Icon
                    as={TimeIcon}
                    boxSize={3}
                    color={credentialColorScheme(userCredential.status).text}
                  />

                  <Text
                    fontSize="xs"
                    color={credentialColorScheme(userCredential.status).text}
                    w="max-content">
                    Expires in:{" "}
                    <Box as="span" fontWeight="medium">
                      {userCredential.days_until_expiration} Days
                    </Box>
                  </Text>
                  <Progress
                    borderRadius="md"
                    colorScheme={credentialColorScheme(userCredential.status).bar}
                    size="sm"
                    value={userCredential.expiration_percentage}
                    width={20}
                  />
                </HStack>
              </>
            ) : (
              <HStack width="100%">
                <Icon
                  as={TimeIcon}
                  boxSize={3}
                  color={credentialColorScheme(userCredential.status).text}
                />
                <Text fontSize="xs" color={credentialColorScheme(userCredential.status).text}>
                  Does not expire
                </Text>
              </HStack>
            )}
          </VStack>
          <ChevronRightIcon alignSelf="center" boxSize={5} color="gray.500" />
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};
