import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Button,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { useCreateBudgetItemQuery } from "../../../../api/budget_items";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { useCurrentUserQuery } from "../../../../api/user";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { set } from "zod";

export const AddExpenditureModal = ({
  isOpen,
  onClose,
  budgetId,
}: {
  isOpen: boolean;
  onClose: () => void;
  budgetId: number;
}) => {
  const currency = useCurrency();
  const { data: currentUser } = useCurrentUserQuery();
  const [expenseName, setExpenseName] = useState("");
  const [page, setPage] = useState(0);

  const { mutateAsync: newBudgetItem, isLoading: isCreatingNewBudgetItem } =
    useCreateBudgetItemQuery(budgetId);

  const handleClose = () => {
    setExpenseName("");
    setPage(0);
    onClose();
  };

  const handleNext = () => {
    if (page === 0) {
      if (currentUser) {
        newBudgetItem({
          budget_id: budgetId,
          item_name: expenseName,
          requested_by_id: currentUser.id,
        }).then(() => setPage(1));
      }
    } else {
      handleClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        {page === 0 ? (
          <ModalHeader>Add New Expenditure Item</ModalHeader>
        ) : (
          <ModalHeader>Success!</ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody>
          {page === 0 ? (
            <FormControl isRequired>
              <FormLabel>Item Name</FormLabel>
              <Input
                placeholder={"Item name"}
                value={expenseName}
                onChange={(e) => setExpenseName(e.target.value)}
              />
              <FormErrorMessage>Item name is required</FormErrorMessage>
            </FormControl>
          ) : (
            <VStack w={"100%"}>
              <CheckCircleIcon color="teal" boxSize={16} mb={10} />
              <Text>
                The expenditure item was created and is pending review from the Finance Admins. You
                will be notified of their response.
              </Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            isDisabled={!expenseName}
            onClick={handleNext}
            isLoading={isCreatingNewBudgetItem}>
            {page === 0 ? "Submit" : "Okay"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
