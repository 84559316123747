export type BarChartData = {
  x?: number | string;
  y?: number;
};

export const candidateOverall = (feedback) => {
  const candidateOverallScore: BarChartData[] = [];

  const scoreMap: { [key: number]: number } = { "1": 0, "2": 0, "3": 0, "4": 0 };
  feedback.forEach((fb) => {
    scoreMap[fb.overall_score.toString()]++;
  });

  for (const key in scoreMap) {
    const obj: BarChartData = {};
    obj.x = Number(key);
    obj.y = scoreMap[key];
    candidateOverallScore.push(obj);
  }
  return candidateOverallScore;
};

export const candidateRoundUp = (feedback) => {
  const candidateRoundUpScore: BarChartData[] = [];
  const dataScoreMap: { [key: string]: number } = {
    technical_score: 0,
    culture_score: 0,
    mission_score: 0,
    communication_score: 0,
  };

  feedback.forEach((fb, idx) => {
    for (const key in dataScoreMap) {
      dataScoreMap[key] += fb[key];
    }
    if (idx === feedback.length - 1) {
      for (const key in dataScoreMap) {
        dataScoreMap[key] /= feedback.length;
      }
    }
  });

  for (const key in dataScoreMap) {
    const obj: BarChartData = {};
    obj.x = key.split("_")[0];
    obj.y = dataScoreMap[key];
    candidateRoundUpScore.push(obj);
  }
  return candidateRoundUpScore;
};

export const radioSections = {
  "Based on your individual experience with the candidate, how interested are you in extending an offer? (Reminder: please look at the candidate's assessment answers before completing this form)":
    ["Not Interested", "Interested", "overall_score"],
  "Rate the candidate on their technical ability to complete the job for which they applied.": [
    "Low Technical Ability",
    "Very High Technical Ability",
    "technical_score",
  ],
  "Rate the candidate on their culture fit with Science": [
    "Poor Culture Fit",
    "Excellent Culture Fit",
    "culture_score",
  ],
  "How mission driven is this candidate?": [
    "Not Mission Driven",
    "Extremely Mission Driven",
    "mission_score",
  ],
  "Rate the candidate on their clarity of thought and communication": [
    "Unclear",
    "Extremely Clear",
    "communication_score",
  ],
};

export const textSections = {
  "What were 1-3 key things that lead to your rating above?": "overall_feedback",
  "Candidate Strengths.": "strengths",
  "Candidate opportunities for growth or areas of concern.": "concerns",
};

export const CANDIDATE_DECISION_MAP = {
  rejected: ["Rejected", "red"],
  references: ["References", "yellow"],
  manager_approved: ["Manager Approved", "purple"],
  manager_rejected: ["Manager Rejected", "red"],
  request_to_hire: ["Request to Hire", "orange"],
  finance_approver_approved: ["Finance Approved", "teal"],
  finance_approver_rejected: ["Finance Rejected", "red"],
  ceo_approved: ["CEO Approved", "blue"],
  ceo_rejected: ["CEO Rejected", "red"],
  offer_accepted: ["Offer Accepted", "green"],
  offer_declined: ["Offer Declined", "red"],
};
