import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { invalidateCapitalEquipments } from "./capital_equipment";
import { SearchParams, searchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { createSearchResponseSchema } from "./shared";

export const CAPITAL_EQUIPMENT_PART_BASE_URL = "/capital_equipment_parts";

export const capitalEquipmentPartSchema = z.object({
  id: z.number(),
  capital_equipment_id: z.number(),
  part_name: z.string(),
  manufacturer_part_number: z.string().nullable(),
  serial_number: z.string().nullable(),
});

export type CapitalEquipmenPartData = z.infer<typeof capitalEquipmentPartSchema>;
export type CapitalEquipmentPartCreateParams = Omit<CapitalEquipmenPartData, "id">;

export const createCapitalEquipmentPart = async (
  capital_equipment_part: CapitalEquipmentPartCreateParams
) => {
  const result = await api.post(CAPITAL_EQUIPMENT_PART_BASE_URL, { capital_equipment_part });
  return zodParse(capitalEquipmentPartSchema.pick({ id: true }), result.data);
};

export const deleteCapitalEquipmentPart = async (id: number) => {
  await api.delete(`${CAPITAL_EQUIPMENT_PART_BASE_URL}/${id}`);
  return id;
};

const searchCapitalEquipmentParts = async ({ aggs, bodyOptions, filters, term }: SearchParams) => {
  const path = [CAPITAL_EQUIPMENT_PART_BASE_URL, "search"];
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...search });

  return zodParse(createSearchResponseSchema(capitalEquipmentPartSchema), result.data);
};

/** hooks */

export const invalidateCapitalEquipmentParts = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [CAPITAL_EQUIPMENT_PART_BASE_URL];
  if (id) queryKey.push(id);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useSearchCapitalEquipmentParts = (params: SearchParams) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENT_PART_BASE_URL, params],
    queryFn: () => searchCapitalEquipmentParts(params),
  });
};

export const useCreateCapitalEquipmentPart = () => {
  return useMutation({
    mutationFn: createCapitalEquipmentPart,
    onSuccess: invalidateCapitalEquipmentParts(),
  });
};

export const useDeleteCapitalEquipmentPart = () => {
  return useMutation({
    mutationFn: deleteCapitalEquipmentPart,
    onSuccess: invalidateCapitalEquipmentParts(),
  });
};
