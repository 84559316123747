import { Box, HStack, Heading } from "@chakra-ui/react";
import React from "react";
import { useDeleteFile } from "../../../../api/blob_files";
import {
  CapitalEquipmentShowData,
  invalidateCapitalEquipments,
} from "../../../../api/capital_equipment";
import { InventoryShowData, invalidateInventories } from "../../../../api/inventory";
import { BlobUploadButton } from "../../../shared/BlobUploadButton";
import { DeleteableFileDownload } from "../../../shared/DeleteableFileDownload";

export type CapitalEquipmentInfoProps = {
  data: CapitalEquipmentShowData | InventoryShowData;
  type: "capital_equipment" | "inventory";
  isManager?: boolean;
  isDisabled?: boolean;
};

export const Attachments = ({ data, isManager, type, isDisabled }: CapitalEquipmentInfoProps) => {
  const invalidations = (type: string) => {
    if (type === "capital_equipment") {
      return invalidateCapitalEquipments(data.id);
    } else {
      return invalidateInventories(data.id);
    }
  };
  const { mutate: deleteFile } = useDeleteFile(() => invalidations(type));
  const recordType = type === "capital_equipment" ? "CapitalEquipment" : "Inventory";
  return (
    <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6} height={"100%"}>
      <Heading size="md" mb={4} fontWeight="medium">
        Attachments
      </Heading>
      <HStack spacing={2} flexWrap="wrap">
        {data.uploaded_files.map((file) => (
          <DeleteableFileDownload
            key={`${file.id}-${file.filename}`}
            file={file}
            deleteFile={deleteFile}
            isDisabled={isDisabled}
          />
        ))}
        {isManager && !isDisabled && (
          <BlobUploadButton
            fileableColumn="uploaded_files"
            recordId={data.id}
            recordType={recordType}
            onSuccessCallback={() => invalidations(type)}
          />
        )}
      </HStack>
    </Box>
  );
};
