import { Tag } from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import React from "react";
import { useSearchPurchases } from "../../../../../../api/purchase";
import { MoneyText } from "../../../../../MoneyText";
import { purchaseRequestStatusColor } from "../../../../../Purchasing/util";
import { buildTabComponent } from "../../../../../TabbedTable";

export const PurchaseTab = buildTabComponent({
  label: "Purchases",
  columns: [
    {
      label: "Purchase",
      orderOptions: { orderKey: "id" },
      render: (purchase) => (
        <RecordLink
          type=""
          identifier={`#${purchase.id}`}
          link={`/services/purchasing/${purchase.id}`}
        />
      ),
    },
    {
      label: "Vendor",
      orderOptions: { orderKey: "vendor_name" },
      render: (purchase) => (
        <RecordLink
          type=""
          maxWidth="15ch"
          identifier={purchase.vendor?.name || ""}
          link={`/services/vendors/${purchase.vendor?.id}`}
        />
      ),
    },
    {
      label: "Amount Paid",
      orderOptions: { orderKey: "amount_cents" },
      render: (purchase) => (
        <MoneyText money={purchase.purchase_payments_paid} formatOptions={{ compact: "never" }} />
      ),
    },
    {
      label: "Cost",
      orderOptions: { orderKey: "amount_cents" },
      render: (purchase) => (
        <MoneyText money={purchase.amount} formatOptions={{ compact: "never" }} />
      ),
    },
    {
      label: "Status",
      orderOptions: { orderKey: "status" },
      render: (purchase) => (
        <Tag colorScheme={purchaseRequestStatusColor(purchase.status)} whiteSpace="nowrap">
          {titleize(humanize(purchase.status))}
        </Tag>
      ),
    },
  ],
  query: useSearchPurchases,
});
