import React from "react";
import { FormModal } from "@sciencecorp/helix-components";
import { Input, FormControl, FormLabel, Flex, Box, HStack } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useCreateCapitalEquipmentPart } from "../../../../api/capital_equipment_part";

type NewPartModalProps = {
  capitalEquipmentId: number;
  isOpen: boolean;
  onClose: () => void;
};

type CapitalEquipmentPartFormValuesType = {
  partName: string;
  manufacturerPartNumber: string;
  serialNumber: string;
};

const defaultFormValues: CapitalEquipmentPartFormValuesType = {
  partName: "",
  manufacturerPartNumber: "",
  serialNumber: "",
};
export const NewCapitalEquipmentPartModal = ({
  capitalEquipmentId,
  isOpen,
  onClose,
}: NewPartModalProps) => {
  const { control, handleSubmit, reset, watch } = useForm({ defaultValues: defaultFormValues });
  const { mutateAsync: createCapitalEquipmentPart, isLoading: isLoadingCreatePart } =
    useCreateCapitalEquipmentPart();

  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };
  const onSubmit = (data: CapitalEquipmentPartFormValuesType) => {
    createCapitalEquipmentPart({
      capital_equipment_id: capitalEquipmentId,
      part_name: data.partName,
      manufacturer_part_number: data.manufacturerPartNumber,
      serial_number: data.serialNumber,
    });
    handleClose();
  };

  return (
    <FormModal
      size={"xl"}
      title="New Part"
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      submitButtonDisabled={!formValues.partName}
      isLoading={isLoadingCreatePart}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="partName"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Part Name</FormLabel>
              <Input {...field} />
            </FormControl>
          )}
        />
        <HStack width={"100%"}>
          <Box width={"50%"}>
            <Controller
              name="manufacturerPartNumber"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Manufacturer Part Name</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            />
          </Box>
          <Box width={"50%"}>
            <Controller
              name="serialNumber"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Serial Number</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            />
          </Box>
        </HStack>
      </Flex>
    </FormModal>
  );
};
