import React, { useMemo, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  useDisclosure,
  StatGroup,
  Stat,
  StatNumber,
  StatLabel,
  useColorModeValue,
  HStack,
  Link,
  Stack,
  Box,
  Tag,
} from "@chakra-ui/react";
import { useCollection, Collection, RecordLink } from "@sciencecorp/helix-components";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CredentialData } from "../../../api/credentials";
import { useUpdateCredential, useDeleteCredential } from "../../../api/credentials";
import { NewCredentialModal } from "./NewCredentialModal";
import { Link as RouterLink } from "react-router-dom";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

export const AllCredentials = ({ data, isLoading, onPagination, pagination }) => {
  const { onOrder, order } = useCollection();
  const [activeCredential, setActiveCredential] = useState<CredentialData | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        label: "Name",
        orderOptions: { orderKey: "name" },
        render: (credential: CredentialData) => (
          <RecordLink
            type=""
            identifier={credential.name}
            link={`/credentials/all/${credential.id}`}
          />
        ),
      },
      {
        label: "Status",
        render: (credential: CredentialData) => (
          <Tag colorScheme={credential.is_draft ? "orange" : "green"}>
            {credential.is_draft ? "Draft" : "Active"}
          </Tag>
        ),
      },
      {
        label: "Visibility",
        render: (credential: CredentialData) => (credential.is_public ? "Public" : "Private"),
      },
      {
        label: "Expires",
        orderOptions: { orderKey: "expiration_months" },
        render: (credential: CredentialData) =>
          credential.expiration_months ? `${credential?.expiration_months} Months` : "N/A",
      },
      {
        label: "Assigned Employees",
        render: (credential: CredentialData) => credential.assigned_users,
      },
      {
        label: "",
        render: (credential: CredentialData) => (
          <ActionsMenu
            credential={credential}
            setActiveCredential={setActiveCredential}
            onOpen={onOpen}
          />
        ),
      },
    ],
    [data]
  );

  return (
    <Box width="100%">
      <Stack
        width="100%"
        justify="space-between"
        spacing={6}
        mb={8}
        direction={{ base: "column", md: "row" }}>
        <StatGroup
          flex="3"
          flexDir={{ base: "column", md: "row" }}
          py={3}
          px={6}
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md">
          <Stat>
            <StatLabel>No. of Credentials</StatLabel>
            <StatNumber>{data?.total}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Credentials with No Assignees</StatLabel>
            <StatNumber>{data?.unassigned_credentials}</StatNumber>
          </Stat>
        </StatGroup>
        <HStack
          py={3}
          px={6}
          flex="1"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Stat>
            <StatLabel>Archived Credentials</StatLabel>
            <StatNumber>{data?.archived_credentials}</StatNumber>
          </Stat>
          <Link as={RouterLink} to="/credentials/archive/credentials" color="teal.500">
            View
          </Link>
        </HStack>
      </Stack>
      <Collection
        columns={columns}
        isLoading={isLoading}
        items={data?.results || []}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        onOrder={onOrder}
        order={order}
      />

      {activeCredential && (
        <NewCredentialModal
          key={activeCredential.id}
          isOpen={isOpen}
          onClose={onClose}
          credential={activeCredential}
        />
      )}
    </Box>
  );
};

export const ActionsMenu = ({ credential, setActiveCredential, onOpen }) => {
  const { mutate: updateCredential } = useUpdateCredential();
  const { mutate: deleteCredential } = useDeleteCredential();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          gap={3}
          onClick={() => {
            setActiveCredential(credential);
            onOpen();
          }}>
          <EditIcon />
          Edit
        </MenuItem>
        {credential.assigned_users > 0 ? (
          <MenuItem
            gap={3}
            color="red.500"
            onClick={() => updateCredential({ id: credential.id, is_archived: true })}>
            <DeleteIcon />
            Archive
          </MenuItem>
        ) : (
          <MenuItem gap={3} color="red.500" onClick={() => deleteCredential(credential.id)}>
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
