import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { Money, moneySchema } from "../helpers/Money";
import { blobFileSchema } from "./blob_files";
import { invalidateReimbursement } from "./reimbursement";
import { zodParse } from "./zodParse";

export const REIMBURSEMENT_LINE_ITEMS_BASE_URL = "reimbursement_line_items";

export const reimbursementLineItemSchema = z.object({
  id: z.number(),
  reimbursement_id: z.number(),
  name: z.string(),
  location: z.string(),
  description: z.string(),
  expense_date: z.string(),
  amount: moneySchema,
});

export const reimbursementLineItemShowSchema = reimbursementLineItemSchema
  .omit({
    reimbursement_id: true,
  })
  .extend({
    uploaded_files: z.array(blobFileSchema),
  });

export type ReimbursementLineItemData = z.infer<typeof reimbursementLineItemSchema>;
export type ReimbursementLineItemShowData = z.infer<typeof reimbursementLineItemShowSchema>;

export type ReimbursementLineItemCreateParams = Pick<
  ReimbursementLineItemData,
  "reimbursement_id"
> & {
  name: string;
  location: string;
  description: string;
  expense_date: string;
  amount: Money;
  files: { name: string; file: string }[];
};

export const createReimbursementLineItem = async (
  reimbursement_line_item: ReimbursementLineItemCreateParams
): Promise<Pick<ReimbursementLineItemData, "id">> => {
  const result = await api.post(REIMBURSEMENT_LINE_ITEMS_BASE_URL, { reimbursement_line_item });
  return zodParse(reimbursementLineItemShowSchema.pick({ id: true }), result.data);
};

export const updateReimbursementLineItem = async (
  reimbursement_line_item: Partial<ReimbursementLineItemData> &
    Pick<ReimbursementLineItemData, "id">
) => {
  const result = await api.put(
    `${REIMBURSEMENT_LINE_ITEMS_BASE_URL}/${reimbursement_line_item.id}`,
    {
      reimbursement_line_item,
    }
  );
  return result.data;
};

export const deleteReimbursementLineItem = async (id: number) => {
  await api.delete(`${REIMBURSEMENT_LINE_ITEMS_BASE_URL}/${id}`);
};

export const useCreateReimbursementLineItem = (reimbursementId: number) => {
  return useMutation({
    mutationFn: createReimbursementLineItem,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};

export const useUpdateReimbursementLineItem = (reimbursementId: number) => {
  return useMutation({
    mutationFn: updateReimbursementLineItem,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};

export const useDeleteReimbursementLineItem = (reimbursementId: number) => {
  return useMutation({
    mutationFn: deleteReimbursementLineItem,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};
