import { Flex, Text, Heading, useToast } from "@chakra-ui/react";
import { EditableText } from "@sciencecorp/helix-components";
import React from "react";
import { InventoryBomData, useUpdateInventoryBom } from "../../../../api/inventory_boms";
import { useCurrentUserQuery } from "../../../../api/user";

type DescriptionBoxProps = {
  description: string;
  bom: InventoryBomData;
};

export const DescriptionBox = ({ description, bom }: DescriptionBoxProps) => {
  const { mutate: updateInventoryBom, isLoading } = useUpdateInventoryBom(bom?.id);
  const { data: user } = useCurrentUserQuery();
  const toast = useToast();

  const isEditable = bom.inventory_bom_users
    ? bom.inventory_bom_users.some((user) => user.user.id === user?.id) || bom.user_id === user?.id
    : false;

  return (
    <Flex
      border="1px solid"
      borderColor="chakra-border-color"
      borderRadius="md"
      p={4}
      width="100%"
      direction="column"
      gap={4}>
      <Text fontSize="lg" fontWeight="bold">
        Description
      </Text>
      <EditableText
        multiline
        defaultValue={description}
        isLoading={isLoading}
        disabled={!isEditable}
        onSubmit={(value) => {
          if (value) {
            updateInventoryBom({ description: value, id: bom.id });
          } else {
            toast({
              title: "Description cannot be empty",
              status: "error",
            });
          }
        }}
      />
    </Flex>
  );
};
