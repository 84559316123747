import { Select } from "@sciencecorp/helix-components";
import _ from "lodash";
import { forwardRef, default as React } from "react";
import { supportedCurrencies } from "../helpers/Money";
import { Box } from "@chakra-ui/react";

export interface CurrencySelectProps {
  value?: string | null;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  placeholder?: string;
  excludedCurrencies?: string[];
  includeOriginal?: boolean;
}

export const CurrencySelect: React.FC<CurrencySelectProps> = forwardRef(
  (
    {
      value,
      onChange,
      isDisabled,
      excludedCurrencies = [],
      placeholder = "Select a currency",
      includeOriginal = false,
    },
    ref
  ) => {
    const options = _.values(supportedCurrencies)
      .map((currency) => ({
        label: `${currency.code} (${currency.symbol})`,
        value: currency.code,
      }))
      .filter((currency) => !excludedCurrencies?.includes(currency.value));

    return (
      <Box data-testid="currency-select-container">
        <Select
          isDisabled={isDisabled || false}
          placeholder={placeholder}
          options={
            includeOriginal ? [...options, { label: "Original", value: "original" }] : options
          }
          value={value}
          onChange={(value) => {
            // This should only ever be a string
            if (value && typeof value === "string") {
              onChange(value);
            }
          }}
        />
      </Box>
    );
  }
);
