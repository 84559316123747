import _ from "lodash";
import React from "react";
import {
  Text,
  Button,
  VStack,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useCurrentUserQuery } from "../../../../api/user";
import { useNavigate } from "react-router";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { SpendingAuthoritySelect } from "../../SpendingAuthoritySelectTree";
import { useNewReimbursement } from "../../../../api/reimbursement";
import { MinimalSpendingAuthority } from "../../../../api/spending_authority";
import { CurrencySelect } from "../../../CurrencySelect";

const defaultFormValues: FormValuesType = {
  reimbursement_type: "",
  submitted_at: "",
  approval_status: "draft",
  spending_authority: null,
  currency: "USD",
};

type FormValuesType = {
  reimbursement_type: string;
  submitted_at: string;
  approval_status: string;
  spending_authority: MinimalSpendingAuthority | null;
  currency: string;
};

type FormValuesConversion = {
  reimbursement_type: string;
  submitted_at: string;
  approval_status: string;
  spending_authority_id: number | undefined;
  spending_authority_type: string | undefined;
  currency: string;
};

type NewReimbusemenetModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewReimbursementModal = ({ isOpen, onClose }: NewReimbusemenetModalProps) => {
  const { mutate: newReimbursement, isLoading: isNewLoading } = useNewReimbursement();
  const currentUser = useCurrentUserQuery();

  const navigate = useNavigate();

  const { handleSubmit, setValue, control, reset, watch, formState, trigger } =
    useForm<FormValuesType>({ mode: "onChange", defaultValues: defaultFormValues });

  const formValues = watch();

  const handleFormValidation = () => {
    return formValues.reimbursement_type && formValues.spending_authority ? true : false;
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const submitFunction = (reimbursementFormData: FormValuesConversion) => {
    currentUser.data &&
      newReimbursement(
        {
          ...reimbursementFormData,
          approval_status: "draft",
          user_id: currentUser.data.id,
        },
        {
          onSuccess: (data) => {
            handleClose();
            navigate(`/services/reimbursements/${data}`);
          },
        }
      );
  };

  const onSubmit = (data: FormValuesType) => {
    const { spending_authority, ...restData } = data;

    const reimbursementData: FormValuesConversion = {
      ...restData,
      spending_authority_id: spending_authority?.id,
      spending_authority_type: spending_authority?.type,
    };
    submitFunction(reimbursementData);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={"3xl"} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={6}>
          <VStack width="100%" align="start">
            <Text>Reimbursement Request</Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody justifyContent="center">
          <VStack gap={4} width="100%" align="start">
            <FormControl isRequired>
              <FormLabel>Reimbursement Type</FormLabel>
              <RadioGroup
                data-testid="reimbursement-type-radio-group"
                onChange={(value) => setValue("reimbursement_type", value)}
                value={formValues.reimbursement_type}
                colorScheme={"teal"}>
                <Stack direction={["column", "row"]} align="start" gap={4}>
                  <Radio value="general">General Reimbursement</Radio>
                  <Radio value="mileage">Mileage Reimbursement</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <Controller
              name="spending_authority"
              control={control}
              rules={{ required: true }}
              render={() => (
                <FormControl isRequired>
                  <FormLabel>Spending Authority</FormLabel>
                  <SpendingAuthoritySelect
                    onChange={(value) => {
                      if (value) {
                        setValue("spending_authority", value);
                      }
                    }}
                    spendingAuthority={formValues.spending_authority}
                  />
                </FormControl>
              )}
            />
            <Controller
              name="currency"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>Currency</FormLabel>
                  <CurrencySelect {...field} />
                </FormControl>
              )}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup gap={3}>
            <Button
              onClick={() => handleSubmit(onSubmit)()}
              data-testid="new-reimbursement-submit-button"
              colorScheme="teal"
              isLoading={isNewLoading}
              isDisabled={!handleFormValidation()}>
              Submit
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
