import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { createPaginatedResponseSchema, paginationSchema } from "./shared";
import { invalidateTraining } from "./trainings";
import { zodParse } from "./zodParse";
import { userMinimalSchema } from "./user";
import { dateTimeSchema } from "../helpers/dateTime";

export const TRAINING_SESSIONS_BASE_URL = "training_sessions";

export const trainingSessionSchema = z.object({
  id: z.number(),
  training_id: z.number(),
  cutoff_score: z.number().nullable(),
  facilitator_id: z.number(),
  started_at: dateTimeSchema.nullable(),
  ended_at: dateTimeSchema.nullable(),
  scheduled_date: dateTimeSchema,
  max_people: z.number().nullable().optional(),
});

export const trainingSessionMinimalSchema = trainingSessionSchema
  .pick({
    id: true,
    scheduled_date: true,
  })
  .extend({
    facilitator: z.lazy(() => userMinimalSchema),
    training: z.object({
      id: z.number(),
      name: z.string(),
    }),
    users: z.lazy(() => z.array(userMinimalSchema)),
  });

export const trainingSessionShowSchema = trainingSessionSchema
  .omit({
    training_id: true,
    facilitator_id: true,
  })
  .extend({
    average_score: z.number().nullable(),
    attendee_count: z.number(),
    spots_remaining: z.number().nullable(),
    training: z.object({
      id: z.number(),
      name: z.string(),
    }),
    facilitator: z.lazy(() => userMinimalSchema),
  });

export const trainingSessionIndexSchema = createPaginatedResponseSchema(trainingSessionShowSchema);

export type TrainingSessionData = z.infer<typeof trainingSessionSchema>;
export type TrainingSessionMinimalData = z.infer<typeof trainingSessionMinimalSchema>;
export type TrainingSessionShowData = z.infer<typeof trainingSessionShowSchema>;
export type TrainingSessionCreateParams = Pick<
  TrainingSessionData,
  "training_id" | "facilitator_id" | "scheduled_date" | "max_people"
>;

// api queries

export const getTrainingSessions = async () => {
  const result = await api.get(TRAINING_SESSIONS_BASE_URL);
  return zodParse(z.array(trainingSessionMinimalSchema), result.data);
};

export const getMyTrainingSessions = async () => {
  const result = await api.get(`${TRAINING_SESSIONS_BASE_URL}/me`);
  return zodParse(z.array(trainingSessionMinimalSchema), result.data);
};

export const getTrainingSession = async (id: number) => {
  const result = await api.get(`${TRAINING_SESSIONS_BASE_URL}/${id}`);
  return zodParse(trainingSessionShowSchema, result.data);
};

export const getAssociatedUserTrainingSessions = async (id: number) => {
  const result = await api.get(`${TRAINING_SESSIONS_BASE_URL}/${id}/users`);
  return result.data;
};

export const newTrainingSession = async (training_session: TrainingSessionCreateParams) => {
  const result = await api.post(TRAINING_SESSIONS_BASE_URL, { training_session });
  return result.data;
};

export const updateTrainingSession = async (training_session: Partial<TrainingSessionData>) => {
  const result = await api.put(`${TRAINING_SESSIONS_BASE_URL}/${training_session.id}`, {
    training_session,
  });
  return result.data;
};

export const deleteTrainingSession = async (id: number) => {
  await api.delete(`${TRAINING_SESSIONS_BASE_URL}/${id}`);
};

export const deleteOptionalUserTrainingSessions = async (id: number) => {
  await api.delete(`${TRAINING_SESSIONS_BASE_URL}/${id}/delete_optional_users`);
};

// query hooks

export const invalidateTrainingSession = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: id ? [TRAINING_SESSIONS_BASE_URL, id] : [TRAINING_SESSIONS_BASE_URL],
    });
};

export const useGetTrainingSessions = () => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL],
    queryFn: () => getTrainingSessions(),
  });
};

export const useGetMyTrainingSessions = () => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL, "me"],
    queryFn: () => getMyTrainingSessions(),
  });
};

export const useGetTrainingSession = (id: number) => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL, id],
    queryFn: () => getTrainingSession(id),
  });
};

export const useGetAssociatedUserTrainingSessions = (id: number) => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL, id, "user"],
    queryFn: () => getAssociatedUserTrainingSessions(id),
  });
};

export const useNewTrainingSession = () => {
  return useMutation({
    mutationFn: newTrainingSession,
    onSuccess: invalidateTraining(),
  });
};

export const useUpdateTrainingSession = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updateTrainingSession,
    onSuccess: onSuccessCallback(),
  });
};

export const useDeleteTrainingSession = (trainingId: number) => {
  return useMutation({
    mutationFn: deleteTrainingSession,
    onSuccess: invalidateTraining(trainingId),
  });
};

export const useDeleteOptionalUserTrainingSessions = (id: number) => {
  return useMutation({
    mutationFn: deleteOptionalUserTrainingSessions,
    onSuccess: invalidateTrainingSession(id),
  });
};
