import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { userMinimalSchema } from "./user";
import { invalidateCapitalEquipments } from "./capital_equipment";
import { zodParse } from "./zodParse";

export const CAPITAL_EQUIPMENT_USER_BASE_URL = "/capital_equipment_users";

export const capitalEquipmentUserSchema = z.object({
  id: z.number(),
  capital_equipment_id: z.number(),
  user: userMinimalSchema,
});

export const capitalEquipmentUserCreateSchema = z.object({
  capital_equipment_id: z.number(),
  user_id: z.number(),
});

export const capitalEquipmentUserBulkCreateSchema = z.object({
  capital_equipment_id: z.number(),
  user_ids: z.array(z.number()),
});

export type CapitalEquipmentUserData = z.infer<typeof capitalEquipmentUserSchema>;
export type CapitalEquipmentUserCreateParams = z.infer<typeof capitalEquipmentUserCreateSchema>;
export type CapitalEquipmentUserBulkCreateParams = z.infer<
  typeof capitalEquipmentUserBulkCreateSchema
>;
export const createCapitalEquipmentUser = async (
  capital_equipment_user: CapitalEquipmentUserCreateParams
) => {
  const result = await api.post(CAPITAL_EQUIPMENT_USER_BASE_URL, { capital_equipment_user });
  return zodParse(capitalEquipmentUserSchema.pick({ id: true }), result.data);
};

export const bulkCreateCapitalEquipmentUser = async (
  capital_equipment_users: CapitalEquipmentUserBulkCreateParams
) => {
  const result = await api.post(`${CAPITAL_EQUIPMENT_USER_BASE_URL}/bulk`, {
    capital_equipment_users,
  });
  return result.status === 201;
};

export const deleteCapitalEquipmentUser = async (id: number) => {
  await api.delete(`${CAPITAL_EQUIPMENT_USER_BASE_URL}/${id}`);
};

/** hooks */

export const useCreateCapitalEquipmentUser = (id?: number) => {
  return useMutation({
    mutationFn: createCapitalEquipmentUser,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useBulkCreateCapitalEquipmentUser = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateCapitalEquipmentUser,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useDeleteCapitalEquipmentUser = (id?: number) => {
  return useMutation({
    mutationFn: deleteCapitalEquipmentUser,
    onSuccess: invalidateCapitalEquipments(id),
  });
};
