import { z } from "zod";

import * as ct from "./collection_types";

// These types and schemas have been deprecated. Use the types from collection_types.ts directly instead.
export const orderDirectionSchema = ct.orderDirectionSchema;
export const orderSchema = ct.orderSchema;
export const paginationSchema = ct.paginationSchema;
export const aggregationResultSchema = z
  .record(z.string(), ct.aggregationSchema)
  .nullable()
  .optional();

export type IndexParams = ct.Pagination;
export type PaginationData = ct.PaginationData;
export type CtIndexParams = ct.IndexParams;
export type OrderDirection = ct.OrderDirection;
export type Order = ct.Order;
export type AggregationResultData = z.infer<typeof aggregationResultSchema>;

export type InferredZodType<T extends z.ZodTypeAny> = z.infer<T>;

export function createPaginatedResponseSchema<ItemType extends z.ZodTypeAny>(
  resultSchema: ItemType
) {
  return z.object({
    pagination: ct.paginationSchema,
    results: z.array(resultSchema),
  });
}

export function createSearchResponseSchema<ItemType extends z.ZodTypeAny>(resultSchema: ItemType) {
  return z.object({
    aggregations: aggregationResultSchema,
    pagination: ct.paginationSchema,
    results: z.array(resultSchema),
  });
}

export type SearchResponse<T> = {
  aggregations?: AggregationResultData;
  pagination: PaginationData;
  results: T[];
};
