import React, { useMemo, useState } from "react";
import {
  buildFacets,
  CollectionPage,
  Column,
  ConfirmationModal,
  Header,
  RecordLink,
  useCollection,
} from "@sciencecorp/helix-components";
import {
  Box,
  Text,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  MenuButton,
  Menu,
  IconButton,
  MenuList,
  MenuItem,
  HStack,
  Tag,
  Tooltip,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { FaEllipsisV } from "react-icons/fa";
import { RepeatIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  InventoryBomShow,
  useSearchInventoryBoms,
  useUpdateInventoryBom,
} from "../../../../api/inventory_boms";
import { MoneyText } from "../../../MoneyText";

export const ArchivedBoms = () => {
  const { pagination, order, onPagination, onOrder, facets, onFacets, filters } = useCollection();
  const [currentBomId, setCurrentBomId] = useState<number | null>(null);
  const { search, debouncedSearch } = useDebouncedSearch();
  const BOM_AGGREGATIONS_QUERY = ["owner", "status"];
  const toast = useToast();
  const { data, isLoading, isSuccess } = useSearchInventoryBoms({
    term: search || "*",
    filters: { ...filters, status: "archived" },
    aggs: BOM_AGGREGATIONS_QUERY,
    pagination,
    order,
  });
  const navigate = useNavigate();

  const { mutateAsync: updateBom, isLoading: isLoadingUpdateBom } = useUpdateInventoryBom(
    Number(currentBomId)
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const handleRestoreClick = () => {
    if (!currentBomId) return;
    updateBom({ status: "active", id: currentBomId }).then(() => {
      toast({
        title: "BOM restored",
        status: "success",
        duration: 3000,
      });
      navigate("/inventory/boms");
    });
  };

  const columns: Column<InventoryBomShow>[] = useMemo(
    () => [
      {
        label: "Bom Name",
        render: (bom) => (
          <HStack alignItems="center">
            <RecordLink
              link={`/inventory/boms/${bom.id}`}
              colorScheme={bom.component_out_of_stock ? "orange" : "gray"}
              identifier={bom.name}
              icon={bom.component_out_of_stock ? <WarningTwoIcon /> : null}
              iconPlacement="left"
            />
          </HStack>
        ),
      },
      {
        label: "Components Needed",
        render: (bom) => (
          <HStack alignItems="center">
            <Text>{bom.inventory_bom_components_length || "-"}</Text>
            {bom.component_out_of_stock ? (
              <Tooltip label="At least one component is out of stock">
                <Tag size="sm" colorScheme="orange" marginLeft={2}>
                  Out of Stock
                </Tag>
              </Tooltip>
            ) : null}
          </HStack>
        ),
      },
      {
        label: "Total Estimated Cost",
        render: (bom) =>
          bom.total_estimated_cost ? (
            <MoneyText
              money={bom.total_estimated_cost}
              formatOptions={{ compact: "never" }}
              as="span"
              fontWeight="semibold"
              color={useColorModeValue("gray.800", "gray.300")}
            />
          ) : (
            <Text>-</Text>
          ),
      },
      {
        label: "Owner",
        render: (bom) => (
          <Avatar
            name={bom.inventory_bom_users?.[0]?.user.name}
            size="xs"
            src={bom.inventory_bom_users?.[0]?.user.picture_uri}
          />
        ),
      },
      {
        label: "",
        render: (bom) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisV />}
              variant="ghost"
              colorScheme="gray"
              size="sm"
              aria-label="Options"
            />
            <MenuList>
              <MenuItem
                icon={<RepeatIcon />}
                onClick={() => {
                  onOpen();
                  setCurrentBomId(bom.id);
                }}>
                Restore
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    [data]
  );

  if (isLoading) {
    return <Spinner />;
  } else if (isSuccess) {
    return (
      <Flex direction="column" gap={4}>
        <Box w="100%" overflowX="scroll">
          <Header
            title="Archive"
            crumbs={[{ url: "/inventory/boms", label: "BOMs" }]}
            crumbsColor="teal.500"
          />
          <CollectionPage
            columns={columns}
            items={data?.results || []}
            pagination={data?.pagination || pagination}
            isLoading={isLoading}
            order={order}
            onPagination={onPagination}
            onOrder={onOrder}
            facets={populatedFacets}
            onFacets={onFacets}
            facetsProps={{ debouncedSearch: debouncedSearch, background: true, search: true }}
          />
        </Box>
        <ConfirmationModal
          colorScheme="teal"
          isOpen={isOpen}
          onClose={onClose}
          confirmText="Continue"
          header="Restore BOM"
          children="Restoring this BOM will place it back on the BOM list"
          onClick={handleRestoreClick}
        />
      </Flex>
    );
  } else {
    return <Text>Error loading BOMs.</Text>;
  }
};
