export type Keyed<T> = T & { key: string };

export const autoKey = async <T>(data: T): Promise<Keyed<T>> => {
  const enc = new TextEncoder();
  const dec = new TextDecoder();

  // NOTE: This doesn't need to be cryptographic (it's strictly for react keys) so SHA-1 is fine.
  const encodedData = await crypto.subtle.digest("SHA-1", enc.encode(JSON.stringify(data)));
  const key = await crypto.subtle.digest("SHA-1", encodedData);
  return {
    ...data,
    key: dec.decode(key),
  };
};

export const autoKeyMany = async <T>(data: T[]) => {
  return await Promise.all(data.map(autoKey));
};
