import React, { useMemo } from "react";
import {
  FormModal,
  Collection,
  RecordLink,
  useCollection,
  EmptyState,
  CollectionTable,
} from "@sciencecorp/helix-components";
import { useDisclosure, Avatar, Tag, Tooltip, Text, Button } from "@chakra-ui/react";
import { InventoryShowData, useGetInventoryPendingItems } from "../../../../api/inventory";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { PurchaseLineItemPendingInventoryData } from "../../../../api/purchase_line_item";
import { useNavigate } from "react-router";
import { humanize, titleize } from "inflection";
import { purchaseRequestStatusColor } from "../../../Purchasing/util";

export const InventoryPendingItems = ({ inventory }: { inventory: InventoryShowData }) => {
  const { pagination, onPagination, order, onOrder } = useCollection();
  const { data: pendingItems, isLoading } = useGetInventoryPendingItems(inventory.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        label: "Purchase",
        render: (lineItem: PurchaseLineItemPendingInventoryData) => (
          <RecordLink
            identifier={lineItem.purchase_id}
            link={`/services/purchasing/${lineItem.purchase_id}`}
          />
        ),
      },
      {
        label: "Quantity",
        render: (lineItem: PurchaseLineItemPendingInventoryData) => lineItem.quantity,
      },
      {
        label: "Estimated Delivery Date",
        render: (lineItem: PurchaseLineItemPendingInventoryData) =>
          lineItem.estimated_delivery_date?.toFormat("MM dd yyyy") || "N/A",
      },
      {
        label: "Requested By",
        render: (lineItem: PurchaseLineItemPendingInventoryData) => (
          <Tooltip label={lineItem.user.name}>
            <Avatar size="sm" name={lineItem.user.name} src={lineItem.user.picture_uri} />
          </Tooltip>
        ),
      },
      {
        label: "Status",
        render: (lineItem: PurchaseLineItemPendingInventoryData) => (
          <Tag colorScheme={purchaseRequestStatusColor(lineItem.status)}>
            {titleize(humanize(lineItem.status))}
          </Tag>
        ),
      },
    ],
    [pendingItems]
  );

  const handleClose = () => {
    onClose();
    navigate(`/inventory/items/${inventory.id}/details`);
  };

  return (
    <>
      <Button variant="ghost" onClick={onOpen} rightIcon={<ChevronRightIcon />}>
        See Purchase Details
      </Button>
      <FormModal
        size="2xl"
        title="Purchase Details"
        isOpen={isOpen}
        submitButtonColorSchema="teal"
        onClose={handleClose}
        colorScheme="teal"
        submitButtonTitle="Done"
        handleSubmit={handleClose}>
        {(pendingItems?.length || 0) > 0 || isLoading ? (
          <CollectionTable items={pendingItems || []} columns={columns} />
        ) : (
          <EmptyState title="No pending items" />
        )}
      </FormModal>
    </>
  );
};
