import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
export const INVENTORY_VENDORS_BASE_URL = "inventory_vendors";

export const vendorSchema = z.object({
  id: z.number(),
  sku: z.string().nullable(),
  link: z.string().nullable(),
  vendor: z.object({
    id: z.number(),
    name: z.string(),
  }),
});

export const inventoryVendorSchema = vendorSchema
  .extend({
    inventory_vendorable_id: z.number(),
    inventory_vendorable_type: z.string(),
    vendor_id: z.number().nullable(),
  })
  .omit({ vendor: true });

export const inventoryVendorShowSchema = inventoryVendorSchema.omit({
  vendor_id: true,
  inventory_vendorable_id: true,
  inventory_vendorable_type: true,
});

export const bulkInventoryVendorCreateParamsSchema = z.object({
  vendor_ids: z.number().array(),
  inventory_vendorable_id: z.number(),
  inventory_vendorable_type: z.string(),
});
export type VendorData = z.infer<typeof vendorSchema>;
export type InventoryVendorData = z.infer<typeof inventoryVendorSchema>;
export type InventoryVendorShowData = z.infer<typeof inventoryVendorShowSchema>;
export type InventoryVendorCreateParams = Omit<InventoryVendorData, "id">;
export type BulkInventoryVendorCreateParams = z.infer<typeof bulkInventoryVendorCreateParamsSchema>;

/** queries */

export const createInventoryVendor = async (inventory_vendor: InventoryVendorCreateParams) => {
  const result = await api.post(INVENTORY_VENDORS_BASE_URL, { inventory_vendor });
  return zodParse(inventoryVendorSchema, result.data);
};

export const bulkCreateInventoryVendor = async (
  inventory_vendor: BulkInventoryVendorCreateParams
) => {
  await api.post(`${INVENTORY_VENDORS_BASE_URL}/bulk`, { inventory_vendor });
};

export const updateInventoryVendor = async (inventory_vendor: Partial<InventoryVendorData>) => {
  await api.put(`${INVENTORY_VENDORS_BASE_URL}/${inventory_vendor.id}`, { inventory_vendor });
};

export const deleteInventoryVendor = async (id: number) => {
  await api.delete(`${INVENTORY_VENDORS_BASE_URL}/${id}`);
  return id;
};

/** hooks */

export const useBulkCreateInventoryVendor = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateInventoryVendor,
  });
};

export const useCreateInventoryVendor = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  const cbFn = onSuccessCallback && onSuccessCallback();
  return useMutation({
    mutationFn: createInventoryVendor,
    onSuccess: () => {
      cbFn && cbFn();
    },
  });
};

export const useUpdateInventoryVendor = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  const cbFn = onSuccessCallback && onSuccessCallback();
  return useMutation({
    mutationFn: updateInventoryVendor,
    onSuccess: () => {
      cbFn && cbFn();
    },
  });
};

export const useDeleteInventoryVendor = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  const cbFn = onSuccessCallback && onSuccessCallback();
  return useMutation({
    mutationFn: deleteInventoryVendor,
    onSuccess: () => {
      cbFn && cbFn();
    },
  });
};
