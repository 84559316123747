import React from "react";
import {
  Card,
  Flex,
  useColorModeValue,
  CardHeader,
  CardBody,
  Text,
  Stack,
  Box,
  HStack,
} from "@chakra-ui/react";
import { useGetVirtualCard } from "../../../../api/virtual_cards";
import { CopyClipboardButton } from "../../../Requests/utils";

export const SubscriptionCardDetails = ({ subscription, showCard }) => {
  const { data: virtualCard } = useGetVirtualCard(subscription.virtual_card_id);
  return (
    <Flex
      p={4}
      justify="center"
      align="center"
      height="100%"
      pos={"relative"}
      _before={{
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: useColorModeValue(
          "linear-gradient(to top, #cbd5e0, #c4d1ea, #c3ccf3, #c9c5f8, #d6bcfa)",
          "linear-gradient(to top, #2d3748, #2b3857, #2d3864, #363770, #44337a)"
        ),
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: 0.2,
        zIndex: -1,
      }}>
      <Card minW="xs">
        <CardHeader display="flex" alignItems="center" pb={2}>
          {showCard ? (
            <>
              <Text
                fontSize="2xl"
                fontWeight="medium"
                mr={2}
                data-testid="subscription-card-number">
                {(virtualCard?.number.match(/.{1,4}/g) || []).join(" ")}
              </Text>
              <CopyClipboardButton text={virtualCard?.number} />
            </>
          ) : (
            <Text fontSize="2xl" fontWeight="medium" mr={2}>
              {("*".repeat(16).match(/.{1,4}/g) || []).join(" ")}
            </Text>
          )}
        </CardHeader>
        <CardBody>
          <Text data-testid={"subscription-stripe-card-id"} display={"none"}>
            {virtualCard?.id}
          </Text>
          <Stack direction={{ base: "column", md: "row" }} justify="space-between" w="100%">
            <Stack direction="column">
              <Box>
                <Text fontSize="2xs">Cardholder Name</Text>
                {showCard ? (
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    data-testid="subscription-cardholder-name">
                    {virtualCard?.cardholder.name}{" "}
                    <CopyClipboardButton text={virtualCard?.cardholder.name} />
                  </Text>
                ) : (
                  <Text fontSize="xs" fontWeight="medium">
                    {"*".repeat(10)}
                  </Text>
                )}
              </Box>
              <Box>
                <Text fontSize="2xs">Address</Text>
                {showCard ? (
                  <HStack>
                    <Text>
                      <Text fontSize="xs" fontWeight="medium" data-testid="subscription-address">
                        {virtualCard?.cardholder.billing_address.line1}
                        {virtualCard?.cardholder.billing_address.line2}
                      </Text>
                      <Text fontSize="xs" fontWeight="medium">
                        {virtualCard?.cardholder.billing_address.city},{" "}
                        {virtualCard?.cardholder.billing_address.state}{" "}
                        {virtualCard?.cardholder.billing_address.postal_code}
                      </Text>
                    </Text>
                    <CopyClipboardButton text={virtualCard?.cardholder.billing_address.line1} />
                  </HStack>
                ) : (
                  <Text fontSize="xs" fontWeight="medium">
                    {"*".repeat(10)}
                  </Text>
                )}
              </Box>
            </Stack>
            <Stack direction="column" textAlign="end">
              <Box>
                <Text fontSize="2xs">Exp. Date</Text>
                {showCard ? (
                  <Text fontSize="xs" fontWeight="medium" data-testid="subscription-exp">
                    {virtualCard?.exp_month}/{virtualCard?.exp_year}
                  </Text>
                ) : (
                  <Text fontSize="xs" fontWeight="medium">
                    {"*".repeat(6)}
                  </Text>
                )}
              </Box>
              <Box>
                <Text fontSize="2xs">CVC</Text>
                {showCard ? (
                  <Text fontSize="xs" fontWeight="medium" data-testid="subscription-cvc">
                    {virtualCard?.cvc}
                  </Text>
                ) : (
                  <Text fontSize="xs" fontWeight="medium">
                    {"*".repeat(3)}
                  </Text>
                )}
              </Box>
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
};
