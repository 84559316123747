import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmptyState } from "@sciencecorp/helix-components";
import { TimelineEventData } from "../../../../api/timeline_events";
import { TimelineEventText } from "../../../shared/TimelineEventText";

type TimelineTableProps = {
  timelineable_type: string;
  timelineable_id: number;
  events: TimelineEventData[];
  allData: any[];
};

export const TimelineLog: React.FC<TimelineTableProps> = ({
  events,
  allData,
  timelineable_id,
  timelineable_type,
}) => {
  const bgColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Flex flexDir="column" w="100%">
      {events.length ? (
        <Stack py={2} width="100%">
          <Stack position="relative" mb={3}>
            <Box
              position="absolute"
              height="100%"
              bg={bgColor}
              width={0.5}
              left={3.5}
              top={2}
              zIndex={-1}
            />
            <VStack align="start" spacing={5} w="100%">
              {allData
                .map((data) => data.timeline_events[data.timeline_events.length - 1])
                .map((event: TimelineEventData, idx: number) => (
                  <HStack gap={1} w="100%" key={event.id}>
                    <TimelineEventLine
                      key={event.id}
                      showInitialIcon={idx === 0}
                      event={event}
                      timelineableId={timelineable_id}
                      timelineableType={timelineable_type}
                      onComment={() => {}}
                    />
                  </HStack>
                ))}
            </VStack>
          </Stack>
          <Divider />
        </Stack>
      ) : (
        <EmptyState title="No events added yet. Add a log to see the timeline." size="3xs" />
      )}
    </Flex>
  );
};

type TimelineEventLineProps = {
  event: TimelineEventData;
  showInitialIcon: boolean;
  timelineableId: number;
  timelineableType: string;
  onComment: (action: "created" | "updated" | "deleted") => void;
};

const TimelineEventLine = ({
  event,
  timelineableId,
  timelineableType,
  onComment,
}: TimelineEventLineProps) => {
  const graybg = useColorModeValue("gray.100", "gray.900");
  return (
    <HStack gap={1} w="100%">
      <Box
        borderRadius="full"
        bg={graybg}
        boxSize={8}
        display="flex"
        alignSelf="start"
        justifyContent="center"
        alignItems="center">
        <Avatar size="sm" src={event.user?.picture_uri} />
      </Box>
      <TimelineEventText
        event={event}
        timelineableId={timelineableId}
        timelineableType={timelineableType}
        onComment={onComment}
        canEdit
      />
    </HStack>
  );
};
