import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useUpdateCapitalEquipment } from "../../../../api/capital_equipment";

type RemindUserModalProps = {
  capitalEquipmentId: number;
  days?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const ReminderModal = ({
  capitalEquipmentId,
  days,
  isOpen,
  onClose,
}: RemindUserModalProps) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({});
  const hasPreviousReminder = days && days > 0;
  const { mutateAsync: updateTool, isLoading: isLoadingUpdateTool } =
    useUpdateCapitalEquipment(capitalEquipmentId);
  const toast = useToast();
  const formValues = watch();
  const handleClose = () => {
    reset();
    onClose();
  };
  const onSubmit = () => {
    updateTool({
      id: capitalEquipmentId,
      reminder_days_prior: formValues.days ? formValues.days : 1,
    }).then(() => {
      toast({
        title: "Reminder time updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
    handleClose();
  };

  useEffect(() => {
    if (hasPreviousReminder) {
      setValue("days", days);
    }
  }, [isOpen]);

  return (
    <FormModal
      size={"md"}
      title={hasPreviousReminder ? "Edit Reminder Time" : "Enable Reminder"}
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      isLoading={isLoadingUpdateTool}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="days"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>When do you want to receive the reminders?</FormLabel>
              <Stack direction={"row"} alignItems={"center"} padding={"2"}>
                <NumberInput
                  defaultValue={hasPreviousReminder ? days : 1}
                  min={1}
                  max={365}
                  width={"50%"}
                  onChange={(value) => setValue("days", Number(value))}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text>days(s) before</Text>
              </Stack>
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};
