import React, { useMemo, useState } from "react";
import { Collection, ConfirmationModal } from "@sciencecorp/helix-components";
import {
  Tag,
  Link,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  useDisclosure,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { useCollection } from "@sciencecorp/helix-components";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useGetTrainingSessions } from "../../../../api/trainings";
import {
  TrainingSessionData,
  TrainingSessionShowData,
  useDeleteTrainingSession,
} from "../../../../api/training_sessions";
import { DateTime } from "luxon";
import { FacilitatorCard, trainingSessionInPast } from "../../util";
import { Link as RouterLink } from "react-router-dom";
import { NewTrainingSession } from "./NewTrainingSession";

export const AllTrainingSessionTable = ({ id }: { id: number }) => {
  const { data: trainingSessions, isLoading } = useGetTrainingSessions(id);
  const { mutate: deleteTrainingSession } = useDeleteTrainingSession(id);
  const { pagination, onPagination, onOrder, order } = useCollection();
  const [activeTrainingSession, setActiveTrainingSession] =
    useState<TrainingSessionShowData | null>(null);
  const {
    isOpen: isOpenDeleteTrainingSession,
    onOpen: onOpenDeleteTrainingSession,
    onClose: onCloseDeleteTrainingSession,
  } = useDisclosure();

  const {
    isOpen: isOpenEditTrainingSession,
    onOpen: onOpenEditTrainingSession,
    onClose: onCloseEditTrainingSession,
  } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        label: "Date",
        render: (trainingSession: TrainingSessionShowData) =>
          trainingSession.scheduled_date.toFormat("LLL dd yyyy") || "N/A",
      },
      {
        label: "Time",
        render: (trainingSession: TrainingSessionShowData) =>
          trainingSession.scheduled_date.toFormat("hh:mm a"),
      },
      {
        label: "Facilitated By",
        render: (trainingSession: TrainingSessionShowData) => (
          <FacilitatorCard facilitator={trainingSession.facilitator} />
        ),
      },
      {
        label: "Attendees",
        render: (trainingSession: TrainingSessionShowData) => trainingSession.attendee_count,
      },
      {
        label: "Status",
        render: (trainingSession: TrainingSessionShowData) =>
          trainingSessionInPast(trainingSession.scheduled_date) ? (
            <Tag>Complete</Tag>
          ) : !trainingSession.started_at &&
            DateTime.now() < (trainingSession?.scheduled_date || DateTime.now()) ? (
            <Tag colorScheme="teal">Upcoming</Tag>
          ) : !trainingSession.ended_at &&
            DateTime.now() > (trainingSession?.scheduled_date || DateTime.now()) ? (
            <Tag colorScheme="blue">Ongoing</Tag>
          ) : (
            <Tag>Completed</Tag>
          ),
      },
      {
        label: "Actions",
        render: (trainingSession: TrainingSessionShowData) => (
          <Link
            as={RouterLink}
            color="teal.500"
            to={`/trainings/${id}/training_sessions/${trainingSession.id}`}>
            View Session
          </Link>
        ),
      },
      {
        label: "",
        render: (trainingSession: TrainingSessionShowData) => (
          <TrainingSessionActions
            trainingSession={trainingSession}
            setActiveTrainingSession={setActiveTrainingSession}
            onOpenDeleteTrainingSession={onOpenDeleteTrainingSession}
            onOpenEditTrainingSession={onOpenEditTrainingSession}
          />
        ),
      },
    ],
    [trainingSessions]
  );

  return (
    <>
      <Collection
        isLoading={isLoading}
        items={trainingSessions?.results || []}
        columns={columns}
        pagination={trainingSessions?.pagination || pagination}
        onPagination={onPagination}
        onOrder={onOrder}
        order={order}
      />
      {activeTrainingSession && (
        <>
          <ConfirmationModal
            colorScheme="red"
            isOpen={isOpenDeleteTrainingSession}
            onClose={onCloseDeleteTrainingSession}
            confirmText="Yes"
            header="Delete Training Session"
            children="Are you sure? You can’t undo this action afterwards."
            onClick={() => {
              deleteTrainingSession(activeTrainingSession.id);
              onCloseDeleteTrainingSession();
            }}
          />
          <NewTrainingSession
            isOpen={isOpenEditTrainingSession}
            onClose={onCloseEditTrainingSession}
            trainingSession={activeTrainingSession}
          />
        </>
      )}
    </>
  );
};

export const TrainingSessionActions = ({
  trainingSession,
  setActiveTrainingSession,
  onOpenDeleteTrainingSession,
  onOpenEditTrainingSession,
}) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          gap={3}
          onClick={() => {
            setActiveTrainingSession(trainingSession);
            onOpenEditTrainingSession();
          }}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem
          gap={3}
          color="red.500"
          onClick={() => {
            setActiveTrainingSession(trainingSession);
            onOpenDeleteTrainingSession();
          }}>
          <DeleteIcon />
          Delete Session
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
