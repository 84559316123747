import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  HStack,
  useColorModeValue,
  useToast,
  Tag,
  Divider,
  Textarea,
  Box,
  useDisclosure,
  IconButton,
  VStack,
  Input,
  ButtonGroup,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { Money } from "../../../../helpers/Money";
import { BudgetGroupShowData, useAddFundingBudgetGroup } from "../../../../api/budget_groups";
import { MoneyInput } from "../../../MoneyInput";
import { MoneyText } from "../../../MoneyText";
import { DateTime } from "luxon";
import { AddIcon } from "@chakra-ui/icons";

type FormValuesAddFundingBudgetGroup = {
  amount: Money;
  message: string;
  type: string;
  occurred_at: string;
};

const defaultFormValues = {
  amount: Money.zero("USD"),
  type: "add",
  message: "",
};

type AddFundingBudgetGroupModalProps = {
  budgetGroup: BudgetGroupShowData;
};

export const AddFundingBudgetGroupModal = ({ budgetGroup }: AddFundingBudgetGroupModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, watch, reset, setValue } = useForm<FormValuesAddFundingBudgetGroup>({
    defaultValues: defaultFormValues,
  });
  const formValues = watch();
  const { mutateAsync: addFundingBudgetGroup, isLoading } = useAddFundingBudgetGroup();

  const borderGray = useColorModeValue("gray.200", "gray.500");
  const backgroundColorGray = useColorModeValue("gray.50", "gray.700");

  const toast = useToast();

  const handleSubmit = () => {
    const amount = formValues.type === "add" ? formValues.amount : formValues.amount.times(-1);
    addFundingBudgetGroup({
      id: budgetGroup.id,
      amount: amount,
      message: formValues.message,
      occurred_at: DateTime.now(),
    })
      .then(() => {
        toast({
          title: "Funding added",
          description: "Funding has been added.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        handleClose();
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const disableSubmitButton = !formValues.amount || formValues.amount.isZero();

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <IconButton aria-label="add funding" icon={<AddIcon />} onClick={onOpen} size="xs" />
      <FormModal
        title="Edit Total Funding"
        submitButtonColorSchema="teal"
        size="xl"
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={handleClose}
        submitButtonDisabled={disableSubmitButton}
        handleSubmit={handleSubmit}>
        <Flex
          width={"100%"}
          border={"1px"}
          borderColor={borderGray}
          p={"4"}
          bgColor={backgroundColorGray}
          borderRadius={"md"}
          direction={"column"}
          textAlign={"center"}>
          <Flex direction={"column"} width={"100%"} p={"4"} borderRadius={"md"}>
            <Text>Total Funding</Text>
            <MoneyText
              as="span"
              fontSize="2xl"
              fontWeight="semibold"
              money={budgetGroup.summary.funding_amount}
              formatOptions={{ compact: "never", hideCents: false }}
            />
          </Flex>
        </Flex>

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Would you like to add or remove funding?</FormLabel>
              <RadioGroup
                onChange={(value) => setValue("type", value)}
                colorScheme="teal"
                value={field.value}>
                <HStack gap={8}>
                  <Radio value="add">Add</Radio>
                  <Radio value="remove">Remove</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
          )}
        />

        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Amount</FormLabel>
              <MoneyInput {...field} />
            </FormControl>
          )}
        />
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Message</FormLabel>
              <Textarea {...field} />
            </FormControl>
          )}
        />
      </FormModal>
    </>
  );
};
