import React, { useMemo, useState } from "react";
import {
  useCollection,
  Collection,
  Facets,
  buildFacets,
  ConfirmationModal,
  EmptyState,
} from "@sciencecorp/helix-components";
import {
  VStack,
  Button,
  HStack,
  useDisclosure,
  Heading,
  Badge,
  Box,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Icon,
  Stack,
  Progress,
  Avatar,
  Checkbox,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlinePersonRemove } from "react-icons/md";

import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { ChevronRightIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import {
  CredentialPackageUserShowData,
  useUnassignCredentialPackageUsers,
  useSearchCredentialPackageUsers,
} from "../../../../api/credential_package_users";
import { AddCredentialPackageUser } from "./AddCredentialPackageUser";
import { PackageProgressModal } from "./PackageProgressModal";

export const UserCredentialPackageTable = ({ credentialPackage }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { onPagination, onOrder, order, pagination, filters, facets, onFacets } = useCollection({
    pagination: { per_page: 100 },
  });
  const { search, debouncedSearch } = useDebouncedSearch();

  const { data, isLoading } = useSearchCredentialPackageUsers({
    term: search || "*",
    aggs: ["team_name", "completed"],
    pagination,
    filters: { ...filters, credential_package_id: credentialPackage.id },
    order,
  });

  const { mutate: unassignCredentialPackageUsers } = useUnassignCredentialPackageUsers();

  const toast = useToast();

  const [activeCredentialPackageUser, setActiveCredentialPackageUser] =
    useState<CredentialPackageUserShowData | null>(null);

  const {
    isOpen: isOpenUnassignCredentialPackageUser,
    onOpen: onOpenUnassignCredentialPackageUser,
    onClose: onCloseUnassignCredentialPackageUser,
  } = useDisclosure();

  const {
    isOpen: isOpenCredentialPackageUserDetails,
    onOpen: onOpenCredentialPackageUserDetails,
    onClose: onCloseCredentialPackageUserDetails,
  } = useDisclosure();

  const handleUnassignModalClose = () => {
    if (activeCredentialPackageUser) {
      setActiveCredentialPackageUser(null);
    }
    onCloseUnassignCredentialPackageUser();
  };

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const columns = useMemo(
    () => [
      {
        label: "",
        render: (credentialPackageUser: CredentialPackageUserShowData) => (
          <Checkbox
            colorScheme="teal"
            isChecked={selectedItems.includes(credentialPackageUser.id)}
            onChange={() => handleCheckboxChange(credentialPackageUser.id)}
          />
        ),
      },
      {
        label: "Employee",
        orderOptions: { orderKey: "user_name" },
        render: (credentialPackageUser: CredentialPackageUserShowData) => (
          <HStack>
            <Avatar
              src={credentialPackageUser.user.picture_uri}
              name={credentialPackageUser.user.name}
              size="sm"
            />
            <Text>{credentialPackageUser.user.name}</Text>
          </HStack>
        ),
      },
      {
        label: "Team",
        orderOptions: { orderKey: "team_name" },
        render: (credentialPackageUser: CredentialPackageUserShowData) => {
          const teams = credentialPackageUser.team.map((team) => team.name).join(", ");
          return <Text>{teams}</Text>;
        },
      },
      {
        label: "Credentials Obtained",
        render: (credentialPackageUser: CredentialPackageUserShowData) => {
          const percentage =
            (credentialPackageUser.progress.credentials_completed /
              credentialPackageUser.progress.total_credentials) *
            100;

          return (
            <HStack w="100%">
              <Progress colorScheme="teal" size="sm" value={percentage} w="100%" hasStripe />
              <HStack w="100%" gap={2}>
                <Text>
                  {credentialPackageUser.progress.credentials_completed} of{" "}
                  {credentialPackageUser.progress.total_credentials}
                </Text>
                <IconButton
                  onClick={() => {
                    setActiveCredentialPackageUser(credentialPackageUser);
                    onOpenCredentialPackageUserDetails();
                  }}
                  aria-label="View Progress"
                  icon={<ChevronRightIcon />}
                  size="sm"
                  colorScheme="gray"
                  variant="ghost"
                />
              </HStack>
            </HStack>
          );
        },
      },
      {
        label: "",
        render: (credentialPackageUser: CredentialPackageUserShowData) => (
          <Box textAlign="end">
            <CredentialPackageUserActions
              credentialPackageUser={credentialPackageUser}
              setCredentialPackageUser={setActiveCredentialPackageUser}
              onOpenUnassignCredentialPackageUser={onOpenUnassignCredentialPackageUser}
            />
          </Box>
        ),
      },
    ],
    [data, selectedItems]
  );

  const handleCheckboxChange = (userId: number) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSelectAll = () => {
    setSelectedItems((data?.results || []).map((user) => user.id));
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  return (
    <>
      <VStack
        p={5}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        align="start"
        spacing={4}
        width="100%">
        <HStack width="100%" justify="space-between">
          <HStack>
            <Heading size="md">Assigned Employees</Heading>
            <Badge>{data?.pagination.total}</Badge>
          </HStack>

          <HStack align="center">
            <Facets
              facets={populatedFacets}
              onChange={onFacets}
              debouncedSearch={debouncedSearch}
              search
              background
              variant="button"
            />
            <AddCredentialPackageUser credentialPackage={credentialPackage} />
          </HStack>
        </HStack>
        {selectedItems.length > 0 && (
          <HStack
            justifyContent="space-between"
            p={4}
            border="1px"
            borderColor="chakra-border-color"
            borderRadius="md"
            align="center"
            width="100%">
            <HStack gap={4}>
              <Text textColor={useColorModeValue("gray.500", "gray.400")}>
                {selectedItems.length} Employees Selected
              </Text>
              <Button
                colorScheme="red"
                variant={"outline"}
                size="sm"
                onClick={onOpenUnassignCredentialPackageUser}>
                Unassign
              </Button>
            </HStack>
            <HStack gap={4}>
              <Button
                onClick={handleSelectAll}
                variant="outline"
                size="sm"
                colorScheme="teal"
                leftIcon={<RiCheckboxMultipleLine />}>
                Select All
              </Button>
              <Button
                onClick={handleDeselectAll}
                variant="outline"
                size="sm"
                leftIcon={<SmallCloseIcon />}>
                Deselect All
              </Button>
            </HStack>
          </HStack>
        )}

        <Box width="100%">
          {isLoading || (data?.results.length || 0) > 0 ? (
            <Collection
              columns={columns}
              items={data?.results || []}
              isLoading={isLoading}
              pagination={data?.pagination || pagination}
              onPagination={onPagination}
              order={order}
              onOrder={onOrder}
            />
          ) : (
            <EmptyState title="No assigned users" size="2xs" />
          )}
        </Box>
      </VStack>
      {activeCredentialPackageUser && (
        <PackageProgressModal
          isOpen={isOpenCredentialPackageUserDetails}
          onClose={onCloseCredentialPackageUserDetails}
          credentialPackageUser={activeCredentialPackageUser}
        />
      )}
      {(activeCredentialPackageUser || selectedItems.length > 0) && (
        <ConfirmationModal
          colorScheme="red"
          isOpen={isOpenUnassignCredentialPackageUser}
          onClose={handleUnassignModalClose}
          header="Unassign"
          confirmText="Continue"
          onClick={() => {
            if (activeCredentialPackageUser) {
              unassignCredentialPackageUsers(
                {
                  ids: [activeCredentialPackageUser.id],
                },
                {
                  onSuccess: () => {
                    toast({
                      title: `You unassigned ${activeCredentialPackageUser.user.name} employees.`,
                      status: "success",
                      duration: 5000,
                    });
                    setActiveCredentialPackageUser(null);
                  },
                }
              );
            } else if (selectedItems.length > 0) {
              unassignCredentialPackageUsers(
                {
                  ids: selectedItems,
                },
                {
                  onSuccess: () => {
                    setSelectedItems([]);
                    toast({
                      title: `You unassigned ${selectedItems.length} employees.`,
                      status: "success",
                      duration: 5000,
                    });
                  },
                }
              );
            }
          }}>
          {activeCredentialPackageUser ? (
            <Text>
              Are you sure you want to unassign {activeCredentialPackageUser.user.name} from this
              credential package?
            </Text>
          ) : (
            <Text>
              Are you sure you want to unassign {selectedItems.length} employees from this
              credential package?
            </Text>
          )}
        </ConfirmationModal>
      )}
    </>
  );
};

export const CredentialPackageUserActions = ({
  credentialPackageUser,
  setCredentialPackageUser,
  onOpenUnassignCredentialPackageUser,
}: {
  credentialPackageUser: CredentialPackageUserShowData | null;
  setCredentialPackageUser: React.Dispatch<
    React.SetStateAction<CredentialPackageUserShowData | null>
  >;
  onOpenUnassignCredentialPackageUser: () => void;
}) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          icon={<Icon as={MdOutlinePersonRemove} boxSize={5} />}
          onClick={() => {
            setCredentialPackageUser(credentialPackageUser);
            onOpenUnassignCredentialPackageUser();
          }}>
          Unassign
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
