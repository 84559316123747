import React, { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Flex, FormControl, FormLabel, IconButton, Input, useDisclosure } from "@chakra-ui/react";
import { invalidateInventories, InventoryShowData } from "../../../../api/inventory";
import { FormModal, Select } from "@sciencecorp/helix-components";
import { useCreateInventoryVendor } from "../../../../api/inventory_vendor";
import { useVendorOptions, UseVendorOptionsProps } from "../../../../api/options";
import {
  CapitalEquipmentShowData,
  invalidateCapitalEquipments,
} from "../../../../api/capital_equipment";
import { Controller, useForm } from "react-hook-form";

type InventoryVendorModalProps = {
  data: InventoryShowData | CapitalEquipmentShowData;
  type: "CapitalEquipment" | "Inventory";
};

type InventoryVendorFormValuesType = {
  vendor_id: number | null;
  sku: string;
  link: string;
};

const defaultFormValues: InventoryVendorFormValuesType = {
  vendor_id: null,
  sku: "",
  link: "",
};

export const InventoryVendorModal = ({ data, type }: InventoryVendorModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: createInventoryVendor } = useCreateInventoryVendor(
    type === "CapitalEquipment"
      ? () => invalidateCapitalEquipments(data.id)
      : () => invalidateInventories(data.id)
  );
  const vendorOptionsProps: UseVendorOptionsProps | undefined = data?.vendors
    ? { excludeIds: data.vendors.map((vendor) => vendor.vendor.id) }
    : undefined;

  const vendorOptions = useVendorOptions(vendorOptionsProps);
  const [vendorName, setVendorName] = useState<string | null>(null);

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: defaultFormValues,
  });
  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = () => {
    createInventoryVendor({
      vendor_id: formValues.vendor_id,
      inventory_vendorable_id: data.id,
      inventory_vendorable_type: type,
      sku: formValues.sku,
      link: formValues.link,
    }).then(() => {
      handleClose();
    });
  };
  const disableSubmit = !formValues.vendor_id;

  return (
    <>
      <IconButton aria-label="add inventory vendor" icon={<AddIcon />} size="xs" onClick={onOpen} />
      <FormModal
        isOpen={isOpen}
        onClose={handleClose}
        size={"xl"}
        title="Add Supplier"
        handleSubmit={handleSubmit(onSubmit)}
        submitButtonColorSchema="teal"
        submitButtonDisabled={disableSubmit}>
        <Flex direction="column" w="100%" gap={4}>
          <Controller
            name="vendor_id"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Vendor</FormLabel>
                <Select
                  options={vendorOptions}
                  placeholder="Select a vendor"
                  value={vendorName}
                  onChange={(value) => {
                    if (value) {
                      setVendorName(value.toString());
                      setValue("vendor_id", Number(value));
                    }
                  }}
                />
              </FormControl>
            )}
          />
          <Flex width={"100%"} gap={"4"}>
            <Flex width={"50%"}>
              <Controller
                name="sku"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>SKU (optional)</FormLabel>
                    <Input placeholder="Type here" {...field} />
                  </FormControl>
                )}
              />
            </Flex>
            <Flex width={"50%"}>
              <Controller
                name="link"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Link (optional)</FormLabel>
                    <Input placeholder="Type here" {...field} />
                  </FormControl>
                )}
              />
            </Flex>
          </Flex>
        </Flex>
      </FormModal>
    </>
  );
};
