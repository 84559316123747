import {
  Alert,
  Box,
  Divider,
  HStack,
  Heading,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { CollectionTable, Column, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import {
  Project,
  ProjectIndex,
  useGetProjectSummaries,
  useSearchProjects,
} from "../../../../api/planning/projects";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyText } from "../../../MoneyText";

interface TeamProjectExpenditureCardProps {
  teamId: number;
  editable: boolean;
  topLevelTeamId?: number;
}

export const TeamProjectExpenditureCard = ({
  teamId,
  topLevelTeamId,
  editable,
}: TeamProjectExpenditureCardProps): JSX.Element | null => {
  const currency = useCurrency();
  const statBgColor = useColorModeValue("gray.50", "gray.700");
  const statBorderColor = useColorModeValue("gray.200", "gray.600");

  const projectSearchQuery = useSearchProjects({
    term: "*",
    filters:
      topLevelTeamId === undefined
        ? { top_level_teams: [teamId], status: "approved" }
        : { teams: [teamId], status: "approved" },
    endDate: DateTime.now().endOf("year"),
  });

  const { data: projectSummaries, isLoading: projectSummariesIsLoading } = useGetProjectSummaries();

  const columns: Column<ProjectIndex>[] = useMemo(
    () => [
      {
        label: "Project",
        weight: 3,
        render: (project) =>
          editable ? (
            <RecordLink
              type={"Project"}
              link={`/planning/projects/${project.id}`}
              identifier={project.title}
            />
          ) : (
            <Text>{project.title}</Text>
          ),
      },
      {
        label: "Total Spent",
        weight: 3,
        render: (project) => {
          if (projectSummariesIsLoading) {
            return <Spinner />;
          } else {
            return (
              <MoneyText
                money={projectSummaries?.[project.id].spent_amount}
                formatOptions={{ compact: "never" }}
              />
            );
          }
        },
      },
      {
        label: "Headcount",
        weight: 3,
        render: (project) => (
          <Text>
            {project.hired_count} / {project.total_people}
          </Text>
        ),
      },
    ],
    [editable, projectSummariesIsLoading]
  );

  if (projectSearchQuery.isLoading) {
    return <Spinner />;
  }

  if (projectSearchQuery.isError) {
    return <Alert status="error">Failed to load projects</Alert>;
  }

  if (projectSearchQuery.isSuccess) {
    const projects = projectSearchQuery.data.results;
    const allTeamProjectSpent: Money = Money.sum(
      Money.zero(currency),
      ...projects.map(
        (project) => projectSummaries?.[project.id]?.spent_amount || Money.zero(currency)
      )
    );

    return (
      <Box flex={1}>
        <VStack
          spacing={4}
          w={"100%"}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          p={6}
          borderRadius="md">
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Heading size="md">Affiliated Projects</Heading>
          </HStack>
          <Divider />
          <HStack
            w={"100%"}
            justifyContent={"space-between"}
            bg={statBgColor}
            p={6}
            borderRadius={"md"}
            borderWidth={"1px"}
            borderColor={statBorderColor}>
            <Stat>
              <StatLabel>Total Affiliated Project Spent Amount</StatLabel>
              <StatNumber>
                <MoneyText money={allTeamProjectSpent} formatOptions={{ compact: "never" }} />
              </StatNumber>
            </Stat>
          </HStack>

          {projects.length > 0 ? (
            <Box width="100%">
              <CollectionTable items={projects} columns={columns} order={{ id: "asc" }} />
            </Box>
          ) : (
            <EmptyState size="sm" title={`This team is not affiliated with any projects.`} />
          )}
        </VStack>
      </Box>
    );
  }

  return null;
};
