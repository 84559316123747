import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { searchParams } from "./collection_types";
import { indexParams } from "./collection_types";
import { SearchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { inventoryBomUserSchema } from "./inventory_bom_user";
import { inventoryBomComponentSchema } from "./inventory_bom_components";
import { moneySchema } from "../helpers/Money";

export const INVENTORY_BOMS_BASE_URL = "inventory_boms";

export const inventoryBomSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.string(),
  description: z.string().nullable(),
  inventory_bom_users: z.array(inventoryBomUserSchema).nullable(),
  previous_active_inventory_bom_id: z.number().nullable(),
  next_active_inventory_bom_id: z.number().nullable(),
  inventory_bom_components: z.array(inventoryBomComponentSchema).nullable(),
  total_estimated_cost: moneySchema.nullable(),
  user_id: z.number(),
  component_out_of_stock: z.boolean().nullable(),
  is_editable: z.boolean(),
});

export const inventoryBomShowSchema = inventoryBomSchema
  .extend({
    inventory_bom_components_length: z.number(),
  })
  .omit({
    description: true,
    previous_active_inventory_bom_id: true,
    next_active_inventory_bom_id: true,
    inventory_bom_components: true,
    is_editable: true,
  });

export type InventoryBomData = z.infer<typeof inventoryBomSchema>;
export type InventoryCreateBomData = Pick<InventoryBomData, "name" | "description">;
export type InventoryBomShow = z.infer<typeof inventoryBomShowSchema>;

export const getInventoryBom = async (id: number): Promise<InventoryBomData> => {
  const result = await api.get<InventoryBomData>(`${INVENTORY_BOMS_BASE_URL}/${id}`);
  return zodParse(inventoryBomSchema, result.data);
};

export const getInventoryBomComponents = async (id: number): Promise<InventoryBomData> => {
  const result = await api.get<InventoryBomData>(`${INVENTORY_BOMS_BASE_URL}/${id}/components`);
  return zodParse(inventoryBomSchema, result.data);
};

export const createInventoryBom = async (
  data: InventoryCreateBomData & { inventory_bom_users: number[] }
) => {
  const result = await api.post<InventoryBomData>(INVENTORY_BOMS_BASE_URL, {
    inventory_bom: { ...data },
  });
  return zodParse(inventoryBomSchema.pick({ id: true }), result.data);
};

export const updateInventoryBom = async (inventory_bom: Partial<InventoryBomData>) => {
  const result = await api.put<InventoryBomData>(`${INVENTORY_BOMS_BASE_URL}/${inventory_bom.id}`, {
    inventory_bom,
  });
  return zodParse(inventoryBomSchema, result.data);
};

export const deleteInventoryBom = async (id: number) => {
  await api.delete(`${INVENTORY_BOMS_BASE_URL}/${id}`);
};

export const searchInventoryBoms = async ({
  aggs,
  filters,
  pagination,
  bodyOptions,
  order,
  term,
}: SearchParams) => {
  const path = [INVENTORY_BOMS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ bodyOptions, aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(inventoryBomShowSchema), result.data);
};

/** hooks */

export const invalidateInventoryBom = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [INVENTORY_BOMS_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useGetInventoryBom = (id: number) => {
  return useQuery({
    queryKey: [INVENTORY_BOMS_BASE_URL, id],
    queryFn: () => getInventoryBom(id),
  });
};

export const useGetInventoryBomComponents = (id: number) => {
  return useQuery({
    queryKey: [INVENTORY_BOMS_BASE_URL, id, "components"],
    queryFn: () => getInventoryBomComponents(id),
  });
};

export const useCreateInventoryBom = () => {
  return useMutation({
    mutationFn: createInventoryBom,
    onSuccess: invalidateInventoryBom(),
  });
};

export const useUpdateInventoryBom = (inventoryBomId: number) => {
  return useMutation({
    mutationFn: updateInventoryBom,
    onSuccess: invalidateInventoryBom(inventoryBomId),
  });
};

export const useSearchInventoryBoms = (params: SearchParams) => {
  return useQuery({
    queryKey: [INVENTORY_BOMS_BASE_URL, params],
    queryFn: () => searchInventoryBoms(params),
  });
};

export const useDeleteInventoryBom = (id: number) => {
  return useMutation({
    mutationFn: deleteInventoryBom,
    onSuccess: invalidateInventoryBom(id),
  });
};
