import React, { useMemo } from "react";
import { Order } from "../../../api/collection_types";
import { Pagination } from "../../../api/collection_types";
import { InventoryBomShow } from "../../../api/inventory_boms";
import { Collection, Column, RecordLink } from "@sciencecorp/helix-components";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";
import { humanize } from "inflection";
import { MoneyText } from "../../MoneyText";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";

export type BomsProps = {
  isLoading: boolean;
  data: InventoryBomShow[];
  pagination: Pagination;
  order: Order;
  onOrder: (order: Order) => void;
  isInventoryManager: boolean;
};

export const BomStatusColor = (status: string) => {
  if (status === "draft") return "gray";
  if (status === "active") return "green";
  if (status === "archived") return "gray";
};

export const Boms = ({ isLoading, data, onPagination, pagination, order, onOrder }) => {
  const navigate = useNavigate();
  const archivedCount =
    data?.aggregations?.status.buckets.find((bucket) => bucket.key === "archived")?.doc_count || 0;
  const columns: Column<InventoryBomShow>[] = useMemo(
    () => [
      {
        label: "Bom Name",
        render: (item) => (
          <Tooltip
            label={item.component_out_of_stock ? "At least one component is out of stock" : ""}>
            <Box>
              <RecordLink
                link={`/inventory/boms/${item.id}`}
                colorScheme={item.component_out_of_stock ? "orange" : "gray"}
                rightEl={item.component_out_of_stock ? <WarningTwoIcon /> : null}
                identifier={item.name}
                icon={<BsReverseLayoutTextSidebarReverse />}
                iconPlacement="left"
              />
            </Box>
          </Tooltip>
        ),
      },
      {
        label: "Components Needed",
        render: (item) => (
          <HStack alignItems="center">
            <Text>{item.inventory_bom_components_length || "-"}</Text>
            {item.component_out_of_stock ? (
              <Tooltip label="At least one component is out of stock">
                <Tag size="sm" colorScheme="orange" marginLeft={2} variant="outline">
                  Out of Stock
                </Tag>
              </Tooltip>
            ) : null}
          </HStack>
        ),
      },
      {
        label: "Total Estimated Cost",
        render: (item) =>
          item.total_estimated_cost ? (
            <MoneyText
              money={item.total_estimated_cost}
              formatOptions={{ compact: "never" }}
              as="span"
              fontWeight="semibold"
            />
          ) : (
            <Text>-</Text>
          ),
      },
      {
        label: "Status",
        render: (item) => (
          <Tag colorScheme={BomStatusColor(item.status)}>{humanize(item.status)}</Tag>
        ),
      },
      {
        label: "Owner",
        render: (item) => {
          const owners = item.inventory_bom_users || [];
          const visibleOwners = owners.slice(0, 3);
          const remainingOwners = owners.slice(3);
          const remainingCount = remainingOwners.length;

          return (
            <HStack>
              {visibleOwners.map((owner, index) => (
                <Tooltip key={index} label={owner.user.name}>
                  <Avatar name={owner.user.name} size="xs" src={owner.user.picture_uri} />
                </Tooltip>
              ))}
              {remainingCount > 0 && (
                <Tooltip label={remainingOwners.map((owner) => owner.user.name).join(", ")}>
                  <Text>+{remainingCount}</Text>
                </Tooltip>
              )}
            </HStack>
          );
        },
      },
    ],
    [data]
  );

  return (
    <>
      <Flex width="100%" direction="column">
        <Stack py={2} gap={4} width="100%">
          <StatGroup display="flex" flexDirection={{ base: "column", md: "row" }} gap={6}>
            <Stat
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              px={6}
              py={4}
              flex="2">
              <StatLabel>Total Bill of Materials Count</StatLabel>
              <StatNumber>{data?.pagination.total || "-"}</StatNumber>
            </Stat>
            <Stat
              border="1px"
              backgroundColor={useColorModeValue("gray.100", "gray.700")}
              borderColor="chakra-border-color"
              borderRadius="md"
              px={6}
              py={4}
              justifyContent={"space-between"}>
              <HStack justifyContent={"space-between"}>
                <VStack align="start" gap={0}>
                  <StatLabel>Archived Bill of Materials</StatLabel>
                  <StatNumber>{archivedCount}</StatNumber>
                </VStack>
                <Button
                  size="sm"
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => navigate("/inventory/boms/archived")}>
                  View
                </Button>
              </HStack>
            </Stat>
          </StatGroup>
          <Collection
            columns={columns}
            items={data?.results || []}
            isLoading={isLoading}
            pagination={data?.pagination || pagination}
            onPagination={onPagination}
            order={order}
            onOrder={onOrder}
          />
        </Stack>
      </Flex>
    </>
  );
};
