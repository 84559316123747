import {
  Modal,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalContent,
  ModalHeader,
  Box,
  Skeleton,
  Flex,
  HStack,
  Stack,
  Avatar,
  Divider,
  VStack,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { EmptyState, RichTextEditor } from "@sciencecorp/helix-components";
import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useGetCapitalEquipmentLogTimelineEvents } from "../../../../api/capital_equipment_log";
import { DateTime } from "luxon";
import { TimelineEventData } from "../../../../api/timeline_events";

export type LogEditHistoryProps = {
  isOpen: boolean;
  onClose: () => void;
  capitalEquipmentLogId: number;
};

export const LogEditHistory = ({ isOpen, onClose, capitalEquipmentLogId }: LogEditHistoryProps) => {
  const { data: events, isLoading } =
    useGetCapitalEquipmentLogTimelineEvents(capitalEquipmentLogId);
  const [modalPage, setModalPage] = useState("main");
  const [selectedEvent, setSelectedEvent] = useState<TimelineEventData | null>(null);

  const goToPage = (page, event) => {
    setModalPage(page);
    setSelectedEvent(event);
  };

  const handleClose = () => {
    onClose();
    setModalPage("main");
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight="semibold">Edit History</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalPage === "main" ? (
            <Box maxH="xs" overflowY="auto">
              <Skeleton isLoaded={!isLoading}>
                {events && events.length > 0 ? (
                  events.map((event) => {
                    return (
                      <HStack
                        key={event.id}
                        w="100%"
                        justify="space-between"
                        borderBottom="1px"
                        borderColor="chakra-border-color"
                        _hover={{ bg: useColorModeValue("gray.50", "gray.700"), cursor: "pointer" }}
                        px={2}
                        py={3}
                        onClick={() => goToPage("details", event)}>
                        <Flex direction="column" gap={1}>
                          <Text fontWeight="semibold">
                            {event.created_at.toFormat("MMMM dd, yyyy HH:mm a")}
                          </Text>
                          <Stack direction="row">
                            <Avatar size="xs" src={event.user?.picture_uri} />
                            <Text>{event.user?.name}</Text>
                          </Stack>
                        </Flex>
                        <ChevronRightIcon />
                      </HStack>
                    );
                  })
                ) : (
                  <EmptyState title="No location history available." />
                )}
              </Skeleton>
            </Box>
          ) : (
            <Box width="100%">
              <HStack gap={2}>
                <ArrowBackIcon onClick={() => goToPage("main", event)} color="teal" boxSize="5" />
                <Text>{selectedEvent?.created_at?.toFormat("MMMM dd, yyyy HH:mm a")}</Text>
              </HStack>

              <HStack justifyContent="space-between" p={2}>
                <Text>Edited by:</Text>
                <HStack>
                  <Avatar size="xs" src={selectedEvent?.user?.picture_uri} />
                  <Text>{selectedEvent?.user?.name}</Text>
                </HStack>
              </HStack>
              <Divider />

              {selectedEvent?.event_data.maintenance_actionable_type === "Vendor" ? (
                <HStack justifyContent="space-between" p={2}>
                  <Text>Vendor:</Text>
                  <Text>{selectedEvent?.event_data.vendor_name}</Text>
                </HStack>
              ) : selectedEvent?.event_data.maintenance_actionable_type === "User" ? (
                <HStack justifyContent="space-between" p={2}>
                  <Text>User:</Text>
                  <HStack>
                    <Avatar size="xs" src={selectedEvent?.event_data.user.picture_uri} />
                    <Text>{selectedEvent?.event_data.user.name}</Text>
                  </HStack>
                </HStack>
              ) : null}

              <Divider />
              <VStack p={2} align="start" width="100%">
                <Text>Comment: </Text>
                <RichTextEditor
                  defaultValue={JSON.stringify(selectedEvent?.event_data.notes)}
                  showToolbar={false}
                  width="100%"
                  border={false}
                  editable={false}
                />
              </VStack>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
