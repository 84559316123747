import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableDate,
  EditableFile,
  EditableSelect,
  EditableText,
  FileDownloadButton,
  RecordLink,
  StatusSelect,
} from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import { DateTime } from "luxon";
import React, { useCallback, useState } from "react";
import { MdOutlineStorefront } from "react-icons/md";
import { useDeleteFile, useUploadFile } from "../../../../api/blob_files";
import { useSitesOptions, useVendorOptions } from "../../../../api/options";
import {
  invalidatePurchases,
  PurchaseShowData,
  ShippingPriority,
  useUpdatePurchase,
  useUpdatePurchaseCurrency,
} from "../../../../api/purchase";
import { UserLoggedInData } from "../../../../api/user";
import { BlobUploadButton } from "../../../shared/BlobUploadButton";
import { DeleteableFileDownload } from "../../../shared/DeleteableFileDownload";
import { isShippingPriority, shippingPriorityOptions } from "../util";
import { UpdateCurrencyButton } from "../../../UpdateCurrencyButton";

const determineCarrier = (trackingNumber) => {
  if (/^1Z[0-9A-Z]{16}$/.test(trackingNumber)) {
    return "https://www.ups.com/track?tracknum=";
  }
  if (/^\d{12}$/.test(trackingNumber) || /^96\d{13}$/.test(trackingNumber)) {
    return "https://www.fedex.com/apps/fedextrack/?tracknumbers=";
  }
  if (/^\d{20,22}$/.test(trackingNumber) || /^[A-Z]{2}\d{9}US$/.test(trackingNumber)) {
    return "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=";
  }
  if (/^\d{10}$/.test(trackingNumber)) {
    return "https://www.dhl.com/en/express/tracking.html?AWB=";
  }
  return "https://www.google.com/search?q=";
};

type PurchaseAttributesTableProps = {
  purchase: PurchaseShowData;
  isPurchasingAdmin?: boolean;
  currentUser?: UserLoggedInData;
};

export const PurchaseAttributeTable = ({
  purchase,
  isPurchasingAdmin,
  currentUser,
}: PurchaseAttributesTableProps) => {
  const [targetField, setTargetField] = useState<string>("");
  const { mutateAsync: updatePurchase, isLoading: isLoadingUpdate } = useUpdatePurchase(() =>
    invalidatePurchases(purchase.id)
  );

  const { mutateAsync: updatePurchaseCurrency, isLoading: isLoadingUpdateCurrency } =
    useUpdatePurchaseCurrency(() => invalidatePurchases(purchase.id));

  const isLoading = isLoadingUpdate || isLoadingUpdateCurrency;

  const siteOptions = useSitesOptions();
  const vendorOptions = useVendorOptions();
  const toast = useToast();

  const approvalChain = purchase.approval_chain || [];
  const userInApprovalChain = approvalChain.find((chain) =>
    chain.leads.some((ele) => ele.id === currentUser?.id)
  );

  const { mutate: uploadFile } = useUploadFile(() => invalidatePurchases(purchase?.id));
  const { mutate: deleteFile } = useDeleteFile(() => invalidatePurchases(purchase?.id));

  const showErrorForField = useCallback(
    (fieldName: string) => {
      return (error) => {
        let description = "An unknown error occurred while updating the field";
        console.error(error);
        try {
          description = error.response.data.errors.join("\n");
        } finally {
          toast({
            status: "error",
            title: `Error updating ${fieldName}`,
            description: description,
          });
        }
      };
    },
    [toast]
  );

  const editable =
    isPurchasingAdmin ||
    (currentUser?.id === purchase.user.id && !purchase.is_deleted) ||
    purchase.can_act_as_lead;

  const canEditCurrency = isPurchasingAdmin || (!purchase.fully_approved && editable);

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.700")}
      borderRadius="md"
      maxWidth={"100%"}
      border="1px"
      borderColor="chakra-border-color"
      overflow="auto"
      p={4}>
      <AttributesTable
        title="Purchase Details"
        attributes={
          [
            {
              label: "Requested By",
              value: (
                <Link href={`/users/${purchase.user.id}`}>
                  <HStack>
                    <Avatar src={purchase.user.name} name={purchase.user.name} size="sm" />
                    <Text fontWeight="semibold" fontSize="sm">
                      {purchase?.user.name}
                    </Text>
                  </HStack>
                </Link>
              ),
            },
            {
              label: "Currency",
              value: (
                <UpdateCurrencyButton
                  total={purchase.line_items_sum}
                  title="Update Currency"
                  model={"Purchase"}
                  isDisabled={!canEditCurrency}
                  previousCurrency={purchase.currency}
                  isLoading={isLoadingUpdateCurrency}
                  handleSubmit={(newCurrency: string, onClose) => {
                    updatePurchaseCurrency({ id: purchase.id, currency: newCurrency })
                      .then(onClose)
                      .catch(showErrorForField("Currency"));
                  }}
                />
              ),
            },
            {
              label: "Shipping Destination",
              value: (
                <EditableSelect
                  options={siteOptions}
                  selectedValue={purchase?.site_id?.toString()}
                  onSubmit={(value) => {
                    value &&
                      updatePurchase({
                        id: purchase.id,
                        site_id: value as number,
                      }).catch(showErrorForField("Shipping Destination"));
                  }}
                  disabled={!editable}
                />
              ),
            },
            {
              label: "Vendor",
              value: (
                <EditableSelect
                  preview={
                    purchase.vendor &&
                    (purchase.vendor.status === "approved" ? (
                      <Tooltip
                        label={titleize(humanize(purchase.vendor.payment_terms || "N/A"))}
                        closeDelay={500}>
                        <Box>
                          <RecordLink
                            maxWidth="15ch"
                            type=""
                            identifier={purchase.vendor?.name}
                            link={`/services/vendors/${purchase.vendor?.id}`}
                            icon={
                              <Box ml={3} mt={1}>
                                <Icon as={MdOutlineStorefront} />
                              </Box>
                            }
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip label="Vendor is pending approval" closeDelay={500}>
                        <Box bg={useColorModeValue("red.50", "")} borderRadius="md">
                          <RecordLink
                            maxWidth="15ch"
                            buttonVariant={"outline"}
                            type=""
                            colorScheme="red"
                            rightEl={
                              <Flex>
                                <WarningTwoIcon ml={2} />
                              </Flex>
                            }
                            identifier={purchase.vendor?.name}
                            link={`/services/vendors/${purchase.vendor?.id}`}
                            icon={
                              <Box ml={3} mt={1}>
                                <Icon as={MdOutlineStorefront} />
                              </Box>
                            }
                          />
                        </Box>
                      </Tooltip>
                    ))
                  }
                  options={vendorOptions || []}
                  selectedValue={purchase.vendor?.id.toString() || undefined}
                  onSubmit={(value) => {
                    value &&
                      updatePurchase({ id: purchase?.id, vendor_id: +value }).catch(
                        showErrorForField("Vendor")
                      );
                  }}
                  disabled={!editable}
                />
              ),
            },
            {
              label: "Request Type",
              value: (
                <EditableSelect
                  options={[
                    { label: "Purchase Request", value: "purchase_request" },
                    { label: "Purchase Order", value: "purchase_order" },
                  ]}
                  selectedValue={purchase.purchase_type || undefined}
                  disabled={!editable}
                  onSubmit={(value) => {
                    setTargetField("request_type");
                    value &&
                      updatePurchase({ id: purchase.id, purchase_type: value.toString() }).catch(
                        showErrorForField("Request Type")
                      );
                  }}
                />
              ),
            },
            {
              label: "Order Number",
              value: (
                <Box style={{ maxWidth: "100%", whiteSpace: "normal", wordBreak: "break-word" }}>
                  <EditableText
                    defaultValue={purchase.order_number || ""}
                    onSubmit={(value) => {
                      setTargetField("order_number");
                      updatePurchase({ id: purchase.id, order_number: value }).catch(
                        showErrorForField("Order Number")
                      );
                    }}
                    isLoading={isLoading && targetField === "order_number"}
                    persistentEdit={isLoading && targetField === "order_number"}
                    disabled={isLoading || !editable}
                  />
                </Box>
              ),
            },
            {
              label: "Tracking Number",
              value: (
                <Box style={{ maxWidth: "100%", whiteSpace: "normal", wordBreak: "break-word" }}>
                  <EditableText
                    defaultValue={purchase.tracking_number || undefined}
                    preview={
                      purchase.tracking_number && (
                        <Link
                          href={`${determineCarrier(purchase.tracking_number)}${
                            purchase.tracking_number
                          }`}
                          isExternal>
                          {purchase.tracking_number?.length > 20
                            ? `${purchase.tracking_number.slice(0, 20)}...`
                            : purchase.tracking_number}
                        </Link>
                      )
                    }
                    onSubmit={(value) => {
                      setTargetField("tracking_number");
                      updatePurchase({ id: purchase.id, tracking_number: value }).catch(
                        showErrorForField("Tracking Number")
                      );
                    }}
                    isLoading={isLoading && targetField === "tracking_number"}
                    persistentEdit={isLoading && targetField === "tracking_number"}
                    disabled={isLoading || !editable}
                  />
                </Box>
              ),
            },
            {
              label: "Shipping Priority",
              value: (
                <EditableSelect
                  size="sm"
                  options={shippingPriorityOptions}
                  selectedValue={purchase.shipping_priority}
                  onSubmit={(value) => {
                    if (isShippingPriority(value)) {
                      updatePurchase({
                        id: purchase?.id,
                        shipping_priority: value as ShippingPriority,
                      }).catch(showErrorForField("Shipping Priority"));
                    }
                  }}
                  disabled={!editable}
                />
              ),
            },
            {
              label: "Estimated Ship Date",
              value: (
                <EditableDate
                  defaultValue={purchase.estimated_ship_date || ""}
                  preview={
                    purchase.estimated_ship_date ? (
                      <Text>
                        {DateTime.fromISO(purchase.estimated_ship_date).toFormat("LLL dd yyyy")}
                      </Text>
                    ) : undefined
                  }
                  onSubmit={(value) => {
                    setTargetField("ship_date");
                    updatePurchase({ id: purchase.id, estimated_ship_date: value }).catch(
                      showErrorForField("Estimated Ship Date")
                    );
                  }}
                  isLoading={isLoading && targetField === "ship_date"}
                  persistentEdit={isLoading && targetField === "ship_date"}
                  disabled={isLoading || !editable}
                />
              ),
            },
            {
              label: "Purchase Order",
              value: (
                <VStack align="start" maxWidth="100%">
                  {purchase.uploaded_purchase_orders.map((file) => (
                    <DeleteableFileDownload
                      key={file.id}
                      file={file}
                      deleteFile={deleteFile}
                      maxChar={20}
                      isDisabled={!isPurchasingAdmin}
                    />
                  ))}
                  <BlobUploadButton
                    recordId={purchase.id}
                    recordType="Purchase"
                    fileableColumn="uploaded_purchase_orders"
                    onSuccessCallback={() => invalidatePurchases(purchase.id)}
                    isDisabled={!isPurchasingAdmin}
                  />
                </VStack>
              ),
            },
            {
              label: "Invoice",
              value: (
                <VStack align="start" maxWidth="100%">
                  {purchase.uploaded_invoices.map((file) => (
                    <DeleteableFileDownload
                      key={file.id}
                      file={file}
                      deleteFile={deleteFile}
                      maxChar={20}
                      isDisabled={!isPurchasingAdmin}
                    />
                  ))}
                  <BlobUploadButton
                    recordId={purchase.id}
                    recordType="Purchase"
                    fileableColumn="uploaded_invoices"
                    onSuccessCallback={() => invalidatePurchases(purchase.id)}
                    isDisabled={!isPurchasingAdmin}
                  />
                </VStack>
              ),
            },
            {
              label: "Receipt",
              value: (
                <Box style={{ maxWidth: "100%", whiteSpace: "normal", wordBreak: "break-word" }}>
                  <EditableFile
                    onSubmit={(value) => {
                      setTargetField("receipt");
                      if (value) {
                        uploadFile(
                          {
                            content_data_uri: value?.file,
                            filename: value?.filename,
                            fileable_id: purchase.id,
                            fileable_type: "Purchase",
                            fileable_field: "uploaded_receipt",
                          },
                          { onError: showErrorForField("Receipt") }
                        );
                      }
                    }}
                    disabled={!isPurchasingAdmin}
                    isLoading={targetField === "packing_slip" && isLoading}
                    preview={
                      purchase.uploaded_receipt && (
                        <FileDownloadButton
                          file={purchase.uploaded_receipt.app_href}
                          filename={purchase.uploaded_receipt.filename}
                          maxChars={20}
                        />
                      )
                    }
                  />
                </Box>
              ),
            },
            {
              label: "Packing Slips",
              value: (
                <VStack align="start" maxWidth="100%">
                  {purchase.uploaded_packing_slips.map((file) => (
                    <DeleteableFileDownload
                      key={file.id}
                      file={file}
                      deleteFile={deleteFile}
                      maxChar={20}
                      isDisabled={!isPurchasingAdmin}
                    />
                  ))}
                  <BlobUploadButton
                    recordId={purchase.id}
                    recordType="Purchase"
                    fileableColumn="uploaded_packing_slips"
                    onSuccessCallback={() => invalidatePurchases(purchase.id)}
                    isDisabled={!isPurchasingAdmin}
                  />
                </VStack>
              ),
            },
            (isPurchasingAdmin || (userInApprovalChain && currentUser?.top_level_team_lead)) && {
              label: "Visibility",
              value: (
                <StatusSelect
                  options={[
                    { label: "Public", value: "public" },
                    { label: "Private", value: "private" },
                  ]}
                  variant="tag"
                  status={
                    purchase.is_private
                      ? { label: "Private", value: "private", color: "blue" }
                      : { label: "Public", value: "public", color: "red" }
                  }
                  onSubmit={(value) => {
                    updatePurchase({ id: purchase.id, is_private: value === "private" }).catch(
                      showErrorForField("Visibility")
                    );
                  }}
                  isDisabled={!editable}
                />
              ),
            },
          ].filter(Boolean) as { label: string; value: React.ReactNode }[]
        }
      />
    </Box>
  );
};
