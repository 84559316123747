import { AttributesTable, EditableSelect, Option } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import {
  Box,
  Heading,
  IconButton,
  Stack,
  Tag,
  useDisclosure,
  HStack,
  VStack,
  Text,
  Flex,
  LinkBox,
  Avatar,
  useColorModeValue,
  Tooltip,
  AlertIcon,
  Alert,
  Button,
} from "@chakra-ui/react";
import {
  CapitalEquipmentShowData,
  useGetCapitalEquipmentUsers,
  useUpdateCapitalEquipment,
} from "../../../../api/capital_equipment";
import { Switch } from "@chakra-ui/react";
import { MaintenanceLogs } from "./MaintenanceLogs";
import { capitalize, humanize } from "inflection";
import { EditIcon } from "@chakra-ui/icons";
import { ReminderModal } from "./ReminderModal";
import { ScheduledDate } from "../ScheduledDate";
import { Link as RouterLink } from "react-router-dom";
import { EditRecipientsModal } from "../DetailsPage/EditRecipients";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";
import { useDeleteCapitalEquipmentUser } from "../../../../api/capital_equipment_user";

export type MaintenanceTabProps = {
  capitalEquipment: CapitalEquipmentShowData;
};

export const MaintenanceTab = ({ capitalEquipment }: MaintenanceTabProps) => {
  const { mutate: updateTool, isLoading: isLoadingUpdateTool } = useUpdateCapitalEquipment(
    Number(capitalEquipment.id)
  );
  const { mutate: deleteEquipmentUser, isLoading } = useDeleteCapitalEquipmentUser(
    Number(capitalEquipment.id)
  );

  const options = [
    "none",
    "weekly",
    "monthly",
    "quarterly",
    "semi-annually",
    "annually",
    "bi-annually",
  ];
  const maintenanceAndCalibrationOptions: Option[] = options.map((option) => ({
    label: capitalize(option),
    value: option,
  }));

  const bg = `linear-gradient(to right, var(--chakra-colors-transparent) 0, var(--chakra-colors-gray-${useColorModeValue(
    "50",
    "700"
  )}) 80%)`;

  const { data: capitalEquipmentUsers } = useGetCapitalEquipmentUsers(capitalEquipment.id);
  const {
    isOpen: isOpenEditRecipients,
    onOpen: onOpenEditRecipients,
    onClose: onCloseEditRecipients,
  } = useDisclosure();
  const {
    isOpen: isOpenReminderModal,
    onOpen: onOpenReminderModal,
    onClose: onCloseReminderModal,
  } = useDisclosure();

  return (
    <Stack direction={["column", "row"]}>
      <Box
        border="1px"
        borderRadius="md"
        borderColor="chakra-border-color"
        p={6}
        w={"100%"}
        height={"100%"}>
        <Heading size="md" mb={4} fontWeight="medium">
          Schedule
        </Heading>
        {capitalEquipment.next_maintenance_date && (
          <ScheduledDate
            type="maintenance"
            date={capitalEquipment.next_maintenance_date}
            overdue={capitalEquipment.is_maintenance_overdue}
          />
        )}
        {capitalEquipment.next_calibration_date && (
          <ScheduledDate
            type="calibration"
            date={capitalEquipment.next_calibration_date}
            overdue={capitalEquipment.is_calibration_overdue}
          />
        )}
        <AttributesTable
          attributes={[
            {
              label: "Maintenance Schedule",
              value: (
                <EditableSelect
                  options={maintenanceAndCalibrationOptions}
                  preview={humanize(capitalEquipment.maintenance_schedule ?? "")}
                  onSubmit={(value) => {
                    if (value) {
                      updateTool({
                        id: capitalEquipment.id,
                        maintenance_schedule: value.toString(),
                      });
                    }
                  }}
                />
              ),
            },
            {
              label: "Calibration Schedule",
              value: (
                <EditableSelect
                  options={maintenanceAndCalibrationOptions}
                  preview={humanize(capitalEquipment.calibration_schedule ?? "")}
                  onSubmit={(value) => {
                    if (value) {
                      updateTool({
                        id: capitalEquipment.id,
                        calibration_schedule: value.toString(),
                      });
                    }
                  }}
                />
              ),
            },
            {
              label: "Reminders",
              value: (
                <VStack align={"flex-start"}>
                  {!capitalEquipment.calibration_schedule &&
                    !capitalEquipment.maintenance_schedule && (
                      <Alert status="info" mb={2}>
                        <AlertIcon />
                        Set Maintenance or Calibration Schedule to enable reminders
                      </Alert>
                    )}
                  <HStack>
                    <Switch
                      isDisabled={
                        !capitalEquipment.calibration_schedule &&
                        !capitalEquipment.maintenance_schedule
                      }
                      size="md"
                      colorScheme="teal"
                      isChecked={capitalEquipment.reminders ?? false}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          onOpenReminderModal();
                        }
                        updateTool({
                          reminders: e.target.checked,
                          id: Number(capitalEquipment.id),
                        });
                      }}
                    />

                    {capitalEquipment.reminders && (
                      <Tag>{capitalEquipment.reminder_days_prior} day(s) before</Tag>
                    )}
                  </HStack>
                  {capitalEquipment.reminders && (
                    <Button
                      variant="link"
                      fontSize={"xs"}
                      color="teal"
                      onClick={() => onOpenReminderModal()}
                      cursor={"pointer"}>
                      Edit Reminder Time
                    </Button>
                  )}
                </VStack>
              ),
            },
            {
              label: "Reminder Recipients",
              value: (
                <HStack>
                  <Flex width="100%" position="relative" zIndex={0}>
                    <PreviewUserSelect
                      handleDelete={deleteEquipmentUser}
                      addedUsers={capitalEquipmentUsers ?? []}
                      isLoading={isLoading}
                      additionalUsersTitle="Recipients"
                      isDisabled={false}
                    />
                  </Flex>
                  <IconButton
                    icon={<EditIcon />}
                    isDisabled={!capitalEquipment.reminders}
                    onClick={onOpenEditRecipients}
                    size="xs"
                    aria-label="Add Reminder Recipient"
                  />
                </HStack>
              ),
            },
          ]}
        />
      </Box>
      <EditRecipientsModal
        isOpen={isOpenEditRecipients}
        onClose={onCloseEditRecipients}
        capitalEquipmentId={capitalEquipment.id}
        capitalEquipmentUsers={capitalEquipmentUsers ?? []}
      />
      <ReminderModal
        isOpen={isOpenReminderModal}
        onClose={onCloseReminderModal}
        capitalEquipmentId={capitalEquipment.id}
        days={capitalEquipment.reminder_days_prior ?? undefined}
      />
      <MaintenanceLogs capitalEquipment={capitalEquipment} />
    </Stack>
  );
};
