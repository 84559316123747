import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";
import { FiChevronRight } from "react-icons/fi";
import { UserLoggedInData } from "../../../api/user";
import { EmptyState } from "@sciencecorp/helix-components";

interface PersonalProjectListProps {
  userData: UserLoggedInData;
}

export const PersonalProjectList: React.FC<PersonalProjectListProps> = ({ userData }) => {
  const hoverBackgroundColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box>
      {userData.projects.length > 0 ? (
        userData.projects.map((project) => (
          <LinkBox
            key={"project-" + project.id}
            _hover={{
              bg: hoverBackgroundColor,
              borderRadius: "md",
            }}
            px={4}
            mx={-4}>
            <LinkOverlay as={RouterLink} to={`/planning/projects/${project.id}`}>
              <HStack justify="space-between" align="center" py={4}>
                <Box>
                  <Heading size="sm" fontWeight="bold">
                    {project.title}
                  </Heading>
                </Box>
                <Spacer />
                <Icon as={FiChevronRight} />
              </HStack>
              <Divider size="lg" />
            </LinkOverlay>
          </LinkBox>
        ))
      ) : (
        <EmptyState title="No active affiliated projects" size="xs" />
      )}
    </Box>
  );
};
